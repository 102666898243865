/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CancelTokenSource } from 'axios';
import { useConfig } from '../context/ConfigContext';
import { IElement, IElementable } from '../models/Element';
import http from '../utils/axios-instance';

export interface ICreateQuestion {
    body: string;
    question_type_id: number;
    // element_id: number;
    card_id: number;
    options?: string;
    feedback_enabled?: boolean;
    is_correct_enabled?: boolean;
    timer_enabled: boolean;
}
export interface IUpdateQuestion {
    order: number;
    body: string;
    question_type_id: number;
    options?: string;
    feedback_enabled?: boolean;
    is_correct_enabled?: boolean;
    timer_enabled: boolean;
}

const useQuestionElementService = () => {
    const { config } = useConfig();
    const serviceUrl = `${config.baseUrl}/questions`;

    const getAll = (source?: CancelTokenSource) => {
        return http.get<IElementable[]>(`${serviceUrl}`, { cancelToken: source?.token });
    };

    const get = (id: number, source?: CancelTokenSource) => {
        return http.get<IElementable>(`${serviceUrl}/${id}`, { cancelToken: source?.token });
    };

    const create = (data: ICreateQuestion, source?: CancelTokenSource) => {
        return http.post<IElement>(`${serviceUrl}`, data, { cancelToken: source?.token });
    };

    const update = (id: number, data: IUpdateQuestion, source?: CancelTokenSource) => {
        return http.put<IElement>(`${serviceUrl}/${id}`, data, { cancelToken: source?.token });
    };

    const remove = (id: number, source?: CancelTokenSource) => {
        return http.delete(`${serviceUrl}/${id}`, { cancelToken: source?.token });
    };

    return {
        getAll,
        get,
        create,
        update,
        remove,
    };
};

export default useQuestionElementService;
