import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { isDesktop, isMobile } from 'react-device-detect';
import { useAuth } from '../../context/AuthContext';
import { Alert } from '@material-ui/lab';

// TODO: Warning created by issue: https://github.com/mui/material-ui/issues/13394
// Fixed with MUI v5 and React 18.

export default function AdminWarningMessage() {
    const { t } = useTranslation();
    const { hasPermission } = useAuth();
    const isDesktopFormatMobile = isDesktop && useMediaQuery('(max-width: 991px)');
    const show = hasPermission('library.manage') && (isMobile || isDesktopFormatMobile);

    const [open, setOpen] = React.useState(true);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <div>
            {show && (
                <Snackbar
                    open={open}
                    autoHideDuration={null}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert elevation={6} variant="filled" onClose={handleClose} severity="warning">
                        {isMobile && t('Common:ADMIN_MOBILE_WARNING_MESSAGE')}
                        {isDesktopFormatMobile && t('Common:ADMIN_DESKTOP_WARNING_MESSAGE')}
                    </Alert>
                </Snackbar>
            )}
        </div>
    );
}
