import { CancelTokenSource } from 'axios';
import { useConfig } from '../context/ConfigContext';
import { ICard } from '../models/Card';
import http from '../utils/axios-instance';

export interface ICardData {
    end_card?: boolean;
}

export interface ICreateCard {
    deck_id: number;
    title: string;
    end_card: boolean;
    prev_card_id?: number;
}

const useCardService = () => {
    const { config } = useConfig();
    const serviceUrl = `${config.baseUrl}/cards`;

    const create = (data: ICreateCard, source?: CancelTokenSource) => {
        return http.post<ICard>(`${serviceUrl}`, data, { cancelToken: source?.token });
    };

    const update = (id: number, data: ICardData, source?: CancelTokenSource) => {
        return http.put(`${serviceUrl}/${id}`, data, { cancelToken: source?.token });
    };

    return {
        create,
        update,
    };
};

export default useCardService;
