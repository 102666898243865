import React, { useCallback, useRef, useState } from 'react';
import { useFormikContext, Formik } from 'formik';
import classes from './Invite.module.scss';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Tags from '@yaireo/tagify/dist/react.tagify';
import RoleSelect from '../../../../components/RoleSelect/RoleSelect';
import GroupSelect from '../../../../components/GroupSelect/GroupSelect';
import ButtonLoadingIcon from '../../../../components/Icons/ButtonLoadingIcon/ButtonLoadingIcon';
import { useHistory } from 'react-router';
import Axios from '../../../../utils/axios-instance';
import { useAuth } from '../../../../context/AuthContext';
import Message from '../../../../components/Message/Message';
import { useConfig } from '../../../../context/ConfigContext';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import toast from 'react-hot-toast';
import { getColor } from '../../../../utils/colors';

const FormTags = () => {
    const { t } = useTranslation();
    // Grab values and submitForm from context
    const { errors, touched, setFieldValue } = useFormikContext();

    const tagifyRef = useRef();
    const defaultTags = '';

    const settings = {
        placeholder: 'email@example.com',
        delimiters: ',|;| ',
        editTags: {
            clicks: 1,
        },
        callbacks: {
            // invalid: onInvalidTag,
            // remove: onRemove,
        },
    };

    const setEmailsField = (value) => {
        if (value) {
            const emails = value.map((tag) => tag.value);
            setFieldValue('emails', emails);
        } else {
            setEmailsField('emails', []);
        }
    };

    const onChange = useCallback((e) => {
        setEmailsField(e.detail.tagify.value);
    }, []);

    return (
        <div className={classes.FormEmails}>
            <div className={classes.FormFieldHeader}>
                <h2 className={`h5 wrap-text ${classes.FormFieldTitle}`}>{t('Members:INVITE_EMAILS_TITLE')}</h2>
                <span className={`wrap-text ${classes.FormFieldSubtitle}`}>{t('Members:INVITE_EMAILS_SUBTITLE')}</span>
            </div>
            <Tags
                tagifyRef={tagifyRef}
                settings={settings}
                defaultValue={defaultTags}
                autoFocus={true}
                onChange={onChange}
            />
            {errors && touched && <div className={classes.errorMessage}>{errors.emails}</div>}
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Invite = () => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const history = useHistory();
    const { auth } = useAuth();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const buttonLoadingIconColor = getColor('--brand-color-10');

    const schema = Yup.object().shape({
        emails: Yup.array()
            .of(Yup.string().email(({ value }) => `${value}: ${t('Common:INPUT_ERROR_EMAIL_VALID')}\n`))
            .required(t('Common:INPUT_ERROR_EMAIL_REQUIRED')),
        roles: Yup.object()
            .shape({
                label: Yup.string().required('Required'),
                value: Yup.string().required('Required'),
            })
            .required(t('Members:INPUT_ERROR_ROLE_REQUIRED')),
        groups: Yup.array().of(Yup.string()).nullable(true),
    });

    const cancelHandler = () => {
        history.goBack();
    };

    const notifyInvitesSend = () => toast.success(t('Common:TOAST_INVITES_SEND_SUCCESS'));
    const notifyInvitesNotSend = () => toast.error(t('Common:TOAST_INVITES_SEND_ERROR'));

    const form = (
        <>
            <div className={classes.InviteForm}>
                <PageHeader title={t('Members:INVITE_HEADER')} subtitle={t('Members:INVITE_SUBTITLE')} />

                <Formik
                    validationSchema={schema}
                    initialValues={{ emails: [], groups: [] }}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={async (values, actions) => {
                        const inviteParams = {
                            invitees: values.emails.join(','),
                            inviter_id: auth.currentUser?.id.toString(),
                            suggested_roles: values.roles.value.toString(),
                            suggested_groups: values.groups.map((group) => group.value).join(','),
                        };

                        try {
                            await Axios.post(`${config.baseUrl}/invite/batch`, inviteParams, {
                                headers: { Authorization: `Bearer ${auth.currentUser?.token}` },
                            });

                            actions.resetForm();
                            setIsSubmitted(true);
                            setIsError(false);
                            actions.setSubmitting(false);

                            cancelHandler();
                            notifyInvitesSend();
                        } catch (error) {
                            setIsSubmitted(false);
                            setIsError(true);
                            actions.setSubmitting(false);

                            notifyInvitesNotSend();
                        }
                    }}
                >
                    {({ values, errors, touched, isValid, dirty, setFieldTouched, handleSubmit, isSubmitting }) => {
                        const disabledSubmit = isSubmitting || !(isValid && dirty);

                        return (
                            <form noValidate={true} onSubmit={handleSubmit}>
                                <div className={`Box ${classes.Form}`}>
                                    <FormTags />
                                    <RoleSelect
                                        name="roles"
                                        value={values.roles}
                                        error={errors.roles}
                                        touched={touched.roles}
                                        isMulti={false}
                                        onBlur={setFieldTouched}
                                    ></RoleSelect>
                                    <GroupSelect
                                        name="groups"
                                        value={values.groups}
                                        error={errors.groups}
                                        touched={touched.groups}
                                        isMulti={true}
                                        onBlur={setFieldTouched}
                                    ></GroupSelect>
                                </div>
                                <div className={`${classes.formActions} ${classes.flexGap}`}>
                                    <button
                                        className={`${classes.submitButton} btn btn-flavour btn-flavour--alt`}
                                        type="submit"
                                        disabled={disabledSubmit}
                                    >
                                        {isSubmitting && <ButtonLoadingIcon fill={buttonLoadingIconColor} />}
                                        {t('Members:INVITE_BUTTON_SEND')}
                                    </button>
                                    <button
                                        tabIndex={-1}
                                        type="button"
                                        className={`${classes.cancelButton} btn btn-flavour btn-flavour--alt-border`}
                                        onClick={cancelHandler}
                                    >
                                        {t('Common:BUTTON_CANCEL')}
                                    </button>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );

    const error = <Message text={t('Members:INVITE_ERROR_GENERAL')}></Message>;

    return <div className={classes.Invite}>{isSubmitted ? (isError ? error : form) : form}</div>;
};

export default Invite;
