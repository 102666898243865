import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Axios from 'axios';
import { Formik } from 'formik';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import ButtonLoadingIcon from '../../../components/Icons/ButtonLoadingIcon/ButtonLoadingIcon';
import InputField from '../../../components/InputField/InputField';
import Message from '../../../components/Message/Message';
import BoxHeader from '../../../components/UI/Box/BoxHeader/BoxHeader';
import { useConfig } from '../../../context/ConfigContext';
import { getFilePathByName, getFilePathForDefaultImage, getGlobalAssetsFilePathByName } from '../../../models/File';
import { Token, User } from '../../../models/User';
import AxiosInstance from '../../../utils/axios-instance';
import { getColor } from '../../../utils/colors';
import { setLocalStorageUser } from '../../../utils/flavourstorage';
import WelcomeBox from '../WelcomeBox/WelcomeBox';
import classes from './RegisterForm.module.scss';

export interface LoginResponse extends Token {
    user: User;
    token: string;
}

const LoginForm: FunctionComponent = () => {
    const { config, loading, loadingConfig } = useConfig();
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();
    const buttonLoadingIconColor = getColor('--brand-color-12');
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [defaultWelcome, setDefaultWelcome] = useState('');

    useEffect(() => {
        if (!loadingConfig) {
            const customFile = config.tenant.images['welcome.png']?.find((file) => file.is_default == false);
            const defaultFile = config.tenant.images['welcome.png']?.find((file) => file.is_default == true);
            setDefaultWelcome(
                getFilePathByName(customFile?.file?.name) || getFilePathForDefaultImage(defaultFile?.file?.name) || '',
            );
        }
    }, [loadingConfig]);

    const schema = Yup.object({
        name: Yup.string().required(t('Common:INPUT_ERROR_NAME_REQUIRED')),
        email: Yup.string()
            .email(t('Common:INPUT_ERROR_EMAIL_INVALID'))
            .required(t('Common:INPUT_ERROR_EMAIL_REQUIRED'))
            .max(255, t('Common:INPUT_ERROR_EMAIL_MAX', { max: 255 })),
        password: Yup.string()
            .required(t('Common:INPUT_ERROR_PASSWORD_REQUIRED'))
            .min(8, t('Common:INPUT_ERROR_PASSWORD_MIN', { min: 8 }))
            .max(255, t('Common:INPUT_ERROR_PASSWORD_MAX', { max: 80 })),
        // accept_conditions: Yup.boolean().oneOf([true], t('Common:INPUT_ERROR_TERMS_REQUIRED')),
    });

    const togglePasswordVisiblity = () => {
        setShowPassword(showPassword ? false : true);
    };

    const error = (
        <div className={classes.formFeedback}>
            <Message text={errorMessage}></Message>
        </div>
    );

    const newAccountVerify = (
        <div className={classes.NewAccountVerify}>
            <div className="Box">
                <BoxHeader
                    title={t('Auth:ACCOUNT_VERIFY_TITLE')}
                    subtitle={t('Auth:ACCOUNT_VERIFY_SUBTITLE')}
                ></BoxHeader>
                <p>{t('Auth:ACCOUNT_VERIFY_DESCRIPTION')}</p>
            </div>
        </div>
    );

    // const newAccountActivated = (
    //     <div className="Box">
    //         <BoxHeader
    //             title={t('Auth:ACCOUNT_ACTIVATED_TITLE')}
    //             subtitle={t('Auth:ACCOUNT_ACTIVATED_SUBTITLE')}
    //         ></BoxHeader>
    //         <p>
    //             {t('Auth:RETURN_TO_LOGIN')}
    //         </p>
    //     </div>
    // );

    const conditionsLink = (
        <a
            className={classes.AcceptTermsLink}
            href={getGlobalAssetsFilePathByName('AlgemeneVoorwaarden.pdf')}
            target="_blank"
            rel="noreferrer"
        >
            {t('Conditions:LINK_TEXT')}
        </a>
    );

    const privacyLink = (
        <a
            className={classes.PrivacyLink}
            href={getGlobalAssetsFilePathByName('PrivacyVerklaring.pdf')}
            target="_blank"
            rel="noreferrer"
        >
            {t('Conditions:PRIVACY_LINK_TEXT')}
        </a>
    );

    const notifyAccountCreated = () => toast.success(t('Common:TOAST_ACCOUNT_CREATED_SUCCESS'));
    const notifyAccountNotCreated = () => toast.error(t('Common:TOAST_ACCOUNT_CREATED_ERROR'));

    return (
        <Fragment>
            {isSubmitted ? (
                newAccountVerify
            ) : (
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        password_confirmation: '',
                        name: '',
                    }}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting }) => {
                        values.password_confirmation = values.password; // TODO: maybe dont require on backend?
                        try {
                            const response = await AxiosInstance.post(`${config.baseUrl}/register`, values);

                            setSubmitting(false);
                            setIsSubmitted(true);
                            setIsError(false);

                            const loginResponse: LoginResponse = response.data;

                            if (loginResponse) {
                                notifyAccountCreated();

                                const user: User = {
                                    ...loginResponse.user,
                                    token: loginResponse.token,
                                };

                                setLocalStorageUser(user);
                            }
                        } catch (error) {
                            notifyAccountNotCreated();

                            setSubmitting(false);
                            setIsSubmitted(false);
                            setIsError(true);

                            setErrorMessage(t('Auth:VALIDATION_OTHER'));
                        }
                    }}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            isValid,
                            dirty,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props;

                        const nameErrorClassName = !!errors.name && !!touched.name ? classes.error : '';
                        const emailErrorClassName = !!errors.email && !!touched.email ? classes.error : '';
                        const passwordErrorClassName = !!errors.password && !!touched.password ? classes.error : '';
                        const disabledSubmit = isSubmitting || !(isValid && dirty);

                        return (
                            <form className={classes.Form} noValidate={true} onSubmit={handleSubmit}>
                                <div className={`${classes.Register} Box`}>
                                    <img src={defaultWelcome} alt="loginImage" />
                                    <div className={classes.RegisterContent}>
                                        <WelcomeBox
                                            title={t('Login:REGISTER_WELCOME')}
                                            text={t('Login:REGISTER_INTRODUCTION')}
                                            callForAction={t('Login:REGISTER_CALL_TO_ACTION')}
                                        ></WelcomeBox>

                                        <div className={`${classes.RegisterForm}`}>
                                            <div className={classes.formGroup}>
                                                <InputField
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    label={t('Common:EMAIL_LABEL')}
                                                    autoComplete="username"
                                                    required
                                                    autoFocus
                                                    autoCorrect="off"
                                                    autoCapitalize="none"
                                                    placeholder={t('Common:EMAIL_PLACEHOLDER')}
                                                />
                                            </div>

                                            <div className={classes.formGroup}>
                                                <div className={classes.inputWrapper}>
                                                    <InputField
                                                        id="new-password"
                                                        name="password"
                                                        type="password"
                                                        label={t('Common:PASSWORD_LABEL')}
                                                        autoComplete="new-password"
                                                        required
                                                        aria-describedby="password-constraints"
                                                        placeholder={t('Auth:PASSWORD_EXAMPLE_PLACEHOLDER')}
                                                    />
                                                </div>
                                                <div id="password-constraints" className={classes.passwordHint}>
                                                    {t('Auth:PASSWORD_RESET_PASSWORD_HINT')}
                                                </div>
                                            </div>

                                            <div className={classes.formGroup}>
                                                <InputField
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    label={t('Common:NAME_LABEL')}
                                                    autoComplete="name"
                                                    required
                                                    placeholder={t('Common:NAME_PLACEHOLDER')}
                                                />
                                            </div>

                                            {isError && error}

                                            <button
                                                className={`${classes.submitButton} btn btn-flavour btn-flavour--alert`}
                                                type="submit"
                                                disabled={disabledSubmit}
                                            >
                                                {isSubmitting && <ButtonLoadingIcon fill={buttonLoadingIconColor} />}
                                                {t('Login:REGISTER_BUTTON_REGISTER')}
                                            </button>

                                            <NavLink className={`${classes.NavLink}`} to="/login">
                                                {t('Login:REGISTER_BUTTON_GO_TO_LOGIN')}
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${classes.formActions}`}>
                                    <div className={classes.AcceptTerms}>
                                        {`${t('Conditions:TEXT_PART1')} `}
                                        {conditionsLink}
                                        {` ${t('Conditions:TEXT_PART2')} `}
                                        {privacyLink}.
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            )}
        </Fragment>
    );
};

export default LoginForm;
