import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { Formik } from 'formik';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';
import ButtonLoadingIcon from '../../../components/Icons/ButtonLoadingIcon/ButtonLoadingIcon';
import InputField from '../../../components/InputField/InputField';
import BoxHeader from '../../../components/UI/Box/BoxHeader/BoxHeader';
import CountDown from '../../../components/UI/CountDown/CountDown';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigContext';
import { getFilePathByName, getFilePathForDefaultImage, getGlobalAssetsFilePathByName } from '../../../models/File';
import { Invite } from '../../../models/InvitedUser';
import { User } from '../../../models/User';
import useInviteService from '../../../services/InviteService';
import http from '../../../utils/axios-instance';
import { getColor } from '../../../utils/colors';
import { setLocalStorageUser } from '../../../utils/flavourstorage';
import { LoginResponse } from '../../Login/LoginForm/LoginForm';
import WelcomeBox from '../../Login/WelcomeBox/WelcomeBox';
import classes from './InviteAccept.module.scss';

type ParamTypes = {
    token: string;
};

const InviteAccept: FunctionComponent = () => {
    const { config, loading: loadingConfig } = useConfig();
    const history = useHistory();
    const { autoLogin } = useAuth();
    const { token } = useParams<ParamTypes>();
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [invite, setInvite] = useState<Invite>();
    const { get } = useInviteService();
    const { t } = useTranslation();
    const buttonLoadingIconColor = getColor('--brand-color-10');

    const [defaultWelcome, setDefaultWelcome] = useState('');

    useEffect(() => {
        if (!loadingConfig) {
            const customFile = config.tenant.images['welcome.png']?.find((file) => file.is_default == false);
            const defaultFile = config.tenant.images['welcome.png']?.find((file) => file.is_default == true);
            setDefaultWelcome(
                getFilePathByName(customFile?.file?.name) || getFilePathForDefaultImage(defaultFile?.file?.name) || '',
            );
        }
    }, [loadingConfig]);

    const schema = Yup.object({
        password: Yup.string()
            .required(t('Common:INPUT_ERROR_PASSWORD_REQUIRED'))
            .min(8, t('Common:INPUT_ERROR_PASSWORD_MIN', { min: 8 }))
            .max(255, t('Common:INPUT_ERROR_PASSWORD_MAX', { max: 80 })),
        name: Yup.string().required(t('Common:INPUT_ERROR_NAME_REQUIRED')),
        // accept_conditions: Yup.boolean().oneOf([true], t('Common:INPUT_ERROR_TERMS_REQUIRED')),
    });

    const togglePasswordVisiblity = () => {
        setShowPassword(showPassword ? false : true);
    };

    const toLogin = () => {
        history.replace('/login');
    };

    useEffect(() => {
        let mounted = true;
        setLoading(true);
        const source = Axios.CancelToken.source();

        get(token, source).then((response) => {
            if (mounted) {
                setLoading(false);

                const data: Invite = response.data;
                setInvite(data);
            }
        });

        return () => {
            source.cancel();
            mounted = false;
        };
    }, [token]);

    const newAccountActivated = (
        <Fragment>
            <BoxHeader
                title={t('Auth:ACCOUNT_ACTIVATED_TITLE')}
                subtitle={t('Auth:ACCOUNT_ACTIVATED_SUBTITLE')}
            ></BoxHeader>
            <p>
                {t('Auth:RETURN_TO_LOGIN')} <CountDown seconds={3} callbackHandler={toLogin}></CountDown>
            </p>
        </Fragment>
    );

    const conditionsLink = (
        <a
            className={classes.AcceptTermsLink}
            href={getGlobalAssetsFilePathByName('AlgemeneVoorwaarden.pdf')}
            target="_blank"
            rel="noreferrer"
        >
            {t('Conditions:LINK_TEXT')}
        </a>
    );

    const privacyLink = (
        <a
            className={classes.PrivacyLink}
            href={getGlobalAssetsFilePathByName('PrivacyVerklaring.pdf')}
            target="_blank"
            rel="noreferrer"
        >
            {t('Conditions:PRIVACY_LINK_TEXT')}
        </a>
    );

    const notifyAccountCreated = () => toast.success(t('Common:TOAST_ACCOUNT_CREATED_SUCCESS'));
    const notifyAccountNotCreated = () => toast.error(t('Common:TOAST_ACCOUNT_CREATED_ERROR'));

    return (
        <div className={classes.InviteAccept}>
            {loading && (
                <div className={classes.Loader}>
                    <div className="spinner card Box"></div>
                </div>
            )}

            {!loading && invite && (
                <Fragment>
                    {isSubmitted ? (
                        newAccountActivated
                    ) : (
                        <Formik
                            initialValues={{
                                email: invite?.email || '',
                                password: '',
                                password_confirmation: '',
                                name: invite?.email.substring(0, invite?.email.lastIndexOf('@')) || '',
                                token: token,
                                // accept_conditions: false,
                            }}
                            validationSchema={schema}
                            onSubmit={async (values, { setSubmitting }) => {
                                try {
                                    const response = await http.post(`${config.baseUrl}/invite/accept`, values);

                                    setSubmitting(false);
                                    setIsSubmitted(true);
                                    setIsError(false);

                                    const loginResponse: LoginResponse = response.data;

                                    if (loginResponse) {
                                        notifyAccountCreated();

                                        const user: User = {
                                            ...loginResponse.user,
                                            token: loginResponse.token,
                                        };

                                        setLocalStorageUser(user);
                                        autoLogin(user);

                                        http.defaults.headers = {
                                            Authorization: `Bearer ${loginResponse.token}`,
                                        };

                                        history.push('/home');
                                    }
                                } catch (error) {
                                    notifyAccountNotCreated();

                                    setSubmitting(false);
                                    setIsSubmitted(false);
                                    setIsError(true);
                                }
                            }}
                        >
                            {(formikProps) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    isSubmitting,
                                    isValid,
                                    dirty,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = formikProps;

                                const nameErrorClassName = !!errors.name && !!touched.name ? classes.error : '';
                                const passwordErrorClassName =
                                    !!errors.password && !!touched.password ? classes.error : '';
                                // const passwordConfirmationErrorClassName =
                                //     !!errors.password_confirmation && !!touched.password_confirmation ? classes.error : '';

                                const disabledSubmit = isSubmitting || !(isValid && dirty);

                                return (
                                    <form className={classes.form} noValidate={true} onSubmit={handleSubmit}>
                                        <div className={`${classes.InviteAcceptForm} Box`}>
                                            <img src={defaultWelcome} alt="loginImage" />
                                            <div className={classes.Content}>
                                                <WelcomeBox
                                                    title={t('Auth:ACCEPT_INVITE_WELCOME')}
                                                    text={t('Auth:ACCEPT_INVITE_INTRODUCTION')}
                                                    callForAction={t('Auth:ACCEPT_INVITE_CALL_TO_ACTION')}
                                                ></WelcomeBox>

                                                <div className={`${classes.Form}`}>
                                                    <div className={classes.formGroup}>
                                                        <label htmlFor="email">{t('Common:EMAIL_LABEL')}</label>
                                                        <div className={classes.inputWrapper}>
                                                            <InputField
                                                                id="email"
                                                                name="email"
                                                                type="email"
                                                                label={t('Common:EMAIL_LABEL')}
                                                                autoComplete="email"
                                                                disabled
                                                                // readOnly
                                                                placeholder={t('Common:EMAIL_PLACEHOLDER')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classes.formGroup}>
                                                        <label htmlFor="password">{t('Common:PASSWORD')}</label>
                                                        <div className={classes.inputWrapper}>
                                                            <InputField
                                                                id="new-password"
                                                                name="password"
                                                                label={t('Common:PASSWORD_LABEL')}
                                                                type="password"
                                                                autoComplete="new-password"
                                                                autoFocus
                                                                required
                                                                aria-describedby="password-constraints"
                                                                placeholder={t('Auth:PASSWORD_EXAMPLE_PLACEHOLDER')}
                                                            />
                                                        </div>
                                                        <div id="password-constraints" className={classes.passwordHint}>
                                                            {t('Auth:PASSWORD_RESET_PASSWORD_HINT')}
                                                        </div>
                                                    </div>
                                                    <div className={classes.formGroup}>
                                                        <label htmlFor="name">{t('Common:NAME_LABEL')}</label>
                                                        <div className={classes.inputWrapper}>
                                                            <InputField
                                                                id="name"
                                                                name="name"
                                                                type="text"
                                                                label={t('Common:NAME_LABEL')}
                                                                autoComplete="name"
                                                                required
                                                                placeholder={t('Common:NAME_PLACEHOLDER')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${classes.formActions}`}>
                                            {isError && (
                                                <div className={classes.formFeedback}>
                                                    {t('Auth:ACTIVATE_ACCOUNT_GENERAL_ERROR')}
                                                </div>
                                            )}
                                            <div className={classes.AcceptTerms}>
                                                {`${t('Conditions:TEXT_PART1')} `}
                                                {conditionsLink}
                                                {` ${t('Conditions:TEXT_PART2')} `}
                                                {privacyLink}.
                                            </div>
                                            <button
                                                className={`${classes.submitButton} btn btn-flavour btn-flavour--alt`}
                                                type="submit"
                                                disabled={disabledSubmit}
                                            >
                                                {isSubmitting && <ButtonLoadingIcon fill={buttonLoadingIconColor} />}
                                                {t('Auth:BUTTON_ACTIVATE_ACCOUNT')}
                                            </button>
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default InviteAccept;
