import { FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { ICard } from '../../models/Card';
import useCardService from '../../services/CardService';
export interface FormFields {
    title: string;
    end_card: boolean;
}

export type ReturnType = {
    handleSubmit: (card: ICard, formFields: FormFields, actions: FormikHelpers<FormFields>) => Promise<void>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
};

const useCardForm = (initialValues: FormFields): ReturnType => {
    const [isSaved, setIsSaved] = useState(!!initialValues.title);
    const { update } = useCardService();
    const handleSubmit = useCallback((card: ICard, formFields: FormFields, actions: FormikHelpers<FormFields>) => {
        return update(card.id, { ...formFields }).then(() => {
            setIsSaved(true);
            actions.resetForm({ values: formFields });
        });
    }, []);

    return {
        handleSubmit,
        initialValues,
        isSaved,
        setIsSaved,
    };
};

export default useCardForm;
