import { getSubdomain } from './Info';

const getOriginUrl = (): string => {
    const currentURL = window.location.href;
    return currentURL;
};

const getBaseUrl = (): string => {
    const tenant = getSubdomain();
    return addTenantToBaseUrl(tenant);
};

const addTenantToBaseUrl = (tenant: string): string => {
    const urlPartSubdomain = tenant != '' ? tenant : '';
    return `/api/${urlPartSubdomain}`;
};

export default {
    addTenantToBaseUrl,
    getBaseUrl,
    getOriginUrl,
};
