import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/PageHeader/PageHeader';
import { CategoryProvider } from '../../context/CategoryProvider';
import classes from './Home.module.scss';
import Quests from './Quests/Quests';
import Welcome from './Welcome/Welcome';

const Home: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <div className={classes.Home}>
            <div className={classes.PageHeaderWrapper}>
                <PageHeader title={t('Common:HOMEPAGE_TITLE')} subtitle="" />
            </div>

            <Welcome />
            <Quests />
        </div>
    );
};

export default Home;
