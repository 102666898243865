import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Logo from '../../components/Logo/Logo';
import classes from './ErrorPage.module.scss';

export interface Props {
    statusCode: number;
}

const ErrorPage: FunctionComponent<Props> = ({ statusCode }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const goBackHandler = () => {
        history.push('/');
        history.replace(location.pathname);
    };

    return (
        <div className={classes.ErrorPage}>
            <h4>{statusCode}</h4>
            <h1>{t(`Common:ERROR_STATUSCODE_${statusCode}`)}</h1>
            <Logo></Logo>
            <figure className={classes.Quote}>
                {/* <p>{t(`Common:ERROR_MESSAGE_QUOTE_START`)}</p> */}
                <q className="h2">A hero is anyone making this a better place for all people.</q>
                <figcaption>Maya Angelou</figcaption>
            </figure>

            {/* <Button text={t('Common:BUTTON_BACK')} alt={true} border={true} onClick={goBackHandler}></Button> */}
        </div>
    );
};

export default ErrorPage;
