import React, { FunctionComponent } from 'react';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import { IFile } from '../../models/File';
import FileUploader from '../FileUploader/FileUploader';
import classes from './CoverImageUpload.module.scss';
import { BackgroundSize, SelectOption } from '../../models/ImageUpload';

type ImageUploadProps = {
    originalFile?: IFile | null;
    defaultBackgroundSize?: BackgroundSize;
    onChange: (file: IFile | undefined) => void;
    onChangeBackgroundSize: (option: SelectOption | null) => void;
};

const CoverImageUpload: FunctionComponent<ImageUploadProps> = ({
    originalFile,
    defaultBackgroundSize = 'cover',
    onChange,
    onChangeBackgroundSize,
}) => {
    const { t } = useTranslation();

    const options: SelectOption[] = [
        { value: 'cover', label: t('Categories:COVER_IMAGE_COVER_DESCRIPTION') },
        { value: 'contain', label: t('Categories:COVER_IMAGE_CONTAIN_DESCRIPTION') },
    ];

    return (
        <div className={`Box ${classes.Container}`}>
            <FileUploader text={t('Common:UPLOAD_IMAGE_LABEL')} onChange={onChange} originalFile={originalFile} />
            <div>
                <label htmlFor="background-size">{t('Categories:COVER_IMAGE_SIZE_INPUT_LABEL')}</label>
                <ReactSelect
                    id="background-size"
                    placeholder={t('Categories:COVER_IMAGE_SIZE_INPUT_PLACEHOLDER')}
                    options={options}
                    defaultValue={options.find((option) => option.value === defaultBackgroundSize)}
                    onChange={onChangeBackgroundSize}
                    className={`react-select-container`}
                    classNamePrefix="react-select"
                ></ReactSelect>
            </div>
        </div>
    );
};

export default CoverImageUpload;
