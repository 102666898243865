import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router';
import { QuestProvider } from '../../context/QuestProvider';
import { IQuest } from '../../models/Quest';
import QuestDetail from './QuestDetail/QuestDetail';
import QuestEditDetail from './QuestEditDetail/QuestEditDetail';
import QuestsDetail from './QuestsDetail/QuestsDetail';

const Quest: FunctionComponent = () => {
    const location = useLocation();
    const { path } = useRouteMatch();

    const [quest, setQuest] = useState<IQuest>();

    useEffect(() => {
        // Set Quest from State
        const quest = location.state as IQuest;
        if (quest) {
            setQuest(quest);
        }
    }, []);

    return (
        <Fragment>
            <Switch>
                <Route exact path={path}>
                    <QuestsDetail />
                </Route>

                <Route exact path={`${path}/create`}>
                    {/* Create Quest */}
                    <QuestProvider>
                        <QuestEditDetail isCreate={true} />
                    </QuestProvider>
                </Route>
                <Route path={`${path}/edit/:questId`}>
                    {/* Edit Quest */}
                    <QuestProvider>
                        <QuestEditDetail isCreate={false} />
                    </QuestProvider>
                </Route>
                <Route path={`${path}/:questId`}>
                    {/* View Quest */}
                    <QuestProvider questOverride={quest}>
                        <QuestDetail />
                    </QuestProvider>
                </Route>

                {/* Redirect /quest to /quest/create */}
                <Redirect from={path} to={`${path}/create`} />
            </Switch>
        </Fragment>
    );
};

export default Quest;
