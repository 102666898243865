import { faImage, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IElement, IElementable } from '../../../../models/Element';
import { getFilePathByName, IFile } from '../../../../models/File';
import Button from '../../../Button/Button';
import { ElementType } from '../../../Card/ElementTypes/ElementTypes';
import ImageUploader from '../../../ImageUploader/ImageUploader';
import ElementHeader from '../../ElementHeader/ElementHeader';
import ElementIcon from '../../Question/QuestionFormik/ElementIcon/ElementIcon';
import useMediaElementForm from '../useMediaElementForm';
import classes from './ImageElement.module.scss';

export type OpenAnswer = {
    body: string;
};

type Props = {
    elementData: IElement;
    isEdit: boolean;
    isReorder: boolean;
    deleteHandler: (element: IElement) => void;
    updateElement?: (element: IElement) => void;
    loaded?: (element: IElement) => void;
};

const ImageElement: FunctionComponent<Props> = ({ elementData, isEdit, isReorder, deleteHandler, updateElement }) => {
    const { t } = useTranslation();
    const [element, setElement] = useState<IElement>(elementData);
    const [imageSrc, setImageSrc] = useState(element.elementable?.src || '');

    useEffect(() => {
        setElement(elementData);
        if (elementData.elementable?.file) {
            const filePath = getFilePathByName(elementData.elementable.file.name);
            setImageSrc(filePath ? filePath : '');
        } else {
            setImageSrc(elementData.elementable?.src || '');
        }
    }, [elementData]);

    useEffect(() => {
        const timeOutId = setTimeout(
            () =>
                setElement((previous) => {
                    const newElementable =
                        previous.elementable && ({ ...previous.elementable, src: imageSrc } as IElementable);
                    return { ...previous, elementable: newElementable };
                }),
            500,
        );
        return () => clearTimeout(timeOutId);
    }, [imageSrc]);

    const { initialValues, handleSubmit, handleDelete, isSaved, setIsSaved } = useMediaElementForm({
        src: element.elementable?.src || '',
        file_id: element.elementable?.file_id,
    });

    const deleteUploadedImage = () => {
        const newElementable = element.elementable && ({ ...element.elementable } as IElementable);
        if (newElementable) {
            newElementable.src = '';
            delete newElementable.file;
            delete newElementable.file_id;
            const newElement: IElement = { ...element, elementable: newElementable };
            setElement(newElement);

            setImageSrc('');

            updateElement?.(newElement);

            handleSubmit(newElement, {
                src: newElementable.src,
                file_id: newElementable.file_id,
            });
        }
    };

    const onUploadImageHandler = (file: IFile | undefined) => {
        const newElementable =
            element.elementable && ({ ...element.elementable, file: file, file_id: file?.id } as IElementable);
        if (newElementable) {
            const newElement: IElement = { ...element, elementable: newElementable };
            setElement(newElement);
            if (file) {
                updateElement?.(newElement);
            }

            setImageSrc(getFilePathByName(file?.name) || '');

            handleSubmit(newElement, {
                src: newElementable.src || '',
                file_id: newElementable.file_id,
            });
        }
    };

    const elementHeader = () => {
        return (
            <ElementHeader
                icon={<ElementIcon elementType={ElementType.Image}></ElementIcon>}
                label={<label>{t('Deck:MEDIA_ELEMENT_IMAGE_HEADER_LABEL')}</label>}
            />
        );
    };

    return (
        <div
            className={`${classes.ImageElement} ${isEdit ? classes['isEdit'] : ''} ${
                isReorder ? classes['isReorder'] : ''
            }`}
        >
            {isEdit ? (
                isReorder ? (
                    elementHeader()
                ) : (
                    <Fragment>
                        <Formik
                            onSubmit={(formValues, actions) => handleSubmit(element, formValues, actions)}
                            initialValues={initialValues}
                        >
                            {({ dirty, isValid, isSubmitting, handleSubmit, setFieldValue }) => {
                                const disabledSubmit = isSubmitting || !(isValid && dirty);
                                return (
                                    <form noValidate={true} onSubmit={handleSubmit}>
                                        <header className={classes.Header}>
                                            {elementHeader()}
                                            <div className={classes.FormButtons}>
                                                <Button
                                                    className={classes.ElementDelete}
                                                    text={``}
                                                    danger
                                                    icon={
                                                        <FontAwesomeIcon
                                                            className={classes.DeleteIcon}
                                                            icon={faTrashAlt}
                                                        />
                                                    }
                                                    iconSide="left"
                                                    onClick={() => {
                                                        handleDelete(element).then(() => {
                                                            deleteHandler(element);
                                                        });
                                                    }}
                                                ></Button>
                                            </div>
                                        </header>

                                        {(!imageSrc || imageSrc?.length === 0) && (
                                            <div className={classes.MissingImage}>
                                                <picture>
                                                    <FontAwesomeIcon
                                                        className={classes.MissingImageIcon}
                                                        icon={faImage}
                                                    />
                                                </picture>
                                            </div>
                                        )}

                                        {imageSrc?.length > 0 && (
                                            <>
                                                <picture>
                                                    <img src={imageSrc} alt="Image of ImageElement"></img>
                                                </picture>
                                            </>
                                        )}

                                        <div className={`${classes.formGroup}`}>
                                            <ImageUploader
                                                onChange={(file: IFile | undefined) => {
                                                    if (file) {
                                                        setFieldValue('file_id', file?.id);
                                                        onUploadImageHandler(file);
                                                    } else {
                                                        // delete
                                                        setFieldValue('file_id', undefined);
                                                        deleteUploadedImage();
                                                    }
                                                }}
                                                originalFile={element.elementable?.file}
                                            />
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </Fragment>
                )
            ) : (
                <>
                    {(!imageSrc || imageSrc?.length === 0) && (
                        <div className={classes.MissingImage}>
                            <picture>
                                <FontAwesomeIcon className={classes.MissingImageIcon} icon={faImage} />
                            </picture>
                        </div>
                    )}

                    {imageSrc?.length > 0 && (
                        <>
                            <picture>
                                <img src={imageSrc} alt="Image of ImageElement"></img>
                            </picture>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default ImageElement;
