import { FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useConfig } from '../../../context/ConfigContext';
import { IProfile } from '../../../models/User';
import AxiosInstance from '../../../utils/axios-instance';

export interface FormFields {
    name: string;
    job_title?: string;
    show_email: boolean;
    description?: string;
    profile_picture_background_size: string;
    email: string;

    // Hidden
    profile_picture_id: number;
}

export type ReturnType = {
    handleSubmit: (profile: IProfile, formFields: FormFields, actions: FormikHelpers<FormFields>) => Promise<void>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
};

const useProfileEditForm = (initialValues: FormFields): ReturnType => {
    const { config } = useConfig();
    const [isSaved, setIsSaved] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();

    const notifySaved = () => toast.success(t('Common:TOAST_PROFILE_SAVED_SUCCESS'));

    const handleSubmit = useCallback(
        (profile: IProfile, formFields: FormFields, actions: FormikHelpers<FormFields>) => {
            const formFieldsBoolToString = { ...formFields, show_email: profile.show_email.toString() };

            return AxiosInstance.put(`${config.baseUrl}/users/${profile.id}`, { ...formFieldsBoolToString }).then(
                () => {
                    setIsSaved(true);
                    actions.resetForm({ values: formFields });
                    history.goBack();
                    notifySaved();
                },
            );
        },
        [],
    );

    return {
        handleSubmit,
        initialValues,
        isSaved,
        setIsSaved,
    };
};

export default useProfileEditForm;
