import { faGripLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { animated, config as SpringConfig, useSprings } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import swap from 'lodash-move';
import clamp from 'lodash.clamp';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../../../components/Button/Button';
import ExtendedMuiList from '../../../components/ExtendedMuiList/ExtendedMuiList';
import { useConfig } from '../../../context/ConfigContext';
import { ICategory } from '../../../models/Categories';
import axiosInstance from '../../../utils/axios-instance';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import ManagementHeader from '../ManagementHeader/ManagementHeader';
import CategoryActionsPopover from './CategoryActionsPopover/CategoryActionsPopover';
import classes from './ManagementCategories.module.scss';

const ManagementCategories: FunctionComponent = () => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const history = useHistory();
    const { response, loading } = useAxiosFetch<ICategory[]>(`${config.baseUrl}/categories`);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [categoryToDelete, setCategoryToDelete] = useState<ICategory>();

    const fixedHeight = 50;
    const onDragScale = 1.02;
    // Reorder
    const [dragging, setDragging] = useState(false);
    const [draggingCurrentIndex, setDraggingCurrentIndex] = useState(0);
    const hasDragged = React.useRef(false);

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    useEffect(() => {
        if (response) {
            const filteredCategories = response.data.filter((cat) => cat.id != null);
            setCategories(filteredCategories);
        }
    }, [response]);

    const onAddCategoryHandler = () => {
        history.push('/categories/create');
    };

    const onEditHandler = (category: ICategory) => {
        history.push(`/categories/edit/${category.id}`);
    };

    const onDeleteHandler = (category: ICategory) => {
        setCategoryToDelete(category);
        setOpen(true);
    };

    const onPermanentlyDeleteHandler = () => {
        if (categoryToDelete === undefined) return;

        axiosInstance.delete(`${config.baseUrl}/categories/${categoryToDelete.id}`);

        const newCategories = [...categories];
        const categoryIndex = newCategories.findIndex((c) => c.id === categoryToDelete.id);

        if (categoryIndex != -1) {
            newCategories.splice(categoryIndex, 1);
            setCategories(newCategories);
        }

        setCategoryToDelete(undefined);
        closeModal();
    };

    const filterDataBySearch = (searchValues: string[]) => {
        return categories.filter((item) => {
            const title = item.title;

            const searchableValues = { title };
            const searchableValuesToString = Object.values(searchableValues);

            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    const fn =
        (order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) =>
        (index: number) =>
            active && index === originalIndex
                ? {
                      y: curIndex * fixedHeight + y,
                      scale: onDragScale,
                      zIndex: 1,
                      shadow: 5,
                      immediate: (key: string) => key === 'zIndex',
                      config: (key: string) => (key === 'y' ? SpringConfig.stiff : SpringConfig.default),
                  }
                : {
                      y: order.indexOf(index) * fixedHeight,
                      scale: 1,
                      zIndex: 0,
                      shadow: 0,
                      immediate: false,
                  };

    const order = useRef(categories.map((_, index) => index)); // Store indicies as a local ref, this represents the item order
    const [springs, setSprings] = useSprings(categories.length, fn(order.current)); // Create springs, each corresponds to an item, controlling its transform, scale, etc.

    let originalOrder = categories.map((item, index) => {
        return { item_id: item.id, index: index };
    });

    const onReorderCategories = (reorderedCategories: ICategory[]) => {
        const newOrder: { id: number; order: number }[] = [];

        reorderedCategories.map((quest, index) => {
            newOrder.push({
                id: quest.id,
                order: index + 1,
            });
        });

        axiosInstance.post(`${config.baseUrl}/categories/order`, { new_order: JSON.stringify(newOrder) });
    };

    const bind = useGesture(
        {
            onDrag: ({ event, args: [originalIndex], active, first, last, movement: [, y] }) => {
                event.preventDefault();

                if (first) hasDragged.current = true;
                if (last) setTimeout(() => (hasDragged.current = false), 0);

                setDragging(true);

                const curIndex = order.current.indexOf(originalIndex);
                setDraggingCurrentIndex(originalIndex);
                const curRow = clamp(Math.round((curIndex * fixedHeight + y) / fixedHeight), 0, categories.length - 1);
                const newOrder = swap(order.current, curIndex, curRow);
                setSprings.start(fn(newOrder, active, originalIndex, curIndex, y)); // Feed springs new style data, they'll animate the view without causing a single render
                if (!active) {
                    setDragging(false);

                    if (newOrder !== order.current) {
                        const newOrderedItems = newOrder.map((val, index) => {
                            return { item_id: originalOrder[val].item_id, order: index };
                        });

                        const orderedItems = categories
                            .slice()
                            .sort(
                                (a, b) =>
                                    newOrderedItems.findIndex((item) => a.id === item.item_id) -
                                    newOrderedItems.findIndex((item) => b.id === item.item_id),
                            );

                        onReorderCategories(orderedItems);

                        order.current = newOrder;
                    }
                }
            },
            onClickCapture: (event) => {
                if (hasDragged.current) {
                    event.event.stopPropagation();
                }
            },
        },
        {
            // delay: true,
            enabled: true,
            drag: {
                filterTaps: true,
            },
        },
    );
    const renderListHeaders = (
        <div>
            <div className={`table-header`}>{t('Common:CATEGORIES_TITLE_HEADER')}</div>
        </div>
    );

    useEffect(() => {
        order.current = categories.map((_, index) => index);
        setSprings.start(fn(order.current));
        originalOrder = categories.map((item, index) => {
            return { item_id: item.id, index: index };
        });
    }, [categories]);

    const listRef = useRef<HTMLDivElement>(null);

    const renderItem = (category: ICategory) => {
        return (
            <div key={`category_${category.id}`}>
                <div>{category.title}</div>
                <div className={classes.CategoryActionsPopover}>
                    <CategoryActionsPopover
                        category={category}
                        onEditHandler={onEditHandler}
                        onDeleteHandler={onDeleteHandler}
                    />
                </div>
            </div>
        );
    };

    const categoriesList = categories?.map((category, index) => {
        const spring = springs[index];

        const zIndex = spring.zIndex;
        const y = spring.y;
        const scale = spring.scale;
        const boxShadow =
            dragging && draggingCurrentIndex === index
                ? 'rgba(0, 0, 0, 0.15) 0px 5px 10px 0px'
                : 'rgba(0, 0, 0, 0.15) 0px 0px 0px 0px';

        return (
            <animated.div
                {...bind(index)}
                key={index}
                style={{
                    zIndex,
                    y,
                    scale,
                    touchAction: 'none',
                }}
            >
                <div
                    key={`category_${category.id}`}
                    style={{ height: fixedHeight, boxShadow }}
                    className={classes.CategoryItem}
                >
                    <div className={classes.ItemHandle}>
                        <FontAwesomeIcon icon={faGripLines}></FontAwesomeIcon>
                    </div>
                    <div className={classes.CategoryTitle}>{category.title}</div>
                    <div className={classes.CategoryActionsPopover}>
                        <CategoryActionsPopover
                            category={category}
                            onEditHandler={onEditHandler}
                            onDeleteHandler={onDeleteHandler}
                        />
                    </div>
                </div>
            </animated.div>
        );
    });

    return (
        <div className={classes.ManagementCategories}>
            <ManagementHeader
                title={t('Common:MANAGEMENT_CATEGORIES_HEADER')}
                buttonText={t('Common:BUTTON_ADD_CATEGORY')}
                onClick={onAddCategoryHandler}
                permission={'library.manage'}
            />

            {
                !loading && (
                    <div className={`Box`}>
                        <div
                            className={`${classes.CategoriesList}`}
                            ref={listRef}
                            style={{ height: categories.length * fixedHeight }}
                        >
                            {categoriesList}
                        </div>
                    </div>
                )
                // <div className={`Box`}>
                //     <ExtendedMuiList
                //         loading={loading}
                //         filterDataBySearch={filterDataBySearch}
                //         searchInputLabel={t('Common:CATEGORIES_SEARCH_INPUT_LABEL')}
                //         noItemsMessage={t('Common:LIST_MESSAGE_NO_CATEGORIES')}
                //         noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_CATEGORIES')}
                //         items={categories}
                //         renderListHeaders={renderListHeaders}
                //         renderItem={renderItem}
                //     />
                // </div>
            }

            <Dialog
                open={open}
                onClose={closeModal}
                className={classes.WarningDialog}
                PaperProps={{ className: `dialog` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:WARNING_HEADER')}</DialogTitle>
                <DialogContent>
                    {t('Common:CATEGORY_DELETION_WARNING_MESSAGE', { title: categoryToDelete?.title })}
                </DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={closeModal} />
                    <Button alt text={t('Common:BUTTON_PERMANENTLY_DELETE')} onClick={onPermanentlyDeleteHandler} />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ManagementCategories;
