import { faRedo, faTrash, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogTitle, DialogContent, DialogActions, TableRow, TableCell } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import ExtendedMuiTable from '../../../../components/ExtendedMuiTable/ExtendedMuiTable';
import AvatarIcon from '../../../../components/Icons/AvatarIcon/AvatarIcon';
import { useConfig } from '../../../../context/ConfigContext';
import { InvitedUser } from '../../../../models/InvitedUser';
import axiosInstance from '../../../../utils/axios-instance';
import { formatDateDistanceToNow } from '../../../../utils/date';
import useAxiosFetch from '../../../../utils/useAxiosFetch';
import classes from '../ManagementMembers.module.scss';
import InviteActionsPopover from './InviteActionsPopover/InviteActionsPopover';

const InvitedList: FunctionComponent = () => {
    const { t } = useTranslation();
    const { config } = useConfig();

    const { response, loading, sendRequest } = useAxiosFetch<InvitedUser[]>(`${config.baseUrl}/invite`);
    const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>([]);

    useEffect(() => {
        if (response) {
            const invitedUserData = response.data;
            setInvitedUsers(invitedUserData);
        }
    }, [response]);

    const [openDelete, setOpenDelete] = useState(false);
    const closeDeleteModal = () => setOpenDelete(false);

    const [inviteToRemove, setInviteToRemove] = useState<InvitedUser>();

    const notifyDeleted = () => toast.success(t('Members:TOAST_INVITE_DELETED_SUCCESS'));
    const notifyNotDeleted = () => toast.error(t('Members:TOAST_INVITE_DELETED_ERROR'));

    const deleteUserInviteClickHandler = (invitedUser: InvitedUser) => {
        setInviteToRemove(invitedUser);
        setOpenDelete((o) => !o);
    };

    const permanentlyDeleteHandler = async () => {
        setOpenDelete(false);

        if (inviteToRemove === undefined) return;

        try {
            await axiosInstance.delete(`${config.baseUrl}/invite/${inviteToRemove.id}`);
            notifyDeleted();
            sendRequest();
        } catch (error) {
            notifyNotDeleted();
        }

        setInviteToRemove(undefined);
    };

    const [openResend, setOpenResend] = useState(false);
    const closeResendModal = () => setOpenResend(false);

    const [inviteToResend, setInviteToResend] = useState<InvitedUser>();

    const notifyResend = () => toast.success(t('Members:TOAST_INVITE_RESEND_SUCCESS'));
    const notifyNotResend = () => toast.error(t('Members:TOAST_INVITE_RESEND_ERROR'));

    const resendInviteClickedHandler = (invitedUser: InvitedUser) => {
        setInviteToResend(invitedUser);
        setOpenResend((o) => !o);
    };

    const resendInviteConfirmClickedHandler = async () => {
        closeResendModal();

        if (inviteToResend === undefined) return;

        try {
            await axiosInstance.get(`${config.baseUrl}/invite/resend/${inviteToResend.id}`);
            notifyResend();
            sendRequest();
        } catch (error) {
            notifyNotResend();
        }

        setInviteToResend(undefined);
    };

    const renderTableHeaders = (
        <TableRow>
            <TableCell>
                <div className={`table-header`}>{t('Common:EMAIL_LABEL')}</div>
            </TableCell>
            <TableCell>
                <div className={`table-header`}>{t('Common:GROUP_LABEL')}</div>
            </TableCell>
            <TableCell>
                <div className={`table-header`}>{t('Common:INVITE_STATUS')}</div>
            </TableCell>
            <TableCell>
                <div className={`table-header`}>{t('Common:EXPIRES_IN')}</div>
            </TableCell>
            <TableCell></TableCell>
        </TableRow>
    );

    const renderInvitedUserItem = (invitedUser: InvitedUser) => {
        return (
            <TableRow key={`invited_user_${invitedUser.id}`}>
                <TableCell>
                    <div className={`${classes.MemberListName}  wrap-text-overflow-1`}>
                        <FontAwesomeIcon icon={faUser} />
                        {invitedUser.email}
                    </div>
                </TableCell>

                <TableCell>
                    <div className={classes.MemberListGroup}>
                        {invitedUser.groups.length == 0
                            ? '-'
                            : invitedUser.groups.map((group, index) => {
                                  return index != invitedUser.groups.length - 1 ? group.name + ', ' : group.name;
                              })}
                    </div>
                </TableCell>

                <TableCell>{t('Members:INVITED_STATUS')}</TableCell>
                <TableCell>{formatDateDistanceToNow(invitedUser.created_at, invitedUser.expiration_date)}</TableCell>

                <TableCell>
                    <div className={classes.InvitedUserControls}>
                        <InviteActionsPopover
                            invitedUser={invitedUser}
                            onResendHandler={resendInviteClickedHandler}
                            onDeleteHandler={deleteUserInviteClickHandler}
                        />
                        {/* <Button
                            alert
                            text=""
                            icon={<FontAwesomeIcon icon={faRedo} />}
                            onClick={() => resendInviteClickedHandler(invitedUser)}
                        />
                        <Button
                            danger
                            text=""
                            icon={<FontAwesomeIcon icon={faTrash} />}
                            onClick={() => deleteUserInviteClickHandler(invitedUser)}
                        /> */}
                    </div>
                </TableCell>
            </TableRow>
        );
    };

    const filterDataBySearch = (searchValues: string[]) => {
        return invitedUsers.filter((item) => {
            // Specific data / array to searchable string
            const name = item.email;
            const groups = item.groups.map((group) => group.name).join('');

            //
            const searchableValues = { name, groups };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    return (
        <div className={classes.ManagementMembers}>
            <div className={`${classes.Header}`}>
                <h2 className={`h3 ${classes.Title}`}>{t('Management:INVITED_USER_HEADER')}</h2>
                <div className={classes.MembersStat}>
                    <AvatarIcon className={classes.MembersIcon}></AvatarIcon>

                    <span className={classes.MembersAmount}>{invitedUsers.length}</span>
                </div>
                <div className="flex-grow"></div>
            </div>

            <div className={`Box`}>
                <ExtendedMuiTable
                    loading={loading}
                    filterDataBySearch={filterDataBySearch}
                    noItemsMessage={t('Common:LIST_MESSAGE_NO_INVITED')}
                    noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_INVITED')}
                    items={invitedUsers}
                    renderItem={renderInvitedUserItem}
                    renderTableHeaders={renderTableHeaders}
                />
            </div>

            <Dialog
                open={openDelete}
                onClose={closeDeleteModal}
                className={classes.WarningDialog}
                PaperProps={{ className: `dialog` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:WARNING_HEADER')}</DialogTitle>
                <DialogContent>
                    {t('Members:INVITE_DELETION_WARNING_MESSAGE', { email: inviteToRemove?.email })}
                </DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={closeDeleteModal} />
                    <Button alt text={t('Common:BUTTON_PERMANENTLY_DELETE')} onClick={permanentlyDeleteHandler} />
                </DialogActions>
            </Dialog>

            <Dialog
                open={openResend}
                onClose={closeResendModal}
                className={classes.WarningDialog}
                PaperProps={{ className: `dialog-warning` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:WARNING_HEADER')}</DialogTitle>
                <DialogContent>
                    {t('Members:INVITE_RESEND_WARNING_MESSAGE', { email: inviteToResend?.email })}
                </DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={closeResendModal} />
                    <Button
                        alt
                        text={t('Common:BUTTON_CONFIRM_RESEND')}
                        icon={<FontAwesomeIcon icon={faRedo} />}
                        iconSide="left"
                        onClick={resendInviteConfirmClickedHandler}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default InvitedList;
