import { useCallback, useState } from 'react';
import Popup from 'reactjs-popup';
import { useConfig } from '../../../../context/ConfigContext';
import { useTimer } from '../../../../context/TimerContext';
import { IElement, IUserAnswer } from '../../../../models/Element';
import Axios from '../../../../utils/axios-instance';

interface FormFields {
    answer: string;
}

export type ReturnType = {
    handleSubmit: (formFields: FormFields) => Promise<void>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
};

export interface IUserAnswerError {
    status: number;
    statusText: string;
    data: any;
}

export const isAnswerError = (answer: IUserAnswer | IUserAnswerError): answer is IUserAnswerError => {
    const isError = (answer as IUserAnswerError).status !== undefined;
    return isError;
};

const useOpenQuestionFormHook = (
    initialValues: FormFields,
    deckId: number,
    element: IElement,
    userAnswered?: (element: IElement, userAnswer: IUserAnswer | IUserAnswerError) => void,
): ReturnType => {
    const { config } = useConfig();
    const [isSaved, setIsSaved] = useState(!!initialValues.answer);
    const { state } = useTimer();

    const handleSubmit = useCallback((formFields: FormFields) => {
        const answerTime = state.originalSeconds - state.seconds;

        const body = {
            deck_id: deckId,
            question_id: element.elementable_id,
            body: formFields.answer,
            answer_time: answerTime,
            within_time: answerTime < state.originalSeconds
        };
        return Axios.post(`${config.baseUrl}/questions_answer`, body)
            .then((response) => {
                // console.log('Answer submitted!', response);
                userAnswered?.(element, response.data as IUserAnswer);
                setIsSaved(true);
            })
            .catch((error) => {
                // console.log('questions_answer error!!!');

                const { status, statusText, data } = error?.response as {
                    status: number;
                    statusText: string;
                    data: any;
                };

                // console.log(status);
                // console.log(statusText);
                // console.log(data);

                if (status === 403) {
                    // console.log(status, statusText);
                    userAnswered?.(element, error?.response as IUserAnswerError);
                }
            });
    }, [state]);

    return {
        handleSubmit,
        initialValues,
        isSaved,
        setIsSaved,
    };
};

export default useOpenQuestionFormHook;
