import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faCircleExclamation, faPlusCircle, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeck } from '../../../context/DeckContext';
import { ICard } from '../../../models/Card';
import { IEdge } from '../../../models/Edge';
import { IFork } from '../../../models/Element';
import useCardService from '../../../services/CardService';
import useEdgeService from '../../../services/EdgeService';
import Button from '../../Button/Button';
import CardSelect from '../../CardSelect/CardSelect';
import ForkElement from '../../Elements/Fork/ForkElement';
import classes from './NavigationElement.module.scss';

interface NavigationElementProps {
    isEdit: boolean;
    navigationChanged: () => void;
}

const NavigationElement: FunctionComponent<NavigationElementProps> = (props) => {
    const { t } = useTranslation();
    const {
        deck,
        cards,
        updateCards,
        currentCard,
        currentCardIndex,
        addCard,
        updateCard,
        currentCardEdge,
        setCurrentCardEdge,
        setCurrentCardIndex,
    } = useDeck();
    const { create: createCard } = useCardService();
    const { create: createEdge, destroy: destroyEdge, update: updateEdge } = useEdgeService();

    const [showFork, setShowFork] = useState(Boolean(currentCard?.fork));
    const [showPath, setShowPath] = useState(
        !Boolean(currentCard?.fork) && (currentCard?.source_of_edges.length || 0) === 1,
    );

    useEffect(() => {
        setShowFork(Boolean(currentCard?.fork));
        setShowPath(!Boolean(currentCard?.fork) && (currentCard?.source_of_edges.length || 0) === 1);
    }, [currentCard]);

    const actionId = currentCardEdge?.action_id || currentCard?.id;

    const hasBranchingEndOnCard = (card?: ICard) => {
        if (!card) return false;
        return !Boolean(navActionsExistOnCard(card));
    };

    const navActionsExistOnCard = (card: ICard) => {
        return card?.source_of_edges.length > 0;
    };

    const endPointLabel = (
        <div className={classes.EndpointLabel}>
            <FontAwesomeIcon className={classes.ExclamationIcon} icon={faCircleExclamation} />
            {t('Common:CARD_END_LABEL')}
        </div>
    );

    const deletedForkHandler = () => {
        if (currentCard) {
            const newCards = _.cloneDeep(cards);
            const newCard = _.cloneDeep(currentCard);

            // Remove old links
            newCard.source_of_edges.map((e) => {
                const prevTargetCard = removeEdgeFromTargetCard(e, newCards);
                newCards[prevTargetCard.targetCardIndex] = prevTargetCard.newTargetCard;
            });

            newCard.fork = undefined;
            newCard.source_of_edges = [];
            newCards[currentCardIndex] = newCard;

            updateCards(newCards);
            setShowFork(false);

            props.navigationChanged();
        }
    };

    const updateFork = (fork: IFork) => {
        setShowFork(true);

        if (currentCard) {
            const newCards = _.cloneDeep(cards);

            const newCard = _.cloneDeep(currentCard);

            // Remove old links
            newCard.source_of_edges.map((e) => {
                const prevTargetCardData = removeEdgeFromTargetCard(e, newCards);
                newCards[prevTargetCardData.targetCardIndex] = prevTargetCardData.newTargetCard;
            });

            newCard.fork = fork;

            // Add new links
            newCard.fork.question.options.map((op) => {
                op.action_of_edges.map((e) => {
                    const targetCard = addEdgeToNewTargetCard(e, newCards);
                    newCards[targetCard.targetCardIndex] = targetCard.newTargetCard;
                });
            });

            newCard.source_of_edges = fork.question.options.flatMap((option) => option.action_of_edges);
            newCards[currentCardIndex] = newCard;

            updateCards(newCards);
        }
    };

    const addNewCardHandler = async (title: string) => {
        const values = {
            deck_id: deck.id,
            title: title ? title : `${t('Deck:NEW_CARD_TITLE')} ${(cards?.length || 0) + 1}`,
            end_card: true,
            prev_card_id: _.last(cards)?.id,
        };

        try {
            const response = await createCard(values);
            if (response) {
                const cardData: ICard = response.data;
                addCard(cardData);

                // notifyCardCreated();
                // setOpenCardDialog(true);
                return Promise.resolve(cardData);
            }
        } catch (error) {
            // notifyCardNotCreated();
        }
    };

    const cardSelectChangeHandler = (newEdge: IEdge | undefined, targetCard?: ICard) => {
        if (!currentCard || !newEdge) return;

        const newEdges = [...currentCard.source_of_edges];
        const edgeIndex = newEdges.findIndex((edge) => edge.action_id === newEdge.action_id);

        if (edgeIndex === -1) {
            createEdge(newEdge).then((response) => {
                newEdges.push(response.data);

                setCurrentCardEdge(response.data);

                const newCard = {
                    ...currentCard,
                    source_of_edges: newEdges,
                };

                const newCards = _.cloneDeep(cards);
                newCards[currentCardIndex] = newCard;

                // Very pretty fallback in case the cards state has not been updated after adding a new card
                const hasTargetCard = newCards.findIndex((c) => c.id === targetCard?.id);
                if (hasTargetCard === -1 && targetCard) newCards.push(targetCard);

                const targetCardData = addEdgeToNewTargetCard(response.data, newCards);
                newCards[targetCardData.targetCardIndex] = targetCardData.newTargetCard;

                updateCards(newCards);
            });
        } else {
            const newCards = _.cloneDeep(cards);

            const prevEdge = newEdges[edgeIndex];
            const prevTargetCardData = removeEdgeFromTargetCard(prevEdge, newCards);
            newCards[prevTargetCardData.targetCardIndex] = prevTargetCardData.newTargetCard;

            // Update edge
            const edgeId = newEdges[edgeIndex].id;
            newEdge.id = edgeId;
            newEdges[edgeIndex] = newEdge;
            updateEdge(edgeId, newEdge).then((response) => {
                setCurrentCardEdge(response.data);
            });

            const newCard = {
                ...currentCard,
                source_of_edges: newEdges,
            };

            newCards[currentCardIndex] = newCard;

            const targetCardData = addEdgeToNewTargetCard(newEdge, newCards);
            newCards[targetCardData.targetCardIndex] = targetCardData.newTargetCard;

            updateCards(newCards);
        }
    };

    const addEdgeToNewTargetCard = (edge: IEdge, cards: ICard[]) => {
        const targetCardIndex = cards.findIndex((c) => c.id === edge.target_id);
        const targetCard = cards[targetCardIndex];
        const newTargetOfEdges = [...targetCard.target_of_edges];
        newTargetOfEdges.push(edge);

        const newTargetCard = {
            ...cards[targetCardIndex],
            target_of_edges: newTargetOfEdges,
        };

        return { targetCardIndex, newTargetCard };
    };

    const removeEdgeFromTargetCard = (edge: IEdge, cards: ICard[]) => {
        const targetCardIndex = cards.findIndex((c) => c.id === edge.target_id);
        const targetCard = cards[targetCardIndex];

        const newTargetOfEdges = [...targetCard.target_of_edges];
        const filteredEdges = newTargetOfEdges.filter((e) => e.id !== edge.id);

        const newTargetCard = {
            ...targetCard,
            target_of_edges: filteredEdges,
        };

        return { targetCardIndex, newTargetCard };
    };

    const deleteEdgeHandler = () => {
        if (currentCardEdge) {
            const prevTargetCard = removeEdgeFromTargetCard(currentCardEdge, cards);
            updateCard(prevTargetCard.newTargetCard);

            destroyEdge(currentCardEdge.id);
        }

        if (currentCard) {
            const newCard = _.cloneDeep(currentCard);
            // There should be only 1 edge to be removed so this should be fine
            newCard.source_of_edges = [];
            updateCard(newCard);
            setCurrentCardEdge(undefined);
        }

        setShowPath(false);
    };

    const navigateToCardHandler = (cardId: number) => {
        const newCardIndex = cards.findIndex((c) => c.id === cardId);
        if (newCardIndex === -1) return;
        setCurrentCardIndex(newCardIndex);
    };

    return (
        <div className={classes.NavigationElementContainer}>
            {/* <label className={classes.Title}>{t('Common:NAVIGATION_ELEMENT_LABEL')}</label> */}
            <div className={`${classes.NavigationElement} ${props.isEdit && classes.edit}`}>
                {props.isEdit ? (
                    <div className={classes.NavigationEdit}>
                        {showFork ? (
                            <div>
                                <ForkElement
                                    fork={currentCard?.fork}
                                    deleteHandler={deletedForkHandler}
                                    updateFork={updateFork}
                                    addNewCardHandler={addNewCardHandler}
                                    navigationChanged={props.navigationChanged}
                                    navigateToCardHandler={navigateToCardHandler}
                                />
                            </div>
                        ) : showPath ? (
                            <div className={classes.NavigationPath}>
                                <div className={classes.PathSelectContainer}>
                                    <header className={classes.Header}>
                                        <label>{t('Common:NAVIGATION_ELEMENT_LABEL')}</label>

                                        <div className={classes.ElementActions}>
                                            {currentCard?.source_of_edges &&
                                                currentCard?.source_of_edges.length > 0 && (
                                                    <Button
                                                        text=""
                                                        icon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
                                                        iconSide="left"
                                                        onClick={() =>
                                                            navigateToCardHandler(
                                                                currentCard?.source_of_edges[0].target_id,
                                                            )
                                                        }
                                                    />
                                                )}
                                            {!deck.is_published && (
                                                <Button
                                                    text=""
                                                    danger
                                                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                                                    onClick={() => {
                                                        deleteEdgeHandler();
                                                        // destroyEdge(currentCardEdge.id);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </header>

                                    {actionId && (
                                        <CardSelect
                                            name={`source_of_edges`}
                                            index={0}
                                            actionId={actionId}
                                            actionModelType={'card'}
                                            edges={currentCard?.source_of_edges}
                                            onChange={(edge, card) => {
                                                cardSelectChangeHandler(edge, card);
                                            }}
                                            addNewCardHandler={addNewCardHandler}
                                            isDisabled={deck.is_published}
                                        />
                                    )}
                                </div>
                                {!deck.is_published && (
                                    <Button
                                        className={classes.AddOption}
                                        text={t('Common:BUTTON_ADD_NAVIGATION_OPTION')}
                                        icon={<FontAwesomeIcon icon={faPlusCircle} />}
                                        iconSide="left"
                                        onClick={() => {
                                            setShowFork(true);
                                            setShowPath(false);
                                            props.navigationChanged();
                                        }}
                                    />
                                )}
                                {deck.is_published && <div>{t('Common:FORK_ELEMENT_PUBLISHED_MESSAGE')}</div>}
                            </div>
                        ) : (
                            <div className={classes.NavigationEndpoint}>
                                {hasBranchingEndOnCard(currentCard) && endPointLabel}

                                {!deck.is_published && (
                                    <Button
                                        className={classes.ButtonAddNavigationOption}
                                        text={t('Common:BUTTON_ADD_NAVIGATION_OPTION')}
                                        icon={<FontAwesomeIcon icon={faPlusCircle} />}
                                        iconSide="left"
                                        onClick={() => {
                                            setShowFork(false);
                                            setShowPath(true);
                                            props.navigationChanged();
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={classes.Navigation}>Navigation</div>
                )}
            </div>
        </div>
    );
};

export default NavigationElement;
