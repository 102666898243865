import React, { FunctionComponent } from 'react';
import Popover from '@material-ui/core/Popover';
import QuestionSettings from './QuestionSettings';
import Button from '../../../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStylesPopup = makeStyles(() =>
    createStyles({
        paper: {
            width: '240px',
        },
    }),
);

export interface QuestionSettingsPopoverProps {
    onDeleteHandler?: () => void;
    onFeedbackHandler?: (enabled: boolean) => void;
    onRightWrongHandler?: (endabled: boolean) => void;
    onTimerHandler?: (enabled: boolean) => void;
}

const QuestionSettingsPopover: FunctionComponent<QuestionSettingsPopoverProps> = (props) => {
    const classesPopup = useStylesPopup();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'question-edit-menu-popover' : undefined;

    return (
        <div>
            <Button
                aria-controls="question-edit-menu"
                aria-haspopup="true"
                text={''}
                icon={<FontAwesomeIcon icon={faEllipsis} />}
                onClick={handleClick}
            ></Button>

            <Popover
                classes={{ paper: classesPopup.paper }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <QuestionSettings {...props}></QuestionSettings>
            </Popover>
        </div>
    );
};

export default QuestionSettingsPopover;
