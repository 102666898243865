import React, { FunctionComponent } from 'react';
import { useConfig } from '../../../context/ConfigContext';
import classes from './CustomizationImages.module.scss';
import EditDefaultImage from './EditDefaultImage/EditDefaultImage';

const CustomizationImages: FunctionComponent = () => {
    const { config, loadingConfig } = useConfig();

    const editDefaultImages = () => {
        const images = Object.entries(config.tenant.images);
        // const infos = Object.entries(defaultImageInfo);

        // images.sort((a, b) => infos.indexOf(a[0]) - infos.indexOf(b[0]));

        return images.map((image) => {
            // return infos.map((image) => {
            return <EditDefaultImage key={image[0]} files={image[1]}></EditDefaultImage>;
        });
    };

    return <div className={classes.CustomizationImages}>{!loadingConfig && editDefaultImages()}</div>;
};

export default CustomizationImages;
