import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { StyleData } from '../models/Theme';
import { getColor } from '../utils/colors';
import useAxiosFetch from '../utils/useAxiosFetch';
import { useConfig } from './ConfigContext';
import { CtxProvider } from './ThemeContext';

type ThemeProviderProps = {
    overrides?: Partial<StyleData>;
};

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({ children, overrides }) => {
    const defaultTheme: StyleData = {
        id: 0,
        name: '',
        css: '',
        active: false,
    };
    const [theme, setTheme] = useState<StyleData>({
        ...defaultTheme,
        ...overrides,
    });

    const { config, loadingConfig } = useConfig();
    const { response, loading } = useAxiosFetch<StyleData>(`${config.baseUrl}/style/active`);

    const MuiTheme = createTheme({
        typography: {
            fontFamily: getColor('--body-font-family'),
            // fontFamily: [
            //     '-apple-system',
            //     'BlinkMacSystemFont',
            //     '"Segoe UI"',
            //     'Roboto',
            //     '"Helvetica Neue"',
            //     'Arial',
            //     'sans-serif',
            //     '"Apple Color Emoji"',
            //     '"Segoe UI Emoji"',
            //     '"Segoe UI Symbol"',
            // ].join(','),
        },
        palette: {
            error: {
                main: getColor('--brand-danger-color'),
            },
            warning: {
                main: getColor('--brand-alert-color'),
            },
            info: {
                main: getColor('--brand-color-5'),
            },
            success: {
                main: getColor('--brand-color-4'),
            },
            primary: {
                main: `rgb(${getColor('--brand-color-5-rgb')})`,
                contrastText: getColor('--text-color-contrast'),
            },
            text: {
                primary: getColor('--text-color'),
            },
        },
        overrides: {
            MuiInputLabel: {
                root: {
                    fontFamily: getColor('--header-font-family'),
                },
            },
            MuiInput: {
                root: {
                    fontFamily: getColor('--body-font-family'),
                },
                input: {
                    height: 40,
                },
            },
            MuiTableCell: {
                root: {
                    borderBottom: `1px solid ${getColor('--divider-color')}`,
                    fontFamily: 'inherit',
                    fontSize: 'inherit',
                },
            },
        },
    });

    useEffect(() => {
        if (response) {
            const activeStyle = response.data;
            setTheme((prevState) => {
                if (prevState.css !== activeStyle.css) {
                    return activeStyle;
                }

                return { ...prevState, ...activeStyle };
            });
        }
        return () => {
            // TODO: Reset to default or null;
        };
    }, [response]);

    return (
        <CtxProvider value={{ theme, setTheme }}>
            <MuiThemeProvider theme={MuiTheme}>{children}</MuiThemeProvider>
        </CtxProvider>
    );
};
