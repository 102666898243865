import { Dialog, DialogActions, DialogContent, DialogTitle, TableCell, TableRow } from '@material-ui/core';
import DOMPurify from 'dompurify';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../../../../components/Button/Button';
import { SelectOption } from '../../../../components/CardSelect/CardSelect';
import ExtendedMuiList from '../../../../components/ExtendedMuiList/ExtendedMuiList';
import ExtendedMuiTable from '../../../../components/ExtendedMuiTable/ExtendedMuiTable';
import AvatarIcon from '../../../../components/Icons/AvatarIcon/AvatarIcon';
import { useConfig } from '../../../../context/ConfigContext';
import { User, UserRole } from '../../../../models/User';
import axiosInstance from '../../../../utils/axios-instance';
import useAxiosFetch from '../../../../utils/useAxiosFetch';
import MemberItem from './MemberItem/MemberItem';
import classes from './MembersList.module.scss';

const MembersList: FunctionComponent = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { config } = useConfig();
    const { response: responseRoles, loading: loadingRoles } = useAxiosFetch<UserRole[]>(`${config.baseUrl}/roles`);
    const { response: responseUsers, loading: loadingUsers } = useAxiosFetch<User[]>(`${config.baseUrl}/users`);
    const { response: responseGuests, loading: loadingGuests } = useAxiosFetch<User[]>(
        `${config.baseUrl}/users/guests`,
    );

    const [roles, setRoles] = useState<UserRole[]>([]);
    const [memberToDelete, setMemberToDelete] = useState<User | undefined>();
    const [members, setMembers] = useState<User[]>([]);
    const [guests, setGuests] = useState<User[]>([]);
    //

    ///
    // const { page, handleChangePage, rowsPerPage, handleChangeRowsPerPage } = usePagination(10);

    useEffect(() => {
        if (responseUsers) {
            const memberData = responseUsers.data;
            setMembers(memberData);
        }
    }, [responseUsers]);

    useEffect(() => {
        if (responseGuests) {
            setGuests(responseGuests.data);
        }
    }, [responseGuests]);

    useEffect(() => {
        if (responseRoles) {
            const roleData = responseRoles.data;
            const dropDownRoles: SelectOption[] = [];

            roleData.map((role, index) => {
                const dropDownSection: SelectOption = {
                    value: index,
                    label: role.name,
                };

                dropDownRoles.push(dropDownSection);
            });

            setRoles(roleData);
        }
    }, [responseRoles]);

    useEffect(() => {
        if (memberToDelete) setOpen(true);
        else closeModal();
    }, [memberToDelete]);

    //

    const filterDataBySearch = (searchValues: string[]) => {
        return members.filter((item) => {
            // Specific data / array to searchable string
            const name = item.name;
            const groups = item.groups.map((group) => group.name).join('');
            const role = item.roles.map((role) => role.name).join('');
            //
            const searchableValues = { name, groups, role };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    const onMemberUpdatedHandler = (updatedMember: User) => {
        const newMembers = _.cloneDeep(members);

        members.map((member, index) => {
            if (member.id === updatedMember.id) {
                newMembers[index] = { ...updatedMember };
            }
        });

        setMembers(newMembers);
    };

    const onToggleActiveHandler = (member: User) => {
        if (member.is_active) {
            onDeactivateMemberHandler(member);
        } else {
            onActivateMemberHandler(member);
        }
    };

    const onActivateMemberHandler = (member: User) => {
        const newMembers = _.cloneDeep(members);
        const foundMember = newMembers.find((newMember) => newMember.id === member.id);

        if (foundMember) {
            foundMember.is_active = true;
        }

        setMembers(newMembers);

        axiosInstance.get(`${config.baseUrl}/users/activate/${member.id}`);
    };

    const onDeactivateMemberHandler = (member: User) => {
        const newMembers = _.cloneDeep(members);
        const foundMember = newMembers.find((newMember) => newMember.id === member.id);

        if (foundMember) {
            foundMember.is_active = false;
        }

        setMembers(newMembers);

        axiosInstance.get(`${config.baseUrl}/users/deactivate/${member.id}`);
    };

    const [open, setOpen] = useState(false);
    const closeModal = () => {
        setOpen(false);
        setMemberToDelete(undefined);
    };

    const onDeleteMemberHandler = (member: User) => {
        setMemberToDelete(member);
    };

    const permanentlyDeleteHandler = () => {
        if (memberToDelete == undefined) {
            closeModal();
            return;
        }

        const newMembers: User[] = _.cloneDeep(members);
        newMembers.splice(
            newMembers.findIndex((member) => {
                return member.id === memberToDelete.id;
            }),
            1,
        );

        setMembers(newMembers);

        axiosInstance.delete(`${config.baseUrl}/users/${memberToDelete.id}`).then(() => {
            setMemberToDelete(undefined);
        });
    };

    const renderMemberItem = (member: User) => {
        return (
            <MemberItem
                key={`member_${member.id}`}
                member={member}
                roles={roles}
                onToggleActiveHandler={onToggleActiveHandler}
                onMemberUpdatedHandler={onMemberUpdatedHandler}
                onDeactivateMemberHandler={onDeactivateMemberHandler}
                onActivateMemberHandler={onActivateMemberHandler}
                onDeleteMemberHandler={onDeleteMemberHandler}
            />
        );
    };

    const onAddMembersHandler = () => {
        history.push(`/settings/members/invite/add_invites`);
    };

    const renderTableHeaders = (
        <TableRow>
            <TableCell>
                <div className={`table-header`}>{t('Common:NAME_LABEL')}</div>
            </TableCell>
            <TableCell>
                <div className={`table-header`}>{t('Common:GROUP_LABEL')}</div>
            </TableCell>
            <TableCell>
                <div className={`table-header`}>{t('Common:ROLE_LABEL')}</div>
            </TableCell>
            <TableCell>
                <div className={`table-header`}>{t('Common:DATE_ADDED_LABEL')}</div>
            </TableCell>
            <TableCell></TableCell>
        </TableRow>
    );

    return (
        <>
            <div className={classes.ManagementMembers}>
                <div className={`${classes.Header}`}>
                    <h2 className={`h3 ${classes.Title}`}>{t('Management:MEMBERS_HEADER')}</h2>
                    <div className={classes.MembersStat}>
                        <AvatarIcon className={classes.MembersIcon}></AvatarIcon>

                        <span className={classes.MembersAmount}>{members.length}</span>
                    </div>
                    <div className="flex-grow"></div>
                    <button className={`btn btn-flavour btn-flavour--alt-border`} onClick={onAddMembersHandler}>
                        {t('Management:BUTTON_ADD_MEMBERS')}
                    </button>
                </div>

                <div className={`Box ${classes.ManagementMembersListBox}`}>
                    <ExtendedMuiTable
                        loading={loadingRoles && loadingUsers}
                        filterDataBySearch={filterDataBySearch}
                        searchInputLabel={t('Common:MEMBERS_SEARCH_INPUT_LABEL')}
                        noItemsMessage={t('Common:LIST_MESSAGE_NO_MEMBERS')}
                        noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_MEMBERS')}
                        items={members}
                        renderTableHeaders={renderTableHeaders}
                        renderItem={renderMemberItem}
                        itemsListContainerClasses={`${classes.MemberListContainer}`}
                        itemsListClasses={`${classes.MemberList}`}
                    />
                </div>
            </div>

            <div className={classes.ManagementMembers}>
                <div className={`${classes.Header}`}>
                    <h2 className={`h3 ${classes.Title}`}>{t('Common:MANAGEMENT_GUESTS_HEADER')}</h2>
                    <div className={classes.MembersStat}>
                        <AvatarIcon className={classes.MembersIcon}></AvatarIcon>

                        <span className={classes.MembersAmount}>{members.length}</span>
                    </div>
                    <div className="flex-grow"></div>
                </div>

                <div className={`Box ${classes.ManagementMembersListBox}`}>
                    <ExtendedMuiTable
                        loading={loadingRoles && loadingUsers}
                        filterDataBySearch={filterDataBySearch}
                        searchInputLabel={t('Common:MEMBERS_SEARCH_INPUT_LABEL')}
                        noItemsMessage={t('Common:LIST_MESSAGE_NO_MEMBERS')}
                        noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_MEMBERS')}
                        items={guests}
                        renderTableHeaders={renderTableHeaders}
                        renderItem={renderMemberItem}
                        itemsListContainerClasses={`${classes.MemberListContainer}`}
                        itemsListClasses={`${classes.MemberList}`}
                    />
                </div>
            </div>

            <Dialog
                open={open}
                onClose={closeModal}
                className={classes.WarningDialog}
                PaperProps={{ className: `dialog` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:WARNING_HEADER')}</DialogTitle>
                <DialogContent>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                t('Common:DELETE_USER_WARNING_MESSAGE', { name: memberToDelete?.name }),
                            ),
                        }}
                    ></p>
                </DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={closeModal} />
                    <Button alt text={t('Common:BUTTON_PERMANENTLY_DELETE')} onClick={permanentlyDeleteHandler} />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MembersList;
