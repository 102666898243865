import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import 'react-flow-renderer/dist/style.css';
import 'react-flow-renderer/dist/theme-default.css';
import ReactFlow, {
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Edge,
    Node,
    Position,
    updateEdge,
} from 'react-flow-renderer/nocss';

import classes from './CardFlowWIP.module.scss';

import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import dagre from 'dagrejs';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDeck } from '../../context/DeckContext';
import { ICard } from '../../models/Card';
import Button from '../Button/Button';
import ConnectionLine from './ConnectionLine/ConnectionLine';
import CustomEdge from './CustomEdge/CustomEdge';
import CardNode from './NodeTypes/CardNode/CardNode';

const nodeTypes = {
    cardNode: CardNode,
};

const edgeTypes = {
    custom: CustomEdge,
};

interface CardFlowWIPProps {
    cards: ICard[];
    open: boolean;
    closeHandler: () => void;
}

export interface EdgeDataType {
    onRemove: (id: string, isNode?: boolean) => void;
}

export interface NodeDataType {
    label: string;
    isStartCard: boolean;
    isEndCard: boolean;
}

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 175;
const nodeHeight = 40;

interface LayoutElementsReturnType {
    nodes: Node[];
    edges: Edge[];
}

const getLayoutedElements = (
    nodes: Node<NodeDataType>[],
    edges: Edge<EdgeDataType>[],
    direction = 'TB',
): LayoutElementsReturnType => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction, edgesep: 80, ranksep: 120, nodesep: 80 });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? Position.Left : Position.Top;
        node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };

        return node;
    });

    return { nodes, edges };
};

const CardFlowWIP: FunctionComponent<CardFlowWIPProps> = ({ cards, open, closeHandler }) => {
    const { deck } = useDeck();
    const { t } = useTranslation();

    useEffect(() => {
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(mapNodes(), mapEdges(), 'LR');
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
    }, [cards]);

    const mapNodes = () => {
        const nodes: Node<NodeDataType>[] = [];

        cards.forEach((card) => {
            nodes.push({
                id: card.id.toString(),
                type: 'cardNode',
                data: {
                    label: card.title,
                    isStartCard: card.start_card,
                    isEndCard: card.source_of_edges.length === 0,
                },
                position: { x: 0, y: 0 },
            });
        });

        return nodes;
    };

    const mapEdges = () => {
        const mEdges: Edge<EdgeDataType>[] = [];
        cards.forEach((card) => {
            card.source_of_edges.forEach((edge) => {
                const customEdge = addCustomEdge(edge.source_id.toString(), edge.target_id.toString());
                mEdges.push(customEdge);
            });
        });

        return _.uniqBy(mEdges, 'id');
    };

    const addCustomEdge = (sourceId: string, targetId: string) => {
        const id = `e${sourceId}-${targetId}`;
        return {
            id: id,
            source: sourceId,
            target: targetId,
            type: 'custom',
            style: { stroke: 'black', strokeWidth: 1.5, cursor: 'pointer' },
            data: {
                onRemove: onRemove,
            },
            animated: true,
            className: 'CustomEdge',
        };
    };

    const onRemove = (id: string, isNode?: boolean) => {
        setEdges((previousEdges) => {
            return _.reject(previousEdges, (x) => x.id.indexOf(id) > -1);
        });
    };

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(mapNodes(), mapEdges(), 'LR');

    const [nodes, setNodes] = useState<Node<NodeDataType>[]>(layoutedNodes);
    const [edges, setEdges] = useState<Edge<EdgeDataType>[]>(layoutedEdges);

    const onNodesChange = useCallback((changes) => setNodes((ns) => applyNodeChanges(changes, ns)), []);
    const onEdgesChange = useCallback((changes) => setEdges((es) => applyEdgeChanges(changes, es)), []);
    // gets called after end of edge gets dragged to another source or target
    const onEdgeUpdate = (oldEdge, newConnection) => {
        setEdges((els) => updateEdge(oldEdge, newConnection, els));
    };
    const onConnect = (params) =>
        setEdges((els) => {
            const customEdge = addCustomEdge(params.source, params.target);

            return addEdge(customEdge, els);
        });

    const onEdgeClick = (event: React.MouseEvent, node: Edge) => {
        // console.log(event, node);
    };

    const onNodeMouseEnter = (event: React.MouseEvent, node: Node) => {
        // console.log(event, node);
    };

    return (
        <Dialog open={open} maxWidth="md" fullWidth onClose={closeHandler}>
            <div className={classes.CardFlowWIP}>
                <DialogTitle disableTypography className={classes.DialogTitle}>
                    <Typography variant="h6">{t('Common:DECK_FLOW_TITLE')}</Typography>

                    <Button
                        aria-label="close"
                        className={classes.closeButton}
                        alt
                        text=""
                        icon={<FontAwesomeIcon icon={faClose} />}
                        onClick={closeHandler}
                    />
                </DialogTitle>

                <DialogContent className={classes.DialogContent}>
                    <ReactFlow
                        className="CardFlow"
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        connectionLineComponent={ConnectionLine}
                        edgeUpdaterRadius={10}
                        // snapToGrid
                        // snapGrid={[10, 10]}
                        onEdgeUpdate={onEdgeUpdate}
                        onConnect={onConnect}
                        onEdgeClick={onEdgeClick}
                        onNodeMouseEnter={onNodeMouseEnter}
                        fitView
                        attributionPosition="bottom-left"
                        // elevateEdgesOnSelect={true}
                        // nodesDraggable={false}
                        nodesConnectable={false}
                        elementsSelectable={false}
                        selectNodesOnDrag={false}
                    >
                        {/* <Background variant={'lines' as BackgroundVariant} gap={10} size={1} /> */}
                        {/* <Controls /> */}
                        {/* <MiniMap
                    nodeColor={(node) => {
                        switch (node.type) {
                            case 'valueNode':
                                return 'LightGreen';
                            case 'dataNode':
                                return 'LightBlue';
                            case 'functionNode':
                                return 'Lavender';
                            case 'sourceNode':
                                return 'Gold';
                            default:
                                return '#eee';
                        }
                    }}
                /> */}
                    </ReactFlow>
                </DialogContent>
            </div>
        </Dialog>
    );
};

export default CardFlowWIP;
