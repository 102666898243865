import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useSticky from '../../hooks/useSticky';
import classes from './QuestContinueActions.module.scss';

interface Props {
    buttonText: string;
    clickContinueHandler: () => void;
}

const QuestContinueActions: React.FC<Props> = ({ buttonText, clickContinueHandler }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isSticky] = useSticky(ref);
    const { t } = useTranslation();

    const continueStickyButton = (
        <div className={`${classes.StickyContainer} ${isSticky && classes.isSticky}`} onClick={clickContinueHandler}>
            {buttonText}
        </div>
    );
    const continueButton = (
        <button
            className={`${classes.ContinueButton} btn btn-flavour ${isSticky && classes.hidden}`}
            // disabled={!continueQuest}
            onClick={clickContinueHandler}
        >
            {buttonText}
        </button>
    );
    return (
        <div className={`${classes.QuestContinueActions}`} ref={ref}>
            {continueStickyButton}
            {continueButton}
        </div>
    );
};

export default QuestContinueActions;
