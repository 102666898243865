import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../../../components/Button/Button';
import ExtendedMuiTable from '../../../components/ExtendedMuiTable/ExtendedMuiTable';
import { useConfig } from '../../../context/ConfigContext';
import { ITag } from '../../../models/Tag';
import axiosInstance from '../../../utils/axios-instance';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import classes from './ManagementTags.module.scss';
import TagActions from './TagActionsPopover/TagActions/TagActions';
import TagActionsPopover from './TagActionsPopover/TagActionsPopover';

const ManagementTags: FunctionComponent = () => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const history = useHistory();
    const { response, loading } = useAxiosFetch<ITag[]>(`${config.baseUrl}/tags`);

    const [tags, setTags] = useState<ITag[]>([]);
    const [tagToDelete, setTagToDelete] = useState<ITag>();

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    useEffect(() => {
        if (response) {
            setTags(response.data);
        }
    }, [response]);

    const addTagHandler = () => {
        history.push('/tags/create');
    };

    const onEditHandler = (tag: ITag) => {
        history.push(`/tags/edit/${tag.id}`);
    };

    const onDeleteHandler = (tag: ITag) => {
        setTagToDelete(tag);
        setOpen(true);
    };

    const onPermanentlyDeleteHandler = () => {
        if (tagToDelete === undefined) return;

        axiosInstance.delete(`${config.baseUrl}/tags/${tagToDelete.id}`);

        const newTags = [...tags];
        const tagIndex = newTags.findIndex((t) => t.id === tagToDelete.id);

        if (tagIndex != -1) {
            newTags.splice(tagIndex, 1);
            setTags(newTags);
        }

        closeModal();
    };

    const filterDataBySearch = (searchValues: string[]) => {
        return tags.filter((item) => {
            const title = item.title;

            const searchableValues = { title };
            const searchableValuesToString = Object.values(searchableValues);

            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    const tagsListHeader = (
        <TableRow>
            <TableCell>
                <div className={`table-header`}>{t('Common:TAGS_TABLE_HEADER_TITLE')}</div>
            </TableCell>
            <TableCell></TableCell>
        </TableRow>
    );

    const renderItem = (tag: ITag) => {
        return (
            <TableRow key={`tag_${tag.id}`}>
                <TableCell>
                    <div className={classes.TagTitle}>
                        <FontAwesomeIcon className={classes.Icon} icon={faTag} />
                        {tag.title}
                    </div>
                </TableCell>
                <TableCell align="right">
                    <TagActionsPopover tag={tag} onEditHandler={onEditHandler} onDeleteHandler={onDeleteHandler} />
                </TableCell>
            </TableRow>
        );
    };

    return (
        <div className={classes.ManagementTags}>
            <div className={`${classes.Header}`}>
                <h2 className={`h3 ${classes.Title}`}>{t('Common:TAGS_HEADER')}</h2>
                <div className="flex-grow"></div>
                <Button alt border text={t('Common:ADD_TAG_BUTTON')} onClick={addTagHandler} />
            </div>

            {!loading && (
                <div className={`Box `}>
                    <ExtendedMuiTable
                        loading={loading}
                        filterDataBySearch={filterDataBySearch}
                        searchInputLabel={t('Common:TAGS_SEARCH_INPUT_LABEL')}
                        noItemsMessage={t('Common:LIST_MESSAGE_NO_TAGS')}
                        noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_TAGS')}
                        items={tags}
                        renderTableHeaders={tagsListHeader}
                        renderItem={renderItem}
                    />
                </div>
            )}

            <Dialog
                open={open}
                onClose={closeModal}
                className={classes.WarningDialog}
                PaperProps={{ className: `dialog` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:WARNING_HEADER')}</DialogTitle>
                <DialogContent>{t('Common:TAG_DELETION_WARNING_MESSAGE', { title: tagToDelete?.title })}</DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={closeModal} />
                    <Button alt text={t('Common:BUTTON_PERMANENTLY_DELETE')} onClick={onPermanentlyDeleteHandler} />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ManagementTags;
