import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import InputField from '../../../../components/InputField/InputField';
import { useConfig } from '../../../../context/ConfigContext';
import { useRole } from '../../../../context/RoleContext';
import { RolePermission, UserRole } from '../../../../models/User';
import useAxiosFetch from '../../../../utils/useAxiosFetch';
import RolePermissionsList from '../RolePermissionsList/RolePermissionsList';
import classes from './RolesEditDetailForm.module.scss';

interface RolesEditDetailFormProps {
    handleFormSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    isSubmitting: boolean;
    isSaved: boolean;
    disabledSubmit: boolean;
    cancelHandler: () => void;
    onAddButtonHandler: (permission: RolePermission) => void;
    onRemoveButtonHandler: (permission: RolePermission) => void;
}

const RolesEditDetailForm: FunctionComponent<RolesEditDetailFormProps> = ({
    handleFormSubmit,
    disabledSubmit,
    isSubmitting,
    isSaved,
    cancelHandler,
    onAddButtonHandler,
    onRemoveButtonHandler,
}) => {
    const { t } = useTranslation();
    const { setFieldValue } = useFormikContext();
    const { role, setRole } = useRole();
    const { config } = useConfig();
    const { loading, response } = useAxiosFetch<RolePermission[]>(`${config.baseUrl}/permissions`);

    useEffect(() => {
        setFieldValue('permissions', role.permissions?.map((permission) => permission.id).toString() || '');
    }, [role]);

    useEffect(() => {
        if (response) {
            const roleData: UserRole = {
                ...role,
                potentialPermissions: response.data,
            };

            setRole(roleData);
        }
    }, [response]);

    const nameChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRole({ ...role, name: event?.target.value });
    };

    return (
        <form noValidate={true} onSubmit={handleFormSubmit}>
            <div className={`Box ${classes.RoleEditDetailForm}`}>
                <div className={`${classes.formGroup} ${isSaved ? classes['saved'] : ''}`}>
                    <label htmlFor="name">{t('Common:ROLES_NAME_INPUT_LABEL')}</label>
                    <InputField
                        name="name"
                        type="text"
                        placeholder={t('Common:ROLE_NAME_INPUT_LABEL')}
                        onChange={nameChangeHandler}
                    />
                </div>

                <div className={classes.RolePermissions}>
                    <label htmlFor="permissions">{t('Common:ROLE_PERMISSIONS_HEADER')}</label>
                    <RolePermissionsList
                        loading={loading}
                        permissions={role.permissions || []}
                        onClick={onRemoveButtonHandler}
                        icon={<FontAwesomeIcon icon={faMinus} />}
                        buttonClassName={`${classes.RemoveButton} btn-flavour--danger`}
                    />
                </div>

                <div className={classes.RolePermissions}>
                    <label htmlFor="permissions">{t('Common:POTENTIAL_ROLE_PERMISSIONS_HEADER')}</label>
                    <RolePermissionsList
                        loading={loading}
                        permissions={role.potentialPermissions || []}
                        onClick={onAddButtonHandler}
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        buttonClassName={`${classes.AddButton}`}
                    />
                </div>
            </div>
            <div className={`${classes.formActions}`}>
                <Button text={t('Common:BUTTON_CANCEL')} onClick={cancelHandler} />
                <Button
                    className={`${classes.submitButton} btn btn-flavour btn-flavour--alt`}
                    type="submit"
                    // onSubmit={onSubmit}
                    disabled={disabledSubmit}
                    text={`${t('Common:BUTTON_CREATE')}`}
                />
            </div>
        </form>
    );
};

export default RolesEditDetailForm;
