import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../context/ConfigContext';
import { defaultCopyGroupTypes } from '../../../models/Defaults';
import classes from './CustomizationCopy.module.scss';
import EditDefaultCopy from './EditDefaultCopy/EditDefaultCopy';

const CustomizationCopy: FunctionComponent = () => {
    const { t } = useTranslation();
    const { config, loadingConfig } = useConfig();

    const editDefaultCopy = () => {
        const defaultCopy = Object.entries(config.tenant.texts);

        return defaultCopy.map((copy) => {
            const key = copy[0] as defaultCopyGroupTypes;
            const value = copy[1];

            switch (key) {
                case 'headquarters':
                    return (
                        <EditDefaultCopy
                            key={key}
                            data={value}
                            title={t(`Common:CUSTOMIZATION_TEXTS_HEADER_${key.toUpperCase()}`)}
                        ></EditDefaultCopy>
                    );
                case 'login':
                    return (
                        <EditDefaultCopy
                            key={key}
                            data={value}
                            title={t(`Common:CUSTOMIZATION_TEXTS_HEADER_${key.toUpperCase()}`)}
                        ></EditDefaultCopy>
                    );
            }
        });
    };

    return <div className={classes.CustomizationCopy}>{!loadingConfig && editDefaultCopy()}</div>;
};

export default CustomizationCopy;
