import { Formik } from 'formik';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import Popup from 'reactjs-popup';
import * as Yup from 'yup';
import Button from '../../../components/Button/Button';
import TrashIcon from '../../../components/Icons/TrashIcon/TrashIcon';
import { useCategory } from '../../../context/CategoryContext';
import { useConfig } from '../../../context/ConfigContext';
import { ICategory } from '../../../models/Categories';
import AxiosInstance from '../../../utils/axios-instance';
import { formatDateToLocal } from '../../../utils/date';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import classes from './CategoryEditDetail.module.scss';
import CategoryEditDetailForm from './CategoryEditDetailForm/CategoryEditDetailForm';
import useCategoryEditForm from './useCategoryEditForm';

type CategoryEditDetailProps = {
    isCreate: boolean;
};

type ParamTypes = {
    categoryId: string;
};

const CategoryEditDetail: FunctionComponent<CategoryEditDetailProps> = ({ isCreate }) => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const history = useHistory();
    const { category, setCategory } = useCategory();
    const { categoryId } = useParams<ParamTypes>();
    const { response, loading } = useAxiosFetch<ICategory>(
        isCreate ? null : `${config.baseUrl}/categories/${categoryId}`,
    );

    const { initialValues, handleSubmit, isSaved } = useCategoryEditForm(
        {
            title: category.title,
            description: category.description,
            cover_background_size: 'contain',
            cover_image_id: 0,
        },
        isCreate,
    );

    const schema = Yup.object({
        title: Yup.string()
            .max(50, t('Common:INPUT_ERROR_TITLE_MAX', { max: 50 }))
            .required(t('Common:INPUT_ERROR_TITLE_REQUIRED')),
        description: Yup.string(),
        // .max(80, t('Common:INPUT_ERROR_DESCRIPTION_MAX', { max: 80 }))
        // .required(t('Common:INPUT_ERROR_DESCRIPTION_REQUIRED')),
    });

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    useEffect(() => {
        if (response) {
            const categoryData = response.data;
            setCategory(categoryData);
        }
    }, [response]);

    const cancelHandler = () => {
        history.goBack();
    };

    const deleteHandler = async () => {
        setOpen((o) => !o);
    };

    const notifyDeleted = () => toast.success(t('Common:TOAST_CATEGORY_DELETED_SUCCESS'));
    const notifyNotDeleted = () => toast.error(t('Common:TOAST_CATEGORY_DELETED_ERROR'));

    const permanentlyDeleteHandler = async () => {
        setOpen(false);

        try {
            await AxiosInstance.delete(`${config.baseUrl}/categories/${categoryId}`);
            cancelHandler();
            notifyDeleted();
        } catch (error) {
            notifyNotDeleted();
        }
    };

    return (
        <Fragment>
            <div className={classes.EditCategoryContainer}>
                <div className={classes.Header}>
                    <h1 className={`h1 wrap-text ${classes.Title}`}>
                        {isCreate ? t('Common:CATEGORY_CREATE_TITLE') : t('Common:CATEGORY_EDIT_TITLE')}
                    </h1>
                    {!loading && !isCreate && (
                        <div className={classes.Subtitle}>
                            <span className={classes.Modified}>
                                {t('Common:MODIFIED', { date: formatDateToLocal(category.updated_at, 'd MMMM HH:mm') })}
                            </span>
                        </div>
                    )}
                </div>

                {loading && (
                    <div className={classes.Loader}>
                        <div className="spinner card Box"></div>
                    </div>
                )}

                {!loading && (
                    <Fragment>
                        <Formik
                            onSubmit={(formValues, actions) => handleSubmit(category, formValues, actions)}
                            initialValues={initialValues}
                            validationSchema={schema}
                        >
                            {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                                const disabledSubmit = isSubmitting || !(isValid && dirty);

                                return (
                                    <CategoryEditDetailForm
                                        isCreate={isCreate}
                                        handleFormSubmit={handleSubmit}
                                        isSubmitting={isSubmitting}
                                        disabledSubmit={disabledSubmit}
                                        isSaved={isSaved}
                                        deleteHandler={deleteHandler}
                                        cancelHandler={cancelHandler}
                                    />
                                );
                            }}
                        </Formik>
                    </Fragment>
                )}
            </div>

            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <div className={`modal Box`}>
                    <h2 className="h4">
                        <strong>{t('Common:WARNING_HEADER')}</strong>
                    </h2>
                    <p>{t('Common:CATEGORY_DELETION_WARNING_MESSAGE', { title: category.title })}</p>
                </div>

                <div className={`${classes.formActions} ${classes.flexGap}`}>
                    <Button text={t('Common:BUTTON_CANCEL')} alt={true} border={true} onClick={closeModal}></Button>
                    {!isCreate && (
                        <Button
                            text={t('Common:BUTTON_PERMANENTLY_DELETE')}
                            danger
                            icon={<TrashIcon />}
                            iconSide="left"
                            onClick={permanentlyDeleteHandler}
                        ></Button>
                    )}
                </div>
            </Popup>
        </Fragment>
    );
};

export default CategoryEditDetail;
