import { IFile } from './File';
import { IElement } from './Element';
export interface IDefaultImageInfo {
    key: DefaultImageTypes;
    title: string;
    description: string;
    format: string;
    dimensions: string;
    defaultImages: IDefaultImage[];
}

// export type defaultInfoTypes = 'logo' | 'welcome' | 'footer' | 'profile' | 'quest' | 'theme' | 'deck';

export const defaultImageInfo: IDefaultImageInfo[] = [
    {
        key: 'logo.png',
        title: 'Logo',
        description:
            '<p>De quest cover is het plaatje dat op de quest kaart op het home scherm staat. Deze afbeelding staat direct op alle Quests die je aanmaakt als je daar zelf geen plaatje aan toevoegt.</p><p>Wij schalen hem linksboven neer en renderen er een klein icoontje van hoe helderder hoe beter.</p>',
        format: 'png',
        dimensions: '767×228',
        defaultImages: [],
    },
    {
        key: 'welcome.png',
        title: 'Welcome',
        description:
            '<p>De quest cover is het plaatje dat op de quest kaart op het home scherm staat. Deze afbeelding staat direct op alle Quests die je aanmaakt als je daar zelf geen plaatje aan toevoegt.</p><p>Wij schalen hem linksboven neer en renderen er een klein icoontje van hoe helderder hoe beter.</p>',
        format: 'png',
        dimensions: '500×500',
        defaultImages: [],
    },
    {
        key: 'footer.png',
        title: 'Footer: Original PNG',
        description:
            '<p>De quest cover is het plaatje dat op de quest kaart op het home scherm staat. Deze afbeelding staat direct op alle Quests die je aanmaakt als je daar zelf geen plaatje aan toevoegt.</p><p>Wij schalen hem linksboven neer en renderen er een klein icoontje van hoe helderder hoe beter.</p>',
        format: 'png',
        dimensions: '2000×519',
        defaultImages: [],
    },
    {
        key: 'footer.webp',
        title: 'Footer: WEBP Large',
        description:
            '<p>De quest cover is het plaatje dat op de quest kaart op het home scherm staat. Deze afbeelding staat direct op alle Quests die je aanmaakt als je daar zelf geen plaatje aan toevoegt.</p><p>Wij schalen hem linksboven neer en renderen er een klein icoontje van hoe helderder hoe beter.</p>',
        format: 'png',
        dimensions: '2000×519',
        defaultImages: [],
    },
    {
        key: 'footer-md.webp',
        title: 'Footer: WEBP Medium',
        description:
            '<p>De quest cover is het plaatje dat op de quest kaart op het home scherm staat. Deze afbeelding staat direct op alle Quests die je aanmaakt als je daar zelf geen plaatje aan toevoegt.</p><p>Wij schalen hem linksboven neer en renderen er een klein icoontje van hoe helderder hoe beter.</p>',
        format: 'png',
        dimensions: '1500×390',
        defaultImages: [],
    },
    {
        key: 'footer-sm.webp',
        title: 'Footer: WEBP Small',
        description:
            '<p>De quest cover is het plaatje dat op de quest kaart op het home scherm staat. Deze afbeelding staat direct op alle Quests die je aanmaakt als je daar zelf geen plaatje aan toevoegt.</p><p>Wij schalen hem linksboven neer en renderen er een klein icoontje van hoe helderder hoe beter.</p>',
        format: 'png',
        dimensions: '1000×260',
        defaultImages: [],
    },
    {
        key: 'profile-avatar.png',
        title: 'Profile',
        description:
            '<p>De quest cover is het plaatje dat op de quest kaart op het home scherm staat. Deze afbeelding staat direct op alle Quests die je aanmaakt als je daar zelf geen plaatje aan toevoegt.</p><p>Wij schalen hem linksboven neer en renderen er een klein icoontje van hoe helderder hoe beter.</p>',
        format: 'png',
        dimensions: '400×400',
        defaultImages: [],
    },
    {
        key: 'quest.png',
        title: 'Quest',
        description:
            '<p>De quest cover is het plaatje dat op de quest kaart op het home scherm staat. Deze afbeelding staat direct op alle Quests die je aanmaakt als je daar zelf geen plaatje aan toevoegt.</p><p>Wij schalen hem linksboven neer en renderen er een klein icoontje van hoe helderder hoe beter.</p>',
        format: 'png',
        dimensions: '1024×682',
        defaultImages: [],
    },
    {
        key: 'theme.png',
        title: 'Theme',
        description:
            '<p>De quest cover is het plaatje dat op de quest kaart op het home scherm staat. Deze afbeelding staat direct op alle Quests die je aanmaakt als je daar zelf geen plaatje aan toevoegt.</p><p>Wij schalen hem linksboven neer en renderen er een klein icoontje van hoe helderder hoe beter.</p>',
        format: 'png',
        dimensions: '1024×682',
        defaultImages: [],
    },
    {
        key: 'deck.png',
        title: 'Deck',
        description:
            '<p>De quest cover is het plaatje dat op de quest kaart op het home scherm staat. Deze afbeelding staat direct op alle Quests die je aanmaakt als je daar zelf geen plaatje aan toevoegt.</p><p>Wij schalen hem linksboven neer en renderen er een klein icoontje van hoe helderder hoe beter.</p>',
        format: 'png',
        dimensions: '1024×682',
        defaultImages: [],
    },
];
export interface IDefaultImage {
    id: number;
    key: string;
    name: string;
    is_default: boolean;
    file: IFile;
}

export type DefaultImageTypes =
    | 'logo.png'
    | 'welcome.png'
    | 'footer.png'
    | 'footer.webp'
    | 'footer-md.webp'
    | 'footer-sm.webp'
    | 'quest.png'
    | 'theme.png'
    | 'deck.png'
    | 'profile-avatar.png';

export enum defaultImageTypes {
    Logo = 'logo.png',
    Welcome = 'welcome.png',
    FooterPng = 'footer.png',
    FooterWebp = 'footer.webp',
    FooterWebpMd = 'footer-md.webp',
    FooterWebpSm = 'footer-sm.webp',
    Quest = 'quest.png',
    Theme = 'theme.png',
    Deck = 'deck.png',
    Profile = 'profile-avatar.png',
}

export type DefaultImages = {
    [key in DefaultImageTypes]?: IDefaultImage[];
};

//
export interface IDefaultCopy {
    id: number;
    text_key: string; // headquarters.welcome
    group_key: string; // headquarters
    created_at: string;
    updated_at: string;
    elements: IElement[];
}

export type defaultCopyGroupTypes = 'headquarters' | 'login';

export type DefaultCopy = {
    [key in defaultCopyGroupTypes]?: IDefaultCopy[];
};
