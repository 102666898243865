import React, { FunctionComponent, useState } from 'react';
import { useClickOutsideListenerRef } from '../../hooks/useClickOutisdeListenerRef';
import DropDown, { DropDownSection } from '../DropDown/DropDown';
import NavigationIcon from '../Icons/NavigationIcon/NavigationIcon';
import SettingsIcon from '../Icons/SettingsIcon/SettingsIcon';
import classes from './NavDropDown.module.scss';

export interface INavDropDownProps {
    items: DropDownSection[];
    onClose?: () => void;
}

const NavDropDown: FunctionComponent<INavDropDownProps> = (props) => {
    const { items } = props;
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const onClick = () => {
        toggle();
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const openedClass = isOpen ? 'isOpen' : 'isClosed';

    const ref = useClickOutsideListenerRef(onClose);

    return (
        <div className={`${classes.NavDropDown} ${openedClass}`} ref={ref}>
            <button className="btn" onKeyPress={() => toggle()} onClick={() => onClick()}>
                <NavigationIcon isSelected={isOpen}>
                    <SettingsIcon />
                </NavigationIcon>
            </button>
            <DropDown items={items} onClose={() => onClose()} isOpen={isOpen} isDesktop={true}></DropDown>
        </div>
    );
};

export default NavDropDown;
