import { faGripLines, faMapMarkedAlt, faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import Button from '../../../../components/Button/Button';
import { IQuest } from '../../../../models/Quest';
import { formatDateDistanceToNow } from '../../../../utils/date';
import QuestActionsPopover from '../QuestActionsPopover/QuestActionsPopover';
import classes from './ManagementQuest.module.scss';

interface ManagementQuestProps {
    quest: IQuest;
    onDelete: (quest: IQuest) => void;
    fixedHeight: number;
    boxShadow: string;
}

const ManagementQuest: FunctionComponent<ManagementQuestProps> = ({ quest, fixedHeight, boxShadow, onDelete }) => {
    const history = useHistory();

    const questPeriod =
        quest.start_date && quest.end_date ? formatDateDistanceToNow(quest.start_date, quest.end_date) : '';

    const onEditQuestClicked = (quest: IQuest) => {
        history.push({ pathname: `/quests/edit/${quest.id}`, state: quest });
    };

    return (
        <div className={classes.QuestItem} style={{ boxShadow, height: fixedHeight }}>
            <div className={classes.ItemHandle}>
                <FontAwesomeIcon icon={faGripLines}></FontAwesomeIcon>
            </div>
            <div className={classes.QuestDetails}>
                <div className={classes.QuestTitle}>
                    <FontAwesomeIcon className={classes.QuestIcon} icon={faMapMarkedAlt} />
                    {quest.title}
                </div>
                <div className={classes.QuestPeriod}>{quest.is_timed && questPeriod}</div>
            </div>
            <div className={classes.QuestControls}>
                <QuestActionsPopover quest={quest} onEditHandler={onEditQuestClicked} onDeleteHandler={onDelete} />
                {/* <Button
                    className={classes.editButton}
                    text=""
                    alt={false}
                    border={false}
                    icon={<FontAwesomeIcon className={classes.EditIcon} icon={faPen} />}
                    onClick={() => onEditQuestClicked(quest)}
                />

                <Button
                    className={classes.DeleteButton}
                    text=""
                    alt={false}
                    border={false}
                    danger
                    icon={<FontAwesomeIcon className={classes.DeleteIcon} icon={faTrashAlt} />}
                    onClick={() => onDelete(quest)}
                /> */}
            </div>
        </div>
    );
};

export default ManagementQuest;
