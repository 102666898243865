import { faCircleNotch, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Button from '../../../components/Button/Button';
import InputField from '../../../components/InputField/InputField';
import TextAreaField from '../../../components/TextAreaField/TextAreaField';
import { useConfig } from '../../../context/ConfigContext';
import { useTheme } from '../../../context/ThemeContext';
import Axios from '../../../utils/axios-instance';
import classes from './CustomizationFonts.module.scss';

interface StyleData {
    id: number;
    name: string;
    css: string;
    active: boolean;
}

interface FormFields {
    css: string;
}

const CustomizationFonts: FunctionComponent = () => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const { theme, setTheme } = useTheme();
    const [isSaved, setIsSaved] = useState(!!theme.css);

    const handleSubmit = (formFields: FormFields) => {
        return Axios.put(`${config.baseUrl}/style/${theme.id}`, formFields).then((response) => {
            toast.success(t('Common:TOAST_CSS_THEME_SAVED_SUCCESS'));
            setTheme({
                id: theme.id,
                name: theme.name,
                css: formFields.css,
                active: true,
            });
            setIsSaved(true);
        });
    };

    const schema = Yup.object({
        css: Yup.string().default('').required(t('Common:INPUT_ERROR_STYLE_CSS_REQUIRED')),
    });

    const initialValues: FormFields = {
        css: theme.css,
    };

    const onChangeHandler = () => {
        setIsSaved(false);
    };

    return (
        <div className={classes.CustomizationFonts}>
            <div className={`Box ${classes.ThemeInput}`}>
                <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema}>
                    {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                        const disabledSubmit = isSubmitting || !(isValid && dirty);

                        return (
                            <form noValidate={true} onSubmit={handleSubmit}>
                                <div className={classes.formGroup}>
                                    <div className={`${classes.InputWrapper} ${isSaved ? classes['saved'] : ''}`}>
                                        <InputField
                                            id="css"
                                            name="css"
                                            type="text"
                                            multiline={true}
                                            rows={10}
                                            label={t('Common:CUSTOMIZATION_CSS_INPUT_LABEL')}
                                            placeholder={t('Common:CUSTOMIZATION_CSS_INPUT_PLACEHOLDER')}
                                            onChange={onChangeHandler}
                                        ></InputField>
                                    </div>

                                    <Button
                                        className={classes.ElementSave}
                                        type="submit"
                                        text={t('Common:BUTTON_SAVE')}
                                        alert={true}
                                        disabled={disabledSubmit}
                                        icon={
                                            isSubmitting ? (
                                                <FontAwesomeIcon
                                                    className={classes.ButtonLoadingIcon}
                                                    icon={faCircleNotch}
                                                    spin={true}
                                                />
                                            ) : (
                                                <FontAwesomeIcon className={classes.SaveIcon} icon={faSave} />
                                            )
                                        }
                                    ></Button>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default CustomizationFonts;
