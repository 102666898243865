import React, { ComponentType } from 'react';

export default function withBox<P>(Wrapped: ComponentType<P>, className: string): (props: P) => JSX.Element {
    const ComponentWithBox = (props: P) => {
        return (
            <div className={`${className} Box`}>
                <Wrapped {...(props as P)} />
            </div>
        );
    };

    return ComponentWithBox;
}
