import { getConfig } from '../context/ConfigProvider';

export type IFile = {
    id: number;
    name: string;
    title?: string; // PDF only
    url?: string; // PDF only
    created_at: string;
    update_at: string;
};

export type IImage = {
    file: IFile;
    background_size: 'cover';
};

export type ImageTypes = 'default' | 'custom';

export const isIterable = (value) => {
    return Symbol.iterator in Object(value);
};

const fileLocationOnEnv = (name: string, type: ImageTypes) => {
    const { subdomain: tenant } = getConfig();

    if (process.env.REACT_APP_ENV === 'local') {
        if (process.env.REACT_APP_ENV_TYPE === 'docker') {
            switch (type) {
                case 'default':
                    return `http://${process.env.REACT_APP_API_DOMAIN}:9000/local/default/${name}`;
                case 'custom':
                    return `http://${process.env.REACT_APP_API_DOMAIN}:9000/local/tenants/${tenant}/${name}`;
                default:
                    return undefined;
            }
        } else {
            switch (type) {
                case 'default':
                    return `http://${process.env.REACT_APP_API_DOMAIN}/storage/default/${name}`;
                case 'custom':
                    return `http://${process.env.REACT_APP_API_DOMAIN}/storage/tenants/${tenant}/${name}`;
                default:
                    return undefined;
            }
        }
    } else {
        switch (type) {
            case 'default':
                return `https://${process.env.REACT_APP_CDN_DOMAIN}/${process.env.REACT_APP_ENV_TAG}/default/${name}`;
            case 'custom':
                return `https://${process.env.REACT_APP_CDN_DOMAIN}/${process.env.REACT_APP_ENV_TAG}/tenants/${tenant}/${name}`;
            default:
                return undefined;
        }
    }
};

export const getFilePathForDefaultImage = (key: string | undefined): string | undefined => {
    if (key) {
        return fileLocationOnEnv(key, 'default');
    }

    return undefined;
};

export const getFilePathByName = (name: string | undefined): string | undefined => {
    if (name) {
        return fileLocationOnEnv(name, 'custom');
    }
    return undefined;
};

export const getGlobalAssetsFilePathByName = (name: string): string => {
    if (process.env.REACT_APP_ENV === 'local') {
        if (process.env.REACT_APP_ENV_TYPE === 'docker') {
            return `http://${process.env.REACT_APP_API_DOMAIN}:9000/local/${process.env.REACT_APP_CDN_ASSETS}/${name}`;
        } else {
            return `http://${process.env.REACT_APP_API_DOMAIN}/storage/${process.env.REACT_APP_CDN_ASSETS}/${name}`;
        }
    } else {
        return `https://${process.env.REACT_APP_CDN_DOMAIN}/${process.env.REACT_APP_ENV_TAG}/${process.env.REACT_APP_CDN_ASSETS}/${name}`;
    }
};
