import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router';
import { GroupProvider } from '../../context/GroupProvider';
import { IGroup } from '../../models/Group';
import GroupDetail from './GroupDetail/GroupDetail';
import GroupEditDetail from './GroupEditDetail/GroupEditDetail';
import GroupsDetail from './GroupsDetail/GroupsDetail';

const Groups: FunctionComponent = () => {
    const location = useLocation();
    const { path } = useRouteMatch();

    const [group, setGroup] = useState<IGroup>();

    useEffect(() => {
        // Set Quest from State
        const group = location.state as IGroup;
        if (group) {
            setGroup(group);
        }
    }, []);

    return (
        <Fragment>
            <Switch>
                <Route exact path={path}>
                    <GroupsDetail></GroupsDetail>
                </Route>

                <Route exact path={`${path}/create`}>
                    {/* Create Group */}
                    <GroupProvider override={group}>
                        <GroupEditDetail isCreate={true}></GroupEditDetail>
                    </GroupProvider>
                </Route>

                <Route path={`${path}/edit/:groupId`}>
                    {/* Edit Group */}
                    <GroupProvider override={group}>
                        <GroupEditDetail isCreate={false}></GroupEditDetail>
                    </GroupProvider>
                </Route>

                <Route path={`${path}/:groupId`}>
                    {/* View Group */}
                    <GroupProvider override={group}>
                        <GroupDetail></GroupDetail>
                    </GroupProvider>
                </Route>
            </Switch>
        </Fragment>
    );
};

export default Groups;
