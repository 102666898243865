import { faCopy, faDownload, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem, ListItemText } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../context/AuthContext';
import { useStylesDeleteButton, useStylesRegularButton } from '../../../../../utils/muiStyles';
import { ILibraryDeckActionsProps } from '../DeckActionsPopover';

const LibraryDeckActions: FunctionComponent<ILibraryDeckActionsProps> = (props) => {
    const { t } = useTranslation();
    const { hasPermission } = useAuth();
    const classesDeleteButton = useStylesDeleteButton();
    const classesRegularButton = useStylesRegularButton();
    const deck = props.deck;

    return (
        <>
            <List>
                {hasPermission('library.manage') && (
                    <ListItem
                        button
                        className={classesRegularButton.root}
                        alignItems="center"
                        onClick={() => props.onEditHandler(deck)}
                    >
                        <FontAwesomeIcon icon={faPencilAlt} />
                        <ListItemText primary={t('Common:BUTTON_EDIT')} />
                    </ListItem>
                )}
                {hasPermission('library.manage') && (
                    <ListItem
                        button
                        className={classesRegularButton.root}
                        alignItems="center"
                        onClick={() => props.onDuplicateHandler(deck)}
                    >
                        <FontAwesomeIcon icon={faCopy} />
                        <ListItemText primary={t('Common:BUTTON_COPY')} />
                    </ListItem>
                )}
                {hasPermission('export.deck.answers') && (
                    <ListItem
                        button
                        className={classesRegularButton.root}
                        alignItems="center"
                        onClick={() => props.onDownloadHandler(deck)}
                    >
                        <FontAwesomeIcon icon={faDownload} />
                        <ListItemText primary={t('Common:BUTTON_DOWNLOAD_RESULTS')} />
                    </ListItem>
                )}

                {hasPermission('library.manage') && (
                    <ListItem
                        button
                        className={classesDeleteButton.root}
                        alignItems="center"
                        onClick={() => props.onDeleteHandler(deck)}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                        <ListItemText primary={t('Common:BUTTON_DELETE')} />
                    </ListItem>
                )}
            </List>
        </>
    );
};

export default LibraryDeckActions;
