import classes from './GroupsIcon.module.scss';
import React, { FunctionComponent } from 'react';

const GroupsIcon: FunctionComponent = () => {
    return (
        <svg
            className={classes.Icon}
            xmlns="http://www.w3.org/2000/svg"
            width="29.841"
            height="25.584"
            viewBox="0 0 29.841 25.584"
        >
            <path
                id="Union_19"
                data-name="Union 19"
                d="M15735,12905.586v-2.631a5.7,5.7,0,0,1,1.665-4.027,5.788,5.788,0,0,1,1.82-1.23,5.656,5.656,0,0,1,2.228-.436h4.917a5.654,5.654,0,0,1,2.224.436,5.5,5.5,0,0,1,1.8,1.23,5.857,5.857,0,0,1,1.23,1.8,5.782,5.782,0,0,1,.451,2.224v2.631Zm18.258-3.751a6.548,6.548,0,0,0-4.681-5.962l.59-.7a5.811,5.811,0,0,1,1.819-1.23,5.654,5.654,0,0,1,2.224-.436h4.918a5.663,5.663,0,0,1,2.228.436,5.572,5.572,0,0,1,1.8,1.23,5.927,5.927,0,0,1,1.23,1.8,5.786,5.786,0,0,1,.451,2.224v2.631Zm-12.157-6.86a5.465,5.465,0,0,1-1.7-1.155,5.307,5.307,0,0,1-1.136-5.819,5.256,5.256,0,0,1,2.833-2.828,5.261,5.261,0,0,1,4.138,0,5.256,5.256,0,0,1,2.833,2.828,5.33,5.33,0,0,1,0,4.143,5.189,5.189,0,0,1-1.136,1.677,5.431,5.431,0,0,1-1.7,1.155,5.306,5.306,0,0,1-4.138,0Zm12.5-3.75a5.522,5.522,0,0,1-1.7-1.155,5.123,5.123,0,0,1-1.135-1.678,5.347,5.347,0,0,1,1.135-5.835,5.463,5.463,0,0,1,1.7-1.136,5.32,5.32,0,0,1,4.139,0,5.463,5.463,0,0,1,1.7,1.136,5.328,5.328,0,0,1,1.135,5.835,5.123,5.123,0,0,1-1.135,1.678,5.522,5.522,0,0,1-1.7,1.155,5.32,5.32,0,0,1-4.139,0Z"
                transform="translate(-15734.499 -12880.502)"
                fill="currentColor"
                stroke="rgba(0,0,0,0)"
                strokeMiterlimit="10"
                strokeWidth="1"
            />
        </svg>
    );
};

export default GroupsIcon;
