import { FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useConfig } from '../../../context/ConfigContext';
import { IModule } from '../../../models/Module';
import AxiosInstance from '../../../utils/axios-instance';

interface FormFields {
    title: string;
    description: string;
    category_id: number;
}

export type ReturnType = {
    handleSubmit: (module: IModule, formFields: FormFields, actions: FormikHelpers<FormFields>) => Promise<void>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
};

const useModuleEditDetailForm = (initialValues: FormFields, isCreate: boolean): ReturnType => {
    const { config } = useConfig();
    const [isSaved, setIsSaved] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();

    const notifyCreated = () => toast.success(t('Common:TOAST_MODULE_CREATED_SUCCESS'));
    const notifyCreatedError = () => toast.error(t('Common:TOAST_MODULE_CREATED_ERROR'));

    const notifySaved = () => toast.success(t('Common:TOAST_MODULE_SAVED_SUCCESS'));
    const notifySavedError = () => toast.error(t('Common:TOAST_MODULE_SAVED_ERROR'));

    const handleSubmit = useCallback((module: IModule, formFields: FormFields, actions: FormikHelpers<FormFields>) => {
        if (isCreate) {
            return AxiosInstance.post(`${config.baseUrl}/modules`, { ...formFields })
                .then(() => {
                    setIsSaved(true);
                    actions.resetForm({ values: formFields });
                    history.goBack();
                    notifyCreated();
                })
                .catch(() => {
                    notifyCreatedError();
                });
        } else {
            return AxiosInstance.put(`${config.baseUrl}/modules/${module.id}`, { ...formFields })
                .then(() => {
                    setIsSaved(true);
                    actions.resetForm({ values: formFields });
                    history.goBack();
                    notifySaved();
                })
                .catch(() => {
                    notifySavedError();
                });
        }
    }, []);

    return {
        handleSubmit,
        initialValues,
        isSaved,
        setIsSaved,
    };
};

export default useModuleEditDetailForm;
