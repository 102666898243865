import { FormikHelpers } from 'formik';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useConfig } from '../../../context/ConfigContext';
import { IGroup } from '../../../models/Group';
import AxiosInstance from '../../../utils/axios-instance';

interface FormFields {
    name: string;
    description: string;
    members: string;
    private: boolean;
    visible: boolean;
}

export type ReturnType = {
    handleSubmit: (group: IGroup, formFields: FormFields, actions: FormikHelpers<FormFields>) => Promise<void>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
};

const useGroupEditForm = (initialValues: FormFields, isCreate: boolean): ReturnType => {
    const { config } = useConfig();
    const [isSaved, setIsSaved] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();

    const notifyCreated = () => toast.success(t('Common:TOAST_GROUP_CREATED_SUCCESS'));
    const notifySaved = () => toast.success(t('Common:TOAST_GROUP_SAVED_SUCCESS'));

    const handleSubmit = useCallback((group: IGroup, formFields: FormFields, actions: FormikHelpers<FormFields>) => {
        if (isCreate) {
            return AxiosInstance.post(`${config.baseUrl}/groups`, { ...formFields }).then(() => {
                setIsSaved(true);
                actions.resetForm({ values: formFields });
                history.goBack();
                notifyCreated();
            });
        } else {
            return AxiosInstance.put(`${config.baseUrl}/groups/${group.id}`, { ...formFields }).then(() => {
                setIsSaved(true);
                actions.resetForm({ values: formFields });
                history.goBack();
                notifySaved();
            });
        }
    }, []);

    return {
        handleSubmit,
        initialValues,
        isSaved,
        setIsSaved,
    };
};

export default useGroupEditForm;
