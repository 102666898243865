import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { RoleProvider } from '../../context/RoleProvider';
import RoleEditDetail from './RoleEditDetail/RoleEditDetail';

const Roles: FunctionComponent = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/create`} exact>
                <RoleProvider>
                    <RoleEditDetail />
                </RoleProvider>
            </Route>

            <Redirect from={path} to={`${path}/create`} />
        </Switch>
    );
};

export default Roles;
