import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, useTheme } from '@material-ui/core';
import React from 'react';
import classes from './TablePaginationActions.module.scss';

interface TablePaginationActionsProps {
    count: number;
    onPageChange: (event, newPage: number) => void;
    page: number | undefined;
    rowsPerPage: number;
}

function TablePaginationActions(props: TablePaginationActionsProps): JSX.Element {
    const { count, page, rowsPerPage, onPageChange } = props;
    const theme = useTheme();

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page !== undefined ? page - 1 : 0);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page !== undefined ? page + 1 : 0);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.Test}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                style={{ fontSize: theme.typography.body2.fontSize }}
            >
                <FontAwesomeIcon icon={faAnglesLeft} />
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                style={{ fontSize: theme.typography.body2.fontSize }}
            >
                <FontAwesomeIcon icon={faAngleLeft} />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page !== undefined ? page >= Math.ceil(count / rowsPerPage) - 1 : true}
                aria-label="next page"
                style={{ fontSize: theme.typography.body2.fontSize }}
            >
                <FontAwesomeIcon icon={faAngleRight} />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page !== undefined ? page >= Math.ceil(count / rowsPerPage) - 1 : true}
                aria-label="last page"
                style={{ fontSize: theme.typography.body2.fontSize }}
            >
                <FontAwesomeIcon icon={faAnglesRight} />
            </IconButton>
        </div>
    );
}

export default TablePaginationActions;
