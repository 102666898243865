import { Formik } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useDeck } from '../../../../context/DeckContext';
import { IElement, IUserAnswer } from '../../../../models/Element';
import CheckmarkIcon from '../../../Icons/CheckmarkIcon/CheckmarkIcon';
import InputField from '../../../InputField/InputField';
import Timer from '../../../Timer/Timer';
import classes from './OpenQuestion.module.scss';
import useOpenQuestionFormHook, { IUserAnswerError } from './OpenQuestionFormHook';
import TimerBar from '../../../TimerBar/TimerBar';
import { useTimer } from '../../../../context/TimerContext';
import Button from '../../../Button/Button';
import Hint from '../../Hint/Hint';

export type OpenAnswer = {
    body: string;
};

type Props = {
    body: string;
    answer: string;
    elementData: IElement;
    sessionAnswer?: IUserAnswer | null;
    onQuestionAnswered?: (element: IElement, userAnswer: IUserAnswer | IUserAnswerError) => void;
    loaded?: (element: IElement) => void;
};

const OpenQuestion: FunctionComponent<Props> = ({ body, answer, elementData, sessionAnswer, onQuestionAnswered }) => {
    const { t } = useTranslation();
    const [element, setElement] = useState<IElement>(elementData);
    const { deck, isHistory } = useDeck();
    const [timerHasStarted, setTimerHasStarted] = useState(false);
    const { state, setTimer, startTimer, stopTimer } = useTimer();

    const outOfTime =
        (sessionAnswer && !sessionAnswer.within_time) || state.originalSeconds - state.seconds >= state.originalSeconds;

    const { initialValues, handleSubmit, isSaved, setIsSaved } = useOpenQuestionFormHook(
        { answer: answer },
        deck.id,
        element,
        onQuestionAnswered,
    );

    useEffect(() => {
        setElement(elementData);
        if (elementData.elementable?.timer) setTimer(elementData.elementable?.timer);
    }, [elementData]);

    useEffect(() => {
        if (sessionAnswer) {
            setIsSaved(true);
        }
    }, [sessionAnswer]);

    const schema = Yup.object({
        answer: Yup.string().default('').required(t('Common:INPUT_ERROR_ANSWER_REQUIRED')),
    });

    const handleTimeUp = () => {
        setIsSaved(true);

        handleSubmit({ answer: answer });
    };

    const answerTime =
        sessionAnswer != undefined && sessionAnswer.answer_time
            ? sessionAnswer.answer_time
            : state.originalSeconds - state.seconds;
    const answerSeconds = answerTime % 60;
    const answerMinutes = (answerTime - answerSeconds) / 60;

    return (
        <div className={`${classes.OpenQuestion}`}>
            <>
                <div className={classes.Header}>
                    <h3 className={`h5 ${classes.Body}`}>{body}</h3>

                    {elementData.elementable?.timer_enabled && (
                        <Timer
                            timer={elementData.elementable?.timer}
                            isSaved={isSaved}
                            isHistory={isHistory}
                            timerHasStarted={timerHasStarted}
                            onTimeUp={handleTimeUp}
                        />
                    )}
                </div>

                {!isHistory &&
                    elementData.elementable?.timer_enabled &&
                    timerHasStarted &&
                    state.timerRunning &&
                    elementData.elementable?.timer !== '00:00' &&
                    !sessionAnswer && (
                        <TimerBar
                            progress={state.originalSeconds}
                            start={timerHasStarted}
                            isRunning={state.timerRunning}
                            completed={false}
                            timeState={'awaiting'}
                            isSaved={isSaved}
                        ></TimerBar>
                    )}

                {!isHistory &&
                    elementData.elementable?.timer_enabled &&
                    !timerHasStarted &&
                    elementData.elementable?.timer !== '00:00' &&
                    !state.timerRunning &&
                    !sessionAnswer && (
                        <Button
                            className={classes.StartButton}
                            text="start timer"
                            onClick={() => {
                                setTimerHasStarted(true);
                                startTimer();
                            }}
                        ></Button>
                    )}

                <Formik
                    onSubmit={(formValues) => {
                        handleSubmit(formValues).then(() => {
                            if (elementData.elementable?.timer !== '00:00') {
                                stopTimer();
                            }
                        });
                    }}
                    initialValues={initialValues}
                    validationSchema={schema}
                >
                    {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                        const disabledSubmit = isSubmitting || !(isValid && dirty);
                        return (
                            <form
                                noValidate={true}
                                onSubmit={handleSubmit}
                                className={`${classes.QuestionForm} ${
                                    !isHistory &&
                                    elementData.elementable?.timer_enabled &&
                                    !timerHasStarted &&
                                    elementData.elementable?.timer !== '00:00' &&
                                    !state.timerRunning &&
                                    !sessionAnswer &&
                                    classes.blur
                                }`}
                            >
                                <div className={classes.formGroup}>
                                    <div className={`${classes.InputWrapper} ${isSaved ? classes['saved'] : ''}`}>
                                        <InputField
                                            name="answer"
                                            type="text"
                                            label={t('Common:OPEN_QUESTION_INPUT_LABEL')}
                                            multiline
                                            placeholder={t('Common:INPUT_ANSWER_PLACEHOLDER')}
                                            disabled={isSaved}
                                        ></InputField>
                                    </div>

                                    <div className={`${classes.formActions}`}>
                                        <Hint
                                            isSaved={isSaved}
                                            timerFeedback={
                                                element.elementable != null &&
                                                element.elementable.timer_enabled === true &&
                                                !outOfTime
                                            }
                                            outOfTime={outOfTime}
                                            minutes={answerMinutes}
                                            seconds={answerSeconds}
                                        />

                                        {!isSaved ? (
                                            <button
                                                className={`${classes.submitButton} btn btn-flavour btn-flavour--danger`}
                                                type="submit"
                                                disabled={disabledSubmit}
                                            >
                                                {t('Common:BUTTON_SUBMIT')}
                                            </button>
                                        ) : (
                                            <div className={classes.Saved}>
                                                <CheckmarkIcon></CheckmarkIcon>
                                                <span>{t('Common:SAVED')}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </>
        </div>
    );
};

export default OpenQuestion;
