import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import InputField from '../../../../components/InputField/InputField';
import { useTag } from '../../../../context/TagContext';
import classes from './TagEditDetailForm.module.scss';

interface TagEditDetailFormProps {
    isCreate: boolean;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    disabledSubmit: boolean;
    cancelHandler: () => void;
}

const TagEditDetailForm: FunctionComponent<TagEditDetailFormProps> = ({
    isCreate,
    handleSubmit,
    disabledSubmit,
    cancelHandler,
}) => {
    const { t } = useTranslation();
    const { tag, setTag } = useTag();

    const titleChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTag({ ...tag, title: e.target.value });
    };

    return (
        <form className={`${classes.TagEditDetailForm}`} noValidate={true} onSubmit={handleSubmit}>
            <div className={`Box ${classes.InputContainer}`}>
                <div className={classes.FormGroup}>
                    {/* <label htmlFor="title"></label> */}
                    <InputField
                        name="title"
                        type="text"
                        label={t('Common:TITLE')}
                        placeholder={t('Common:TAG_TITLE_INPUT_PLACEHOLDER')}
                        onChange={titleChangeHandler}
                        autoFocus
                        max={50}
                    />
                </div>
            </div>

            <div className={classes.formActions}>
                <Button text={t('Common:BUTTON_CANCEL')} onClick={cancelHandler} alt border />
                <Button
                    text={isCreate ? t('Common:BUTTON_CREATE') : t('Common:BUTTON_SAVE')}
                    type="submit"
                    disabled={disabledSubmit}
                    alt
                />
            </div>
        </form>
    );
};

export default TagEditDetailForm;
