import {
    faAlignLeft,
    faClipboard,
    faFilePdf,
    faImage,
    faListCheck,
    faQuestion,
    faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { ElementType } from '../../../../Card/ElementTypes/ElementTypes';
import classes from './ElementIcon.module.scss';

interface ElementIconProps {
    elementType?: ElementType;
}

const ElementIcon: FunctionComponent<ElementIconProps> = (props) => {
    const getIconBasedOnElementType = () => {
        switch (props.elementType) {
            case ElementType.Text:
                return <FontAwesomeIcon icon={faAlignLeft} />;
            case ElementType.Image:
                return <FontAwesomeIcon icon={faImage} />;
            case ElementType.Video:
                return <FontAwesomeIcon icon={faVideo} />;
            case ElementType.Pdf:
                return <FontAwesomeIcon icon={faFilePdf} />;
            case ElementType.Open:
                return <FontAwesomeIcon icon={faQuestion} />;
            case ElementType.MultipleChoice:
                return <FontAwesomeIcon icon={faListCheck} />;
            case ElementType.Poll:
                return <FontAwesomeIcon icon={faClipboard} />;
            default:
                return <FontAwesomeIcon icon={faAlignLeft} />;
        }
    };

    return <div className={classes.ElementIcon}>{getIconBasedOnElementType()}</div>;
};

export default ElementIcon;
