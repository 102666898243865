import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover from '@material-ui/core/Popover';
import React, { FunctionComponent } from 'react';
import Button from '../../../../components/Button/Button';
import { ITag } from '../../../../models/Tag';
import TagActions from './TagActions/TagActions';
import classes from './TagActionsPopover.module.scss';

export interface ITagActionsProps {
    tag: ITag;
    onEditHandler: (tag: ITag) => void;
    onDeleteHandler: (tag: ITag) => void;
}

const TagActionsPopover: FunctionComponent<ITagActionsProps> = ({ tag, onEditHandler, onDeleteHandler }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'deck-edit-menu-popover' : undefined;

    return (
        <div className={classes.TagActionsPopover}>
            <Button
                aria-controls="deck-edit-menu"
                aria-haspopup="true"
                text=""
                icon={<FontAwesomeIcon icon={faEllipsis} />}
                onClick={handleClick}
            />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <TagActions
                    tag={tag}
                    onEditHandler={onEditHandler}
                    onDeleteHandler={() => {
                        handleClose();
                        onDeleteHandler(tag);
                    }}
                />
            </Popover>
        </div>
    );
};

export default TagActionsPopover;
