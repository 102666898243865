import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import backgroundImageMD from '../../assets/tenants/herocenter/footer-md.webp';
import backgroundImageSM from '../../assets/tenants/herocenter/footer-sm.webp';
import backgroundImage from '../../assets/tenants/herocenter/footer.webp';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { getGlobalAssetsFilePathByName } from '../../models/File';
import classes from './HCFooter.module.scss';

const HCFooter: FunctionComponent = () => {
    const { t } = useTranslation();
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const env = `v${process.env.REACT_APP_VERSION}`;
    const mail = process.env.REACT_APP_SUPPORT_MAIL;

    const terms = getGlobalAssetsFilePathByName('AlgemeneVoorwaarden.pdf');
    const privacy = getGlobalAssetsFilePathByName('PrivacyVerklaring.pdf');

    return (
        <footer className={classes.Footer}>
            <svg width="0" height="0">
                <defs>
                    <clipPath id="footerCurve" clipPathUnits="objectBoundingBox">
                        <path
                            d={
                                isDesktop
                                    ? 'M 0,0.35 C .35 0, .65 -0.1, 1 0.2 L 1,1.1 L 0,1.1 Z'
                                    : 'M 0,0.2 C .35 0, .65 0, 1 0.1 L 1,1.1 L 0,1.1 Z'
                            }
                        />
                    </clipPath>
                </defs>
            </svg>
            {isDesktop ? (
                <img
                    className={`${classes.BackgroundDesktopImage} ${classes.CurvedClipPath}`}
                    alt="Footer image"
                    srcSet={`${backgroundImageSM} 480w, ${backgroundImageMD} 800w`}
                    sizes="(max-width: 600px) 480px, 800px"
                    src={backgroundImage}
                ></img>
            ) : (
                <div
                    className={`${classes.BackgroundMobileImage} ${classes.CurvedClipPath}`}
                    style={{
                        backgroundImage: `url('${backgroundImageSM}')`,
                    }}
                ></div>
            )}

            <div className={classes.Content}>
                <h5 className={classes.Title}>{t('Common:POWERED_BY')}</h5>
                <div className={classes.SubContent}>
                    <div className={classes.SubContentFlex}>
                        <div className={classes.SubContentLeft}>
                            <a href={`${terms}`} className="alt" target="_blank" rel="noreferrer">
                                {t('Common:TERMS_AND_CONDITIONS')}
                            </a>
                            <a href={`${privacy}`} className="alt" target="_blank" rel="noreferrer">
                                {t('Conditions:PRIVACY_LINK_TEXT')}
                            </a>
                        </div>
                    </div>
                    <div className={classes.Divider}></div>
                    <div className={classes.SubContentFlex}>
                        <div className={classes.SubContentRight}>
                            <a
                                className="alt"
                                href="https://feedback.flavour.nl/ticket/hero-center"
                                rel="noreferrer"
                                target="_blank"
                            >
                                {t('Common:FEEDBACK_LINK')}
                            </a>
                            <a href={`mailto:${mail}`} className="alt">
                                {mail}
                            </a>
                            {env}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default HCFooter;
