import React, { ForwardRefRenderFunction, useImperativeHandle, useRef } from 'react';
import classes from './CustomizationCover.module.scss';
// import { useSpring, animated } from 'react-spring';
import { useSpring, animated } from '@react-spring/web';
import useHover from '../../../hooks/useHover';

export interface GroupCoverProps {
    editMode?: boolean;
    delay?: number;
    label: string;
    children: React.ReactNode;
}

export type IncrementedRef = {
    current: HTMLDivElement | null;
};

const CustomizationCover: ForwardRefRenderFunction<IncrementedRef, GroupCoverProps> = ({ label, children }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const groupRef = useRef<HTMLDivElement>(null);

    const isHover = useHover(containerRef);

    useImperativeHandle(ref, () => ({
        current: containerRef.current,
    }));

    const calc = (x: number, y: number, width: number, height: number) => {
        const rot = 15;
        return [-(y - height / 2) / rot, (x - width / 2) / rot, 1.04];
    };

    const trans = (x: number, y: number, s: number) =>
        `perspective(700px) rotateX(${-x}deg) rotateY(${-y}deg) scale(${s})`;
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 3, tension: 550, friction: 40 } }));

    const hoverClass = isHover ? classes['hover'] : '';
    const multipleCards = false;
    const multipleCardsClass = multipleCards ? classes['multiple-cards'] : classes['single-card'];

    return (
        <div className={`${classes.CustomizationCoverContainer} ${hoverClass}`} ref={containerRef}>
            <div className={`${classes.CustomizationCoverDropshadow} ${hoverClass}`}>
                <animated.div
                    className={`${classes.CustomizationCover} ${multipleCardsClass}`}
                    onMouseMove={(e) => {
                        const node = e.target as HTMLElement;
                        const rect = node.getBoundingClientRect();
                        const x = e.clientX - rect.left;
                        const y = e.clientY - rect.top;
                        set.start({ xys: calc(x, y, rect.width, rect.height) });
                    }}
                    onMouseLeave={() => {
                        set.start({ xys: [0, 0, 1] });
                    }}
                    style={{ transform: props?.xys?.to(trans) }}
                >
                    <div
                        className={`Box transform3djagggedlinesfix ${classes.Cover} ${hoverClass} ${multipleCardsClass}`}
                        ref={groupRef}
                    >
                        {children}
                        <label className="h7">{label}</label>
                    </div>
                </animated.div>
            </div>
        </div>
    );
};

export default React.forwardRef(CustomizationCover);
