import { faClose, faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogActions, DialogTitle, Typography } from '@material-ui/core';
import React, { FunctionComponent, memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeck } from '../../context/DeckContext';
import { ICard } from '../../models/Card';
import Button from '../Button/Button';
import Card from '../Card/Card';
import CardActions from '../Card/CardActions/CardActions';
import { ElementType } from '../Card/ElementTypes/ElementTypes';
import NavigationElement from '../Card/NavigationElement/NavigationElement';
import classes from './CardDialog.module.scss';

interface CardDialogProps {
    cardData: ICard | undefined;
    open: boolean;
    closeClickHandler: () => void;
    onAddCardHandler: (title?: string) => Promise<ICard | undefined>;
    onNavigateToCardById: (cardId: number | undefined) => void;
    onViewCardById: () => void;
    onDeleteCardHandler: (card: ICard | undefined) => void;
}

const CardDialog: FunctionComponent<CardDialogProps> = (props) => {
    const { t } = useTranslation();
    const { deck, currentCard, setOpenCardDialog } = useDeck();
    const [isReorder, setIsReorder] = useState(false);
    const [isAddElementType, setIsAddElementType] = useState<ElementType | undefined>();

    const bottomRef = useRef<HTMLDivElement>(null);

    const onNavigateToCardById = (cardId: number | undefined) => {
        props.onNavigateToCardById?.(cardId);
    };

    const onViewClickHandler = () => {
        setOpenCardDialog(false);
        props.onViewCardById?.();
    };

    const onReorderClickhandler = () => {
        setIsReorder((isReorder) => {
            const reorder = !isReorder;
            return reorder;
        });
    };

    const scrollToBottomContent = (delay = 0) => {
        setTimeout(() => bottomRef.current?.scrollIntoView({ behavior: 'smooth' }), delay);
    };

    const onAddElementClickHandler = (type: ElementType) => {
        setIsAddElementType(type);
    };

    const onElementAddedTriggered = () => {
        setIsAddElementType(undefined);

        scrollToBottomContent(300);
    };

    const navigationChanged = () => {
        scrollToBottomContent(100);
    };

    const onCloseClickHandler = () => {
        props.closeClickHandler();
    };

    return (
        <Dialog open={props.open} maxWidth="md" fullWidth disableEnforceFocus={true} disableAutoFocus={true}>
            <div className={classes.NewCardDialog}>
                <DialogTitle disableTypography className={classes.DialogTitle}>
                    <Typography variant="h6" className={classes.CardName}>
                        {t('Common:EDIT_CARD_HEADER_LABEL')}
                        {currentCard?.start_card && <FontAwesomeIcon icon={faHouse} />}
                    </Typography>

                    <Button
                        aria-label="close"
                        className={classes.closeButton}
                        alt
                        text=""
                        icon={<FontAwesomeIcon icon={faClose}></FontAwesomeIcon>}
                        onClick={onCloseClickHandler}
                    ></Button>
                </DialogTitle>

                <div className={classes.Content}>
                    <Card
                        cardData={props.cardData}
                        isEdit={true}
                        stateClass={'card-current'}
                        current={true}
                        enableDeleteCardButton={false} // TODO: cards.length > 1, is the card already created, etc
                        isReorder={isReorder}
                        isAddElementType={isAddElementType}
                        deleteHandler={props.onDeleteCardHandler}
                        addNewCardHandler={props.onAddCardHandler}
                        onNavigateToCardById={onNavigateToCardById}
                        onElementAddedTriggered={onElementAddedTriggered}
                    ></Card>
                    <NavigationElement isEdit={true} navigationChanged={navigationChanged}></NavigationElement>
                    <div ref={bottomRef} />
                </div>

                <DialogActions className={classes.CardActions}>
                    <CardActions
                        isReorder={isReorder}
                        onViewClickHandler={onViewClickHandler}
                        onReorderClickhandler={onReorderClickhandler}
                        onAddElementClickHandler={onAddElementClickHandler}
                    ></CardActions>
                </DialogActions>
            </div>
        </Dialog>
    );
};

export default memo(CardDialog);
