import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import Button from '../../../../../components/Button/Button';
import { SelectOption } from '../../../../../components/CardSelect/CardSelect';
import EditIcon from '../../../../../components/Icons/EditIcon/EditIcon';
import { useConfig } from '../../../../../context/ConfigContext';
import { User, UserRole } from '../../../../../models/User';
import axiosInstance from '../../../../../utils/axios-instance';
import classes from './RoleSelect.module.scss';

interface RoleSelectProps {
    member: User;
    roles: UserRole[];
    onMemberUpdated: (member: User) => void;
    disabled: boolean;
}

type FormValues = {
    name: string;
};

const RoleSelect: FunctionComponent<RoleSelectProps> = ({ member, roles, disabled, onMemberUpdated }) => {
    const { t } = useTranslation();

    const [options, setOptions] = useState<SelectOption[]>([]);
    const [currentOption, setCurrentOption] = useState<SelectOption>();
    const [edit, setEdit] = useState(false);
    const initialValues: FormValues = {
        name: 'user',
    };
    const { config } = useConfig();

    useEffect(() => {
        const newOptions: SelectOption[] = [];

        roles.map((role, index) => {
            newOptions.push({
                value: index,
                label: role.name,
            });
        });

        setOptions(newOptions);
        //setCurrentOption(newOptions[findRoleIndex(member?.roles?.[0].name || 'user')]);
    }, [roles]);

    useEffect(() => {
        if (options.length <= 0) return;
        setCurrentOption(options[findRoleIndex(member?.roles?.[0].name || 'user')]);
    }, [options]);

    const findRoleIndex = (label: string) => {
        const idx = options.findIndex((option) => option.label == label);
        return idx;
    };

    const editRoleClickHandler = () => {
        setEdit(!edit);
    };

    const onRoleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const response = await axiosInstance.post(`${config.baseUrl}/roles/user/${member.id}`, {
            name: currentOption?.label,
        });

        const newMember: User = response.data;
        onMemberUpdated(newMember);
        setEdit(false);
    };

    return (
        <div className={classes.RoleSelect}>
            {edit ? (
                <div className={classes.MemberListRole}>
                    <form className={classes.RoleForm} onSubmit={onRoleSubmit}>
                        <ReactSelect
                            id="role_select"
                            placeholder={t('Common:INPUT_ROLE_PLACEHOLDER')}
                            options={options}
                            defaultValue={currentOption}
                            onChange={(option) => {
                                if (option) {
                                    setCurrentOption(option);
                                }
                            }}
                            value={currentOption}
                            className={`react-select-container ${classes.RoleDropdown}`}
                            classNamePrefix="react-select"
                        ></ReactSelect>

                        <div className={classes.FormControls}>
                            <Button
                                text={''}
                                icon={<FontAwesomeIcon icon={faTimes} />}
                                onClick={editRoleClickHandler}
                            />
                            <Button text={''} icon={<FontAwesomeIcon icon={faSave} />} alert type="submit" />
                        </div>
                    </form>
                </div>
            ) : (
                <div className={classes.MemberListRole}>
                    <Fragment>
                        <div className={classes.RoleLabel}>
                            {member.roles && member.roles.length == 0
                                ? '-'
                                : member.roles.map((role, index) => {
                                      return index != member.roles.length - 1 ? role.name + ', ' : role.name;
                                  })}
                        </div>

                        {member.is_active && member.access_code_id === null && !disabled && (
                            <Button
                                className={classes.editRoleButton}
                                text=""
                                icon={<EditIcon />}
                                onClick={editRoleClickHandler}
                            />
                        )}
                    </Fragment>
                </div>
            )}
        </div>
    );
};

export default RoleSelect;
