import React, { FunctionComponent } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClock, faMessage, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { QuestionSettingsPopoverProps } from './QuestionSettingsPopover';
import { FormFields } from '../useQuestionForm';
import { useFormikContext } from 'formik';
import { useElement } from '../../ElementProvider';

const useStylesListItem = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            gap: '1rem',
        },
    }),
);

const useStylesDeleteButton = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: `${theme.palette.error.main}`,
            '&:hover': {
                backgroundColor: theme.palette.error.main,
                color: `${theme.palette.error.contrastText}`,
            },
            gap: '1rem',
        },
    }),
);

const QuestionSettings: FunctionComponent<QuestionSettingsPopoverProps> = (props) => {
    const classesDeleteButton = useStylesDeleteButton();
    const classesListItem = useStylesListItem();
    const { state } = useElement();
    const { setFieldValue, values } = useFormikContext<FormFields>();

    return (
        <>
            <List subheader={<ListSubheader>General</ListSubheader>} dense={true}>
                <ListItem classes={{ root: classesListItem.root }}>
                    <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                    <ListItemText id="question-settings-options-timer" primary="Timer" />
                    <ListItemSecondaryAction>
                        <Switch
                            id="timer_enabled"
                            name="timer_enabled"
                            edge="end"
                            checked={values.timer_enabled}
                            onChange={(e) => {
                                setFieldValue('timer_enabled', e.target.checked, false);
                                props.onTimerHandler?.(e.target.checked);
                            }}
                            inputProps={{ 'aria-labelledby': 'question-settings-options-timer' }}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
            {(state.elementable?.question_type?.type === 'multiple_choice' ||
                state.elementable?.question_type?.type === 'poll') && (
                <List subheader={<ListSubheader>Options</ListSubheader>} dense={true}>
                    {(state.elementable?.question_type?.type === 'multiple_choice' ||
                        state.elementable?.question_type?.type === 'poll') && (
                        <ListItem classes={{ root: classesListItem.root }}>
                            <FontAwesomeIcon icon={faMessage}></FontAwesomeIcon>
                            <ListItemText id="question-settings-options-feedback" primary="Feedback" />
                            <ListItemSecondaryAction>
                                {/* <SwitchField name="feedback_enabled"></SwitchField> */}
                                <Switch
                                    id="feedback_enabled"
                                    name="feedback_enabled"
                                    edge="end"
                                    checked={values.feedback_enabled}
                                    onChange={(e) => {
                                        setFieldValue('feedback_enabled', e.target.checked, false);
                                        props.onFeedbackHandler?.(e.target.checked);
                                    }}
                                    inputProps={{ 'aria-labelledby': 'question-settings-options-feedback' }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                    {state.elementable?.question_type?.type === 'multiple_choice' && (
                        <ListItem classes={{ root: classesListItem.root }}>
                            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                            <ListItemText id="question-settings-options-right-wrong" primary="Right/Wrong" />
                            <ListItemSecondaryAction>
                                <Switch
                                    id="is_correct_enabled"
                                    name="is_correct_enabled"
                                    edge="end"
                                    checked={values.is_correct_enabled}
                                    onChange={(e) => {
                                        setFieldValue('is_correct_enabled', e.target.checked, false);
                                        props.onRightWrongHandler?.(e.target.checked);
                                    }}
                                    inputProps={{ 'aria-labelledby': 'question-settings-options-right-wrong' }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>
            )}
            <ListItem button className={classesDeleteButton.root} alignItems="center" onClick={props.onDeleteHandler}>
                <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                <ListItemText primary="Delete" />
            </ListItem>
        </>
    );
};

export default QuestionSettings;
