import { get } from 'lodash';
import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ErrorPage from '../ErrorPage/ErrorPage';

const ErrorHandler: FunctionComponent = ({ children }) => {
    const history = useHistory();
    const location = useLocation();
    const statusCode = get(location.state, 'errorStatusCode');

    useEffect(() => {
        if (statusCode != undefined) {
            history.push({ state: {} });
        }
    }, []);

    // 401 already redirected to login
    // 403
    // 404
    // 500
    // 503
    switch (statusCode) {
        case 403:
            return <ErrorPage statusCode={statusCode} />;
        case 404:
            return <ErrorPage statusCode={statusCode} />;
        case 500:
            return <ErrorPage statusCode={statusCode} />;
        case 503:
            return <ErrorPage statusCode={statusCode} />;

        default:
            return <Fragment>{children}</Fragment>;
    }
};

export default ErrorHandler;
