import { faCircleNotch, faFilePdf, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IElement, IElementable } from '../../../../models/Element';
import { getFilePathByName, IFile } from '../../../../models/File';
import Button from '../../../Button/Button';
import { ElementType } from '../../../Card/ElementTypes/ElementTypes';
import PDFUploader from '../../../PDFUploader/PDFUploader';
import ElementHeader from '../../ElementHeader/ElementHeader';
import ElementTypeIndicator from '../../ElementTypeIndicator/ElementTypeIndicator';
import ElementIcon from '../../Question/QuestionFormik/ElementIcon/ElementIcon';
import useMediaElementForm from '../useMediaElementForm';
import classes from './PDFElement.module.scss';

export type OpenAnswer = {
    body: string;
};

type Props = {
    elementData: IElement;
    isEdit: boolean;
    isReorder: boolean;
    deleteHandler: (element: IElement) => void;
};

const PDFElement: FunctionComponent<Props> = ({ elementData, isEdit, isReorder, deleteHandler }) => {
    const { t } = useTranslation();
    const [element, setElement] = useState<IElement>(elementData);
    const [src, setSrc] = useState(element.elementable?.src || '');
    const [name, setName] = useState(elementData.elementable?.file?.title || elementData.elementable?.file?.name || '');

    useEffect(() => {
        setElement(elementData);
        if (elementData.elementable?.file) {
            setSrc(elementData.elementable.file?.url || getFilePathByName(elementData.elementable.file.name) || '');
            setName(elementData.elementable.file?.title || elementData.elementable.file?.name || '');
        } else {
            setSrc(elementData.elementable?.src || '');
            setName(elementData.elementable?.src || '');
        }
    }, [elementData]);

    useEffect(() => {
        const timeOutId = setTimeout(
            () =>
                setElement((previous) => {
                    const newElementable =
                        previous.elementable && ({ ...previous.elementable, src: src } as IElementable);
                    return { ...previous, elementable: newElementable };
                }),
            500,
        );
        return () => clearTimeout(timeOutId);
    }, [src]);

    const onUploadImageHandler = (file: IFile | undefined) => {
        const newElementable =
            element.elementable && ({ ...element.elementable, file: file, file_id: file?.id } as IElementable);
        setElement({ ...element, elementable: newElementable });

        setSrc(file?.url || getFilePathByName(file?.name) || '');
        setName(elementData.elementable?.file?.title || elementData.elementable?.file?.name || '');
        setIsSaved(false);
    };

    const { initialValues, handleSubmit, handleDelete, isSaved, setIsSaved } = useMediaElementForm({
        src: element.elementable?.src || '',
        file_id: element.elementable?.file_id,
    });

    const elementHeader = () => {
        return (
            <ElementHeader
                icon={<ElementIcon elementType={ElementType.Pdf}></ElementIcon>}
                label={<label>{t('Deck:MEDIA_ELEMENT_PDF_HEADER_LABEL')}</label>}
            />
        );
    };

    return (
        <div
            className={`${classes.PDFElement} ${isEdit ? classes['isEdit'] : ''} ${
                isReorder ? classes['isReorder'] : ''
            }`}
        >
            {isEdit ? (
                isReorder ? (
                    elementHeader()
                ) : (
                    <Fragment>
                        <Formik
                            onSubmit={(formValues, actions) => handleSubmit(element, formValues, actions)}
                            initialValues={initialValues}
                        >
                            {({ dirty, isValid, isSubmitting, handleSubmit, setFieldValue }) => {
                                const disabledSubmit = isSubmitting || !(isValid && dirty);
                                return (
                                    <form noValidate={true} onSubmit={handleSubmit}>
                                        <header className={classes.Header}>
                                            {elementHeader()}
                                            <div className={classes.FormButtons}>
                                                {!isSaved ? (
                                                    <Button
                                                        className={classes.ElementSave}
                                                        type="submit"
                                                        text={t('Common:BUTTON_SAVE')}
                                                        alert={true}
                                                        disabled={disabledSubmit}
                                                        icon={
                                                            isSubmitting ? (
                                                                <FontAwesomeIcon
                                                                    className={classes.ButtonLoadingIcon}
                                                                    icon={faCircleNotch}
                                                                    spin={true}
                                                                />
                                                            ) : (
                                                                <FontAwesomeIcon
                                                                    className={classes.SaveIcon}
                                                                    icon={faSave}
                                                                />
                                                            )
                                                        }
                                                    ></Button>
                                                ) : null}

                                                <Button
                                                    className={classes.ElementDelete}
                                                    text={``}
                                                    danger
                                                    icon={
                                                        <FontAwesomeIcon
                                                            className={classes.DeleteIcon}
                                                            icon={faTrashAlt}
                                                        />
                                                    }
                                                    iconSide="left"
                                                    onClick={() => {
                                                        handleDelete(element).then(() => {
                                                            deleteHandler(element);
                                                        });
                                                    }}
                                                ></Button>
                                            </div>
                                        </header>

                                        {(!src || src?.length === 0) && (
                                            <div className={classes.MissingImage}>
                                                <picture>
                                                    <FontAwesomeIcon
                                                        className={classes.MissingImageIcon}
                                                        icon={faFilePdf}
                                                    />
                                                </picture>
                                            </div>
                                        )}

                                        {/* {src?.length > 0 && (
                                            <>
                                                {src?.length > 0 && (
                                                    <a href={src}>{name}</a>
                                                )}
                                            </>
                                        )} */}

                                        <div className={`${classes.formGroup}`}>
                                            <PDFUploader
                                                onChange={(file: IFile | undefined) => {
                                                    setFieldValue('file_id', file?.id);
                                                    onUploadImageHandler(file);
                                                }}
                                                originalFile={element.elementable?.file}
                                            />
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </Fragment>
                )
            ) : (
                <>
                    {src?.length > 0 && (
                        <>
                            <ElementTypeIndicator type={'pdf'} />

                            <a
                                href={
                                    element.elementable?.file?.url || getFilePathByName(element.elementable?.file?.name)
                                }
                                target={'_blank'}
                                rel={'noreferrer'}
                            >
                                {name}
                            </a>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default PDFElement;
