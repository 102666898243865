import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeck } from '../../../context/DeckContext';
import { ICard } from '../../../models/Card';
import { IFork, IUserAnswer } from '../../../models/Element';
import { IOption } from '../../../models/Option';
import OptionList from '../../Elements/Question/MultipleChoiceQuestion/OptionList/OptionList';
import classes from './ForkQuestion.module.scss';
import useForkQuestionForm from './useForkQuestionForm';

interface ForkQuestionProps {
    card: ICard; // fork with question with sessionAnswer
    fork: IFork;
    body: string;
    options: IOption[];
    user_answers?: IUserAnswer[];
    onQuestionAnswered?: (fork: IFork, userAnswer: IUserAnswer, isAnsweredBefore: boolean | undefined) => void;
}

const ForkQuestion: FunctionComponent<ForkQuestionProps> = ({
    card,
    fork,
    body,
    options,
    user_answers,
    onQuestionAnswered,
}) => {
    const { t } = useTranslation();
    const { deck, setCurrentCardEdge, isHistory } = useDeck();
    const [answers, setAnswers] = useState<number[]>(() => {
        if (user_answers) {
            const reducedAnswersFromOptions = _.reduce(
                user_answers,
                (acc: number[], userAnswer) => {
                    if (userAnswer.options) {
                        const firstOptionBecauseFork = _.first(userAnswer.options);
                        if (firstOptionBecauseFork) {
                            acc.push(firstOptionBecauseFork.id);
                        }
                    }
                    return acc;
                },
                [],
            );

            return reducedAnswersFromOptions;
        }

        return [];
    });
    const [ops, setOps] = useState<IOption[]>(
        options.map((option) => {
            const found = answers.find((answer) => answer === option.id);
            option.is_selected = found ? true : false;
            return option;
        }),
    );

    const { handleSubmit, isSaved, setIsSaved } = useForkQuestionForm(
        { answers: answers },
        deck.id,
        fork.question.id || fork.question_id,
        false,
    );

    const { currentCard, historyCardIds, currentHistoryIndex } = useDeck();

    const isAlreadyAnswered = () => {
        if (currentCard) {
            let count = 0;

            _.forEach(historyCardIds, (cardId, index) => {
                if (cardId === currentCard.id) count += 1;
                if (index === currentHistoryIndex) return false;
            });

            const sessionAnswersLength = currentCard?.fork?.question.sessionAnswers.length || 0;
            return sessionAnswersLength >= count;
        }
    };

    const handleClick = (optionId: number) => {
        let changedOps = _.cloneDeep(ops);

        changedOps = options.map((option) => {
            option.is_selected = option.id === optionId;

            if (option.is_selected) {
                // Select new card edge
                if (option.action_of_edges.length > 0) {
                    setCurrentCardEdge(option.action_of_edges[0]);
                }
            }

            return { ...option };
        });

        setOps(_.cloneDeep(changedOps));

        // Save selected options as answers
        const answers = _.cloneDeep(changedOps.filter((option) => option.is_selected));

        const newAnswers = answers.map((option) => option.id);

        setAnswers(newAnswers);

        const wasAlreadyAnswered = isAlreadyAnswered();
        handleSubmit({ answers: newAnswers }, wasAlreadyAnswered ? fork.question.sessionAnswer?.id : undefined).then(
            (response) => {
                onQuestionAnswered?.(fork, response.data, wasAlreadyAnswered);
                setIsSaved(true);
            },
        );
    };

    return (
        <div className={`${classes.ForkQuestion}`}>
            <h3 className={`h5 ${classes.Body}`}>{body}</h3>
            <form>
                {
                    <OptionList
                        options={ops}
                        handleClick={handleClick}
                        multipleAnswersPossible={false}
                        disabled={isHistory}
                        isSaved={isSaved}
                        correctEnabled={true}
                    ></OptionList>
                }
            </form>
        </div>
    );
};

export default ForkQuestion;
