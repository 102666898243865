import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { FormikProps, FormikProvider, useFormik } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useDeck } from '../../context/DeckContext';
import Button from '../Button/Button';
import classes from './ReportDialog.module.scss';
import ReportForm from './ReportForm/ReportForm';
import ReportSubmitButton from './ReportForm/ReportSubmitButton/ReportSubmitButton';
import useReportForm, { FormFields } from './ReportForm/useReportForm';

interface ReportDialogProps {
    open: boolean;
    onCloseClickHandler: () => void;
}

export type ReportType = 'content' | 'bug' | 'request';

export interface IReport {
    body: string;
    type: ReportType;
    cardId: number;
    consent: boolean;
}

const ReportDialog: FunctionComponent<ReportDialogProps> = ({ open, onCloseClickHandler }) => {
    const { currentCard } = useDeck();
    const { t } = useTranslation();

    const defaultReport: IReport = {
        body: '',
        type: 'content',
        cardId: currentCard?.id || 0,
        consent: false,
    };

    const [report, setReport] = useState<IReport>(defaultReport);

    const schema = Yup.object({
        body: Yup.string().required(t('Common:INPUT_ERROR_REPORT_BODY_REQUIRED')),
        consent: Yup.bool().required().oneOf([true], t('Common:INPUT_ERROR_REPORT_CONSENT_REQUIRED')),
    });

    const formik: FormikProps<FormFields> = useFormik<FormFields>({
        initialValues: {
            body: '',
            type: 'content',
            cardId: currentCard?.id || 0,
        },
        onSubmit: (formValues, actions) => handleSubmit(report, formValues, actions),
        validationSchema: schema,
    });

    const { handleSubmit } = useReportForm(formik);

    useEffect(() => {
        if (currentCard) {
            setReport({ ...report, cardId: currentCard.id });
        }
    }, [currentCard]);

    return (
        <FormikProvider value={formik}>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth
                onClose={() => {
                    formik.resetForm();
                    onCloseClickHandler();
                }}
            >
                <div className={classes.ReportDialog}>
                    <DialogTitle disableTypography className={classes.DialogTitle}>
                        <Typography variant="h6" className={classes.CardName}>
                            {t('Common:REPORT_DIALOG_TITLE')}
                        </Typography>

                        <Button
                            aria-label="close"
                            className={classes.closeButton}
                            alt
                            text=""
                            icon={<FontAwesomeIcon icon={faClose}></FontAwesomeIcon>}
                            onClick={() => {
                                formik.resetForm();
                                onCloseClickHandler();
                            }}
                        ></Button>
                    </DialogTitle>

                    <DialogContent>
                        <ReportForm handleFormSubmit={formik.handleSubmit} report={report} setReport={setReport} />
                    </DialogContent>

                    <DialogActions>
                        <ReportSubmitButton
                            disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
                            onCloseHandler={() => {
                                formik.resetForm();
                                onCloseClickHandler();
                            }}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </FormikProvider>
    );
};

export default ReportDialog;
