import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';
import AxiosInstance from './axios-instance';

export interface AxiosFetchResponse<T> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: AxiosResponse<T> | null;
    loading: boolean;
    // error: boolean;
    // errorMessage: null;
    sendRequest: () => Promise<void>;
}

const useAxiosFetch = <T>(url: string | null, timeout?: number): AxiosFetchResponse<T> => {
    const [response, setResponse] = useState<AxiosResponse<T> | null>(null);
    // const [error, setError] = useState(false);
    // const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    let source: CancelTokenSource;
    let isCancelled = false;

    const sendRequest = () => {
        source = Axios.CancelToken.source();
        if (url != null) {
            const getData = async () => {
                try {
                    const data = await AxiosInstance.get<T>(url, {
                        cancelToken: source.token,
                        timeout: timeout,
                    });

                    if (!isCancelled) {
                        setResponse(data);
                        setLoading(false);
                    }
                } catch (e) {
                    setResponse(null);
                    setLoading(false);
                }
            };

            return getData();
        }

        isCancelled = true;
        source.cancel('Cancelling in cleanup');

        return Promise.resolve();
    };

    useEffect(() => {
        if (url !== null) {
            sendRequest();
        } else {
            setResponse(null);
            setLoading(false);
        }
        return () => {
            isCancelled = true;
            if (source) {
                source.cancel('Cancelling useAxiosFetch');
            }
        };
    }, [url]);
    // return { response, loading, error, errorMessage, sendRequest };
    return { response, loading, sendRequest };
};

export default useAxiosFetch;
