import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { useRole } from '../../../context/RoleContext';
import { RolePermission } from '../../../models/User';
import classes from './RoleEditDetail.module.scss';
import RolesEditDetailForm from './RolesEditDetailForm/RolesEditDetailForm';
import useRoleEditForm from './useRoleEditForm';

const RoleEditDetail: FunctionComponent = () => {
    const { t } = useTranslation();
    const { role, setRole, addPermission, removePermission } = useRole();
    const history = useHistory();

    const { initialValues, handleSubmit, isSaved } = useRoleEditForm({
        name: '',
    });

    const schema = Yup.object({
        name: Yup.string().required(t('Common:INPUT_ERROR_NAME_REQUIRED')),
    });

    const cancelHandler = () => {
        history.goBack();
    };

    const onAddButtonHandler = (permission: RolePermission) => {
        addPermission(permission);
    };

    const onRemoveButtonHandler = (permission: RolePermission) => {
        removePermission(permission);
    };

    return (
        <div className={classes.RoleEditDetail}>
            <PageHeader title={t('Common:ROLES_CREATE_HEADER')} />

            <Formik
                onSubmit={(formValues, actions) => handleSubmit(role, formValues, actions)}
                initialValues={initialValues}
                validationSchema={schema}
            >
                {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                    const disabledSubmit = isSubmitting || !(isValid && dirty);

                    return (
                        <RolesEditDetailForm
                            handleFormSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                            disabledSubmit={disabledSubmit}
                            isSaved={isSaved}
                            cancelHandler={cancelHandler}
                            onAddButtonHandler={onAddButtonHandler}
                            onRemoveButtonHandler={onRemoveButtonHandler}
                        />
                    );
                }}
            </Formik>
        </div>
    );
};

export default RoleEditDetail;
