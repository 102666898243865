import { faGripLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animated, config as SpringConfig, useSprings } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import swap from 'lodash-move';
import clamp from 'lodash.clamp';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ICard } from '../../../models/Card';
import { IElement, IUserAnswer } from '../../../models/Element';
import useElementsService from '../../../services/ElementsService';
import { IUserAnswerError } from '../../Elements/Question/OpenQuestion/OpenQuestionFormHook';
import ExclamationIcon from '../../Icons/ExclamationIcon/ExclamationIcon';
import ElementComponent from '../ElementComponent/ElementComponent';
import classes from './ElementsList.module.scss';

export interface IElementProps {
    onReorderElements: (elements: IElement[]) => void;
    onQuestionAnswered?: (element: IElement, userAnswer: IUserAnswer | IUserAnswerError) => void;
    updateElement?: (element: IElement) => void;
    deleteElement?: (element: IElement) => void;
    loaded?: (element: IElement) => void;
    deleteHandler: (element: IElement) => void;
    addNewCardHandler: (title: string) => Promise<ICard | undefined>;
    onNavigateToCardById: (cardId: number | undefined) => void;
}
interface ElementsListProps extends IElementProps {
    elements: IElement[];
    isEdit: boolean;
    isReorder: boolean;
    lastAddedElementId: number | null;
}

const ElementsList: FunctionComponent<ElementsListProps> = (props) => {
    const { order: orderElements } = useElementsService();

    const [dragging, setDragging] = useState(false);
    const [draggingCurrentIndex, setDraggingCurrentIndex] = useState(0);
    const hasDragged = React.useRef(false);

    const listRef = useRef<HTMLDivElement>(null);
    const fixedHeight = 50;
    const onDragScale = 1.02;

    const fn =
        (order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) =>
        (index: number) =>
            active && index === originalIndex
                ? {
                      y: curIndex * fixedHeight + y,
                      scale: onDragScale,
                      zIndex: 1,
                      shadow: 5,
                      immediate: (key: string) => key === 'zIndex',
                      config: (key: string) => (key === 'y' ? SpringConfig.stiff : SpringConfig.default),
                  }
                : {
                      y: order.indexOf(index) * fixedHeight,
                      scale: 1,
                      zIndex: 0,
                      shadow: 0,
                      immediate: false,
                  };

    const order = useRef(props.elements.map((_, index) => index)); // Store indicies as a local ref, this represents the item order
    const [springs, setSprings] = useSprings(props.elements.length, fn(order.current)); // Create springs, each corresponds to an item, controlling its transform, scale, etc.

    let originalOrder = props.elements.map((element, index) => {
        return { element_id: element.id, index: index };
    });

    const bind = useGesture(
        {
            onDrag: async ({ event, args: [originalIndex], active, first, last, movement: [, y] }) => {
                event.preventDefault();

                if (first) hasDragged.current = true;
                if (last) setTimeout(() => (hasDragged.current = false), 0);

                setDragging(true);

                const curIndex = order.current.indexOf(originalIndex);
                setDraggingCurrentIndex(originalIndex);
                const curRow = clamp(
                    Math.round((curIndex * fixedHeight + y) / fixedHeight),
                    0,
                    props.elements.length - 1,
                );
                const newOrder = swap(order.current, curIndex, curRow);
                setSprings.start(fn(newOrder, active, originalIndex, curIndex, y)); // Feed springs new style data, they'll animate the view without causing a single render
                if (!active) {
                    setDragging(false);

                    if (newOrder !== order.current) {
                        const newOrderedItems = newOrder.map((val, index) => {
                            return { element_id: originalOrder[val].element_id, order: index };
                        });

                        const orderedItems = props.elements
                            .slice()
                            .sort(
                                (a, b) =>
                                    newOrderedItems.findIndex((item) => a.id === item.element_id) -
                                    newOrderedItems.findIndex((item) => b.id === item.element_id),
                            );

                        props.onReorderElements(orderedItems);

                        order.current = newOrder;

                        const newOrderedElements = newOrder.map((val, index) => {
                            return { element_id: originalOrder[val].element_id, order: index };
                        });
                        const response = await orderElements({ ordered_elements: JSON.stringify(newOrderedElements) });
                    }
                }
            },
            onClickCapture: (event) => {
                if (hasDragged.current) {
                    event.event.stopPropagation();
                }
            },
        },
        {
            // delay: true,
            enabled: props.isReorder,
            drag: {
                filterTaps: true,
            },
        },
    );

    useEffect(() => {
        order.current = props.elements.map((_, index) => index);
        setSprings.start(fn(order.current));
        originalOrder = props.elements.map((element, index) => {
            return { element_id: element.id, index: index };
        });
    }, [props.elements]);

    const elementsList = props.elements.map((element, index) => {
        const spring = springs[index];

        const zIndex = spring.zIndex;
        const y = spring.y;
        const scale = spring.scale;
        const boxShadow =
            dragging && draggingCurrentIndex === index
                ? 'rgba(0, 0, 0, 0.15) 0px 5px 10px 0px'
                : 'rgba(0, 0, 0, 0.15) 0px 0px 0px 0px';
        return (
            <animated.div
                {...bind(index)}
                key={`index-${index}-${element.id}`}
                style={
                    !props.isEdit || !props.isReorder
                        ? {}
                        : {
                              zIndex,
                              y,
                              scale,
                              touchAction: 'none',
                          }
                }
            >
                <div
                    className={`${classes.Element} ${props.isEdit ? classes['isEdit'] : ''} ${
                        props.isReorder ? classes['isReorder'] : ''
                    }`}
                    style={{
                        boxShadow,
                    }}
                >
                    {!props.isEdit &&
                        element.elementable_type === 'question_element' &&
                        (element.elementable?.sessionAnswer && element.elementable?.sessionAnswer === null
                            ? true
                            : false) && (
                            <div className={classes.ElementStatusIndicator}>
                                <ExclamationIcon />
                            </div>
                        )}

                    <div style={{ flex: 1, width: '100%' }}>
                        {props.isEdit && props.isReorder && (
                            <div className={classes.ItemHandle}>
                                <FontAwesomeIcon icon={faGripLines}></FontAwesomeIcon>
                            </div>
                        )}

                        <div className={classes.ItemContent}>
                            <ElementComponent element={element} index={index} {...props}></ElementComponent>
                        </div>
                    </div>
                </div>
            </animated.div>
        );
    });

    return (
        <div
            ref={listRef}
            className={classes.ElementsList}
            style={{ height: props.isEdit && props.isReorder ? props.elements.length * fixedHeight : '100%' }}
        >
            {elementsList}
        </div>
    );
};

export default ElementsList;
