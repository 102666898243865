import React from 'react';
import { CardCompleted } from '../components/Deck/DeckDetails';
import { ICard } from '../models/Card';
import { IDeck } from '../models/Deck';
import { IEdge } from '../models/Edge';
import { IUserAnswer } from '../models/Element';
import { createCtx } from './createCtx';

export type DeckContextType = {
    deck: IDeck;
    setDeck: (deck: IDeck) => void;
    cards: ICard[];
    updateCards: (cards: ICard[]) => void;
    addCard: (card: ICard) => void;
    updateCard: (card: ICard) => void;
    removeCard: (card: ICard) => void;
    edges: IEdge[];
    setEdges: React.Dispatch<React.SetStateAction<IEdge[]>>;
    addEdge: (edge: IEdge) => void;
    previousEdgeIndex: number;
    setPreviousEdgeIndex: React.Dispatch<React.SetStateAction<number>>;
    currentCardEdge: IEdge | undefined;
    setCurrentCardEdge: React.Dispatch<React.SetStateAction<IEdge | undefined>>;
    historyCardIds: number[];
    setHistoryCardIds: React.Dispatch<React.SetStateAction<number[]>>;
    currentHistoryIndex: number;
    setCurrentHistoryIndex: React.Dispatch<React.SetStateAction<number>>;
    isHistory: boolean;
    setProgressWhereToContinue: (
        deckData: IDeck,
        initial?: boolean,
        deckState?: {
            deck: IDeck;
            currentCardIndex: number;
            currentCardId: number;
        },
        queryCardNum?: string | null,
    ) => void;
    currentCardIndex: number;
    setCurrentCardIndex: React.Dispatch<React.SetStateAction<number>>;
    currentCard: ICard | undefined;
    currentCardProgress: CardCompleted;
    currentAnswerFromSession: () => IUserAnswer[] | undefined;
    deckProgress: CardCompleted[];
    // setDeckProgress: React.Dispatch<React.SetStateAction<CardCompleted[]>>;
    isDeckCompleted: boolean;
    nextCard: (nextIndex: number) => void;
    previousCard: () => void;
    previousButtonEnabled: boolean;
    nextButtonEnabled: boolean;
    currentCardCompleted: boolean;
    openCardDialog: boolean;
    setOpenCardDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

export const [useDeck, CtxProvider] = createCtx<DeckContextType>();
