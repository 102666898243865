import React, { Fragment, FunctionComponent, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import DeckDetails from '../../components/Deck/DeckDetails';
import { CategoryProvider } from '../../context/CategoryProvider';
import { DeckProvider } from '../../context/DeckProvider';
import DeckEditDetail from './DeckEditDetail/DeckEditDetail';

const Deck: FunctionComponent = () => {
    const { path } = useRouteMatch();

    const [resetKey, setResetKey] = useState(0);

    const reset = () => {
        setResetKey((val) => val + 1);
    };

    return (
        <Fragment>
            <CategoryProvider>
                <DeckProvider>
                    <Switch>
                        <Route exact path={`${path}/create`}>
                            <DeckEditDetail isCreate={true} />
                        </Route>
                        <Route path={`${path}/edit/:deckId/:cardId?`}>
                            <DeckEditDetail isCreate={false} />
                        </Route>
                        <Route path={`${path}/:deckId`}>
                            <DeckDetails key={`${resetKey}`} resetDeck={reset} />
                        </Route>
                    </Switch>
                </DeckProvider>
            </CategoryProvider>
        </Fragment>
    );
};

export default Deck;
