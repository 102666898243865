import { Formik } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/PageHeader/PageHeader';
import classes from './AccessCodeEditDetail.module.scss';
import useAccessCodeEditForm from './useAccessCodeEditForm';
import * as Yup from 'yup';
import AccessCodeEditDetailForm from './AccessCodeEditDetailForm/AccessCodeEditDetailForm';
import { useHistory, useParams } from 'react-router';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import { useConfig } from '../../../context/ConfigContext';
import { IAccessCode } from '../../../models/AccessCode';
import { addYears } from 'date-fns';

type AccessCodeEditDetailProps = {
    isCreate: boolean;
};

type ParamTypes = {
    accessCodeId: string;
};
const AccessCodeEditDetail: FunctionComponent<AccessCodeEditDetailProps> = ({ isCreate }) => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const history = useHistory();
    const [accessCode, setAccessCode] = useState<IAccessCode>({
        id: 0,
        title: '',
        code: '',
        user_count: 0,
        max_users: 10,
        expires_at: addYears(new Date(), 10).toISOString(),
    });

    const { accessCodeId } = useParams<ParamTypes>();
    const { response, loading } = useAxiosFetch<IAccessCode>(
        isCreate ? null : `${config.baseUrl}/accesscodes/${accessCodeId}`,
    );

    const { initialValues, handleSubmit, isSaved } = useAccessCodeEditForm(
        {
            title: accessCode.title,
            expires_at: accessCode.expires_at,
            max_users: accessCode.max_users,
        },
        isCreate,
    );

    const schema = Yup.object({
        title: Yup.string().required(t('Common:INPUT_ERROR_TITLE_REQUIRED')),
        max_users: Yup.number().min(
            accessCode.user_count > 0 ? accessCode.user_count : 1,
            t('Common:INPUT_ERROR_MIN_USER_COUNT', { min: accessCode.user_count > 0 ? accessCode.user_count : 1 }),
        ),
    });

    useEffect(() => {
        if (response) {
            setAccessCode(response.data);
        }
    }, [response]);

    const cancelHandler = () => {
        history.goBack();
    };

    return (
        <div className={classes.AccessCodeEditDetail}>
            <PageHeader title={t('Common:ACCESS_CODES_HEADER')} />

            {!loading && (
                <Formik
                    onSubmit={(formValues, actions) => handleSubmit(accessCode, formValues, actions)}
                    initialValues={initialValues}
                    validationSchema={schema}
                >
                    {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                        const disabledSubmit = isSubmitting || !(isValid && dirty);

                        return (
                            <AccessCodeEditDetailForm
                                accessCode={accessCode}
                                handleSubmit={handleSubmit}
                                disabledSubmit={disabledSubmit}
                                isCreate={isCreate}
                                cancelHandler={cancelHandler}
                            />
                        );
                    }}
                </Formik>
            )}
        </div>
    );
};

export default AccessCodeEditDetail;
