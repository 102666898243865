import React, { FunctionComponent } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { TagProvider } from '../../context/TagProvider';
import TagEditDetail from './TagEditDetail/TagEditDetail';

const Tags: FunctionComponent = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/create`} exact>
                <TagProvider>
                    <TagEditDetail isCreate={true} />
                </TagProvider>
            </Route>
            <Route path={`${path}/edit/:tagId`} exact>
                <TagProvider>
                    <TagEditDetail isCreate={false} />
                </TagProvider>
            </Route>
        </Switch>
    );
};

export default Tags;
