import React, { FunctionComponent, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import classes from './InputField.module.scss';
import CharactersLeft from '../CharactersLeft/CharactersLeft';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    name: string;
    label?: string;
    multiline?: boolean;
    rows?: number;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const InputField: FunctionComponent<Props> = (props) => {
    const [field, meta] = useField(props);
    const hasError = Boolean(meta.error && meta.touched);
    const [showPassword, setShowPassword] = useState(false);

    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (document.hasFocus() && ref.current?.contains(document.activeElement)) {
            if (props.autoFocus) {
                ref.current.focus();
                ref.current?.select?.();
            }
        }
    }, []);
    // console.log(meta);

    const handleClickShowPassword = () => {
        // setValues({ ...values, showPassword: !values.showPassword });
        setShowPassword((previousValue) => !previousValue);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const getAdornment = () => {
        switch (props.type) {
            case 'password':
                return {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? (
                                    <FontAwesomeIcon icon={faEye} />
                                ) : (
                                    <FontAwesomeIcon icon={faEyeSlash} />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                };
            default:
                undefined;
        }
    };

    return (
        <div className={classes.InputField}>
            <TextField
                ref={ref}
                inputProps={{
                    ...props,
                    ...field,
                    type: props.type === 'password' ? (showPassword ? 'text' : 'password') : props.type,
                }}
                label={props.label}
                error={hasError}
                helperText={hasError ? meta.error : ' '}
                multiline={props.multiline}
                onChange={(e) => {
                    field.onChange(props.name)(e.target.value);
                    props.onChange?.(e);
                }}
                onFocus={(e) => {
                    e.currentTarget.select();
                }}
                // className={`form-control ${className}`}
                variant="outlined"
                InputProps={getAdornment()}
            />

            <div className={classes.BelowField}>
                {props.maxLength && (
                    <CharactersLeft max={props.maxLength} current={field.value.length}></CharactersLeft>
                )}
            </div>
        </div>
    );
};

export default InputField;
