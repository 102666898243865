import React, { FunctionComponent } from 'react';
import { QuestionType } from '../../../../models/Element';
import { IElementProps } from '../../../Card/ElementsList/ElementsList';
import { useElement } from '../../ElementProvider';
import MultipleChoice from '../MultipleChoiceQuestion/MultipleChoice';
import OpenQuestion from '../OpenQuestion/OpenQuestion';
import Poll from '../Poll/Poll';
import classes from './QuestionView.module.scss';

const QuestionView: FunctionComponent<IElementProps> = (props) => {
    const { state, dispatch } = useElement();

    const questionViewByType = (type?: QuestionType) => {
        switch (type) {
            case 'multiple_choice':
                return (
                    <MultipleChoice
                        options={state.elementable?.options || []}
                        user_answers={state.elementable?.sessionAnswer?.options}
                        sessionAnswer={state.elementable?.sessionAnswer}
                        elementData={state}
                        onQuestionAnswered={props.onQuestionAnswered}
                        loaded={props.loaded}
                    ></MultipleChoice>
                );
            case 'open_question':
                return (
                    <OpenQuestion
                        body={state.elementable?.body || ''}
                        sessionAnswer={state.elementable?.sessionAnswer}
                        answer={state.elementable?.sessionAnswer ? state.elementable?.sessionAnswer.body : ''}
                        elementData={state}
                        onQuestionAnswered={props.onQuestionAnswered}
                        loaded={props.loaded}
                    ></OpenQuestion>
                );
            case 'poll':
                return (
                    <Poll
                        options={state.elementable?.options || []}
                        element={state}
                        user_answer={state.elementable?.sessionAnswer}
                        onQuestionAnswered={props.onQuestionAnswered}
                        loaded={props.loaded}
                    ></Poll>
                );
            default:
                return <div>{`This is an unknown element: ${state.elementable?.question_type?.type}`}</div>;
        }
    };

    return questionViewByType(state.elementable?.question_type?.type);
};

export default QuestionView;
