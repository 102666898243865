import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../InputField/InputField';
import classes from './TimerInput.module.scss';

interface ITimerInputProps {
    checked: boolean;
    onChange: (checked: boolean, time?: string) => void;
}

export interface FormFields {
    time: string;
}

const TimerInput: FunctionComponent<ITimerInputProps> = (props) => {
    const { t } = useTranslation();
    const [checked, setChecked] = React.useState(props.checked);

    // const handleTimeCheckedChange = (checked: boolean) => {
    //     setChecked(checked);
    //     props.onChange(checked);
    // };

    const handleTimeInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newTimerValue = event.target.value === '' ? '00:10' : event.target.value;
        // if (event.target.value) setFieldValue('timer', newTimerValue);
        props.onChange(checked, newTimerValue);
    };

    return (
        <div className={classes.TimedInput}>
            {checked && (
                <InputField
                    className={classes.TimeInput}
                    id="timer"
                    name={`timer`}
                    type="time"
                    min="00:10"
                    max="99:99"
                    defaultValue="00:10"
                    label={t('Common:ELEMENT_TIMER_LABEL')}
                    // type="number"
                    // min={0}
                    // max={180}
                    onChange={handleTimeInputChange}
                />
            )}
        </div>
    );
};

export default TimerInput;
