import DOMPurify from 'dompurify';
import React, { FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import CoverImage from '../../../../components/CoverImage/CoverImage';
import DefaultImageUploader from '../../../../components/DefaultImageUploader/DefaultImageUploader';
import { useConfig } from '../../../../context/ConfigContext';
import { defaultImageInfo, defaultImageTypes, IDefaultImage } from '../../../../models/Defaults';
import { getFilePathByName, getFilePathForDefaultImage, IFile } from '../../../../models/File';
import { BackgroundSize, SelectOption } from '../../../../models/ImageUpload';
import Axios from '../../../../utils/axios-instance';
import classes from './EditDefaultImage.module.scss';

interface EditDefaultImageProps {
    files?: IDefaultImage[] | null;
    defaultBackgroundSize?: BackgroundSize;
    onChange?: (file: IFile | undefined) => void;
    onChangeBackgroundSize?: (option: SelectOption | null) => void;
}

const EditDefaultImage: FunctionComponent<EditDefaultImageProps> = ({ files, onChange }) => {
    const { t } = useTranslation();
    const { config, setConfig } = useConfig();

    const options: SelectOption[] = [
        { value: 'cover', label: t('Categories:COVER_IMAGE_COVER_DESCRIPTION') },
        { value: 'contain', label: t('Categories:COVER_IMAGE_CONTAIN_DESCRIPTION') },
    ];

    const defaultFile = files?.find((file) => file.is_default == true);
    const [customFile, setCustomFile] = useState(files?.find((file) => file.is_default == false));
    const [imageSrc, setImageSrc] = useState(
        getFilePathByName(customFile?.file?.name) || getFilePathForDefaultImage(defaultFile?.file?.name) || '',
    );

    const key = defaultImageInfo.map((info) => info.key).find((key) => defaultFile?.key.includes(key));
    const info = key && defaultImageInfo.find((info) => info.key === key);
    const transparentBackground =
        key === defaultImageTypes.Logo ||
        key === defaultImageTypes.FooterPng ||
        key === defaultImageTypes.FooterWebp ||
        key === defaultImageTypes.FooterWebpMd ||
        key === defaultImageTypes.FooterWebpSm;

    const onUploadImageHandler = async (file: IFile | undefined) => {
        const newConfig = { ...config };

        if (file) {
            const response = await Axios.post(`${config.baseUrl}/images`, {
                key: defaultFile?.key,
                file_id: file?.id,
            });

            const customFile: IDefaultImage = response.data;
            setCustomFile(customFile);
            setImageSrc(getFilePathByName(customFile.file?.name) || '');

            if (defaultFile) {
                // update default images to configprovider
                newConfig.tenant.images[defaultFile.key] = [defaultFile, customFile];
            }

            toast.success(t('Common:TOAST_IMAGE_NEW_DEFAULT_SUCCESS'));
            onChange?.(customFile.file);
        } else {
            if (defaultFile) {
                newConfig.tenant.images[defaultFile.key] = [defaultFile];
            }

            toast.success(t('Common:TOAST_IMAGE_ORIGINAL_DEFAULT_SUCCESS'));
            setImageSrc(getFilePathForDefaultImage(defaultFile?.file?.name) || '');
        }
        setConfig(newConfig);
    };

    return (
        <div className={classes.EditDefaultImage}>
            <div className={`Box ${classes.Container}`}>
                {info && (
                    <div className={classes.Info}>
                        {<h3 className={classes.Title}>{info.title}</h3>}

                        {info.description && info.key && (
                            <div
                                className={classes.Description}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        t(`Common:IMAGE_INFO_DESCRIPTION_${info.key.toUpperCase()}`),
                                    ),
                                }}
                            ></div>
                        )}

                        {info.dimensions && info.format && (
                            <div className={classes.DimensionsAndFormat}>
                                {t(`Common:IMAGE_INFO_DIMENSIONS_AND_FORMAT`, {
                                    dimensions: info.dimensions,
                                    format: info.format.toUpperCase(),
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className={`${classes.Container}`}>
                <div className={`Box ${classes.Image} ${transparentBackground && classes.TransparentBackground}`}>
                    <CoverImage src={imageSrc} backgroundSize={'contain'}></CoverImage>
                </div>
                <div className={`${classes.FileUploader}`}>
                    <DefaultImageUploader
                        text={t('Common:IMAGE')}
                        onChange={onUploadImageHandler}
                        defaultFile={defaultFile}
                        customFile={customFile}
                    />
                </div>
            </div>
        </div>
    );
};

export default EditDefaultImage;
