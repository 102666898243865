import { Formik } from 'formik';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import Popup from 'reactjs-popup';
import * as Yup from 'yup';
import Button from '../../../components/Button/Button';
import TrashIcon from '../../../components/Icons/TrashIcon/TrashIcon';
import { useConfig } from '../../../context/ConfigContext';
import { useModule } from '../../../context/ModuleContext';
import { ICategory } from '../../../models/Categories';
import { IModule } from '../../../models/Module';
import AxiosInstance from '../../../utils/axios-instance';
import { formatDateToLocal } from '../../../utils/date';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import classes from './ModuleEditDetail.module.scss';
import ModuleEditDetailForm from './ModuleEditDetailForm/ModuleEditDetailForm';
import useModuleEditDetailForm from './useModuleEditDetailForm';

type ModuleEditDetailProps = {
    isCreate: boolean;
};

type ParamTypes = {
    moduleId: string;
};

const ModuleEditDetail: FunctionComponent<ModuleEditDetailProps> = ({ isCreate }) => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const location = useLocation();

    const history = useHistory();

    const [categoryId, setCategoryId] = useState(0);
    const { module, setModule } = useModule();
    const { moduleId } = useParams<ParamTypes>();
    const { response, loading } = useAxiosFetch<IModule>(isCreate ? null : `${config.baseUrl}/modules/${moduleId}`);

    const { initialValues, handleSubmit, isSaved } = useModuleEditDetailForm(
        {
            title: module.title,
            description: module.description,
            category_id: isCreate ? categoryId : module.category_id,
        },
        isCreate,
    );

    const schema = Yup.object({
        title: Yup.string().required(t('Common:INPUT_ERROR_TITLE_REQUIRED')),
        // .max(16, t('Common:INPUT_ERROR_TITLE_MAX', { max: 16 }))
        description: Yup.string().required(t('Common:INPUT_ERROR_DESCRIPTION_REQUIRED')),
        // .max(80, t('Common:INPUT_ERROR_DESCRIPTION_MAX', { max: 80 }))
    });

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    useEffect(() => {
        const category = location.state as ICategory;

        if (category) {
            setCategoryId(category.id);
        }
    }, []);

    useEffect(() => {
        if (response) {
            const moduleData = response.data;
            setModule(moduleData);
        }
    }, [response]);

    const cancelHandler = () => {
        history.goBack();
    };

    const deleteHandler = async () => {
        setOpen((o) => !o);
    };

    const notifyDeleted = () => toast.success(t('Common:TOAST_MODULE_DELETED_SUCCESS'));
    const notifyNotDeleted = () => toast.error(t('Common:TOAST_MODULE_DELETED_ERROR'));

    const permanentlyDeleteHandler = async () => {
        setOpen(false);

        try {
            await AxiosInstance.delete(`${config.baseUrl}/modules/${moduleId}`);
            cancelHandler();
            notifyDeleted();
        } catch (error) {
            notifyNotDeleted();
        }
    };

    return (
        <Fragment>
            <div className={classes.EditModuleContainer}>
                <div className={classes.Header}>
                    <h1 className={`h1 wrap-text ${classes.Title}`}>
                        {isCreate ? t('Modules:MODULE_CREATE_TITLE') : t('Modules:MODULE_EDIT_TITLE')}
                    </h1>
                    {!loading && !isCreate && (
                        <div className={classes.Subtitle}>
                            <span className={classes.Modified}>
                                {t('Common:MODIFIED', { date: formatDateToLocal(module.updated_at, 'd MMMM HH:mm') })}
                            </span>
                        </div>
                    )}
                </div>

                {loading && (
                    <div className={classes.Loader}>
                        <div className="spinner card Box"></div>
                    </div>
                )}

                {!loading && (
                    <Fragment>
                        <Formik
                            onSubmit={(formValues, actions) => handleSubmit(module, formValues, actions)}
                            initialValues={initialValues}
                            validationSchema={schema}
                        >
                            {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                                const disabledSubmit = isSubmitting || !(isValid && dirty);

                                return (
                                    <ModuleEditDetailForm
                                        isCreate={isCreate}
                                        handleFormSubmit={handleSubmit}
                                        isSubmitting={isSubmitting}
                                        disabledSubmit={disabledSubmit}
                                        isSaved={isSaved}
                                        deleteHandler={deleteHandler}
                                        cancelHandler={cancelHandler}
                                    />
                                );
                            }}
                        </Formik>
                    </Fragment>
                )}
            </div>

            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <div className={`modal Box`}>
                    <h2 className="h4">
                        <strong>{t('Common:WARNING_HEADER')}</strong>
                    </h2>
                    <p>{t('Modules:DELETION_WARNING_MESSAGE')}</p>
                </div>

                <div className={`${classes.formActions} ${classes.flexGap}`}>
                    <Button text={t('Common:BUTTON_CANCEL')} alt={true} border={true} onClick={closeModal}></Button>
                    {!isCreate && (
                        <Button
                            text={t('Common:BUTTON_PERMANENTLY_DELETE')}
                            danger
                            icon={<TrashIcon />}
                            iconSide="left"
                            onClick={permanentlyDeleteHandler}
                        ></Button>
                    )}
                </div>
            </Popup>
        </Fragment>
    );
};

export default ModuleEditDetail;
