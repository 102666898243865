import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import Popup from 'reactjs-popup';
import partyImage from '../../../assets/feest.png';
import { useConfig } from '../../../context/ConfigContext';
import { ICard } from '../../../models/Card';
import { IProgress } from '../../../models/Progress';
import { IQuest } from '../../../models/Quest';
import AxiosInstance from '../../../utils/axios-instance';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import Button from '../../Button/Button';
import RedoIcon from '../../Icons/RedoIcon/RedoIcon';
import CardProgressBar from './CardProgressBar/CardProgressBar';
import classes from './DeckBranchResults.module.scss';

type DeckBranchResultsProps = {
    currentCard?: ICard;
    deckId: number;
    deckTitle: string;
    deckResultsUrl: string;
    setShowDeckResults: React.Dispatch<React.SetStateAction<boolean>>;
    showDeckResults: boolean;
    deckReset: () => void;
    showEndings: boolean;
};

type DeckBranchResultResponse = {
    userEndCard?: string;
    totalAnswers?: number;
    endCards?: EndCard[];
    user_progress?: IProgress[];
    quests?: IQuest[];
};

export type EndCard = {
    title: string;
    answerCount: number;
    userHasAnswered: boolean;
};

const DeckBranchResults: FunctionComponent<DeckBranchResultsProps> = ({
    currentCard,
    deckId,
    deckTitle,
    deckResultsUrl,
    setShowDeckResults,
    showDeckResults,
    deckReset,
    showEndings,
}) => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const history = useHistory();
    const location = useLocation();
    const { response, loading } = useAxiosFetch<DeckBranchResultResponse>(deckResultsUrl);
    const [result, setResult] = useState<DeckBranchResultResponse>();

    const onClickContinueHandler = () => {
        history.goBack();
    };
    const closeModal = () => setShowDeckResults(false);

    useEffect(() => {
        if (response) {
            const result = response.data;
            setResult(result);
        }
    }, [response]);

    const onClickReplayHandler = async () => {
        closeModal();
        const response = await AxiosInstance.post(`${config.baseUrl}/decksessions`, { deck_id: deckId });
        if (response) {
            deckReset();
        }
    };

    const endCardsList =
        showEndings &&
        result?.endCards?.map((card) => {
            return (
                <CardProgressBar
                    key={`progressbar_${card.title}`}
                    title={card.userHasAnswered ? card.title : t('Deck:BRANCHING_NOT_ENCOUNTERED_TITLE')}
                    percentage={card.answerCount / (result.totalAnswers || 1)}
                />
            );
        });

    const userEndCardTitle = result ? result.userEndCard : '';

    return (
        <Fragment>
            <Popup className={'results-popup'} open={showDeckResults} closeOnDocumentClick={false} onClose={closeModal}>
                <div className={`Box ${classes.ResultContainer}`}>
                    <div className={classes.DeckResultsHeader}>
                        <h2 className={classes.DeckResultsTitle}>{t('Deck:RESULT_BRANCHING_TITLE')}</h2>
                        <h3 className={`h2 ${classes.DeckResultsSubtitle}`}>
                            {t('Deck:RESULT_BRANCHING_SUBTITLE', { title: deckTitle })}
                        </h3>
                        {showEndings && <div className={classes.DeckResultEndpoint}>{` '${userEndCardTitle}' `}</div>}

                        {showEndings && (
                            <div className={classes.DeckResultOverviewDescription}>
                                {t('Deck:RESULT_BRANCHING_OVERVIEW_DESCRIPTION')}
                            </div>
                        )}
                    </div>
                    {showEndings ? (
                        <div className={classes.EndCardsContainer}>{endCardsList}</div>
                    ) : (
                        <div className={classes.PartyImageContainer}>
                            <div
                                className={classes.PartyImage}
                                style={{
                                    backgroundImage: `url(${partyImage})`,
                                }}
                            ></div>
                        </div>
                    )}
                </div>
                <div className={classes.ActionButtons}>
                    <Button
                        className={`${classes.ReplayButton}`}
                        text={t('Deck:BUTTON_REPLAY')}
                        alert
                        onClick={onClickReplayHandler}
                        icon={<RedoIcon />}
                        iconSide="left"
                    ></Button>
                    <button
                        className={`${classes.ContinueButton} btn btn-flavour btn-flavour--alt`}
                        onClick={onClickContinueHandler}
                    >
                        {t('Deck:DECK_BUTTON_CONTINUE')}
                    </button>
                </div>
            </Popup>
        </Fragment>
    );
};

export default DeckBranchResults;
