import { faGripLines, faMinus, faPen, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animated, config as SpringConfig, useSprings } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import swap from 'lodash-move';
import clamp from 'lodash.clamp';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Button from '../../../../../../components/Button/Button';
import PublicationStateLabel from '../../../../../../components/PublicationStateLabel/PublicationStateLabel';
import { IDeck } from '../../../../../../models/Deck';
import classes from './DecksList.module.scss';

interface DecksListProps {
    items: IDeck[];
    taskButtonOnClickHandler: (deck: IDeck) => void;
    taskButtonOnEditClickHandler: (deck: IDeck) => void;
    removeDeck: (deck: IDeck) => void;
    onReorderItems: (decks: IDeck[]) => void;
}

const DecksList: FunctionComponent<DecksListProps> = ({
    items,
    taskButtonOnClickHandler,
    taskButtonOnEditClickHandler,
    removeDeck,
    onReorderItems,
}) => {
    const fixedHeight = 50;
    const onDragScale = 1.02;
    // Reorder
    const [dragging, setDragging] = useState(false);
    const [draggingCurrentIndex, setDraggingCurrentIndex] = useState(0);
    const hasDragged = React.useRef(false);

    const fn =
        (order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) =>
        (index: number) =>
            active && index === originalIndex
                ? {
                      y: curIndex * fixedHeight + y,
                      scale: onDragScale,
                      zIndex: 1,
                      shadow: 5,
                      immediate: (key: string) => key === 'zIndex',
                      config: (key: string) => (key === 'y' ? SpringConfig.stiff : SpringConfig.default),
                  }
                : {
                      y: order.indexOf(index) * fixedHeight,
                      scale: 1,
                      zIndex: 0,
                      shadow: 0,
                      immediate: false,
                  };

    const order = useRef(items.map((_, index) => index)); // Store indicies as a local ref, this represents the item order
    const [springs, setSprings] = useSprings(items.length, fn(order.current)); // Create springs, each corresponds to an item, controlling its transform, scale, etc.

    let originalOrder = items.map((item, index) => {
        return { item_id: item.id, index: index };
    });

    const bind = useGesture(
        {
            onDrag: ({ event, args: [originalIndex], active, first, last, movement: [, y] }) => {
                event.preventDefault();

                if (first) hasDragged.current = true;
                if (last) setTimeout(() => (hasDragged.current = false), 0);

                setDragging(true);

                const curIndex = order.current.indexOf(originalIndex);
                setDraggingCurrentIndex(originalIndex);
                const curRow = clamp(Math.round((curIndex * fixedHeight + y) / fixedHeight), 0, items.length - 1);
                const newOrder = swap(order.current, curIndex, curRow);
                setSprings.start(fn(newOrder, active, originalIndex, curIndex, y)); // Feed springs new style data, they'll animate the view without causing a single render
                if (!active) {
                    setDragging(false);

                    if (newOrder !== order.current) {
                        const newOrderedItems = newOrder.map((val, index) => {
                            return { item_id: originalOrder[val].item_id, order: index };
                        });

                        const orderedItems = items
                            .slice()
                            .sort(
                                (a, b) =>
                                    newOrderedItems.findIndex((item) => a.id === item.item_id) -
                                    newOrderedItems.findIndex((item) => b.id === item.item_id),
                            );

                        onReorderItems(orderedItems);

                        order.current = newOrder;
                    }
                }
            },
            onClickCapture: (event) => {
                if (hasDragged.current) {
                    event.event.stopPropagation();
                }
            },
        },
        {
            // delay: true,
            enabled: true,
            drag: {
                filterTaps: true,
            },
        },
    );

    useEffect(() => {
        order.current = items.map((_, index) => index);
        setSprings.start(fn(order.current));
        originalOrder = items.map((item, index) => {
            return { item_id: item.id, index: index };
        });
    }, [items]);

    const listRef = useRef<HTMLDivElement>(null);

    const decksList = items.map((item, index) => {
        const spring = springs[index];

        const zIndex = spring.zIndex;
        const y = spring.y;
        const scale = spring.scale;
        const boxShadow =
            dragging && draggingCurrentIndex === index
                ? 'rgba(0, 0, 0, 0.15) 0px 5px 10px 0px'
                : 'rgba(0, 0, 0, 0.15) 0px 0px 0px 0px';

        return (
            <animated.div
                {...bind(index)}
                key={index}
                style={{
                    zIndex,
                    y,
                    scale,
                    touchAction: 'none',
                }}
            >
                <div className={`${classes.Item}`} style={{ boxShadow, height: fixedHeight }}>
                    <div className={classes.DeckDetails}>
                        <div className={classes.ItemHandle}>
                            <FontAwesomeIcon icon={faGripLines}></FontAwesomeIcon>
                        </div>

                        <div className={classes.DeckName}>{`${item.title}`}</div>
                        <PublicationStateLabel isPublished={item.is_published} />
                    </div>
                    <div className="flex-grow"></div>
                    <div className={classes.DeckControls}>
                        <Button
                            text=""
                            border
                            icon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
                            onClick={() => taskButtonOnClickHandler(item)}
                        />
                        <Button
                            text=""
                            border
                            icon={<FontAwesomeIcon className={classes.EditIcon} icon={faPen} />}
                            onClick={() => taskButtonOnEditClickHandler(item)}
                        ></Button>
                        <Button
                            text=""
                            danger
                            icon={<FontAwesomeIcon icon={faMinus} />}
                            onClick={() => removeDeck(item)}
                        ></Button>
                    </div>
                </div>
            </animated.div>
        );
    });

    return (
        <>
            <div ref={listRef} className={classes.DecksList} style={{ height: items.length * fixedHeight }}>
                {decksList}
            </div>
        </>
    );
};

export default DecksList;
