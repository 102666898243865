/* eslint-disable @typescript-eslint/no-explicit-any */
import { Editor } from '@tinymce/tinymce-react';
import DOMPurify from 'dompurify';
import { useFormikContext } from 'formik';
import React, { FunctionComponent } from 'react';
import { IElement } from '../../models/Element';
import classes from './TextElementField.module.scss';
import { FormFields } from './useTextElementForm';

type Props = {
    element: IElement;
    onChange?: (id: number, value: string) => void;
};

const TextElementField: FunctionComponent<Props> = ({ element, onChange }) => {
    const { setFieldValue } = useFormikContext<FormFields>();

    const handleEditorChange = (e) => {
        const sanitized = DOMPurify.sanitize(e.target.getContent(), {
            ADD_ATTR: ['target'],
        });
        setFieldValue('body', sanitized);
        onChange?.(element.id, sanitized);
    };

    return (
        <div className={classes.TextElementField}>
            <Editor
                apiKey="ujlfgzlc89rbjmaeo8z4uz2row57k2a90t5675o7sre0mbv2"
                initialValue={element.elementable?.body || ''}
                init={{
                    document_base_url: 'app/',
                    branding: false,
                    inline: false,
                    auto_focus: true,
                    menubar: false,
                    plugins: [
                        'advlist autolink autoresize lists link image imagetools',
                        'print preview anchor',
                        'searchreplace visualblocks',
                        'table paste wordcount',
                    ],
                    paste_block_drop: false,
                    image_dimensions: false,
                    object_resizing: false,
                    toolbar_mode: 'floating',
                    toolbar:
                        'formatselect | bold italic forecolor | alignleft aligncenter alignright alignjustify  | bullist numlist outdent indent | link image | visualblocks',
                }}
                onChange={handleEditorChange}
            />
        </div>
    );
};

export default TextElementField;
