import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Hint.module.scss';

interface HintProps {
    isSaved: boolean;
    timerFeedback: boolean;
    outOfTime: boolean;
    minutes: number;
    seconds: number;
}

const Hint: FunctionComponent<HintProps> = ({ isSaved, timerFeedback, outOfTime, minutes, seconds }) => {
    const { t } = useTranslation();
    const timedFeedback = outOfTime
        ? t('Common:TIMER_QUESTION_OUT_OF_TIME')
        : minutes > 0
        ? t('Common:TIMER_STATS_FEEDBACK_MINUTES', {
              minutes: minutes,
              seconds: seconds,
          })
        : t('Common:TIMER_STATS_FEEDBACK', {
              time: seconds,
          });

    return (
        <div className={classes.Hint}>
            {!isSaved ? t('Common:OPEN_QUESTION_TIP') : timerFeedback ? timedFeedback : ''}
        </div>
    );
};

export default Hint;
