import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { parseJSON } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useFormikContext } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import InputField from '../../../../components/InputField/InputField';
import { IAccessCode } from '../../../../models/AccessCode';
import { FormFields } from '../useAccessCodeEditForm';
import classes from './AccessCodeEditDetailForm.module.scss';

interface AccessCodeEditDetailFormProps {
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    isCreate: boolean;
    disabledSubmit: boolean;
    cancelHandler: () => void;
    accessCode: IAccessCode;
}

const AccessCodeEditDetailForm: FunctionComponent<AccessCodeEditDetailFormProps> = ({
    handleSubmit,
    isCreate,
    disabledSubmit,
    cancelHandler,
    accessCode,
}) => {
    const { t } = useTranslation();
    const { setFieldValue } = useFormikContext<FormFields>();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [selectedDate, setSelectedDate] = useState<Date | undefined>();

    useEffect(() => {
        if (accessCode.expires_at != undefined) {
            const date = parseJSON(accessCode.expires_at);
            const localDate = utcToZonedTime(date, timeZone);
            setSelectedDate(localDate);
        }
    }, []);

    const handleDateChange = (date: MaterialUiPickersDate | null) => {
        if (date) {
            setSelectedDate(date);

            const utcDate = zonedTimeToUtc(date, timeZone);
            setFieldValue('expires_at', utcDate.toISOString());
        }
    };

    return (
        <form className={`${classes.AccessCodeEditDetailForm}`} noValidate={true} onSubmit={handleSubmit}>
            <div className={`Box ${classes.InputContainer}`}>
                <div className={`${classes.formGroup}`}>
                    <InputField
                        name="title"
                        type="text"
                        label={t('Common:TITLE')}
                        placeholder={t('Common:ACCESS_CODE_TITLE_INPUT_PLACEHOLDER')}
                    />
                </div>

                <div className={`${classes.formGroup}`}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                            id="expires_at"
                            name="expires_at"
                            label={t('Common:ACCESS_CODE_EXPIRES_AT_LABEL')}
                            InputLabelProps={{ shrink: true }}
                            value={selectedDate}
                            disablePast
                            animateYearScrolling
                            ampm={false}
                            format="dd MMMM yyyy HH:mm"
                            views={['date']}
                            onChange={handleDateChange}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                <div className={classes.formGroup}>
                    <InputField
                        name="max_users"
                        type="number"
                        label={t('Common:ACCESS_CODE_MAX_USERS')}
                        placeholder={t('Common:ACCESS_CODE_MAX_USERS_INPUT_PLACEHOLDER')}
                        min={accessCode.user_count > 0 ? accessCode.user_count : 1}
                    />
                </div>
            </div>

            <div className={classes.formActions}>
                <Button text={t('Common:BUTTON_CANCEL')} onClick={cancelHandler} alt border />
                <Button
                    text={isCreate ? t('Common:BUTTON_CREATE') : t('Common:BUTTON_SAVE')}
                    type="submit"
                    disabled={disabledSubmit}
                    alt
                />
            </div>
        </form>
    );
};

export default AccessCodeEditDetailForm;
