import { format as formatDate, isDate } from 'date-fns';
import { enGB, enUS, nl, nlBE } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import BackendHTTP from 'i18next-http-backend';
import BackendLocize from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';
registerLocale('enGB', enGB);
registerLocale('enUS', enUS);
registerLocale('nl', nl);
registerLocale('nlBE', nlBE);

type LocalesType = {
    [key: string]: Locale;
};

export const locales: LocalesType = {
    ['enGB']: enGB,
    ['enUS']: enUS,
    ['nl']: nl,
    ['nlBE']: nlBE,
};

const isProduction = process.env.REACT_APP_ENV === 'production';

const locizeOptions = {
    projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID || '',
    apiKey: process.env.REACT_APP_LOCIZE_API_KEY || '',
    referenceLng: 'en',
    version: process.env.REACT_APP_LOCIZE_VERSION,
    allowedAddOrUpdateHosts: ['flavour.herocenter.local'],
    // allowedHosts: ['flavour.hero-centre.local'],
};

const productionBackendOptions = {
    loadPath: '/app/locales/{{lng}}/{{ns}}.json',
};

if (!isProduction) {
    // locize-lastused
    // sets a timestamp of last access on every translation segment on locize
    // -> safely remove the ones not being touched for weeks/months
    // https://github.com/locize/locize-lastused
    i18n.use(LastUsed);
    i18n.use(BackendLocize);
} else {
    i18n.use(BackendHTTP);
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(
        {
            // lng: 'nl', // Language to use (overrides language detection)
            fallbackLng: {
                default: ['en'],
            },
            ns: [
                'Auth',
                'Categories',
                'Conditions',
                'Common',
                'Date',
                'Deck',
                'Groups',
                'Roles',
                'Login',
                'Members',
                'Modules',
                'Management',
                'Profile',
                'Library',
                'Quests',
            ],
            load: 'languageOnly',
            defaultNS: 'Common',
            debug: false,
            saveMissing: false, // do not use saveMissing in production
            saveMissingTo: 'current',
            interpolation: {
                escapeValue: false,
                format: (value, format, lng) => {
                    if (isDate(value)) {
                        if (lng && format) {
                            const locale = locales[lng];
                            return formatDate(value, format, { locale });
                        }
                    }

                    return value;
                },
            },
            backend: !isProduction ? locizeOptions : productionBackendOptions,
            locizeLastUsed: locizeOptions,
        },
        (err) => {
            console.log(
                `%ci18n language: %c${i18n.language}`,
                'color: #264567; font-size: 12px',
                `color: #264567; font-size: 12px; font-weight: bold;`,
            );
            console.groupEnd();
            setDefaultLocale(i18n.language);
        },
    );

export default i18n;
