import { gsap } from 'gsap';
import React, { createRef, FunctionComponent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { IGroup } from '../../../../models/Group';
import GroupCover, { IncrementedRef } from '../GroupCover/GroupCover';
import classes from './GroupSection.module.scss';

export type IGroupSection = {
    title: string;
    groups: IGroup[];
};

type GroupSectionProps = {
    groupSection: IGroupSection;
    noGroupMessage?: string;
};

const GroupSection: FunctionComponent<GroupSectionProps> = ({ groupSection, noGroupMessage }) => {
    const [groups, setGroups] = useState<IGroup[]>([]);
    const containerRef = createRef<HTMLDivElement>();
    const history = useHistory();
    const tl = gsap.timeline();
    const list = useRef<IncrementedRef[]>([]);
    const [refOnce, setRefOnce] = useState(false);

    useEffect(() => {
        if (groupSection.groups) {
            setGroups(groupSection.groups);
        }
    }, [groupSection]);

    const animateInList = (listReference: IncrementedRef[]) => {
        const l = listReference.map((el) => el.current);
        tl.set(l, { alpha: 0, scale: 0.7 });
        tl.to(l, {
            duration: 0.25,
            alpha: 1,
            scale: 1,
            ease: 'back',
            stagger: {
                amount: 0.35,
            },
        });
    };

    const setRefs = (target: IncrementedRef | null, listReference: IncrementedRef[], itemIndex: number) => {
        if (target && listReference && !refOnce) {
            const ref = (list.current[itemIndex] = target);
            if (itemIndex === groups.length - 1) {
                // All refs are set for this list. So animation is possible.
                animateInList(listReference);
                setRefOnce(true);
            }
            return ref;
        }
    };

    const groupsCoverList = groups.map((group, i) => {
        return (
            <GroupCover
                group={group}
                key={group.id}
                ref={(target) => {
                    setRefs(target, list.current, i);
                }}
                clickHandler={() => {
                    history.push({ pathname: `groups/${group.id}`, state: group });
                }}
            ></GroupCover>
        );
    });

    return (
        <div className={classes.GroupSection}>
            <div className={`${classes.Header}`}>
                <h2 className={`h3 ${classes.Title}`}>{groupSection.title}</h2>
            </div>
            <div className={classes.Group} ref={containerRef}>
                {groups.length === 0 && noGroupMessage && <div className={`${classes.NoGroup}`}>{noGroupMessage}</div>}
                {groups.length != 0 && groupsCoverList}
            </div>
        </div>
    );
};

export default GroupSection;
