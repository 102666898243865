import { animated } from '@react-spring/web';
import React, { FunctionComponent, useEffect } from 'react';
import { useDeck } from '../../../context/DeckContext';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { ICard } from '../../../models/Card';
import { IElement, IUserAnswer } from '../../../models/Element';
import { getColor } from '../../../utils/colors';
import Card from '../../Card/Card';
import { IUserAnswerError } from '../../Elements/Question/OpenQuestion/OpenQuestionFormHook';
import classes from './Cards.module.scss';

interface CardsProps {
    cards: ICard[];
    onNavigateToCardById: (cardId: number | undefined) => void;
    onQuestionAnswered: (element: IElement, userAnswer: IUserAnswer | IUserAnswerError) => void;
    addNewCardHandler: (title: string) => Promise<ICard | undefined>;
    onReorderElementsTriggered: () => void;
}

const Cards: FunctionComponent<CardsProps> = ({
    cards,
    onNavigateToCardById,
    onQuestionAnswered,
    addNewCardHandler,
    onReorderElementsTriggered,
}) => {
    const { deck, historyCardIds, currentHistoryIndex } = useDeck();
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const cardColor = getColor('--card-overlay-background-color');

    let perspectiveClass = 'perspective-ismobile';
    if (isDesktop) {
        perspectiveClass = 'perspective-isdesktop';
    }

    const convertRemToPixels = (rem) => {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    };

    useEffect(() => {
        console.log('history card ids', historyCardIds);
    }, [historyCardIds]);

    return (
        <animated.div className={`${classes.TransitionGroup} ${classes[perspectiveClass]}`}>
            {historyCardIds.map((cardId, i) => {
                const card = cards.find((card) => card.id === cardId);

                const current = currentHistoryIndex === i;
                const diff = currentHistoryIndex - i;
                const diffAbs = Math.abs(currentHistoryIndex - i);

                const display = !current
                    ? diffAbs <= (isDesktop ? 3 : 2) && diff != 0 && deck.allow_history
                        ? 'flex'
                        : 'none'
                    : 'flex';

                let stateClass: 'card-previous' | 'card-current' | 'card-next' | 'card-other' = 'card-other';
                if (current) stateClass = 'card-current';

                const position = current ? 'initial' : 'absolute';

                const zIndex = current ? 99 : 99 - diffAbs;

                const cardsWithOpacity = isDesktop ? 4 : 2;
                const opacity = diffAbs >= 1 ? 0.98 : 1; // 0.98 because areatext input is not hidden?
                const overlayOpacity =
                    diffAbs >= 1 ? Math.min(Math.max(0.25 + diffAbs * (1 / cardsWithOpacity), 0), 1) : 1;
                const backgroundColor = `rgba(${cardColor}, ${overlayOpacity})`;

                // Rotation
                const rz = 0;
                const ry = 0;

                // // Translate X
                const offsetX = isDesktop ? 25 : 0;
                const incrementX = isDesktop ? 50 : (convertRemToPixels(1) - 4) / cardsWithOpacity;
                const tx = diff < 0 ? -offsetX - incrementX * diffAbs : diff > 0 ? offsetX + incrementX * diffAbs : 0;

                // // Translate Y
                const offsetY = 0;
                const incrementY = isDesktop ? 0 : incrementX;
                const ty = offsetY + incrementY * diffAbs;

                // // Translate Z
                const offsetZ = isDesktop ? 10 : 50;
                const incrementZ = isDesktop ? 50 : 50;
                const tz = isDesktop
                    ? diff < 0
                        ? -offsetZ - incrementZ * diffAbs
                        : diff > 0
                        ? -offsetZ - incrementZ * diffAbs
                        : 0
                    : 0;
                // const tz = 0;

                const height = `calc(100% - ${incrementY * 2 * diffAbs}px`;

                // // Transform
                const transform = `translateX(${-tx}px) translateY(${ty}px) rotateZ(${rz}deg) rotateY(${ry}deg) translateZ(${tz}px)`;

                return (
                    <Card
                        key={i}
                        cardData={card}
                        isEdit={false}
                        style={{
                            display: display,
                            position: position,
                            zIndex: zIndex,
                            opacity: opacity,
                            transform: transform,
                            height: height,
                        }}
                        overlayStyle={{ backgroundColor: backgroundColor }}
                        stateClass={stateClass}
                        current={current}
                        onNavigateToCardById={onNavigateToCardById}
                        onQuestionAnswered={onQuestionAnswered}
                        addNewCardHandler={addNewCardHandler}
                        onReorderElementsTriggered={onReorderElementsTriggered}
                    ></Card>
                );
            })}
        </animated.div>
    );
};

export default Cards;
