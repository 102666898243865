import { faTrashCan, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import React, { Fragment, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import Button from '../../../../components/Button/Button';
import CardsList from '../../../../components/Card/CardsList/CardsList';
import CardDialog from '../../../../components/CardDialog/CardDialog';
import CardFlowWIP from '../../../../components/CardFlowWIP/CardFlowWIP';
import BoxHeader from '../../../../components/UI/Box/BoxHeader/BoxHeader';
import { useConfig } from '../../../../context/ConfigContext';
import { useDeck } from '../../../../context/DeckContext';
import { ICard } from '../../../../models/Card';
import { default as AxiosInstance } from '../../../../utils/axios-instance';
import classes from './CardsForm.module.scss';

type CardsFormProps = {
    cardId?: string;
    onAddCardHandler: (title?: string) => Promise<ICard | undefined>;
    openCardFlowDialog: boolean;
    closeCardFlowDialogHandler: () => void;
};

const CardsForm: FunctionComponent<CardsFormProps> = ({
    cardId,
    onAddCardHandler,
    openCardFlowDialog,
    closeCardFlowDialogHandler,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    const {
        deck,
        cards,
        addCard,
        removeCard,
        currentCardIndex,
        setCurrentCardIndex,
        currentCard,
        openCardDialog,
        setOpenCardDialog,
    } = useDeck();

    const [openDeleteCardModal, setOpenDeleteCardModal] = useState(false);
    const [cardToDelete, setCardToDelete] = useState<ICard>();

    const { config } = useConfig();

    const notifyCardDeleted = () => toast.success(t('Common:TOAST_CARD_DETELED_SUCCESS'));
    const notifyCardNotDeleted = () => toast.error(t('Common:TOAST_CARD_DELETED_ERROR'));

    const onViewCardById = () => {
        if (currentCard) history.push({ pathname: `/deck/${deck.id}`, state: { deck, currentCardId: currentCard.id } });
    };
    const usePrevCardsLength = (value: number | undefined) => {
        const ref = useRef<number>();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const prevCardsLength = usePrevCardsLength(cards?.length);

    const lastCard = () => {
        setCurrentCardIndex(() => {
            if (cards) {
                const max = cards.length - 1;
                return max;
            } else {
                return 0;
            }
        });
    };

    const onNavigateToCardById = (cardId: number | undefined) => {
        setCurrentCardIndex((previousValue) => {
            if (cards) {
                const cardIndex = cards?.findIndex((card) => {
                    return card.id === cardId;
                });

                if (cardIndex === -1) {
                    return currentCardIndex;
                }

                return cardIndex;
            } else {
                return previousValue;
            }
        });
    };

    const editCardById = useCallback(
        (cardId: number | undefined) => {
            onNavigateToCardById(cardId);
            setOpenCardDialog(true);
        },
        [cardId, cards],
    );

    const deleteCardById = async (card: ICard | undefined) => {
        setOpenDeleteCardModal(true);
        setCardToDelete(card);
    };

    const permanentlyDeleteCardHandler = async () => {
        setOpenDeleteCardModal(false);

        if (cards.length === 1) {
            return;
        }

        if (cardToDelete) {
            try {
                await AxiosInstance.delete(`${config.baseUrl}/cards/${cardToDelete.id}`);
                removeCard(cardToDelete);
                notifyCardDeleted();
            } catch (error) {
                notifyCardNotDeleted();
            }
        }
    };

    const notifyCopied = () => toast.success(t('Common:TOAST_CARD_COPIED_SUCCESS'));
    const notifyCopyFailed = () => toast.success(t('Common:TOAST_CARD_COPIED_FAILED'));

    const copyCardById = (id: number) => {
        AxiosInstance.get(`${config.baseUrl}/cards/duplicate/${id}`)
            .then((response) => {
                const card: ICard = response.data;
                if (card) {
                    addCard(card);
                }
                notifyCopied();
            })
            .catch((error) => {
                notifyCopyFailed();
            });
    };

    // TODO: find another way to set/get specific data bases on other set data
    useEffect(() => {
        if (cards) {
            if (prevCardsLength) {
                if (prevCardsLength < cards.length) {
                    // TODO: change to something else when cards can be added to other index
                    if (!openCardDialog) lastCard();
                }
            }
        }
    }, [cards]);

    useEffect(() => {
        if (cardId !== undefined) {
            setOpenCardDialog(true);
        }
    }, [cardId]);

    const handleDialogClose = useCallback(() => setOpenCardDialog(false), []);

    return (
        <Fragment>
            <div className={`Box`}>
                <div className={classes.Header}>
                    <BoxHeader title={`${t('Common:CARDS_LIST_HEADER')} (${cards.length})`}></BoxHeader>
                </div>
                <CardsList
                    items={cards}
                    isEdit={true}
                    onCopyCardById={copyCardById}
                    onEditCardById={editCardById}
                    onDeleteCardById={deleteCardById}
                ></CardsList>
            </div>

            <CardFlowWIP
                cards={cards}
                open={openCardFlowDialog}
                closeHandler={closeCardFlowDialogHandler}
            ></CardFlowWIP>

            <CardDialog
                key={currentCard?.id}
                cardData={currentCard}
                open={openCardDialog}
                closeClickHandler={handleDialogClose}
                onAddCardHandler={onAddCardHandler}
                onDeleteCardHandler={deleteCardById}
                onNavigateToCardById={editCardById}
                onViewCardById={onViewCardById}
            ></CardDialog>

            <Dialog
                open={openDeleteCardModal}
                onClose={() => setOpenDeleteCardModal(false)}
                className={classes.WarningDialog}
                PaperProps={{ className: `dialog` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:WARNING_HEADER')}</DialogTitle>
                <DialogContent>
                    {cardToDelete && cardToDelete?.target_of_edges.length > 0 ? (
                        <>
                            <p>{t('Common:CARD_TARGET_DELETION_WARNING_MESSAGE', { cardTitle: cardToDelete.title })}</p>

                            <div className={classes.SourceCardList}>
                                <h6 className={classes.SourceCardListTitle}>{t('Common:CARD_LINK_DELETION_LABEL')}</h6>
                                {cardToDelete?.target_of_edges.map((edge) => {
                                    const sourceCard = cards.find((c) => c.id === edge.source_id);
                                    return (
                                        sourceCard && (
                                            <div className={classes.SourceCardItem}>
                                                <p>{sourceCard.title}</p>
                                                <Button
                                                    text=""
                                                    alt
                                                    icon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
                                                    onClick={() => {
                                                        setCardToDelete(undefined);
                                                        editCardById(sourceCard.id);
                                                    }}
                                                />
                                            </div>
                                        )
                                    );
                                })}
                            </div>
                        </>
                    ) : (
                        <p>{t('Common:CARD_DELETION_WARNING_MESSAGE')}</p>
                    )}
                </DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={() => setOpenDeleteCardModal(false)} />
                    <Button alt text={t('Common:BUTTON_PERMANENTLY_DELETE')} onClick={permanentlyDeleteCardHandler} />
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default CardsForm;
