import { FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { IElement } from '../../models/Element';
import useTextElementService from '../../services/TextElementService';
export interface FormFields {
    body: string;
}

export type ReturnType = {
    handleSubmit: (element: IElement, formFields: FormFields, actions: FormikHelpers<FormFields>) => Promise<void>;
    handleDelete: (element: IElement) => Promise<void>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
    isSubmitting: boolean;
};

const useTextElementForm = (initialValues: FormFields): ReturnType => {
    const [isSaved, setIsSaved] = useState(!!initialValues.body);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { update, remove } = useTextElementService();

    const handleSubmit = useCallback(
        (element: IElement, formFields: FormFields, actions: FormikHelpers<FormFields>) => {
            setIsSubmitting(true);

            if (!element.elementable) return Promise.reject();
            return update(element.elementable.id, {
                ...formFields,
            }).then((response) => {
                setIsSaved(true);
                setIsSubmitting(false);
                actions.resetForm({ values: formFields });
            });
        },
        [],
    );

    const handleDelete = useCallback((element: IElement) => {
        if (!element.elementable) return Promise.reject();
        return remove(element.elementable.id).then(() => {
            setIsSaved(false);
        });
    }, []);

    return {
        handleSubmit,
        handleDelete,
        initialValues,
        isSaved,
        setIsSaved,
        isSubmitting,
    };
};

export default useTextElementForm;
