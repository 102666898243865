import React, { FunctionComponent } from 'react';
import classes from './ElementHeader.module.scss';

interface IElementHeaderProps {
    icon?: JSX.Element;
    label?: JSX.Element;
}

const ElementHeader: FunctionComponent<IElementHeaderProps> = (props) => {
    return (
        <div className={classes.ElementHeader}>
            {props.icon}
            {props.label}
        </div>
    );
};

export default ElementHeader;
