import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import classes from './RedoCircleIcon.module.scss';

const RedoCircleIcon: FunctionComponent = (props) => {
    return (
        <div className={classes.RedoCircleIcon}>
            <FontAwesomeIcon className={`${classes.ReplayIcon}`} icon={faRedo} />
        </div>
    );
};

export default RedoCircleIcon;
