import React from 'react';
import { IModule } from '../models/Module';
import { createCtx } from './createCtx';

export type ModuleContextType = {
    module: IModule;
    setModule: (module: IModule) => void;
    updateModule: (module: IModule) => void;
    isDataChanged: boolean;
    setIsDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

export const [useModule, CtxProvider] = createCtx<ModuleContextType>();
