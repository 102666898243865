import React, { Fragment, FunctionComponent } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { ProfileProvider } from '../../context/ProfileProvider';
import ProfileDetail from './ProfileDetail/ProfileDetail';
import ProfileEditDetail from './ProfileEditDetail/ProfileEditDetail';

const Profile: FunctionComponent = () => {
    // const history = useHistory();
    const { path } = useRouteMatch();

    return (
        <Fragment>
            <Switch>
                {/* View Profile */}
                <Route exact path={path}>
                    <ProfileProvider>
                        <ProfileDetail isAuthUser={true} />
                    </ProfileProvider>
                </Route>

                {/* Edit Profile */}
                <Route path={`${path}/edit/:profileId`}>
                    <ProfileProvider>
                        <ProfileEditDetail />
                    </ProfileProvider>
                </Route>

                {/* View Others Profile */}
                <Route path={`${path}/:profileId`}>
                    <ProfileProvider>
                        <ProfileDetail isAuthUser={false} />
                    </ProfileProvider>
                </Route>
            </Switch>
        </Fragment>
    );
};

export default Profile;
