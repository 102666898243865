/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CancelTokenSource } from 'axios';
import { useConfig } from '../context/ConfigContext';
import { IElementable, IElement } from '../models/Element';
import http from '../utils/axios-instance';

// export interface IMediaElement {
//     id: number;
//     body: string;
//     created_at: string;
//     updated_at: string;
// }

export interface ICreateMediaElement {
    type: string; // video / audio / image
    src: string;
    provider: string;
    model_type: string;
    model_id: number;
}

export interface IUpdateMediaElement {
    src: string;
    provider?: string;
}

const useMediaElementService = () => {
    const { config } = useConfig();
    const serviceUrl = `${config.baseUrl}/mediaelement`;

    const getAll = (source?: CancelTokenSource) => {
        return http.get<IElementable[]>(`${serviceUrl}`, { cancelToken: source?.token });
    };

    const get = (id: number, source?: CancelTokenSource) => {
        return http.get<IElementable>(`${serviceUrl}/${id}`, { cancelToken: source?.token });
    };

    const create = (data: ICreateMediaElement, source?: CancelTokenSource) => {
        return http.post<IElement>(`${serviceUrl}`, data, { cancelToken: source?.token });
    };

    const update = (id: number, data: IUpdateMediaElement, source?: CancelTokenSource) => {
        return http.put<IElement>(`${serviceUrl}/${id}`, data, { cancelToken: source?.token });
    };

    const remove = (id: number, source?: CancelTokenSource) => {
        return http.delete(`${serviceUrl}/${id}`, { cancelToken: source?.token });
    };

    return {
        getAll,
        get,
        create,
        update,
        remove,
    };
};

export default useMediaElementService;
