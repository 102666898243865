import { useFormikContext } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { SelectOption } from '../../../../../components/GroupSelect/GroupSelect';
import { useConfig } from '../../../../../context/ConfigContext';
import { ITag } from '../../../../../models/Tag';
import axiosInstance from '../../../../../utils/axios-instance';
import useAxiosFetch from '../../../../../utils/useAxiosFetch';
import { FormFields } from '../../useDeckEditForm';
import classes from './TagSelect.module.scss';

const TagSelect: FunctionComponent = () => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const { response, loading } = useAxiosFetch<ITag[]>(`${config.baseUrl}/tags`);
    const [tags, setTags] = useState<ITag[]>([]);
    const [options, setOptions] = useState<SelectOption[]>([]);
    const { setFieldValue, values } = useFormikContext<FormFields>();

    useEffect(() => {
        if (response) {
            setTags(response.data);
        }
    }, [response]);

    useEffect(() => {
        if (tags) {
            const optionData: SelectOption[] = tags.map((tag) => {
                return { value: tag.id, label: tag.title };
            });
            setOptions(optionData);
        }
    }, [tags]);

    const handleCreate = (inputValue: string) => {
        axiosInstance.post<ITag>(`${config.baseUrl}/tags`, { title: inputValue }).then((response) => {
            const newTags = [...tags];
            newTags.push(response.data);
            setTags(newTags);

            const newDeckTags = values.tags ? [...values.tags] : [];
            newDeckTags.push({ value: response.data.id, label: response.data.title });
            setFieldValue('tags', newDeckTags);
        });
    };

    return (
        <div className={classes.TagSelect}>
            <label htmlFor="tags">{t('Common:INPUT_LABEL_TAGS')}</label>
            {!loading && (
                <CreatableSelect
                    id={'tags'}
                    options={options}
                    defaultValue={values.tags}
                    placeholder={t('Common:TAGS_SELECT_PLACEHOLDER')}
                    noOptionsMessage={() => t('Common:TAGS_SELECT_NO_MORE_OPTIONS')}
                    onChange={(option) => {
                        if (option) {
                            setFieldValue('tags', option);
                        }
                    }}
                    isMulti={true}
                    value={values.tags}
                    onCreateOption={handleCreate}
                    className={`react-select-container`}
                    classNamePrefix="react-select"
                />
            )}
        </div>
    );
};

export default TagSelect;
