import React, { Fragment, FunctionComponent } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { CategoryProvider } from '../../context/CategoryProvider';
import CategoryDetail from './CategoryDetail/CategoryDetail';
// import classes from './Category.module.scss';
import CategoryEditDetail from './CategoryEditDetail/CategoryEditDetail';

const Category: FunctionComponent = () => {
    // const history = useHistory();
    const { path } = useRouteMatch();

    return (
        <Fragment>
            <Switch>
                {/* <Route exact path={path}> */}
                {/* Create Category? Or higher view? */}
                {/* </Route> */}
                <Route exact path={`${path}/create`}>
                    {/* Create Category */}
                    <CategoryProvider>
                        <CategoryEditDetail isCreate={true} />
                    </CategoryProvider>
                </Route>
                <Route path={`${path}/edit/:categoryId`}>
                    {/* Edit Category */}
                    <CategoryProvider>
                        <CategoryEditDetail isCreate={false} />
                    </CategoryProvider>
                </Route>
                <Route path={`${path}/:categoryId`}>
                    {/* View Category */}
                    <CategoryProvider>
                        <CategoryDetail />
                    </CategoryProvider>
                </Route>

                {/* Redirect /category to /category/create */}
                <Redirect from={path} to={`${path}/create`} />
            </Switch>
        </Fragment>
    );
};

export default Category;
