import { Editor } from '@tinymce/tinymce-react';
import DOMPurify from 'dompurify';
import { useFormikContext } from 'formik';
import React, { FunctionComponent } from 'react';
import classes from './TextField.module.scss';

interface TextFieldProps {
    body: string;
}

// type Props = {
//     element: IElement;
//     onChange?: (id: number, value: string) => void;
// };

export interface FormFields {
    body: string;
}

const TextField: FunctionComponent<TextFieldProps> = (props) => {
    const { setFieldValue } = useFormikContext<FormFields>();

    const handleEditorChange = (e) => {
        const sanitized = DOMPurify.sanitize(e.target.getContent());
        setFieldValue('body', sanitized);
    };

    return (
        <div className={classes.TextElementField}>
            <Editor
                apiKey="ujlfgzlc89rbjmaeo8z4uz2row57k2a90t5675o7sre0mbv2"
                initialValue={props.body}
                init={{
                    document_base_url: 'app/',
                    branding: false,
                    inline: false,
                    auto_focus: undefined,
                    menubar: false,
                    plugins: [
                        'advlist autolink autoresize lists link image imagetools',
                        'print preview anchor',
                        'searchreplace visualblocks',
                        'table paste wordcount',
                    ],
                    paste_block_drop: false,
                    image_dimensions: false,
                    object_resizing: false,
                    toolbar_mode: 'floating',
                    toolbar:
                        'formatselect | bold italic forecolor | alignleft aligncenter alignright alignjustify  | bullist numlist outdent indent | image | visualblocks',
                }}
                onChange={handleEditorChange}
            />
        </div>
    );
};

export default TextField;
