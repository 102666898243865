import { useFormikContext } from 'formik';
import React, { ChangeEvent, FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import CoverImageUpload from '../../../../components/CoverImageUpload/CoverImageUpload';
import ButtonLoadingIcon from '../../../../components/Icons/ButtonLoadingIcon/ButtonLoadingIcon';
import TrashIcon from '../../../../components/Icons/TrashIcon/TrashIcon';
import InputCheckbox from '../../../../components/InputCheckbox/InputCheckbox';
import InputField from '../../../../components/InputField/InputField';
import TextAreaField from '../../../../components/TextAreaField/TextAreaField';
import { useProfile } from '../../../../context/ProfileContext';
import { IFile } from '../../../../models/File';
import { SelectOption } from '../../../../models/ImageUpload';
import { getColor } from '../../../../utils/colors';
import ProfileCover from '../../ProfileCover/ProfileCover';
import { FormFields } from '../useProfileEditForm';
import classes from './ProfileEditDetailForm.module.scss';

type FormProps = {
    handleFormSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    isSubmitting: boolean;
    disabledSubmit: boolean;
    isSaved: boolean;
    deleteHandler: () => void;
    cancelHandler: () => void;
};

const ProfileEditDetailForm: FunctionComponent<FormProps> = ({
    handleFormSubmit,
    deleteHandler,
    cancelHandler,
    disabledSubmit,
    isSubmitting,
}) => {
    const { t } = useTranslation();
    const { profile, setProfile } = useProfile();
    const { setFieldValue } = useFormikContext<FormFields>();
    const buttonLoadingIconColor = getColor('--brand-color-10');
    const mailTo = 'support@herocenter.com';

    useEffect(() => {
        setFieldValue('profile_picture_id', profile.profile_picture?.id);
    }, [profile.profile_picture]);

    useEffect(() => {
        setFieldValue('profile_picture_background_size', profile.profile_picture_background_size);
    }, [profile.profile_picture_background_size]);

    const nameChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setProfile({ ...profile, name: event.target.value });
    };

    const jobTitleChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setProfile({ ...profile, job_title: event.target.value });
    };

    const showEmailChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setProfile({ ...profile, show_email: event.target.checked ? 1 : 0 });
    };

    const descriptionChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setProfile({ ...profile, description: event.target.value });
    };

    const backgroundSizeChangeHandler = (option: SelectOption | null) => {
        if (option) {
            setProfile({ ...profile, profile_picture_background_size: option.value });
        }
    };

    const onUploadImageHandler = (file: IFile | undefined) => {
        setProfile({ ...profile, profile_picture: file });
    };

    return (
        <form noValidate={true} onSubmit={handleFormSubmit}>
            <div className={classes.formGroup}>
                <div className={classes.ProfileEditDetailForm}>
                    <div className={classes.InputContainer}>
                        <div className={`${classes.FormElement}`}>
                            <CoverImageUpload
                                onChange={onUploadImageHandler}
                                originalFile={profile.profile_picture}
                                defaultBackgroundSize={profile.profile_picture_background_size}
                                onChangeBackgroundSize={backgroundSizeChangeHandler}
                            />
                        </div>
                        <div className={`${classes.FormElement} Box ${classes.InputContent} `}>
                            <div className={classes.formInput}>
                                <div className={`${classes.formGroup}`}>
                                    <div className={`${classes.NameInput}`}>
                                        <InputField
                                            id="name"
                                            name="name"
                                            type="text"
                                            label={t('Profile:EDIT_NAME_INPUT_LABEL')}
                                            autoComplete="name"
                                            required
                                            autoFocus
                                            placeholder={t('Common:PROFILE_EDIT_NAME_INPUT_PLACEHOLDER')}
                                            onChange={nameChangeHandler}
                                        ></InputField>
                                    </div>
                                </div>
                                <div className={`${classes.formGroup} ${classes.EmailFormGroup}`}>
                                    <div className={`checkbox ${classes.ShowEmailInput}`}>
                                        <InputCheckbox
                                            id="show_email"
                                            name="show_email"
                                            type="checkbox"
                                            label={
                                                <label htmlFor="show_email">
                                                    {t('Common:PROFILE_EDIT_SHOWEMAIL_LABEL')}
                                                </label>
                                            }
                                            onChange={showEmailChangeHandler}
                                        ></InputCheckbox>
                                    </div>
                                    <div className={`${classes.Emailaddress}`}>
                                        <InputField
                                            id="email"
                                            name="email"
                                            type="email"
                                            label={t('Profile:EDIT_EMAILADDRESS_LABEL')}
                                            autoComplete="email"
                                            disabled={true}
                                            readOnly={true}
                                        ></InputField>
                                    </div>
                                </div>
                                <div className={`${classes.formGroup}`}>
                                    <InputField
                                        id="job_title"
                                        name="job_title"
                                        type="text"
                                        label={t('Profile:EDIT_JOBTITLE_INPUT_LABEL')}
                                        placeholder={t('Profile:EDIT_JOBTITLE_INPUT_PLACEHOLDER')}
                                        onChange={jobTitleChangeHandler}
                                    ></InputField>
                                </div>
                                <div className={`${classes.formGroup}`}>
                                    <InputField
                                        id="description"
                                        name="description"
                                        type="text"
                                        multiline={true}
                                        label={t('Common:PROFILE_EDIT_DESCRIPTION_INPUT_LABEL')}
                                        placeholder={t('Common:PROFILE_EDIT_DESCRIPTION_INPUT_PLACEHOLDER')}
                                        onChange={descriptionChangeHandler}
                                    ></InputField>
                                </div>

                                <div className={`${classes.formGroup}`}>
                                    <div className={`${classes.RequestText}`}>
                                        {`${t('Common:EDIT_GDPR_REQUEST_TEXT')} `}
                                        <a href={`mailto:${mailTo}`}>{mailTo}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`${classes.formActions} ${classes.formGap}`}>
                            <Button
                                className={classes.formDelete}
                                text={t('Common:BUTTON_DELETE')}
                                danger
                                icon={<TrashIcon />}
                                iconSide="left"
                                onClick={deleteHandler}
                            ></Button>

                            <Button
                                text={t('Common:BUTTON_CANCEL')}
                                alt={true}
                                border={true}
                                onClick={cancelHandler}
                            ></Button>

                            <button
                                className={`${classes.submitButton} btn btn-flavour btn-flavour--alt`}
                                type="submit"
                                // onSubmit={onSubmit}
                                disabled={disabledSubmit}
                            >
                                {isSubmitting && <ButtonLoadingIcon fill={buttonLoadingIconColor} />}
                                {t('Common:BUTTON_SAVE')}
                            </button>
                        </div>
                    </div>
                    <div className={classes.ProfileCover}>
                        <ProfileCover></ProfileCover>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ProfileEditDetailForm;
