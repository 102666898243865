import React, { FormEvent, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import InputField from '../../../components/InputField/InputField';
import classes from './RegisterAccessCodeForm.module.scss';

interface RegisterAccessCodeFormProps {
    handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
    disabledSubmit: boolean;
    cancelHandler: () => void;
}

const RegisterAccessCodeForm: FunctionComponent<RegisterAccessCodeFormProps> = ({
    handleSubmit,
    disabledSubmit,
    cancelHandler,
}) => {
    const { t } = useTranslation();

    return (
        <form className={classes.RegisterAccessCodeForm} noValidate={true} onSubmit={handleSubmit}>
            <div className={`Box`}>
                <div className={classes.formGroup}>
                    <InputField
                        name="access_code"
                        type="text"
                        label={t('Common:ACCESS_CODE_LABEL')}
                        placeholder={t('Common:ACCESS_CODE_INPUT_PLACEHOLDER')}
                    />
                </div>
            </div>

            <div className={classes.formActions}>
                <Button text={t('Common:BUTTON_CANCEL')} alt border onClick={cancelHandler} />
                <Button type="submit" text={t('Common:BUTTON_CONFIRM')} disabled={disabledSubmit} />
            </div>
        </form>
    );
};

export default RegisterAccessCodeForm;
