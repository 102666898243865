import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { RolePermission, UserRole } from '../models/User';
import { CtxProvider } from './RoleContext';

type RoleProviderProps = {
    override?: Partial<UserRole>;
};

export const RoleProvider: FunctionComponent<RoleProviderProps> = ({ children, override }) => {
    const defaultRole: UserRole = {
        id: 0,
        is_default: true,
        name: '',
        permissions: [],
    };

    const getDefaultRole = (override: Partial<UserRole>) => {
        return { ...defaultRole, ...override };
    };

    const [role, updateRole] = useState<UserRole>(() => {
        if (override != undefined) {
            return getDefaultRole(override);
        }

        return { ...defaultRole };
    });

    const setRole = (newRole: UserRole) => {
        updateRole((current) => {
            return { ...current, ...newRole };
        });
    };

    const addPermission = (permission: RolePermission) => {
        const newRolePermissions: RolePermission[] = _.cloneDeep(role.permissions || []);
        newRolePermissions.push(permission);

        const newPotentialPermissions: RolePermission[] = _.cloneDeep(role.potentialPermissions || []);
        newPotentialPermissions.splice(
            newPotentialPermissions.findIndex((findPermission) => {
                return permission.id === findPermission.id;
            }),
            1,
        );

        const newRole = { ...role, permissions: newRolePermissions, potentialPermissions: newPotentialPermissions };
        setRole(newRole);
    };

    const removePermission = (permission: RolePermission) => {
        const newRolePermissions: RolePermission[] = _.cloneDeep(role.permissions || []);
        newRolePermissions.splice(
            newRolePermissions.findIndex((findPermission) => {
                return permission.id === findPermission.id;
            }),
            1,
        );

        const newPotentialPermissions: RolePermission[] = _.cloneDeep(role.potentialPermissions || []);
        newPotentialPermissions.push(permission);

        const newRole = { ...role, permissions: newRolePermissions, potentialPermissions: newPotentialPermissions };
        setRole(newRole);
    };

    return <CtxProvider value={{ role, setRole, addPermission, removePermission }}>{children}</CtxProvider>;
};
