import { faEllipsisH, faToggleOff, faToggleOn, faTrashAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableCell, TableRow } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import DropDown, { DropDownSection, ItemType } from '../../../../../components/DropDown/DropDown';
import AvatarIcon from '../../../../../components/Icons/AvatarIcon/AvatarIcon';
import NavigationIcon from '../../../../../components/Icons/NavigationIcon/NavigationIcon';
import { useAuth } from '../../../../../context/AuthContext';
import { useClickOutsideListenerRef } from '../../../../../hooks/useClickOutisdeListenerRef';
import { User, UserRole } from '../../../../../models/User';
import { formatDateToLocal } from '../../../../../utils/date';
import MemberActionsPopover from '../MemberActionsPopover/MemberActionsPopover';
import RoleSelect from '../RoleSelect/RoleSelect';
import classes from './MemberItem.module.scss';

interface MemberItemProps {
    member: User;
    roles: UserRole[];
    onToggleActiveHandler: (user: User) => void;
    onMemberUpdatedHandler: (user: User) => void;
    onDeactivateMemberHandler: (user: User) => void;
    onActivateMemberHandler: (user: User) => void;
    onDeleteMemberHandler: (user: User) => void;
}

const MemberItem: FunctionComponent<MemberItemProps> = ({
    member,
    roles,
    onToggleActiveHandler,
    onMemberUpdatedHandler,
    onDeleteMemberHandler,
}) => {
    const { t } = useTranslation();
    const { auth } = useAuth();
    const inactive = !member.is_active;
    const disabled = member.id === auth.currentUser?.id;

    return (
        <TableRow>
            <TableCell>
                <div
                    className={`${classes.MemberListName} ${inactive && classes.InactiveMember} ${
                        disabled && classes.Disabled
                    }`}
                >
                    <FontAwesomeIcon icon={faUser} />
                    {member.name}
                </div>
            </TableCell>

            <TableCell>
                <div
                    className={`${classes.MemberListGroup} ${inactive && classes.InactiveMember} ${
                        disabled && classes.Disabled
                    }`}
                >
                    {member.groups.length == 0
                        ? '-'
                        : member.groups.map((group, index) => {
                              return index != member.groups.length - 1 ? group.name + ', ' : group.name;
                          })}
                </div>
            </TableCell>

            <TableCell>
                <div
                    className={`${classes.MemberListRole} ${
                        (inactive || member.access_code_id != null) && classes.InactiveMember
                    } ${disabled && classes.Disabled}`}
                >
                    <RoleSelect
                        member={member}
                        roles={roles}
                        disabled={disabled}
                        onMemberUpdated={onMemberUpdatedHandler}
                    />
                </div>
            </TableCell>

            <TableCell>
                <div
                    className={`${classes.MemberListDateAdded} ${inactive && classes.InactiveMember} ${
                        disabled && classes.Disabled
                    }`}
                >
                    {formatDateToLocal(member.created_at, 'dd MMMM yy')}
                </div>
            </TableCell>

            <TableCell>
                <div className={classes.MemberControls}>
                    {!disabled ? (
                        <MemberActionsPopover
                            user={member}
                            onToggleActiveHandler={onToggleActiveHandler}
                            onDeleteHandler={onDeleteMemberHandler}
                        />
                    ) : (
                        <div className={classes.Disabled}>{t('Common:MANAGEMENT_MEMBERS_ME')}</div>
                    )}
                </div>
            </TableCell>
        </TableRow>
    );
};

export default MemberItem;
