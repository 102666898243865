import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './NavButton.module.scss';
interface IProps {
    to: string;
    label?: string;
    onClick?: () => void;
}

const NavButton: FunctionComponent<IProps> = (props) => {
    return (
        <NavLink
            className={`${classes.NavButton} nav-link btn btn-flavour btn-flavour--round`}
            to={props.to}
            activeClassName={classes.Selected}
            onClick={() => props.onClick?.()}
        >
            {props.children}
            <label className={classes.Label}>{props.label}</label>
        </NavLink>
    );
};

export default NavButton;
