import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/Button/Button';
import ExtendedMuiList from '../../../../../../components/ExtendedMuiList/ExtendedMuiList';
import PublicationStateLabel from '../../../../../../components/PublicationStateLabel/PublicationStateLabel';
import { IDeck } from '../../../../../../models/Deck';
import classes from './DecksDialog.module.scss';

interface DecksDialogProps {
    open: boolean;
    toggleOpen: () => void;
    decks: IDeck[];
    onAddDeckHandler: (deck: IDeck) => void;
}

const DecksDialog: FunctionComponent<DecksDialogProps> = ({ open, toggleOpen, decks, onAddDeckHandler }) => {
    const { t } = useTranslation();

    const filterPotentialDeckDataBySearch = (searchValues: string[]) => {
        return decks.filter((item) => {
            const title = item.title.toLowerCase();

            const searchableValues = { title };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    const renderDeckItem = (deck: IDeck) => {
        return (
            <div className={classes.DeckItem} key={`deck_${deck.id}`}>
                <div className={classes.DeckDetails}>
                    <div className={classes.DeckName}>{`${deck.title}`}</div>
                    <PublicationStateLabel isPublished={deck.is_published} />
                </div>
                <div className="flex-grow"></div>
                <div className={classes.DeckControls}>
                    <Button
                        className={`${classes.EditButton} ${classes.AddButton}`}
                        text=""
                        icon={<FontAwesomeIcon className={classes.PlusIcon} icon={faPlus} />}
                        onClick={() => onAddDeckHandler(deck)}
                    ></Button>
                </div>
            </div>
        );
    };

    return (
        <Dialog open={open} onClose={toggleOpen} maxWidth="sm" fullWidth>
            <DialogTitle disableTypography className={classes.DialogTitle}>
                <Typography variant="h6">{t('Quests:POTENTIAL_DECKS_HEADER')}</Typography>

                <Button
                    aria-label="close"
                    className={classes.closeButton}
                    alt
                    text=""
                    icon={<FontAwesomeIcon icon={faClose}></FontAwesomeIcon>}
                    onClick={toggleOpen}
                ></Button>
            </DialogTitle>

            <DialogContent>
                <ExtendedMuiList
                    loading={false}
                    defaultRowsPerPage={5}
                    defaultEnabled={true}
                    searchInputLabel={t('Common:DECKS_SEARCH_INPUT_LABEL')}
                    filterDataBySearch={filterPotentialDeckDataBySearch}
                    noItemsMessage={t('Common:LIST_MESSAGE_NO_DECKS')}
                    noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_DECKS')}
                    items={decks}
                    renderItem={renderDeckItem}
                    itemsListClasses={`${classes.ModalDecksList}`}
                />
            </DialogContent>
        </Dialog>
    );
};

export default DecksDialog;
