import { IGroup } from '../models/Group';
import { User } from '../models/User';
import { createCtx } from './createCtx';
import { ActionButtonState, JoinState } from './GroupProvider';

export type GroupContextType = {
    group: IGroup;
    setGroup: (group: IGroup) => void;
    joinState: JoinState;
    actionButtonState: ActionButtonState;
    addUser: (user: User) => void;
    removeUser: (user: User) => void;
};

export const [useGroup, CtxProvider] = createCtx<GroupContextType>();
