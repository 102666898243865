import { ICategory } from '../models/Categories';
import { createCtx } from './createCtx';

export type CategoryContextType = {
    category: ICategory;
    setCategory: (category: ICategory) => void;
    updateCategory: (category: ICategory) => void;
    isDataChanged: boolean;
    setIsDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

export const [useCategory, CtxProvider] = createCtx<CategoryContextType>();
