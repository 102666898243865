import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStylesRegularButton = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            gap: '1rem',
        },
    }),
);

export const useStylesDeleteButton = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: `${theme.palette.error.main}`,
            '&:hover': {
                backgroundColor: theme.palette.error.main,
                color: `${theme.palette.error.contrastText}`,
            },
            gap: '1rem',
        },
    }),
);

export const useStylesPopoverActions = makeStyles(() => createStyles({
    paper: {
        width: '240px',
    }
}));
