import React, { FunctionComponent } from 'react';

type SVGProps = {
    fill?: string;
};

const SettingsIcon: FunctionComponent<SVGProps> = ({ fill = '#ffffff' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path
                id="Path_2969"
                data-name="Path 2969"
                d="M102,61.214V54.786H98.338a11.72,11.72,0,0,0-1.048-2.531l2.589-2.589L95.334,45.12l-2.589,2.589a11.741,11.741,0,0,0-2.53-1.048V43H83.786v3.662a11.73,11.73,0,0,0-2.53,1.048l-2.59-2.589L74.12,49.666l2.589,2.589a11.73,11.73,0,0,0-1.048,2.53H72v6.429h3.662a11.742,11.742,0,0,0,1.048,2.53L74.12,66.334l4.546,4.546,2.589-2.59a11.721,11.721,0,0,0,2.531,1.048V73h6.429V69.338a11.731,11.731,0,0,0,2.53-1.048l2.589,2.589,4.546-4.546-2.589-2.589a11.73,11.73,0,0,0,1.048-2.53ZM87,63.357A5.357,5.357,0,1,1,92.357,58,5.357,5.357,0,0,1,87,63.357Z"
                transform="translate(-72 -43)"
                fill={fill}
            />
        </svg>
    );
};

export default SettingsIcon;
