import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../../../../components/Button/Button';
import DropDownButton from '../../../../components/DropDownButton/DropDownButton';
import ProgressBar from '../../../../components/ProgressBar/Progressbar';
import { useAuth } from '../../../../context/AuthContext';
import { IGroupProgress } from '../../../../models/Progress';
import { IQuest } from '../../../../models/Quest';
import classes from './GroupsQuestProgress.module.scss';

export interface GroupsQuestProgressProps {
    quest: IQuest;
}

const GroupsQuestProgress: FunctionComponent<GroupsQuestProgressProps> = ({ quest }) => {
    const { t } = useTranslation();
    const { auth } = useAuth();
    const history = useHistory();
    const questHasTeamProgress = quest.quest_progress !== undefined;
    const teamQuestCompleted = questHasTeamProgress ? quest.quest_progress.completedQuests : 0;
    const teamQuestTotal = questHasTeamProgress ? quest.quest_progress.totalTasks : 0;
    const teamQuestProgress = questHasTeamProgress ? quest.quest_progress.completedTasks : 0;
    const teamParticipantsTotal = questHasTeamProgress ? quest.quest_progress.totalParticipants : 0;
    const teamProgress = teamQuestTotal === 0 ? 0 : teamQuestProgress / teamQuestTotal;

    const participantsText = teamQuestCompleted === 1 ? t('Common:PARTICIPANT') : t('Common:PARTICIPANTS');

    const [isTeamProgressOpen, setIsTeamProgressOpen] = useState(false);
    const openedTeamProgressClass = isTeamProgressOpen ? classes.isTeamProgressOpen : classes.isTeamProgressClosed;

    const myGroups = quest.quest_progress.group_progress
        ? quest.quest_progress.group_progress.filter((groupProgress) => {
              return groupProgress.group.users?.find((user) => user.id === auth.currentUser?.id);
          })
        : [];

    const otherGroups = quest.quest_progress.group_progress
        ? quest.quest_progress.group_progress.filter((groupProgress) => {
              let otherGroup = true;
              groupProgress.group.users?.forEach((user) => {
                  if (user.id === auth.currentUser?.id) {
                      otherGroup = false;
                      return;
                  }
              });

              return otherGroup;
          })
        : [];

    const onNavigateToGroupHandler = (groupId: number) => {
        history.push(`/groups/${groupId}`);
    };

    const mapGroupToList = (groupProgress: IGroupProgress) => {
        const groupTaskProgress = groupProgress.completedTasks / groupProgress.totalTasks;
        const memberText = groupProgress.completedQuests === 1 ? t('Common:MEMBER') : t('Common:MEMBERS');
        return (
            <div className={classes.GroupProgress} key={`${groupProgress.group.id}`}>
                <div className={`${classes.GroupHeader}`}>
                    <label className={`${classes.GroupLabel}`}>{groupProgress.group.name}</label>
                    <span className={classes.GroupPeopleCompleted}>{`${groupProgress.completedQuests}/${
                        groupProgress.totalParticipants
                    } ${memberText} ${t('Common:COMPLETE')}`}</span>
                </div>
                <div className={classes.GroupProgressBar}>
                    <ProgressBar
                        progressLabel={`${groupProgress.completedTasks} ${t('Quests:PROGRESS_BAR_TASKS_COMPLETE')}`}
                        completed={groupTaskProgress === 1}
                        timeState={quest.time_state}
                        upperBoundsLabel={`${groupProgress.totalTasks}`}
                        styleClass={quest.time_state}
                        progress={groupTaskProgress}
                        styles={{
                            height: '40px',
                        }}
                    ></ProgressBar>
                    <Button
                        text=""
                        icon={<FontAwesomeIcon icon={faUserGroup} />}
                        onClick={() => onNavigateToGroupHandler(groupProgress.group.id)}
                        className={classes.GroupsButton}
                    />
                </div>
            </div>
        );
    };

    const myGroupList = myGroups.map(mapGroupToList);

    const otherGroupList = otherGroups.map(mapGroupToList);

    const onTeamProgressOpenHandler = () => {
        setIsTeamProgressOpen(true);
    };

    const onTeamProgressCloseHandler = () => {
        setIsTeamProgressOpen(false);
    };

    return (
        <div className={classes.GroupsQuestProgress}>
            {quest.time_state != 'awaiting' && (
                <Fragment>
                    <div className={`Box ${classes.TeamProgress}`}>
                        <div className={`${classes.TeamProgressHeader}`}>
                            <h2 className={`h5 ${classes.TeamProgressTitle}`}>{t('Quests:PROGRESS_TITLE')}</h2>
                            <span
                                className={classes.TeamProgressPeopleCompleted}
                            >{`${teamQuestCompleted}/${teamParticipantsTotal} ${participantsText} ${t(
                                'Common:COMPLETE',
                            ).toLowerCase()}`}</span>
                        </div>
                        <div className={classes.TeamProgressBar}>
                            <ProgressBar
                                progressLabel={`${teamQuestProgress} ${t('Quests:PROGRESS_BAR_TASKS_COMPLETE')}`}
                                completed={teamProgress === 1}
                                timeState={quest.time_state}
                                upperBoundsLabel={`${teamQuestTotal}`}
                                styleClass={quest.time_state}
                                progress={teamProgress}
                                styles={{
                                    height: '50px',
                                    fillGradient: true,
                                    borderWidth: 0,
                                }}
                            ></ProgressBar>
                            {myGroupList.length + otherGroupList.length >= 2 && (
                                <DropDownButton
                                    onOpen={onTeamProgressOpenHandler}
                                    onClose={onTeamProgressCloseHandler}
                                />
                            )}
                        </div>
                    </div>

                    {myGroupList.length + otherGroupList.length >= 2 && (
                        <div className={`Box ${classes.GroupsProgress} ${openedTeamProgressClass}`}>
                            {myGroupList.length > 0 && (
                                <div className={classes.MyGroups}>
                                    <div className={`${classes.GroupsProgressHeader}`}>
                                        <h2 className={`h5 ${classes.GroupsProgressTitle}`}>
                                            {myGroupList.length === 1
                                                ? t('Groups:MY_GROUP_TITLE')
                                                : t('Groups:MY_GROUPS_TITLE')}
                                        </h2>
                                    </div>
                                    {myGroupList}
                                </div>
                            )}

                            {otherGroupList.length > 0 && (
                                <div className={classes.OtherGroups}>
                                    <div className={`${classes.GroupsProgressHeader}`}>
                                        <h2 className={`h5 ${classes.GroupsProgressTitle}`}>
                                            {otherGroupList.length === 1
                                                ? t('Groups:OTHER_GROUP_TITLE')
                                                : t('Groups:OTHER_GROUPS_TITLE')}
                                        </h2>
                                    </div>
                                    {otherGroupList}
                                </div>
                            )}
                        </div>
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default GroupsQuestProgress;
