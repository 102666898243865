import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useConfig } from '../../context/ConfigContext';
import { Permission } from '../../models/User';

type PublicRouteProps = {
    component: React.FC;
    path: string;
    exact: boolean;
    public: boolean;
    permission?: Permission;
};

const PublicRoute: React.FC<PublicRouteProps> = (props: PublicRouteProps) => {
    const { config, loading } = useConfig();

    return !loading && config.tenant.is_public ? (
        <Route path={props.path} exact={props.exact} component={props.component} />
    ) : (
        <Redirect to={'/'} />
    );
};
export default PublicRoute;
