import { FormikHelpers } from 'formik';
import { useCallback, useState } from 'react';
import { IElement } from '../../../models/Element';
import useMediaElementService from '../../../services/MediaElementService';
import { Provider } from '../../Plyr/PlyrComponent';

interface FormFields {
    src: string;
    provider?: Provider;
    file_id?: number;
}

export type ReturnType = {
    handleSubmit: (element: IElement, formFields: FormFields, actions?: FormikHelpers<FormFields>) => Promise<void>;
    handleDelete: (element: IElement) => Promise<void>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
    isSubmitting: boolean;
};

const useMediaElementForm = (initialValues: FormFields): ReturnType => {
    const [isSaved, setIsSaved] = useState(!!initialValues.src);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { update, remove } = useMediaElementService();

    const handleSubmit = useCallback(
        (element: IElement, formFields: FormFields, actions?: FormikHelpers<FormFields>) => {
            setIsSubmitting(true);

            const body = {
                ...formFields,
            };
            if (!element.elementable) return Promise.reject();

            return update(element.elementable.id, body).then(() => {
                setIsSaved(true);
                setIsSubmitting(false);
                actions?.resetForm({ values: formFields });
            });
        },
        [],
    );

    const handleDelete = useCallback((element: IElement) => {
        if (!element.elementable) return Promise.reject();

        return remove(element.elementable.id).then(() => {
            setIsSaved(false);
        });
    }, []);

    return {
        handleSubmit,
        handleDelete,
        initialValues,
        isSaved,
        setIsSaved,
        isSubmitting,
    };
};

export default useMediaElementForm;
