import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionType } from '../../../../../models/Element';
import classes from './QuestionElementLabel.module.scss';

interface QuestionElementLabelProps {
    questionType?: QuestionType;
}

const QuestionElementLabel: FunctionComponent<QuestionElementLabelProps> = (props) => {
    const { t } = useTranslation();
    const [labelText, setLabelText] = useState('');

    const getLabelTextBasesOnQuestionType = () => {
        switch (props.questionType) {
            case 'open_question':
                return t('Common:ELEMENT_TYPE_OPEN_QUESTION');
            case 'multiple_choice':
                return t('Common:ELEMENT_TYPE_MULTIPLE_CHOICE');
            case 'poll':
                return t('Common:ELEMENT_TYPE_POLL');
            default:
                return t('Common:DEFAULT_ELEMENT_HEADER_LABEL');
        }
    };

    useEffect(() => {
        setLabelText(getLabelTextBasesOnQuestionType());
    }, [props.questionType]);

    return <label className={classes.QuestionElementLabel}>{labelText}</label>;
};

export default QuestionElementLabel;
