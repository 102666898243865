import React, { FunctionComponent } from 'react';
import classes from './Loader.module.scss';

interface LoaderProps {
    spinner?: boolean;
}

const Loader: FunctionComponent<LoaderProps> = (props) => {
    return (
        <div className={classes.Loader}>
            <div className="spinner card Box"></div>
        </div>
    );
};

export default Loader;
