import { FormikHelpers } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { IElement } from '../../../models/Element';
import { IOption } from '../../../models/Option';
import { useElement } from '../ElementProvider';
import useQuestionService from './useQuestionService';

export interface FormFields {
    order: number;
    question_type_id: number;
    body: string;
    options?: IOption[];
    feedback_enabled?: boolean;
    is_correct_enabled?: boolean;
    timer_enabled?: boolean;
    timer?: string;
}

export type ReturnType = {
    handleSubmit: (element: IElement, formFields: FormFields, actions: FormikHelpers<FormFields>) => Promise<IElement>;
    handleDelete: (element: IElement) => Promise<void>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
    isSubmitting: boolean;
};

const useQuestionForm = (): ReturnType => {
    const { state, dispatch } = useElement();

    const initialValues = useMemo(() => {
        return {
            order: state.order,
            body: state.elementable?.body || '',
            question_type_id: state.elementable?.question_type_id || state.elementable?.question?.question_type_id || 1,
            options: state.elementable?.options || [],
            feedback_enabled: state.elementable?.feedback_enabled || false,
            is_correct_enabled: state.elementable?.is_correct_enabled || false,
            timer_enabled: state.elementable?.timer_enabled || false,
            timer: state.elementable?.timer || '00:00',
        };
    }, [
        state.order,
        state.elementable?.body,
        state.elementable?.question_type_id,
        state.elementable?.options,
        state.elementable?.feedback_enabled,
        state.elementable?.is_correct_enabled,
        state.elementable?.timer_enabled,
        state.elementable?.timer,
    ]);

    const [isSaved, setIsSaved] = useState(!!initialValues.body);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { update, remove } = useQuestionService();

    const handleSubmit = useCallback((element: IElement, formFields: FormFields) => {
        setIsSubmitting(true);

        // TODO: based on question type create body string
        // element.elementable.question?.question_type

        const jsonString = JSON.stringify(
            formFields.options?.map((option) => {
                return { ...option };
            }),
        );
        const body = {
            ...formFields,
            options: jsonString,
        };

        if (!element.elementable) return Promise.reject();
        return update(element.elementable.id, body).then((response) => {
            setIsSaved(true);
            setIsSubmitting(false);

            return response.data;
        });
    }, []);

    const handleDelete = useCallback((element: IElement) => {
        if (!element.elementable) return Promise.reject();
        return remove(element.elementable.id).then(() => {
            // console.log('Removed');
        });
    }, []);

    return {
        handleSubmit,
        handleDelete,
        initialValues,
        isSaved,
        setIsSaved,
        isSubmitting,
    };
};

export default useQuestionForm;
