import { animated, useSpring } from '@react-spring/web';
import React, { FunctionComponent } from 'react';
import ProgressBar from '../../../ProgressBar/Progressbar';
import classes from './CardProgressBar.module.scss';

type CardProgressBarProps = {
    title: string;
    percentage: number;
};

const CardProgressBar: FunctionComponent<CardProgressBarProps> = ({ title, percentage }) => {
    const props = useSpring({ number: percentage * 100, from: { number: 0 }, delay: 500 });
    const percentageNumber = props.number.to((x) => `${x.toFixed(0)}%`);

    return (
        <div className={classes.CardProgressBar}>
            <div className={classes.ProgressLabel}>
                <span>{`'${title}'`}</span>
                <animated.span>{percentageNumber}</animated.span>
            </div>

            <ProgressBar progress={percentage} completed={percentage === 1} timeState={`in_progress`} />
        </div>
    );
};

export default CardProgressBar;
