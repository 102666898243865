import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../../../components/Button/Button';
import TrashIcon from '../../../components/Icons/TrashIcon/TrashIcon';
import { useConfig } from '../../../context/ConfigContext';
import { UserRole } from '../../../models/User';
import axiosInstance from '../../../utils/axios-instance';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import classes from './ManagementRoles.module.scss';
import RoleItem from './RoleItem/RoleItem';

// interface ManagementRolesProps {}

const ManagementRoles: FunctionComponent = () => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const { response, loading } = useAxiosFetch<UserRole[]>(`${config.baseUrl}/roles`);
    const [roles, setRoles] = useState<UserRole[]>([]);
    const [roleToDelete, setRoleToDelete] = useState<UserRole | undefined>();
    const history = useHistory();

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    useEffect(() => {
        if (response) {
            const roles = response.data;
            setRoles(roles);
        }
    }, [response]);

    const onRoleAddHandler = () => {
        history.push(`/roles/create`);
    };

    const onRoleDeleteHandler = (role: UserRole) => {
        setRoleToDelete(role);
    };

    useEffect(() => {
        if (roleToDelete === undefined) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [roleToDelete]);

    const rolesList = roles.map((role) => {
        return <RoleItem key={`role_${role.id}`} role={role} onDeleteHandler={onRoleDeleteHandler} />;
    });

    const permanentlyDeleteHandler = () => {
        if (!roleToDelete) return;

        axiosInstance.delete(`${config.baseUrl}/roles/${roleToDelete.id}`);

        const newRoles = _.cloneDeep(roles);
        newRoles.splice(
            newRoles.findIndex((role) => role.id === roleToDelete.id),
            1,
        );

        setRoles(newRoles);
        setRoleToDelete(undefined);
    };

    return (
        <>
            <div className={classes.ManagementRoles}>
                <div className={classes.Header}>
                    <h2 className={`h3 ${classes.Title}`}>{t('Common:MANAGEMENT_ROLES_TITLE')}</h2>
                    <Button
                        className={`btn btn-flavour btn-flavour--alt-border`}
                        text={t('Common:BUTTON_CREATE')}
                        onClick={onRoleAddHandler}
                    />
                </div>
                <div className={`Box`}>
                    <div className={classes.RolesList}>{rolesList}</div>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={closeModal}
                className={classes.WarningDialog}
                PaperProps={{ className: `dialog` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:WARNING_HEADER')}</DialogTitle>
                <DialogContent>{t('Common:ROLES_DELETION_WARNING_MESSAGE')}</DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={closeModal} />
                    <Button alt text={t('Common:BUTTON_PERMANENTLY_DELETE')} onClick={permanentlyDeleteHandler} />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ManagementRoles;
