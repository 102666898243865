import './utils/Logging';
import './i18n';
//
import './scss/main.scss';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { ConfigProvider } from './context/ConfigProvider';
import HCErrorHandler from './pages/HCErrorHandler/HCErrorHandler';

export const history = createBrowserHistory({ basename: '/app' });

ReactDOM.render(
    <React.StrictMode>
        <Router history={history}>
            <Suspense fallback={<div className="spinner card Box"></div>}>
                <HCErrorHandler>
                    <ConfigProvider>
                        <App />
                    </ConfigProvider>
                </HCErrorHandler>
            </Suspense>
        </Router>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
