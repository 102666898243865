import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover from '@material-ui/core/Popover';
import React, { FunctionComponent } from 'react';
import Button from '../../../../components/Button/Button';
import { IQuest } from '../../../../models/Quest';
import QuestActions from './QuestActions/QuestActions';
import classes from './QuestActionsPopover.module.scss';

export interface IQuestActionsProps {
    quest: IQuest;
    onEditHandler: (quest: IQuest) => void;
    onDeleteHandler: (quest: IQuest) => void;
}

const QuestActionsPopover: FunctionComponent<IQuestActionsProps> = ({ quest, onEditHandler, onDeleteHandler }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'deck-edit-menu-popover' : undefined;

    return (
        <div className={classes.TagActionsPopover}>
            <Button
                aria-controls="deck-edit-menu"
                aria-haspopup="true"
                text=""
                icon={<FontAwesomeIcon icon={faEllipsis} />}
                onClick={handleClick}
            />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <QuestActions quest={quest} onEditHandler={onEditHandler} onDeleteHandler={onDeleteHandler} />
            </Popover>
        </div>
    );
};

export default QuestActionsPopover;
