import { CancelTokenSource } from 'axios';
import { useConfig } from '../context/ConfigContext';
import { IEdge } from '../models/Edge';
import http from '../utils/axios-instance';

export interface IEdgeData {
    source_id: number;
    source_model_type: string;
    action_id: number;
    action_model_type: string;
    target_id: number;
    target_model_type: string;
}

const useEdgeService = () => {
    const { config } = useConfig();
    const serviceUrl = `${config.baseUrl}/edges`;

    const create = (data: IEdgeData, source?: CancelTokenSource) => {
        return http.post<IEdge>(`${serviceUrl}`, data, { cancelToken: source?.token });
    };

    const update = (id: number, data: IEdgeData, source?: CancelTokenSource) => {
        return http.put<IEdge>(`${serviceUrl}/${id}`, data, { cancelToken: source?.token });
    };

    const destroy = (id: number, source?: CancelTokenSource) => {
        return http.delete(`${serviceUrl}/${id}`, { cancelToken: source?.token });
    };

    return {
        create,
        update,
        destroy,
    };
};

export default useEdgeService;
