import { useFormikContext } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { SelectOption } from '../../../../../components/GroupSelect/GroupSelect';
import { useConfig } from '../../../../../context/ConfigContext';
import { ICategory } from '../../../../../models/Categories';
import axiosInstance from '../../../../../utils/axios-instance';
import useAxiosFetch from '../../../../../utils/useAxiosFetch';
import { FormFields } from '../../useDeckEditForm';
import classes from './CategorySelect.module.scss';

const CategorySelect: FunctionComponent = () => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const { response, loading } = useAxiosFetch<ICategory[]>(`${config.baseUrl}/categories`);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [options, setOptions] = useState<SelectOption[]>([]);
    const { setFieldValue, values } = useFormikContext<FormFields>();

    const [value, setValues] = useState<SelectOption[]>([]);
    useEffect(() => {
        if (response) {
            setCategories(response.data);
        }
    }, [response]);

    useEffect(() => {
        if (categories) {
            const filteredCategories = categories.filter((category) => category.id != null);
            const optionData: SelectOption[] = filteredCategories.map((category) => {
                return { value: category.id, label: category.title };
            });
            setOptions(optionData);
        }
    }, [categories]);

    const handleCreate = (inputValue: string) => {
        axiosInstance.post<ICategory>(`${config.baseUrl}/categories`, { title: inputValue }).then((response) => {
            const newCategories = [...categories];
            newCategories.push(response.data);
            setCategories(newCategories);

            const newDeckCategories = values.categories ? [...values.categories] : [];
            newDeckCategories.push({ value: response.data.id, label: response.data.title });
            setFieldValue('categories', newDeckCategories);
        });
    };

    return (
        <div className={classes.CategorySelect}>
            <label htmlFor="categories">{t('Common:INPUT_LABEL_CATEGORIES')}</label>
            {!loading && (
                <CreatableSelect
                    id={'categories'}
                    options={options}
                    defaultValue={values.categories}
                    placeholder={t('Common:CATEGORY_SELECT_PLACEHOLDER')}
                    noOptionsMessage={() => t('Common:CATEGORY_SELECT_NO_MORE_OPTIONS')}
                    onChange={(option) => {
                        if (option) {
                            setFieldValue('categories', option);
                        }
                    }}
                    isMulti={true}
                    value={values.categories}
                    onCreateOption={handleCreate}
                    className={`react-select-container`}
                    classNamePrefix="react-select"
                />
            )}
        </div>
    );
};

export default CategorySelect;
