import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import Button from '../../../components/Button/Button';
import { useConfig } from '../../../context/ConfigContext';
import { IQuest } from '../../../models/Quest';
import axiosInstance from '../../../utils/axios-instance';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import ManagementQuest from './ManagementQuest/ManagementQuest';
import classes from './ManagementQuests.module.scss';
import { animated, config as SpringConfig, useSprings } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import swap from 'lodash-move';
import clamp from 'lodash.clamp';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import ManagementHeader from '../ManagementHeader/ManagementHeader';

const ManagementQuests: FunctionComponent = () => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const history = useHistory();
    const { response, loading, sendRequest } = useAxiosFetch<IQuest[]>(`${config.baseUrl}/quests`);
    const [quests, setQuests] = useState<IQuest[]>([]);
    const [questToRemove, setQuestToRemove] = useState<IQuest>();
    const fixedHeight = 50;
    const onDragScale = 1.02;
    // Reorder
    const [dragging, setDragging] = useState(false);
    const [draggingCurrentIndex, setDraggingCurrentIndex] = useState(0);
    const hasDragged = React.useRef(false);

    useEffect(() => {
        if (response) {
            const categoryData = response.data;
            setQuests(categoryData);
        }
    }, [response]);

    const onAddQuestHandler = () => {
        history.push(`/quests/create`);
    };

    const [open, setOpen] = useState(false);
    const closeModal = () => {
        setQuestToRemove(undefined);
        setOpen(false);
    };

    const deleteHandler = async (quest: IQuest) => {
        setQuestToRemove(quest);
        setOpen((o) => !o);
    };

    const notifyDeleted = () => toast.success(t('Common:TOAST_QUEST_DELETED_SUCCESS'));
    const notifyNotDeleted = () => toast.error(t('Common:TOAST_QUEST_DELETED_ERROR'));

    const permanentlyDeleteHandler = async () => {
        setOpen(false);
        if (questToRemove === undefined) return;

        try {
            await axiosInstance.delete(`${config.baseUrl}/quests/${questToRemove?.id}`);
            sendRequest();

            notifyDeleted();
        } catch (error) {
            notifyNotDeleted();
        }
    };

    const fn =
        (order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) =>
        (index: number) =>
            active && index === originalIndex
                ? {
                      y: curIndex * fixedHeight + y,
                      scale: onDragScale,
                      zIndex: 1,
                      shadow: 5,
                      immediate: (key: string) => key === 'zIndex',
                      config: (key: string) => (key === 'y' ? SpringConfig.stiff : SpringConfig.default),
                  }
                : {
                      y: order.indexOf(index) * fixedHeight,
                      scale: 1,
                      zIndex: 0,
                      shadow: 0,
                      immediate: false,
                  };

    const order = useRef(quests.map((_, index) => index)); // Store indicies as a local ref, this represents the item order
    const [springs, setSprings] = useSprings(quests.length, fn(order.current)); // Create springs, each corresponds to an item, controlling its transform, scale, etc.

    let originalOrder = quests.map((item, index) => {
        return { item_id: item.id, index: index };
    });

    const onReorderQuests = (reorderedQuests: IQuest[]) => {
        const newOrder: { id: number; order: number }[] = [];

        reorderedQuests.map((quest, index) => {
            newOrder.push({
                id: quest.id,
                order: index + 1,
            });
        });

        axiosInstance.post(`${config.baseUrl}/quests/order`, { new_order: JSON.stringify(newOrder) });
    };

    const bind = useGesture(
        {
            onDrag: ({ event, args: [originalIndex], active, first, last, movement: [, y] }) => {
                event.preventDefault();

                if (first) hasDragged.current = true;
                if (last) setTimeout(() => (hasDragged.current = false), 0);

                setDragging(true);

                const curIndex = order.current.indexOf(originalIndex);
                setDraggingCurrentIndex(originalIndex);
                const curRow = clamp(Math.round((curIndex * fixedHeight + y) / fixedHeight), 0, quests.length - 1);
                const newOrder = swap(order.current, curIndex, curRow);
                setSprings.start(fn(newOrder, active, originalIndex, curIndex, y)); // Feed springs new style data, they'll animate the view without causing a single render
                if (!active) {
                    setDragging(false);

                    if (newOrder !== order.current) {
                        const newOrderedItems = newOrder.map((val, index) => {
                            return { item_id: originalOrder[val].item_id, order: index };
                        });

                        const orderedItems = quests
                            .slice()
                            .sort(
                                (a, b) =>
                                    newOrderedItems.findIndex((item) => a.id === item.item_id) -
                                    newOrderedItems.findIndex((item) => b.id === item.item_id),
                            );

                        onReorderQuests(orderedItems);

                        order.current = newOrder;
                    }
                }
            },
            onClickCapture: (event) => {
                if (hasDragged.current) {
                    event.event.stopPropagation();
                }
            },
        },
        {
            // delay: true,
            enabled: true,
            drag: {
                filterTaps: true,
            },
        },
    );

    useEffect(() => {
        order.current = quests.map((_, index) => index);
        setSprings.start(fn(order.current));
        originalOrder = quests.map((item, index) => {
            return { item_id: item.id, index: index };
        });
    }, [quests]);

    const listRef = useRef<HTMLDivElement>(null);

    const questList = quests?.map((quest, index) => {
        const spring = springs[index];

        const zIndex = spring.zIndex;
        const y = spring.y;
        const scale = spring.scale;
        const boxShadow =
            dragging && draggingCurrentIndex === index
                ? 'rgba(0, 0, 0, 0.15) 0px 5px 10px 0px'
                : 'rgba(0, 0, 0, 0.15) 0px 0px 0px 0px';

        return (
            <animated.div
                {...bind(index)}
                key={index}
                style={{
                    zIndex,
                    y,
                    scale,
                    touchAction: 'none',
                }}
            >
                <ManagementQuest
                    key={`quest_${quest.id}`}
                    boxShadow={boxShadow}
                    fixedHeight={fixedHeight}
                    quest={quest}
                    onDelete={deleteHandler}
                />
            </animated.div>
        );
    });

    return (
        <Fragment>
            <div className={classes.ManagementQuests}>
                <ManagementHeader
                    title={t('Management:QUESTS_TITLE')}
                    buttonText={t('Management:ADD_QUEST_BUTTON_LABEL')}
                    onClick={onAddQuestHandler}
                    permission="library.manage"
                />

                {!loading && (
                    <div className={`Box ${classes.ManagementQuestList}`}>
                        {/* {questList.length === 0 && ( */}

                        {/* )} */}
                        <div
                            className={`${classes.QuestList}`}
                            ref={listRef}
                            style={{ height: quests.length * fixedHeight }}
                        >
                            {questList}
                        </div>
                    </div>
                )}
            </div>

            <Dialog
                open={open}
                onClose={closeModal}
                className={classes.WarningDialog}
                PaperProps={{ className: `dialog` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:WARNING_HEADER')}</DialogTitle>
                <DialogContent>
                    {t('Common:QUEST_DELETION_WARNING_MESSAGE', { title: questToRemove?.title })}
                </DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={closeModal} />
                    <Button alt text={t('Common:BUTTON_PERMANENTLY_DELETE')} onClick={permanentlyDeleteHandler} />
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default ManagementQuests;
