/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CancelTokenSource } from 'axios';
import { useConfig } from '../context/ConfigContext';
import { Invite } from '../models/InvitedUser';
import http from '../utils/axios-instance';

const useInviteService = () => {
    const { config } = useConfig();
    const serviceUrl = `${config.baseUrl}/invite`;

    const getAll = (source?: CancelTokenSource) => {
        return http.get<Invite[]>(`${serviceUrl}`, { cancelToken: source?.token });
    };

    const get = (token: string, source?: CancelTokenSource) => {
        return http.get<Invite>(`${serviceUrl}/${token}`, { cancelToken: source?.token });
    };

    return {
        getAll,
        get,
    };
};

export default useInviteService;
