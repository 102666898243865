import { faArrowsAlt, faCircleExclamation, faHouse, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import { useDeck } from '../../../context/DeckContext';
import { ICard } from '../../../models/Card';
import useDeckService from '../../../services/DeckService';
import Button from '../../Button/Button';
import ElementAddButton from '../Buttons/ElementAddButton/ElementAddButton';
import { ElementType } from '../ElementTypes/ElementTypes';
import classes from './CardActions.module.scss';

interface CardActionsProps {
    isReorder: boolean;
    onViewClickHandler: () => void;
    onReorderClickhandler: () => void;
    onAddElementClickHandler: (type: ElementType) => void;
}

const CardActions: FunctionComponent<CardActionsProps> = ({
    isReorder,
    onViewClickHandler,
    onReorderClickhandler,
    onAddElementClickHandler,
}) => {
    const { t } = useTranslation();
    const { hasPermission } = useAuth();
    const canManage = hasPermission('library.manage') && isDesktop;
    const { deck, setDeck, currentCard, updateCards } = useDeck();
    const elements = currentCard?.elements || [];
    const { updateStart } = useDeckService();

    const moveElementsButton = elements.length >= 2 && (
        <Button
            text={`${isReorder ? t('Common:REORDER_BUTTON_DONE') : t('Common:REORDER_BUTTON')}`}
            alt
            iconSide={'left'}
            icon={<FontAwesomeIcon icon={faArrowsAlt} />}
            onClick={onReorderClickhandler}
        />
    );

    const setStartButton = (
        <Button
            text={t('Common:BUTTON_MAKE_START_CARD')}
            alt
            icon={<FontAwesomeIcon icon={faHouse} />}
            iconSide="left"
            onClick={() => {
                if (currentCard) {
                    const data = {
                        start_card_id: currentCard?.id,
                    };
                    const newCards = [...(deck.cards || [])];

                    const prevStart = newCards.findIndex((c) => c.start_card);
                    if (prevStart != -1) {
                        newCards[prevStart] = { ...newCards[prevStart], start_card: false };
                    }

                    const cardIdx = newCards.findIndex((c) => c.id === currentCard?.id);
                    if (cardIdx != -1) {
                        newCards[cardIdx] = { ...currentCard, start_card: true };
                    }

                    // TODO update cards with start card
                    updateStart(deck.id, data).then(() => {
                        updateCards(newCards);
                    });
                }
            }}
        />
    );
    const addElementButton = <ElementAddButton addElementButtonHandler={onAddElementClickHandler}></ElementAddButton>;

    const viewButton = (
        <Button
            className={`${classes.ViewButton}`}
            text={`${t('Common:CARD')}`}
            alt
            icon={<FontAwesomeIcon className={classes.PlusIcon} icon={faUpRightFromSquare} />}
            iconSide="left"
            onClick={onViewClickHandler}
        />
    );

    // const endPointLabel = (
    //     <div className={classes.EndpointLabel}>
    //         <FontAwesomeIcon className={classes.ExclamationIcon} icon={faCircleExclamation} />
    //         {t('Common:CARD_END_LABEL')}
    //     </div>
    // );

    // const publishedStateLabel = (
    //     <div className={classes.PublishedStateLabel}>
    //         ({deck.is_published ? t('Common:DECK_PUBLISED_STATE_LABEL') : t('Common:DECK_CONCEPT_STATE_LABEL')})
    //     </div>
    // );

    // const hasBranchingEndOnCard = (card?: ICard) => {
    //     if (!card) return false;
    //     return !Boolean(navActionsExistOnCard(card));
    // };

    // const navActionsExistOnCard = (card: ICard) => {
    //     return card?.source_of_edges.length > 0;
    // };

    const infoContainer = (
        <div className={`${classes.InfoContainer} ${canManage && classes.canManage}`}>
            {/* {hasBranchingEndOnCard(currentCard) && endPointLabel} */}
            {/* <div className={classes.TitleContainer}>{publishedStateLabel}</div> */}
        </div>
    );

    const onReorderButtons = <>{moveElementsButton}</>;
    const allButtons = (
        <>
            {!deck.is_published && !currentCard?.start_card && setStartButton}
            {viewButton}
            {moveElementsButton}
            {addElementButton}
        </>
    );

    const actionbarButtons = (
        <>
            <div className={classes.LeftActions}>{infoContainer}</div>

            <div className={classes.RightActions}>
                {isReorder && onReorderButtons}
                {!isReorder && allButtons}
            </div>
        </>
    );

    return (
        <div className={`${classes.CardActions} ${deck.is_published ? classes.published : classes.concept}`}>
            {actionbarButtons}
        </div>
    );
};

export default CardActions;
