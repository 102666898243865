import { useField } from 'formik';
import React, { FunctionComponent, InputHTMLAttributes } from 'react';
import classes from './InputCheckbox.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputCheckbox: FunctionComponent<Props> = (props) => {
    const [field, meta, helpers] = useField({
        name: props.name,
        type: props.type,
    });

    const className = meta.error && meta.touched ? classes.error : '';

    return (
        <div className={classes.InputCheckbox}>
            <div className={classes.Input}>
                <input
                    {...field}
                    {...props}
                    onChange={(e) => {
                        helpers.setValue(e.target.checked);
                        props.onChange?.(e);
                    }}
                    className={`${className}`}
                />

                {props.label}
            </div>
            <div className={classes.BelowField}>
                {meta.error && meta.touched && <div className={classes.errorMessage}>{meta.error}</div>}
            </div>
        </div>
    );
};

export default InputCheckbox;
