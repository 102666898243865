import React, { FunctionComponent, useEffect, useState } from 'react';
import { useConfig } from '../../../../context/ConfigContext';
import { IElement } from '../../../../models/Element';
import classes from './EditDefaultCopy.module.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from '../../../../utils/axios-instance';
import TextField from '../TextField/TextField';
import useTextElementForm from '../../../../components/TextElement/useTextElementForm';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faSave } from '@fortawesome/free-solid-svg-icons';
import { IDefaultCopy } from '../../../../models/Defaults';
import toast from 'react-hot-toast';

interface EditDefaultCopyProps {
    title: string;
    data?: IDefaultCopy[];
}

const EditDefaultCopy: FunctionComponent<EditDefaultCopyProps> = ({ title, data }) => {
    const { t } = useTranslation();
    const { config, setConfig, loadingConfig } = useConfig();
    const [element, setElement] = useState(() => {
        if (data && data.length > 0) {
            return data[0].elements.reverse().find((element) => element.elementable_type === 'text_element');
        }
    });

    const schema = Yup.object({
        body: Yup.string().default('').required(t('Common:INPUT_ERROR_STYLE_CSS_REQUIRED')),
    });

    const { initialValues, handleSubmit, handleDelete, isSaved, setIsSaved } = useTextElementForm({
        body: element?.elementable?.body || '',
    });

    return (
        <div className={`Box ${classes.EditDefaultCopy}`}>
            {data && element && (
                <Formik
                    onSubmit={(formValues, actions) => {
                        const newElementable = { ...element.elementable, body: formValues.body };
                        const newElement = { ...element, elementable: newElementable } as IElement;
                        setElement(newElement);

                        const newDefaultCopy = {
                            ...data[0],
                            elements: [newElement],
                        };

                        // update config.tenant.texts
                        // TODO: just look at it
                        const newConfig = { ...config };
                        newConfig.tenant.texts[data[0].group_key][0] = newDefaultCopy;

                        toast.success(t('Common:TOAST_COPY_SAVED_SUCCESS'));

                        setConfig(newConfig);

                        handleSubmit(element, formValues, actions);
                    }}
                    initialValues={initialValues}
                    validationSchema={schema}
                >
                    {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                        const disabledSubmit = isSubmitting || !(isValid && dirty);

                        return (
                            <form noValidate={true} onSubmit={handleSubmit}>
                                <div className={classes.formGroup}>
                                    <h2>{title}</h2>
                                    <div className={`${classes.InputWrapper} ${isSaved ? classes['saved'] : ''}`}>
                                        <TextField body={initialValues.body}></TextField>
                                    </div>

                                    <Button
                                        className={classes.ElementSave}
                                        type="submit"
                                        text={t('Common:BUTTON_SAVE')}
                                        alert={true}
                                        disabled={disabledSubmit}
                                        icon={
                                            isSubmitting ? (
                                                <FontAwesomeIcon
                                                    className={classes.ButtonLoadingIcon}
                                                    icon={faCircleNotch}
                                                    spin={true}
                                                />
                                            ) : (
                                                <FontAwesomeIcon className={classes.SaveIcon} icon={faSave} />
                                            )
                                        }
                                    ></Button>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            )}
        </div>
    );
};

export default EditDefaultCopy;
