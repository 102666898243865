import { User } from '../models/User';
import { getSubdomain } from './Info';

export const setLocalStorageUser = (user: User): void => {
    const tenantUser = `${getSubdomain()}_user`;
    localStorage.setItem(tenantUser, JSON.stringify(user));
};

export const getLocalStorageUser = (): string | null => {
    const tenantUser = `${getSubdomain()}_user`;
    return localStorage.getItem(tenantUser);
};
