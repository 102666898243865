import React, { FunctionComponent } from 'react';
import { ITag } from '../../models/Tag';
import classes from './TagLabel.module.scss';

interface TagLabelProps {
    tag: ITag;
}

const TagLabel: FunctionComponent<TagLabelProps> = ({ tag }) => {
    return <div className={classes.TagLabel}>{tag.title}</div>;
};

export default TagLabel;
