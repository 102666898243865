import { useFormikContext } from 'formik';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
// import CoverImageUpload from '../../../../components/CoverImageUpload/CoverImageUpload';
import ButtonLoadingIcon from '../../../../components/Icons/ButtonLoadingIcon/ButtonLoadingIcon';
import TrashIcon from '../../../../components/Icons/TrashIcon/TrashIcon';
import InputField from '../../../../components/InputField/InputField';
// import TextAreaField from '../../../../components/TextAreaField/TextAreaField';
import { useCategory } from '../../../../context/CategoryContext';
// import { IFile } from '../../../../models/File';
// import { SelectOption } from '../../../../models/ImageUpload';
import { getColor } from '../../../../utils/colors';
// import CategoryCover from '../../../Home/Categories/CategoryCover/CategoryCover';
import classes from './CategoryEditDetailForm.module.scss';

type FormProps = {
    isCreate: boolean;
    handleFormSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    isSubmitting: boolean;
    disabledSubmit: boolean;
    isSaved: boolean;
    deleteHandler: () => void;
    cancelHandler: () => void;
};

const CategoryEditDetailForm: FunctionComponent<FormProps> = ({
    isCreate,
    handleFormSubmit,
    deleteHandler,
    cancelHandler,
    disabledSubmit,
    isSubmitting,
}) => {
    const { t } = useTranslation();
    const { category, setCategory } = useCategory();
    const { setFieldValue } = useFormikContext();
    const buttonLoadingIconColor = getColor('--brand-color-10');

    useEffect(() => {
        setFieldValue('cover_image_id', category.cover_image?.id);
    }, [category.cover_image]);

    useEffect(() => {
        setFieldValue('cover_background_size', category.cover_background_size);
    }, [category.cover_background_size]);

    const titleChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCategory({ ...category, title: event.target.value });
    };

    const descriptionChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCategory({ ...category, description: event.target.value });
    };

    // const backgroundSizeChangeHandler = (option: SelectOption | null) => {
    //     if (option) {
    //         setCategory({ ...category, cover_background_size: option.value });
    //     }
    // };

    // TODO: temp disabled. Uncomment when upload works
    // const onUploadImageHandler = (file: IFile | undefined) => {
    //     setCategory({ ...category, cover_image: file });
    // };

    return (
        <form noValidate={true} onSubmit={handleFormSubmit}>
            <div className={classes.formGroup}>
                <div className={classes.CategoryDetail}>
                    <div className={classes.InputContainer}>
                        {/* <div className={`${classes.formGroup}`}>
                            <CoverImageUpload
                                onChange={onUploadImageHandler}
                                originalFile={category.cover_image}
                                onChangeBackgroundSize={backgroundSizeChangeHandler}
                            />
                        </div> */}
                        <div className={`Box ${classes.InputContent}`}>
                            <div className={classes.formInput}>
                                <div className={`${classes.formGroup}`}>
                                    <InputField
                                        name="title"
                                        type="text"
                                        label={t('Common:CATEGORY_TITLE_INPUT_LABEL')}
                                        placeholder={t('Common:CATEGORY_TITLE_INPUT_PLACEHOLDER')}
                                        // maxLength={16}
                                        onChange={titleChangeHandler}
                                        autoFocus
                                    ></InputField>
                                </div>
                                <div className={`${classes.formGroup}`}>
                                    <InputField
                                        id="description"
                                        name="description"
                                        type="text"
                                        label={t('Common:CATEGORY_DESCRIPTION_INPUT_LABEL')}
                                        multiline
                                        placeholder={t('Common:CATEGORY_DESCRIPTION_INPUT_PLACEHOLDER')}
                                        // maxLength={80}
                                        onChange={descriptionChangeHandler}
                                    ></InputField>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.formActions}`}>
                            {!isCreate && (
                                <Button
                                    className={classes.formDelete}
                                    text={t('Common:BUTTON_DELETE')}
                                    danger
                                    icon={<TrashIcon />}
                                    iconSide="left"
                                    onClick={deleteHandler}
                                ></Button>
                            )}

                            <Button
                                text={t('Common:BUTTON_CANCEL')}
                                alt={true}
                                border={true}
                                onClick={cancelHandler}
                            ></Button>

                            <button
                                className={`${classes.submitButton} btn btn-flavour btn-flavour--alt`}
                                type="submit"
                                // onSubmit={onSubmit}
                                disabled={disabledSubmit}
                            >
                                {isSubmitting && <ButtonLoadingIcon fill={buttonLoadingIconColor} />}
                                {isCreate ? t('Common:BUTTON_CREATE') : t('Common:BUTTON_SAVE')}
                            </button>
                        </div>
                    </div>
                    {/* <div className={classes.CategoryCover}>
                        <CategoryCover editMode={true}></CategoryCover>
                    </div> */}
                </div>
            </div>
        </form>
    );
};

export default CategoryEditDetailForm;
