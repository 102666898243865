import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../../../../../components/Button/Button';
import InputError from '../../../../../components/InputError/InputError';
import { useConfig } from '../../../../../context/ConfigContext';
import { useQuest } from '../../../../../context/QuestContext';
import { IDeck } from '../../../../../models/Deck';
import useAxiosFetch from '../../../../../utils/useAxiosFetch';
import { FormFields } from '../../useQuestEditForm';
import classes from './Decks.module.scss';
import DecksDialog from './DecksDialog/DecksDialog';
import DecksList from './DecksList/DecksList';

type FormProps = {
    isCreate: boolean;
};

const Decks: FunctionComponent<FormProps> = ({ isCreate }) => {
    const { config } = useConfig();
    const history = useHistory();
    const { t } = useTranslation();
    const { response, loading } = useAxiosFetch<IDeck[]>(isCreate ? `${config.baseUrl}/decks` : null);
    const { quest, setQuest } = useQuest();
    const { setFieldValue, errors } = useFormikContext<FormFields>();
    const [decks, setDecks] = useState<IDeck[]>([]);

    const deckRef = useRef<IDeck[]>(quest.decks);

    const addDeck = (deck: IDeck) => {
        const newDecks: IDeck[] = _.cloneDeep(quest.decks);
        newDecks.push(deck);

        const newPotentialDecks: IDeck[] = _.cloneDeep(potentialdecks);
        newPotentialDecks.splice(
            newPotentialDecks.findIndex((findDeck) => {
                return deck.id === findDeck.id;
            }),
            1,
        );

        deckRef.current = newDecks;

        setDecks(newPotentialDecks);

        const newQuest = { ...quest, decks: newDecks, potential_decks: newPotentialDecks };
        setQuest(newQuest);
    };

    const removeDeck = (deck: IDeck) => {
        const newDecks: IDeck[] = _.cloneDeep(quest.decks);
        newDecks.splice(
            newDecks.findIndex((findDeck) => {
                return deck.id === findDeck.id;
            }),
            1,
        );

        deckRef.current = newDecks;

        const newPotentialDecks: IDeck[] = _.cloneDeep(potentialdecks);
        newPotentialDecks.push(deck);

        const sortedNewPotentialDecks = _.sortBy(newPotentialDecks, ['id']);

        setDecks(sortedNewPotentialDecks);

        const newQuest = { ...quest, decks: newDecks, potential_decks: sortedNewPotentialDecks };
        setQuest(newQuest);
    };

    useEffect(() => {
        if (response) {
            setDecks(response.data);
        }
    }, [response]);

    useEffect(() => {
        setFieldValue('decks', quest.decks.map((deck) => deck.id).toString());
    }, [quest.decks]);

    const taskButtonOnClickHandler = (deck: IDeck) => {
        history.push({ pathname: `/deck/${deck.id}`, state: deck });
    };

    const taskButtonOnEditClickHandler = (deck: IDeck) => {
        history.push({ pathname: `/deck/edit/${deck.id}`, state: deck });
    };

    const [open, setOpen] = useState<boolean>(false);
    const toggleOpen = () => setOpen(!open);

    const potentialdecks = isCreate ? decks : quest.potential_decks;

    const onReorderItems = (newDecks: IDeck[]) => {
        const newQuest = { ...quest, decks: newDecks };
        setQuest(newQuest);
    };

    return (
        <div className={`Box ${classes.Decks}`}>
            <div className={`${classes.Decks}`}>
                <div className={`${classes.DecksHeader}`}>
                    <div className={classes.DeckDescription}>
                        <span className={`h2 ${classes.DecksTitle}`}>{t('Quests:DECKS_HEADER')}</span>
                        <div className={classes.DecksStat}>
                            <FontAwesomeIcon className={classes.DecksIcon} icon={faLayerGroup} />
                            <span className={classes.DecksAmount}>{quest?.decks?.length || 0}</span>
                        </div>
                    </div>
                    <Button text={t('Common:BUTTON_ADD_DECK')} onClick={toggleOpen} />
                </div>

                <div className={classes.DecksListContainer}>
                    <DecksList
                        items={deckRef.current}
                        taskButtonOnClickHandler={taskButtonOnClickHandler}
                        taskButtonOnEditClickHandler={taskButtonOnEditClickHandler}
                        removeDeck={removeDeck}
                        onReorderItems={onReorderItems}
                    />
                    <div className={classes.BelowField}>
                        {errors.decks && <InputError error={errors.decks}></InputError>}
                    </div>
                </div>
            </div>

            <DecksDialog decks={potentialdecks} onAddDeckHandler={addDeck} open={open} toggleOpen={toggleOpen} />
        </div>
    );
};

export default Decks;
