import React, { useEffect, useReducer, useRef, useState } from 'react';
import { add } from 'date-fns';
import { FunctionComponent } from 'react';
import { CtxProvider, ITimerState } from './TimerContext';

export const TimerProvider: FunctionComponent = ({ children }) => {
    const [originalTimer, updateOriginalTimer] = useState('00:00');

    const initialState = {
        seconds: 0,
        originalSeconds: 0,
        formattedTime: '00:00',
        originalTime: '00:00',
        timerRunning: false,
    };
    const intervalId = useRef(0);

    const clear = () => {
        if (intervalId.current) {
            window.clearInterval(intervalId.current);
        }
    };

    const formatTimer = (timeInSeconds: number) => {
        // get minutes from seconds
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;

        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const reducer = (state: ITimerState, action) => {
        const formattedTime = formatTimer(action.payload);

        switch (action.type) {
            case 'initCount':
                return {
                    seconds: Number(action.payload),
                    originalSeconds: Number(action.payload),
                    formattedTime: formattedTime,
                    originalTime: formattedTime,
                    timerRunning: false,
                };
            case 'startCount':
                return {
                    seconds: Number(action.payload),
                    originalSeconds: Number(action.payload),
                    formattedTime: formattedTime,
                    originalTime: formattedTime,
                    timerRunning: true,
                };
            case 'pauseCount':
                return {
                    seconds: state.seconds,
                    originalSeconds: state.originalSeconds,
                    formattedTime: state.formattedTime,
                    originalTime: state.originalTime,
                    timerRunning: false,
                };
            case 'stopCount':
                return {
                    seconds: state.seconds,
                    originalSeconds: state.originalSeconds,
                    formattedTime: state.formattedTime,
                    originalTime: state.originalTime,
                    timerRunning: false,
                };
            case 'tick':
                if (state.seconds - 1 === 0) {
                    clear();
                    return {
                        seconds: 0,
                        originalSeconds: state.originalSeconds,
                        formattedTime: '00:00',
                        originalTime: state.originalTime,
                        timerRunning: false,
                    };
                } else {
                    const formattedTime = formatTimer(state.seconds - 1);
                    return {
                        seconds: state.seconds - 1,
                        originalSeconds: state.originalSeconds,
                        formattedTime: formattedTime,
                        originalTime: state.originalTime,
                        timerRunning: true,
                    };
                }
            case 'reset':
                return {
                    seconds: Number(action.payload),
                    originalSeconds: Number(action.payload),
                    formattedTime: formattedTime,
                    originalTime: formattedTime,
                    timerRunning: false,
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const setTimer = (timer: string) => {
        updateOriginalTimer(timer);
        dispatch({ type: 'initCount', payload: getTimeInSeconds(timer) });
    };

    const getTimeInSeconds = (timer: string) => {
        const dateNow = new Date();
        const dateWithTimerAdded = add(dateNow, {
            minutes: Number(timer.split(':')[0]),
            seconds: Number(timer.split(':')[1]),
        });
        const timeDiff = dateWithTimerAdded.getTime() - dateNow.getTime();
        // const timeDiffInMinutes = timeDiff / 1000 / 60;
        const timeDiffInSeconds = timeDiff / 1000;

        return timeDiffInSeconds;
    };

    const startTimer = () => {
        clear();

        dispatch({ type: 'startCount', payload: getTimeInSeconds(originalTimer) });
        intervalId.current = window.setInterval(() => {
            dispatch({ type: 'tick' });
        }, 1000);
    };

    const pauseTimer = () => {
        clear();
        dispatch({ type: 'pauseCount' });
    };

    const stopTimer = () => {
        clear();
        dispatch({ type: 'stopCount' });
    };

    const resetTimer = () => {
        clear();
        dispatch({ type: 'reset', payload: getTimeInSeconds(originalTimer) });
    };

    useEffect(() => {
        return () => clear();
    }, []);

    return (
        <CtxProvider value={{ state, setTimer, startTimer, pauseTimer, stopTimer, resetTimer }}>{children}</CtxProvider>
    );
};
