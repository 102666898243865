import React, { ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState } from 'react';
import classes from './GroupCover.module.scss';
import { useSpring, animated } from '@react-spring/web';
import useHover from '../../../../hooks/useHover';
import GroupsIcon from '../../../../components/Icons/GroupsIcon/GroupsIcon';
import { useTranslation } from 'react-i18next';
import { IGroupBase } from '../../../../models/Group';

export interface GroupCoverProps {
    editMode?: boolean;
    delay?: number;
    group: IGroupBase;
    clickHandler?: () => void;
}

export type IncrementedRef = {
    current: HTMLDivElement | null;
};

const GroupCover: ForwardRefRenderFunction<IncrementedRef, GroupCoverProps> = ({ group, clickHandler }, ref) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const isHover = useHover(containerRef);

    useImperativeHandle(ref, () => ({
        current: containerRef.current,
    }));

    useEffect(() => {
        set.start({ xys: [0, 0, 1] });
    }, []);

    const [animating, setAnimating] = useState(false);

    const calc = (x: number, y: number, width: number, height: number) => {
        const rot = 40;
        return [-(y - height / 2) / rot, (x - width / 2) / rot, 1.04];
    };

    const [props, set] = useSpring(() => ({
        xys: [0, 0, 1],
        config: { mass: 3, tension: 550, friction: 40 },
        onStart: () => {
            setAnimating(true);
        },
        onRest: () => {
            setAnimating(false);
        },
    }));

    const trans = (x: number, y: number, s: number) => {
        const perspective = `perspective(700px) rotateX(${-x}deg) rotateY(${-y}deg) scale(${s})`;
        if (!animating) {
            if (isHover) {
                return perspective;
            } else {
                return `scale(${s})`;
            }
        }
        return perspective;
    };

    return (
        <div className={`cover ${classes.GroupCover}`} onClick={clickHandler}>
            <animated.div
                ref={containerRef}
                className={`deck Box ${classes.GroupCoverCard}`}
                onMouseMove={(e) => {
                    const node = e.target as HTMLElement;
                    const rect = node.getBoundingClientRect();
                    const x = e.clientX - rect.left;
                    const y = e.clientY - rect.top;
                    set.start({ xys: calc(x, y, rect.width, rect.height) });
                }}
                onMouseLeave={() => {
                    set.start({ xys: [0, 0, 1] });
                }}
                style={{ transform: props?.xys?.to(trans) }}
            >
                <div className={classes.HitArea}>
                    <div className={classes.Header}>
                        <div className={classes.Avatar}>
                            <GroupsIcon />
                        </div>
                        <div>
                            <h3 className={`${classes.Title} wrap-text-overflow-2`}>{group.name}</h3>
                            <span className={`${classes.Subtitle} wrap-text-overflow-2`}>{`${group.users_count} ${
                                group.users_count === 1 ? t('Common:MEMBER') : t('Common:MEMBERS')
                            }`}</span>
                        </div>
                    </div>
                    <div className={`${classes.Description} wrap-text-overflow-3`}>{group.description}</div>
                </div>
            </animated.div>
        </div>
    );
};

export default React.forwardRef(GroupCover);
