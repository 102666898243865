import { faMinus, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import ExtendedMuiList from '../../../../../components/ExtendedMuiList/ExtendedMuiList';
import { useConfig } from '../../../../../context/ConfigContext';
import { useQuest } from '../../../../../context/QuestContext';
import { IGroup, IGroupsResponse } from '../../../../../models/Group';
import useAxiosFetch from '../../../../../utils/useAxiosFetch';
import classes from './Groups.module.scss';
import GroupsDialog from './GroupsDialog/GroupsDialog';

type FormProps = {
    isCreate: boolean;
};

const Groups: FunctionComponent<FormProps> = ({ isCreate }) => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const { response, loading } = useAxiosFetch<IGroupsResponse>(isCreate ? `${config.baseUrl}/groups` : null);
    const { quest, setQuest } = useQuest();
    const { setFieldValue } = useFormikContext();
    const [groups, setGroups] = useState<IGroup[]>([]);

    const addGroup = (group: IGroup) => {
        const newGroups: IGroup[] = _.cloneDeep(quest.current_groups);
        newGroups.push(group);

        const newPotentialGroups: IGroup[] = _.cloneDeep(potentialgroups);
        newPotentialGroups.splice(
            newPotentialGroups.findIndex((findGroup) => {
                return group.id === findGroup.id;
            }),
            1,
        );

        setGroups(newPotentialGroups);

        const newQuest = { ...quest, current_groups: newGroups, potential_groups: newPotentialGroups };
        setQuest(newQuest);
    };

    const removeGroup = (group: IGroup) => {
        const newGroups: IGroup[] = _.cloneDeep(quest.current_groups);
        newGroups.splice(
            newGroups.findIndex((findGroup) => {
                return group.id === findGroup.id;
            }),
            1,
        );

        const newPotentialGroups: IGroup[] = _.cloneDeep(potentialgroups);
        newPotentialGroups.push(group);
        const sortPotentialGroups = _.sortBy(newPotentialGroups, ['id']);

        setGroups(sortPotentialGroups);

        const newQuest = { ...quest, current_groups: newGroups, potential_groups: sortPotentialGroups };
        setQuest(newQuest);
    };

    useEffect(() => {
        if (response) {
            const groupsResponse = response.data;

            const myGroups: IGroup[] = _.cloneDeep(groupsResponse.my_groups);
            const otherGroups: IGroup[] = _.cloneDeep(groupsResponse.other_groups);
            const mergedGroups: IGroup[] = [...myGroups, ...otherGroups];

            setGroups(mergedGroups);
        }
    }, [response]);

    useEffect(() => {
        setFieldValue(
            'groups',
            quest.current_groups.length > 0 ? quest.current_groups.map((group) => group.id).toString() : '',
        );
    }, [quest.current_groups]);

    const potentialgroups = isCreate ? groups : quest.potential_groups;

    const renderCurrentGroupItem = (group: IGroup) => {
        return (
            <div className={classes.GroupItem} key={`user_${group.id}`}>
                <div className={classes.GroupAvatar}>
                    <FontAwesomeIcon className={classes.GroupsIcon} icon={faUserFriends} />
                </div>
                <div className={classes.GroupName}>{group.name}</div>
                <div className="flex-grow"></div>
                <Button
                    text=""
                    danger={true}
                    icon={<FontAwesomeIcon icon={faMinus} />}
                    onClick={() => removeGroup(group)}
                ></Button>
            </div>
        );
    };

    const filterCurrentGroupsDataBySearch = (searchValues: string[]) => {
        return quest.current_groups.filter((item) => {
            const name = item.name.toLowerCase();

            const searchableValues = { name };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    const [open, setOpen] = useState<boolean>(false);
    const toggleOpen = () => setOpen(!open);

    return (
        <div className={`Box ${classes.Groups}`}>
            <div className={`${classes.Groups}`}>
                <div className={`${classes.GroupsHeader}`}>
                    <div className={classes.GroupsDetails}>
                        <h2 className={classes.GroupsTitle}>{t('Quests:GROUP_HEADER')}</h2>
                        <div className={classes.GroupsStat}>
                            <FontAwesomeIcon className={classes.GroupsIcon} icon={faUserFriends} />
                            <span className={classes.GroupsAmount}>{quest?.current_groups?.length || 0}</span>
                        </div>
                    </div>
                    <Button text={t('Common:BUTTON_ADD_GROUP')} onClick={toggleOpen} />
                </div>

                <ExtendedMuiList
                    loading={loading}
                    searchInputLabel={t('Common:GROUPS_SEARCH_INPUT_LABEL')}
                    filterDataBySearch={filterCurrentGroupsDataBySearch}
                    noItemsMessage={t('Common:LIST_MESSAGE_NO_MEMBERS')}
                    noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_MEMBERS')}
                    items={quest.current_groups}
                    renderItem={renderCurrentGroupItem}
                    itemsListClasses={`${classes.GroupsList}`}
                />
            </div>
            <GroupsDialog open={open} toggleOpen={toggleOpen} groups={potentialgroups} addGroup={addGroup} />
        </div>
    );
};

export default Groups;
