/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CancelTokenSource } from 'axios';
// import { IElement, IElementable } from '../pages/Home/Categories/Categories';
import { useConfig } from '../context/ConfigContext';
import { IElementable, IElement } from '../models/Element';
import http from '../utils/axios-instance';

// export interface ITextElement {
//     id: number;
//     body: string;
//     created_at: string;
//     updated_at: string;
// }

export interface ICreateTextElement {
    body: string;
    model_type: string;
    model_id: number;
}

export interface IUpdateTextElement {
    body: string;
}

const useTextElementService = () => {
    const { config } = useConfig();
    const serviceUrl = `${config.baseUrl}/textelement`;

    const getAll = (source?: CancelTokenSource) => {
        return http.get<IElementable[]>(`${serviceUrl}`, { cancelToken: source?.token });
    };

    const get = (id: number, source?: CancelTokenSource) => {
        return http.get<IElementable>(`${serviceUrl}/${id}`, { cancelToken: source?.token });
    };

    const create = (data: ICreateTextElement, source?: CancelTokenSource) => {
        return http.post<IElement>(`${serviceUrl}`, data, { cancelToken: source?.token });
    };

    const update = (id: number, data: IUpdateTextElement, source?: CancelTokenSource) => {
        return http.put<IElement>(`${serviceUrl}/${id}`, data, { cancelToken: source?.token });
    };

    const remove = (id: number, source?: CancelTokenSource) => {
        return http.delete(`${serviceUrl}/${id}`, { cancelToken: source?.token });
    };

    return {
        getAll,
        get,
        create,
        update,
        remove,
    };
};

export default useTextElementService;
