import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { useConfig } from '../../context/ConfigContext';
import { IGroup, IGroupsResponse } from '../../models/Group';
import useAxiosFetch from '../../utils/useAxiosFetch';
import classes from './GroupSelect.module.scss';

export interface SelectOption {
    value: number;
    label: string;
}

export interface GroupSelectProps {
    name: string;
    options: SelectOption[];
    value: SelectOption;
    error: string;
    touched: boolean;
    onChange: (e: SelectOption | null) => void;
    onBlur: (event: React.FocusEvent<HTMLElement>) => void;
    isMulti?: boolean;
}

const GroupSelect: FunctionComponent<GroupSelectProps> = (props) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const { setFieldValue } = useFormikContext();
    const { config } = useConfig();
    const { response, loading } = useAxiosFetch<IGroupsResponse>(`${config.baseUrl}/groups`);
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [options, setOptions] = useState<SelectOption[]>([]);

    const className = props.error && props.touched ? classes.error : '';

    useEffect(() => {
        if (response) {
            const groupsResponse = response.data;

            const myGroups: IGroup[] = _.cloneDeep(groupsResponse.my_groups);
            const otherGroups: IGroup[] = _.cloneDeep(groupsResponse.other_groups);
            const mergedGroups: IGroup[] = [...myGroups, ...otherGroups];

            setGroups(mergedGroups);
        }
    }, [response]);

    useEffect(() => {
        if (groups) {
            const optionsData: SelectOption[] = groups.map((group) => {
                return { value: group.id, label: group.name };
            });
            setOptions(optionsData);
        }
    }, [groups]);

    return (
        <div className={classes.FormRoles}>
            <div className={classes.FormFieldHeader}>
                <h2 className={`h5 wrap-text ${classes.FormFieldTitle}`}>{t('Members:GROUP_SELECT_TITLE')}</h2>
                <span className={`wrap-text ${classes.FormFieldSubtitle}`}>{t('Members:GROUP_SELECT_SUBTITLE')}</span>
            </div>
            <div className={`${classes.GroupSelect}`}>
                {!loading && (
                    <ReactSelect
                        id={props.name}
                        ref={ref}
                        defaultValue={props.value}
                        placeholder={t('Members:GROUP_SELECT_PLACEHOLDER')}
                        noOptionsMessage={() => t('Members:GROUP_SELECT_NO_MORE_OPTIONS')}
                        options={options}
                        isMulti={props.isMulti}
                        onBlur={props.onBlur}
                        onChange={(option) => {
                            if (option) {
                                setFieldValue(props.name, option);
                            }
                        }}
                        value={props.value}
                        className={`react-select-container ${className}`}
                        classNamePrefix="react-select"
                    ></ReactSelect>
                )}

                <div className={classes.BelowField}>
                    {props.error && props.touched && <div className={classes.errorMessage}>{props.error}</div>}
                </div>
            </div>
        </div>
    );
};

export default GroupSelect;
