import { FormikHelpers, FormikProps } from 'formik';
import { useCallback } from 'react';
import * as rdd from 'react-device-detect';
import { useConfig } from '../../../context/ConfigContext';
import http from '../../../utils/axios-instance';
import { IReport, ReportType } from '../ReportDialog';

export interface FormFields {
    body: string;
    type: ReportType;
    cardId: number;
}

export type ReturnType = {
    handleSubmit: (report: IReport, formFields: FormFields, actions: FormikHelpers<FormFields>) => Promise<void>;
};

const useReportForm = (formik: FormikProps<FormFields>): ReturnType => {
    const { config } = useConfig();

    const handleSubmit = useCallback((report: IReport, formFields: FormFields, actions: FormikHelpers<FormFields>) => {
        const deviceInfo = rdd.getSelectorsByUserAgent(navigator.userAgent);
        const jsonString = JSON.stringify(deviceInfo);

        const data = {
            ...report,
            deviceInfo: jsonString,
        };

        return http.post(`${config.baseUrl}/report`, data).then(() => actions.resetForm());
    }, []);

    return {
        handleSubmit,
    };
};

export default useReportForm;
