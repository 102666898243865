import { faAngleLeft, faAngleRight, faCheck, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigContext';
import { useDeck } from '../../../context/DeckContext';
import useSticky from '../../../hooks/useSticky';
import { IEdge } from '../../../models/Edge';
import AxiosInstance from '../../../utils/axios-instance';
import { getColor } from '../../../utils/colors';
import Button from '../../Button/Button';
import { CardCompleted } from '../DeckDetails';
import classes from './DeckActions.module.scss';
interface Props {
    cardCompleted: boolean;
    deckCompleted: boolean;
    deckProgress: CardCompleted[];
    deckCardsTotal: number;
    currentIndex: number;
    hasBranchingEnd: boolean;
    isPublished: boolean;
    clickCompleteDeckHandler: () => void;
    clickContinueHandler: () => void;
    clickEditHandler: () => void;
    clickEditDeckHandler: () => void;
    forceTrackStickyEvent: number;
}

const DeckActions: React.FC<Props> = ({
    cardCompleted,
    deckCompleted,
    deckProgress,
    currentIndex,
    hasBranchingEnd,
    isPublished,
    clickCompleteDeckHandler,
    clickEditHandler,
    clickEditDeckHandler,
    forceTrackStickyEvent,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isSticky, trackStickyEvent] = useSticky(ref);
    const {
        deck,
        cards,
        currentCard,
        currentCardIndex,
        previousButtonEnabled,
        nextButtonEnabled,
        currentCardCompleted,
        nextCard,
        previousCard,
        currentAnswerFromSession,
        currentCardEdge,
        previousEdgeIndex,
        setPreviousEdgeIndex,
        edges,
        setEdges,
        historyCardIds,
        setHistoryCardIds,
        currentHistoryIndex,
        setCurrentHistoryIndex,
        isHistory,
    } = useDeck();
    const { t } = useTranslation();
    const { hasPermission } = useAuth();
    const [whereToContinueIndex, setWhereToContinueIndex] = useState(0);
    const { config } = useConfig();
    const historyCount = historyCardIds.length;

    const isDesktopFormatMobile = useMediaQuery('(min-width: 992px)');
    const canManage = hasPermission('library.manage') && isDesktop;

    const showPrevButton = previousButtonEnabled;

    const showCompleteButton = cardCompleted && hasBranchingEnd;
    const showNextButton = nextButtonEnabled;

    const showEditButton = canManage;

    const showActions = showNextButton || showPrevButton || showCompleteButton || showEditButton;

    useEffect(() => {
        trackStickyEvent();
    }, [currentCardIndex, deck, forceTrackStickyEvent]);

    useEffect(() => {
        setWhereToContinueIndex((_: number) => {
            return deckProgress.findIndex((prog) => {
                return prog.completed === false;
            });
        });
    }, [deckProgress]);

    const nextButtonHandler = async () => {
        if (deck.deckSession && currentCardEdge && !isHistory) {
            // Add edge when not history
            setEdges((previousValue) => {
                const newEdges: IEdge[] = [...previousValue];
                newEdges.push(currentCardEdge);

                const min = 0;
                const maxEdges = newEdges.length - 1;
                const nextEdgeIndex = Math.min(maxEdges, Math.max(min, previousEdgeIndex + 1));

                setPreviousEdgeIndex(nextEdgeIndex);

                const nextIndex = cards.findIndex((card) => card.id === currentCardEdge?.target_id);

                nextCard(nextIndex);

                return newEdges;
            });

            setHistoryCardIds((previousValue) => {
                const newHistoryCardIds: number[] = [...previousValue];
                newHistoryCardIds.push(currentCardEdge.target_id);

                setCurrentHistoryIndex(newHistoryCardIds.length - 1);

                return newHistoryCardIds;
            });

            if (deck.is_published) {
                AxiosInstance.put(`${config.baseUrl}/decksessions/${deck.deckSession.id}`, {
                    edge_id: currentCardEdge.id,
                });
            }
        }
        if (isHistory) {
            // Get edge from History

            const min = 0;
            const maxEdges = edges.length - 1;

            const nextEdgeIndex = Math.min(maxEdges, Math.max(min, previousEdgeIndex + 1));
            const nextCardId = edges[nextEdgeIndex].target_id;

            const nextCardIndex = cards.findIndex((card) => card.id === nextCardId);

            setPreviousEdgeIndex(nextEdgeIndex);
            setCurrentHistoryIndex((previousValue) => previousValue + 1);

            nextCard(nextCardIndex);
        }
    };

    const onSelectedHistoryCardIdChangedHandler = (event: React.ChangeEvent<unknown>, page: number) => {
        const nextCurrentHistoryIndex = page - 1;

        const min = -1;
        const maxEdges = edges.length - 1;

        const nextEdgeIndex = Math.min(maxEdges, Math.max(min, nextCurrentHistoryIndex - 1));
        const nextCardId = historyCardIds[nextCurrentHistoryIndex];

        const nextCardIndex = cards.findIndex((card) => card.id === nextCardId);

        setPreviousEdgeIndex(nextEdgeIndex);
        setCurrentHistoryIndex(nextCurrentHistoryIndex);

        nextCard(nextCardIndex);
    };
    const useStyles = makeStyles((theme: Theme) => ({
        root: {
            '& .MuiPaginationItem-root': {
                color: canManage ? getColor('--text-color-contrast') : getColor('--text-color'),
            },
            '& .Mui-selected': {
                color: canManage ? getColor('--text-color') : getColor('--text-color-contrast'),
                backgroundColor: canManage ? getColor('--brand-color-12') : getColor('--brand-alert-color'),
            },
            '& .Mui-selected:hover': {
                color: canManage ? getColor('--text-color') : getColor('--text-color-contrast'),
                backgroundColor: canManage ? getColor('--brand-color-12') : getColor('--brand-alert-color'),
                transform: 'scale(1.1, 1.1)',
                transition: 'all 0.1s cubic-bezier(0.4, 0, 0.2, 1)',
            },
        },
    }));
    const paginationClasses = useStyles();

    const paginationComponent = (
        <Pagination
            hideNextButton
            hidePrevButton
            className={paginationClasses.root}
            count={historyCount}
            page={currentHistoryIndex + 1}
            onChange={onSelectedHistoryCardIdChangedHandler}
            boundaryCount={1}
            siblingCount={isDesktopFormatMobile ? 1 : 0}
        />
    );

    const completeDeckButton = (
        <Button
            className={`${classes.CompleteDeckButton} ${canManage && classes.canManage}`}
            // alt={canManage}
            text={canManage || isDesktop ? t('Deck:DECK_BUTTON_FINISH') : ''}
            icon={<FontAwesomeIcon className={classes.CheckmarkIcon} icon={faCheck} />}
            onClick={clickCompleteDeckHandler}
        ></Button>
    );

    const editButton = (
        <>
            <Button
                className={`${classes.editButton} btn btn-flavour btn-flavour--alt`}
                type="button"
                text={t('Common:BUTTON_EDIT_DECK')}
                icon={<FontAwesomeIcon icon={faPen} />}
                iconSide="left"
                onClick={clickEditDeckHandler}
            />
            <Button
                className={`${classes.editButton} btn btn-flavour btn-flavour--alt`}
                type="button"
                text={t('Common:BUTTON_EDIT_CARD')}
                icon={<FontAwesomeIcon icon={faPen} />}
                iconSide="left"
                onClick={clickEditHandler}
            />
        </>
    );

    const prevButton = (
        <Button
            className={`${classes.Previous} ${previousEdgeIndex !== -1 ? classes.enabled : classes.disabled}`}
            type="button"
            text={isDesktopFormatMobile ? t('Common:BUTTON_PREVIOUS') : ''}
            alt={true}
            alert={!canManage}
            onClick={previousCard}
            icon={<FontAwesomeIcon className={classes.ArrowIcon} icon={faAngleLeft} />}
            iconSide="left"
        ></Button>
    );

    const nextButton = (
        <Button
            className={`${classes.Next} ${nextButtonEnabled ? classes.enabled : classes.disabled} ${
                !canManage && currentCard?.end_card ? classes.hidden : ''
            }`}
            type="button"
            text={isDesktopFormatMobile ? t('Common:BUTTON_NEXT') : ''}
            alt={true}
            alert={!canManage}
            onClick={nextButtonHandler}
            icon={<FontAwesomeIcon className={classes.ArrowIcon} icon={faAngleRight} />}
            iconSide="right"
        ></Button>
    );

    const actionbarButtons = (
        <div className={`${classes.ActionbarButtons} ${canManage && classes.canManage}`}>
            <div className={classes.LeftActions}>{showPrevButton && prevButton}</div>
            {deck.allow_history && historyCount > 1 && paginationComponent}
            <div className={classes.RightActions}>
                {canManage && editButton}
                {nextButton}
            </div>
        </div>
    );

    const actionbarSticky = (
        <div className={`${classes.ActionbarStickyContainer} ${isSticky && classes.isSticky}`}>
            <div
                className={`${classes.ActionbarSticky}  ${isPublished ? classes.published : classes.concept} ${
                    canManage && classes.canManage
                }`}
            >
                {actionbarButtons}
                {deckCompleted && completeDeckButton}
            </div>
        </div>
    );

    const actionbar = (
        <>
            <div
                className={`${classes.Actionbar} ${isSticky && classes.hidden} ${
                    isPublished ? classes.published : classes.concept
                } ${canManage && classes.canManage}`}
            >
                {actionbarButtons}
            </div>
            {deckCompleted && completeDeckButton}
        </>
    );

    return (
        <div className={`${classes.DeckActions} `} ref={ref}>
            {actionbar}
            {actionbarSticky}
        </div>
    );
};

export default DeckActions;
