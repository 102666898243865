import React, { FunctionComponent, useState } from 'react';
import { IProfile } from '../models/User';
import { CtxProvider } from './ProfileContext';

type ProfileProviderProps = {
    overrides?: Partial<IProfile>;
};

export const ProfileProvider: FunctionComponent<ProfileProviderProps> = ({ children, overrides }) => {
    const defaultProfile: IProfile = {
        id: 0,
        name: '',
        email: '',
        email_verified_at: '',
        show_email: 0,
        job_title: '',
        description: '',
        profile_picture_id: 0,
        profile_picture_background_size: 'cover',
        created_at: '',
        updated_at: '',
        user_statistics: {
            cardsCompleted: 0,
            decksCompleted: 0,
            categoriesCompleted: 0,
            questionPercentageCorrect: 0,
            questsCompleted: 0,
        },
        groups: [],
        roles: [],
    };

    const [profile, setProfile] = useState<IProfile>({
        ...defaultProfile,
        ...overrides,
    });

    const updateProfile = (profile: IProfile) => {
        setProfile((current) => {
            return { ...current, ...profile };
        });
    };

    const [isDataChanged, setIsDataChanged] = useState(false);

    return (
        <CtxProvider value={{ profile, setProfile, updateProfile, isDataChanged, setIsDataChanged }}>
            {children}
        </CtxProvider>
    );
};
