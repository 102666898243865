import { FormikHelpers } from 'formik';
import { useCallback } from 'react';
import { useConfig } from '../../../context/ConfigContext';
import { ITag } from '../../../models/Tag';
import axiosInstance from '../../../utils/axios-instance';

export interface IFormFields {
    title: string;
}

export type ReturnType = {
    handleSubmit: (tag: ITag, formFields: IFormFields, actions: FormikHelpers<IFormFields>) => Promise<void>;
    initialValues: IFormFields;
};

const useTagsEditForm = (tag: ITag, initialValues: IFormFields, isCreate: boolean): ReturnType => {
    const { config } = useConfig();

    const handleSubmit = useCallback((tag: ITag, formFields: IFormFields, actions: FormikHelpers<IFormFields>) => {
        if (isCreate) {
            return axiosInstance.post(`${config.baseUrl}/tags`, { ...formFields }).then(() => {
                actions.resetForm({ values: formFields });
            });
        } else {
            return axiosInstance
                .put(`${config.baseUrl}/tags/${tag.id}`, {
                    ...formFields,
                })
                .then(() => {
                    actions.resetForm();
                });
        }
    }, []);

    return {
        handleSubmit,
        initialValues,
    };
};

export default useTagsEditForm;
