import { faCircleNotch, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { IElement, IElementable } from '../../../../models/Element';
import Button from '../../../Button/Button';
import { ElementType } from '../../../Card/ElementTypes/ElementTypes';
import InputField from '../../../InputField/InputField';
import PlyrComponent, { combinedRegex, Provider } from '../../../Plyr/PlyrComponent';
import ElementHeader from '../../ElementHeader/ElementHeader';
import ElementIcon from '../../Question/QuestionFormik/ElementIcon/ElementIcon';
import useMediaElementForm from '../useMediaElementForm';
import classes from './VideoElement.module.scss';

export type OpenAnswer = {
    body: string;
};

type Props = {
    elementData: IElement;
    isEdit: boolean;
    isReorder: boolean;
    deleteHandler: (element: IElement) => void;
    updateElement?: (element: IElement) => void;
    loaded?: (element: IElement) => void;
};

const VideoElement: FunctionComponent<Props> = ({ elementData, isEdit, isReorder, deleteHandler, updateElement }) => {
    const { t } = useTranslation();
    const [element, setElement] = useState<IElement>(elementData);
    const [query, setQuery] = useState(element.elementable?.src || '');

    useEffect(() => {
        setElement(elementData);
        setQuery(elementData.elementable?.src || '');
    }, [elementData]);

    useEffect(() => {
        const timeOutId = setTimeout(
            () =>
                setElement((previous) => {
                    const newElementable =
                        previous.elementable && ({ ...previous.elementable, src: query } as IElementable);
                    const newElement = { ...previous, elementable: newElementable };

                    return newElement;
                }),
            500,
        );
        return () => clearTimeout(timeOutId);
    }, [query]);

    const { initialValues, handleSubmit, handleDelete, isSaved, setIsSaved } = useMediaElementForm({
        src: element.elementable?.src || '',
        provider: element.elementable?.provider as Provider,
    });

    const schema = Yup.object({
        src: Yup.string()
            .matches(combinedRegex, t('Common:INPUT_ERROR_SOURCE_INVALID'))
            .test('is-valid-url', t('Common:INPUT_ERROR_SOURCE_INVALID'), (value) => {
                if (!value) return false;
                const match = value.match(combinedRegex);
                let provider: Provider = 'youtube';
                let id = '';
                if (match) {
                    if (match[4] && match[4].match(/(youtube|youtu.be)/)) {
                        provider = 'youtube';
                        if (match[6] && match[6].length == 11) {
                            id = match[6];
                        }
                    } else if (match[10] && match[10].match(/(vimeo)/)) {
                        provider = 'vimeo';
                        id = match[12];
                    }
                }

                return provider.length !== 0 && id.length !== 0;
            })
            .required(t('Common:INPUT_ERROR_SOURCE_REQUIRED')),
    });

    const srcChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // TODO: also set provider
        setQuery(event.target.value);
        setIsSaved(false);
    };

    const elementHeader = () => {
        return (
            <ElementHeader
                icon={<ElementIcon elementType={ElementType.Video}></ElementIcon>}
                label={<label>{t('Deck:MEDIA_ELEMENT_VIDEO_HEADER_LABEL')}</label>}
            />
        );
    };

    return (
        <div
            className={`${classes.VideoElement} ${isEdit ? classes['isEdit'] : ''} ${
                isReorder ? classes['isReorder'] : ''
            }`}
        >
            {isEdit ? (
                isReorder ? (
                    elementHeader()
                ) : (
                    <Fragment>
                        <Formik
                            onSubmit={(formValues, actions) => {
                                updateElement?.(element);
                                handleSubmit(element, formValues, actions);
                            }}
                            initialValues={initialValues}
                            validationSchema={schema}
                        >
                            {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                                // const disabledSubmit = isSubmitting || !(isValid && dirty);
                                const disabledSubmit = isSubmitting || !isValid;
                                return (
                                    <form noValidate={true} onSubmit={handleSubmit}>
                                        <header className={classes.Header}>{elementHeader()}</header>

                                        <div className={`${classes.formGroup}`}>
                                            <div className={classes.SourceInput}>
                                                <InputField
                                                    name="src"
                                                    type="text"
                                                    placeholder={t('Deck:MEDIA_ELEMENT_VIDEO_SRC_INPUT_PLACEHOLDER')}
                                                    onChange={srcChangeHandler}
                                                ></InputField>

                                                <div className={classes.FormButtons}>
                                                    {!isSaved ? (
                                                        <Button
                                                            className={classes.ElementSave}
                                                            type="submit"
                                                            text={t('Common:BUTTON_SAVE')}
                                                            alert={true}
                                                            disabled={disabledSubmit}
                                                            icon={
                                                                isSubmitting ? (
                                                                    <FontAwesomeIcon
                                                                        className={classes.ButtonLoadingIcon}
                                                                        icon={faCircleNotch}
                                                                        spin={true}
                                                                    />
                                                                ) : (
                                                                    <FontAwesomeIcon
                                                                        className={classes.SaveIcon}
                                                                        icon={faSave}
                                                                    />
                                                                )
                                                            }
                                                        ></Button>
                                                    ) : null}

                                                    <Button
                                                        className={classes.ElementDelete}
                                                        text={``}
                                                        danger
                                                        icon={
                                                            <FontAwesomeIcon
                                                                className={classes.DeleteIcon}
                                                                icon={faTrashAlt}
                                                            />
                                                        }
                                                        iconSide="left"
                                                        onClick={() => {
                                                            handleDelete(element).then(() => {
                                                                deleteHandler(element);
                                                            });
                                                        }}
                                                    ></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>

                        <PlyrComponent url={query}></PlyrComponent>
                    </Fragment>
                )
            ) : (
                <PlyrComponent url={query}></PlyrComponent>
            )}
        </div>
    );
};

export default React.memo(VideoElement);
