import { faPencil, faRedo, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem, ListItemText } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useStylesDeleteButton, useStylesRegularButton } from '../../../../../../utils/muiStyles';
import { IInviteActionsProps } from '../InviteActionsPopover';

const InviteActions: FunctionComponent<IInviteActionsProps> = (props) => {
    const { t } = useTranslation();
    const classesRegularButton = useStylesRegularButton();
    const classesDeleteButton = useStylesDeleteButton();
    const invitedUser = props.invitedUser;

    return (
        <List>
            <ListItem
                button
                className={classesRegularButton.root}
                alignItems="center"
                onClick={() => props.onResendHandler(invitedUser)}
            >
                <FontAwesomeIcon icon={faRedo} />
                <ListItemText primary={t('Common:BUTTON_RESEND')} />
            </ListItem>
            <ListItem
                button
                className={classesDeleteButton.root}
                alignItems="center"
                onClick={() => props.onDeleteHandler(invitedUser)}
            >
                <FontAwesomeIcon icon={faTrashAlt} />
                <ListItemText primary={t('Common:BUTTON_DELETE')} />
            </ListItem>
        </List>
    );
};

export default InviteActions;
