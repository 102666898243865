import React, { FunctionComponent } from 'react';
import classes from './ElementTypeIndicator.module.scss';
import { useTranslation } from 'react-i18next';
import { MediaType } from '../../../models/Element';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage, faFilePdf, faFileVideo } from '@fortawesome/free-regular-svg-icons';

type Props = {
    type: MediaType;
};

const ElementTypeIndicator: FunctionComponent<Props> = ({ type }) => {
    const { t } = useTranslation();

    const labelAndIcon = () => {
        switch (type) {
            case 'image':
                return (
                    <>
                        <FontAwesomeIcon className={classes.Icon} icon={faFileImage} />
                        <label>{t('Deck:MEDIA_ELEMENT_IMAGE_HEADER_LABEL')}</label>
                    </>
                );
            case 'pdf':
                return (
                    <>
                        <FontAwesomeIcon className={classes.Icon} icon={faFilePdf} />
                        <label>{t('Deck:MEDIA_ELEMENT_PDF_HEADER_LABEL')}</label>
                    </>
                );
            case 'video':
                return (
                    <>
                        <FontAwesomeIcon className={classes.Icon} icon={faFileVideo} />
                        <label>{t('Deck:MEDIA_ELEMENT_VIDEO_HEADER_LABEL')}</label>
                    </>
                );
            default:
                break;
        }
    };

    return <div className={classes.ElementTypeIndicator}>{labelAndIcon()}</div>;
};

export default ElementTypeIndicator;
