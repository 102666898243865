import React, { FunctionComponent } from 'react';
import TaskItem from '../../../../components/TaskItem/TaskItem';
import { IDeck } from '../../../../models/Deck';
import { TimeState } from '../../../../models/Quest';
import classes from './TaskList.module.scss';

export interface TaskListProps {
    taskToContinueID?: number;
    questTimeState: TimeState;
    decks: IDeck[];
    clickHandler: (IDeck) => void;
}
const TaskList: FunctionComponent<TaskListProps> = ({ questTimeState, taskToContinueID, decks, clickHandler }) => {
    const compareTaskProgress = (a: IDeck, b: IDeck): number => {
        const aTaskCompleted = a.deckSession?.is_complete || false;
        const bTaskCompleted = b.deckSession?.is_complete || false;

        if (aTaskCompleted > bTaskCompleted) {
            return -1;
        }
        if (aTaskCompleted < bTaskCompleted) {
            return 1;
        }
        return 0;
    };

    const taskList = decks.map((deck, index) => {
        return (
            <TaskItem
                key={`${deck.title}_${index}`}
                task={deck}
                index={index}
                timeState={questTimeState}
                toContinue={taskToContinueID ? taskToContinueID === deck.id : false}
                clickHandler={clickHandler}
            ></TaskItem>
        );
    });

    return <div className={`${classes.TaskList}`}>{taskList}</div>;
};

export default TaskList;
