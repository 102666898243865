import React, { FunctionComponent } from 'react';
import { IOption } from '../../../../../models/Option';
import Option from '../Option/Option';
import classes from './OptionList.module.scss';

type OptionProps = {
    options: IOption[];
    handleClick: (optionId: number) => void;
    multipleAnswersPossible: boolean;
    disabled: boolean;
    isSaved: boolean;
    correctEnabled?: boolean;
    outOfTime?: boolean;
};

const OptionList: FunctionComponent<OptionProps> = ({
    options,
    handleClick,
    multipleAnswersPossible,
    disabled,
    isSaved,
    correctEnabled,
    outOfTime,
}) => {
    const optionList = options.map((option, index) => {
        return (
            <Option
                key={index}
                option={option}
                text={option.body}
                handleClick={(option) => handleClick(option.id)}
                selected={option.is_selected}
                multipleAnswersPossible={multipleAnswersPossible}
                disabled={disabled}
                isSaved={isSaved}
                correctEnabled={correctEnabled}
                outOfTime={outOfTime}
            ></Option>
        );
    });

    return <div className={classes.OptionList}>{optionList}</div>;
};

export default OptionList;
