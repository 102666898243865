import React, { FunctionComponent, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useConfig } from '../../../context/ConfigContext';
import axiosInstance from '../../../utils/axios-instance';
import { LoginResponse } from '../LoginForm/LoginForm';
import classes from './GoogleCallback.module.scss';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const GoogleCallback: FunctionComponent = () => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const queryState = useQuery().get('state');

    const notifyLoginFailed = () => toast.error(t('Common:TOAST_GOOGLE_LOGIN_FAILED'));

    useEffect(() => {
        axiosInstance.get(`/admin/auth/callback${location.search}`).then((response) => {
            const googleId = response.data.google_id;
            if (!googleId) {
                notifyLoginFailed();
                history.replace('/login');
            }

            window.location.assign(`http://${queryState}${config.googleRedirectUrl}/app/login?google_id=${googleId}`);
        });
    }, []);

    return (
        <div className={classes.GoogleCallback}>
            <div className={classes.Loader}>
                <div className="spinner card Box"></div>
            </div>
        </div>
    );
};

export default GoogleCallback;
