import { CancelTokenSource } from 'axios';
import { useConfig } from '../context/ConfigContext';
import { IFork } from '../models/Element';
import http from '../utils/axios-instance';

export interface IForkCreateData {
    card_id: number;
    question_id?: number;
    navigation_type: string;

    body: string;
    options?: string;
}

export interface IForkUpdateData {
    navigation_type: string;
    body: string;
    options?: string;
}

const useForkService = () => {
    const { config } = useConfig();
    const serviceUrl = `${config.baseUrl}/forks`;

    const create = (data: IForkCreateData, source?: CancelTokenSource) => {
        return http.post<IFork>(`${serviceUrl}`, data, { cancelToken: source?.token });
    };

    const update = (id: number, data: IForkUpdateData, source?: CancelTokenSource) => {
        return http.put<IFork>(`${serviceUrl}/${id}`, data, { cancelToken: source?.token });
    };

    const remove = (id: number, source?: CancelTokenSource) => {
        return http.delete(`${serviceUrl}/${id}`, { cancelToken: source?.token });
    };

    return {
        create,
        update,
        remove,
    };
};

export default useForkService;
