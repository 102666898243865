import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useTimer } from '../../context/TimerContext';
import classes from './Timer.module.scss';
import { gsap } from 'gsap';

interface ITimerProps {
    timer: string | undefined;
    isSaved: boolean;
    isHistory: boolean;
    timerHasStarted: boolean;
    onTimeUp: () => void;
}

const Timer: FunctionComponent<ITimerProps> = ({ timer, timerHasStarted, isHistory, isSaved, onTimeUp }) => {
    const { state } = useTimer();
    const timerLabelRef = useRef<HTMLDivElement>(null);
    const tlTimerDanger = useRef<gsap.core.Timeline>();

    useEffect(() => {
        if (!isHistory && state.timerRunning && state.seconds <= 5 && !tlTimerDanger.current?.isActive()) {
            tlTimerDanger.current = gsap

                .timeline()
                .to(timerLabelRef.current, {
                    rotate: 2,
                    duration: 0.1,
                })
                .to(timerLabelRef.current, {
                    rotate: -2,
                    scale: 1.1,
                    duration: 0.2,
                })
                .to(timerLabelRef.current, {
                    rotate: 2,
                    duration: 0.2,
                })
                .to(timerLabelRef.current, {
                    rotate: -2,
                    scale: 1,
                    duration: 0.2,
                })
                .to(timerLabelRef.current, {
                    rotate: 0,
                    duration: 0.1,
                });
        }

        if (!isHistory && timerHasStarted && state.seconds === 0) {
            onTimeUp();
        }
    }, [state.seconds]);

    return (
        <>
            {timer && timer !== '00:00' && (
                <div
                    className={`${classes.Timer} ${timer !== '00:00' && state.timerRunning && classes.running} ${
                        isSaved && classes.savedTimer
                    }`}
                >
                    <div ref={timerLabelRef} className={`h5 ${classes.Label}`}>
                        {state.formattedTime}
                    </div>
                    <FontAwesomeIcon icon={faStopwatch} />
                </div>
            )}
        </>
    );
};

export default Timer;
