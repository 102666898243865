import { faCircleNotch, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../Button/Button';
import classes from './CardSaveButton.module.scss';

interface CardSaveButtonProps {
    isSubmitting: boolean;
    disabledSubmit: boolean;
}

const CardSaveButton: FunctionComponent<CardSaveButtonProps> = (props) => {
    const { t } = useTranslation();

    return (
        <Button
            className={classes.CardSaveButton}
            type="submit"
            text={t('Common:BUTTON_SAVE')}
            alert={true}
            disabled={props.disabledSubmit}
            icon={
                props.isSubmitting ? (
                    <FontAwesomeIcon icon={faCircleNotch} spin={true} />
                ) : (
                    <FontAwesomeIcon icon={faSave} />
                )
            }
        ></Button>
    );
};

export default CardSaveButton;
