import React, { FunctionComponent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionEdit from './QuestionEdit';
import classes from './QuestionElement.module.scss';
import { QuestionElementProps } from './QuestionElementModel';
import QuestionView from './QuestionView/QuestionView';

const QuestionElement: FunctionComponent<QuestionElementProps> = (props) => {
    const { t } = useTranslation();
    const elementRef = useRef<HTMLDivElement>(null);

    return (
        <div
            className={`${classes.OpenQuestionEditElement} ${props.isReorder ? classes['isReorder'] : ''}`}
            ref={elementRef}
        >
            {props.isEdit ? <QuestionEdit {...props}></QuestionEdit> : <QuestionView {...props}></QuestionView>}
        </div>
    );
};

export default QuestionElement;
