import { useState } from 'react';

type PaginationReturnType = {
    page: number;
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement | MouseEvent> | null, newPage: number) => void;
    rowsPerPage: number;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const usePagination = (defaultRowsPerPage = 5): PaginationReturnType => {
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement | MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    return { page, handleChangePage, rowsPerPage, handleChangeRowsPerPage };
};

export default usePagination;
