import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import AvatarIcon from '../../../../components/Icons/AvatarIcon/AvatarIcon';
import ButtonLoadingIcon from '../../../../components/Icons/ButtonLoadingIcon/ButtonLoadingIcon';
// import NavigationIcon from '../../../../components/Icons/NavigationIcon/NavigationIcon';
import TrashIcon from '../../../../components/Icons/TrashIcon/TrashIcon';
import InputCheckbox from '../../../../components/InputCheckbox/InputCheckbox';
import InputField from '../../../../components/InputField/InputField';
// import usePagination from '../../../../components/Pagination/usePagination';
import { useConfig } from '../../../../context/ConfigContext';
import { useGroup } from '../../../../context/GroupContext';
import { IGroup } from '../../../../models/Group';
import { User } from '../../../../models/User';
import { getColor } from '../../../../utils/colors';
import useAxiosFetch from '../../../../utils/useAxiosFetch';
import GroupCover from '../../GroupsDetail/GroupCover/GroupCover';
import GroupMemberList from '../GroupMemberList/GroupMemberList';
import classes from './GroupEditDetailForm.module.scss';
import MembersDialog from './MembersDialog/MembersDialog';

type FormProps = {
    isCreate: boolean;
    handleFormSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    // onSubmit: () => void;
    isSubmitting: boolean;
    disabledSubmit: boolean;
    isSaved: boolean;
    deleteHandler: () => void;
    cancelHandler: () => void;
    onAddButtonHandler: (user: User) => void;
    onRemoveButtonHandler: (user: User) => void;
};

const GroupEditDetailForm: FunctionComponent<FormProps> = ({
    isCreate,
    handleFormSubmit,
    // onSubmit,
    isSubmitting,
    disabledSubmit,
    isSaved,
    deleteHandler,
    cancelHandler,
    onAddButtonHandler,
    onRemoveButtonHandler,
}) => {
    const { config } = useConfig();
    const { response, loading } = useAxiosFetch<User[]>(isCreate ? `${config.baseUrl}/users` : null);
    // Grab values and submitForm from context
    const { group, setGroup } = useGroup();
    const { setFieldValue } = useFormikContext();
    const { t } = useTranslation();
    const buttonLoadingIconColor = getColor('--brand-color-10');

    useEffect(() => {
        setFieldValue('members', group.users?.map((user) => user.id).toString() || '');
    }, [group]);

    useEffect(() => {
        if (response) {
            const groupData: IGroup = {
                ...group,
                potential_members: response.data,
            };
            setGroup(groupData);
        }
    }, [response]);

    const nameChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setGroup({ ...group, name: event.target.value });
    };

    const descriptionChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setGroup({ ...group, description: event.target.value });
    };

    const enablePrivateGroupChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGroup({ ...group, private: event.target.checked });
    };

    const enableVisibleGroupChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGroup({ ...group, visible: event.target.checked });
    };

    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen(!open);

    const groupMembers = (
        <div className={`Box ${classes.GroupMembers}`}>
            <div className={`${classes.GroupMembers}`}>
                <div className={`${classes.GroupMembersHeader}`}>
                    <div className={`${classes.GroupMembersDetails}`}>
                        <h2 className={`h5 ${classes.GroupMembersTitle}`}>{t('Groups:GROUP_MEMBERS_HEADER')}</h2>
                        <div className={classes.GroupMembersStat}>
                            <AvatarIcon className={classes.GroupMembersIcon}></AvatarIcon>

                            <span className={classes.GroupMembersAmount}>{group.users?.length || 0}</span>
                        </div>
                    </div>
                    <Button text={t('Common:BUTTON_ADD_MEMBERS')} onClick={() => setOpen(true)} />
                </div>

                <GroupMemberList
                    members={group.users ? group.users : []}
                    icon={<FontAwesomeIcon icon={faMinus} />}
                    buttonClassName={`${classes.EditButton} ${classes.RemoveButton} btn-flavour--danger`}
                    onClick={onRemoveButtonHandler}
                />
            </div>
        </div>
    );

    return (
        <form noValidate={true} onSubmit={handleFormSubmit}>
            <div className={classes.formGroup}>
                <div className={classes.GroupEditDetailContent}>
                    <div className={classes.InputContainer}>
                        <div className={`Box ${classes.InputContent}`}>
                            <div className={`${classes.formGroup} ${isSaved ? classes['saved'] : ''}`}>
                                <InputField
                                    name="name"
                                    type="text"
                                    label={t('Groups:GROUP_NAME_INPUT_LABEL')}
                                    placeholder={t('Groups:GROUP_NAME_INPUT_PLACEHOLDER')}
                                    maxLength={100}
                                    onChange={nameChangeHandler}
                                    autoFocus
                                ></InputField>
                            </div>
                            <div className={`${classes.formGroup} ${isSaved ? classes['saved'] : ''}`}>
                                <InputField
                                    name="description"
                                    type="text"
                                    label={t('GROUP_DESCRIPTION_INPUT_LABEL')}
                                    multiline
                                    placeholder={t('Groups:GROUP_DESCRIPTION_INPUT_PLACEHOLDER')}
                                    maxLength={80}
                                    onChange={descriptionChangeHandler}
                                ></InputField>
                            </div>

                            <div className={`checkbox ${classes.Toggle}`}>
                                <InputCheckbox
                                    id="private"
                                    name="private"
                                    type="checkbox"
                                    label={<label htmlFor="private">{t('Common:EDIT_GROUP_PRIVATE_LABEL')}</label>}
                                    onChange={enablePrivateGroupChangedHandler}
                                ></InputCheckbox>
                            </div>
                            <div className={`checkbox ${classes.Toggle}`}>
                                <InputCheckbox
                                    id="visible"
                                    name="visible"
                                    type="checkbox"
                                    label={<label htmlFor="visible">{t('Common:EDIT_GROUP_VISIBLE_LABEL')}</label>}
                                    onChange={enableVisibleGroupChangedHandler}
                                ></InputCheckbox>
                            </div>
                        </div>

                        {loading && (
                            <div className={classes.Loader}>
                                <div className="spinner card Box"></div>
                            </div>
                        )}

                        {!loading && groupMembers}

                        <div className={`${classes.formActions} `}>
                            <Button
                                className={classes.formDelete}
                                text={t('Common:BUTTON_DELETE')}
                                danger
                                icon={<TrashIcon />}
                                iconSide="left"
                                onClick={deleteHandler}
                            ></Button>
                            <Button
                                text={t('Common:BUTTON_CANCEL')}
                                alt={true}
                                border={true}
                                onClick={cancelHandler}
                            ></Button>

                            <button
                                className={`${classes.submitButton} btn btn-flavour btn-flavour--alt`}
                                type="submit"
                                // onSubmit={onSubmit}
                                disabled={disabledSubmit}
                            >
                                {isSubmitting && <ButtonLoadingIcon fill={buttonLoadingIconColor} />}
                                {isCreate ? t('Groups:BUTTON_CREATE') : t('Groups:BUTTON_SAVE')}
                            </button>
                        </div>
                    </div>

                    <div className={classes.GroupCover}>
                        <GroupCover group={group} key={group.id}></GroupCover>
                    </div>
                </div>
            </div>

            <MembersDialog
                open={open}
                toggleOpen={toggleOpen}
                users={group.potential_members}
                onAddButtonHandler={onAddButtonHandler}
            />
        </form>
    );
};

export default GroupEditDetailForm;
