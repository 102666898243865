import { Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import Axios from '../../../utils/axios-instance';
import * as Yup from 'yup';
import classes from './PasswordRequest.module.scss';
import { useHistory } from 'react-router';
import BoxHeader from '../../../components/UI/Box/BoxHeader/BoxHeader';
import EmailSend from '../EmailSend/EmailSend';
import Message from '../../../components/Message/Message';
import { useConfig } from '../../../context/ConfigContext';
import { useTranslation } from 'react-i18next';
import InputField from '../../../components/InputField/InputField';

const PasswordRequest: FunctionComponent = () => {
    const { config } = useConfig();
    const history = useHistory();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation();

    const cancelHandler = () => {
        history.replace('/login');
    };

    const schema = Yup.object({
        email: Yup.string()
            .email(t('Common:INPUT_ERROR_EMAIL_INVALID'))
            .required(t('Common:INPUT_ERROR_EMAIL_REQUIRED'))
            .max(255, t('Common:INPUT_ERROR_EMAIL_MAX', { max: 255 })),
    });

    const form = (
        <Formik
            initialValues={{ email: '' }}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
                Axios.post(`${config.baseUrl}/password/email`, values);

                setSubmitting(false);
                setIsSubmitted(true);
                setIsError(false);
            }}
        >
            {(props) => {
                const { isSubmitting, isValid, dirty, handleSubmit } = props;
                const disabledSubmit = isSubmitting || !(isValid && dirty);

                return (
                    <form noValidate={true} onSubmit={handleSubmit} className={classes.form}>
                        <div className={`${classes.PasswordRequestForm} Box`}>
                            <BoxHeader
                                title={t('Auth:PASSWORD_REQUEST_TITLE')}
                                subtitle={t('Auth:PASSWORD_REQUEST_INTRODUCTION')}
                            ></BoxHeader>
                            <div className={classes.formGroup}>
                                <InputField
                                    id="email"
                                    name="email"
                                    type="email"
                                    label="Email"
                                    autoComplete="username"
                                    required
                                    autoFocus
                                    autoCorrect="off"
                                    autoCapitalize="none"
                                    placeholder={t('Common:EMAIL_PLACEHOLDER')}
                                ></InputField>
                            </div>
                        </div>
                        <div className={`${classes.formActions} ${classes.flexGap}`}>
                            <button
                                className={`${classes.submitButton} btn btn-flavour btn-flavour--alt`}
                                type="submit"
                                disabled={disabledSubmit}
                            >
                                {t('Auth:PASSWORD_REQUEST_BUTTON_RECOVER')}
                            </button>
                            <button
                                tabIndex={-1}
                                className={`${classes.cancelButton} btn btn-flavour btn-flavour--alt-border`}
                                onClick={cancelHandler}
                            >
                                {t('Common:BUTTON_CANCEL')}
                            </button>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );

    const emailSend = <EmailSend />;

    const error = <Message text={t('Auth:PASSWORD_RECOVER_ERROR')}></Message>;

    return <div className={classes.PasswordRequest}>{isSubmitted ? emailSend : isError ? error : form}</div>;
};

export default PasswordRequest;
