import React, { FunctionComponent } from 'react';
import classes from './CircleIcon.module.scss';

interface IProps {
    className?: string;
    color?: string;
}

const CircleIcon: FunctionComponent<IProps> = (props) => {
    return <div className={`circle-icon ${classes.CircleIcon} ${props.className}`}>{props.children}</div>;
};

export default CircleIcon;
