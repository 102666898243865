import Axios from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Tenant } from '../models/Tenant';
import API from '../utils/api';
import AxiosInstance from '../utils/axios-instance';
import { getSubdomain } from '../utils/Info';
import { Config, CtxProvider } from './ConfigContext';

export const getConfig = (): { baseUrl: string; googleRedirectUrl: string; subdomain: string } => {
    const subdomain = getSubdomain();
    const tenant = subdomain;
    // Subdomain added to baseUrl within Axios instance.
    const baseUrl = API.addTenantToBaseUrl(tenant);
    const googleRedirectUrl = process.env.REACT_APP_GOOGLE_REDIRECT_URI || '';
    return { baseUrl, googleRedirectUrl, subdomain };
};

export const ConfigProvider: FunctionComponent = ({ children }) => {
    const defaultConfig: Config = {
        baseUrl: '',
        googleRedirectUrl: '',
        tenant: { name: '', id: '', is_public: false, images: {}, texts: {}, question_types: [] },
    };
    const [config, setConfig] = useState<Config>(defaultConfig);
    const [loadingConfig, setConfigLoading] = useState(true);
    const [loading, setLoading] = useState(true);

    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(0);
    const [diff, setDiff] = useState(0);

    useEffect(() => {
        let mounted = true;
        setLoading(true);
        const source = Axios.CancelToken.source();

        const { baseUrl, googleRedirectUrl, subdomain } = getConfig();

        // Fetch Tenant Data
        const fetchData = async () => {
            setStart(window.performance.now());
            try {
                const response = await AxiosInstance.get(`${baseUrl}/info`, { cancelToken: source?.token });
                if (mounted) {
                    const tenant: Tenant = response.data;
                    tenant.id = subdomain;

                    document.title = `${tenant.name} - Hero Center`;
                    setEnd(window.performance.now());
                    setConfig({ baseUrl, googleRedirectUrl, tenant });
                }
            } catch (error) {
                // TODO: show 404 page
            }
        };

        fetchData();

        return () => {
            source.cancel();
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (end > 0) {
            setDiff(end - start);
        }
    }, [end]);

    useEffect(() => {
        if (config.tenant.id !== '' && diff > 0) {
            setConfigLoading(false);

            const wait = Math.min(1500, Math.max(500, 1500 - diff));

            const timeOutId = setTimeout(() => {
                setLoading(false);
            }, wait);
            return () => clearTimeout(timeOutId);
        }
    }, [config, diff]);

    return <CtxProvider value={{ config, setConfig, loading, loadingConfig }}>{children}</CtxProvider>;
};
