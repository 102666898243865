import {
    compareAsc,
    differenceInDays,
    differenceInHours,
    differenceInMilliseconds,
    differenceInWeeks,
    formatDistance,
    formatDistanceToNow,
    parseJSON,
} from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import i18n, { locales } from '../i18n';
import { TimeState } from '../models/Quest';

export const formatDateToLocal = (dateString: string | Date, formatString = 'E, d MMMM yyyy HH:mm:ss'): string => {
    console.log('datestring', dateString);
    const date = typeof dateString === 'string' ? parseJSON(dateString) : dateString;
    console.log('date', date);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = utcToZonedTime(date, timeZone);
    console.log('localDate', localDate);
    const localFormatedDate = format(localDate, formatString, { timeZone: timeZone, locale: locales[i18n.language] });
    console.log('localFormatted', localFormatedDate);
    return localFormatedDate;
};

export const dateToLocal = (dateString: string | Date): Date => {
    const date = typeof dateString === 'string' ? parseJSON(dateString) : dateString;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = utcToZonedTime(date, timeZone);
    return localDate;
};

export const formatDateDistance = (dateString: string, dateStringToCompare: string): string => {
    const date = parseJSON(dateString);
    const dateToCompare = parseJSON(dateStringToCompare);
    const distance = formatDistance(date, dateToCompare);
    return distance;
};

export const compareNowToDates = (startDateString: string, endDateString: string): number => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const startDate = parseJSON(startDateString);
    const localStartDate = utcToZonedTime(startDate, timeZone);

    const endDate = parseJSON(endDateString);
    const localEndDate = utcToZonedTime(endDate, timeZone);

    const compareToStart = compareAsc(new Date(), localStartDate);
    const compareToEnd = compareAsc(new Date(), localEndDate);

    if (compareToStart === -1) {
        return -1;
    } else {
        if (compareToEnd === -1) {
            return 0;
        } else {
            return 1;
        }
    }
};

export const nowToDates = (startDateString: string, endDateString: string): number => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const endDate = parseJSON(endDateString);
    const localEndDate = utcToZonedTime(endDate, timeZone);
    const diff = differenceInMilliseconds(new Date(), localEndDate);

    return diff;
};

export const formatDateDistanceToNow = (startDateString: string, endDateString: string): string => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const startDate = parseJSON(startDateString);
    const localStartDate = utcToZonedTime(startDate, timeZone);

    const endDate = parseJSON(endDateString);
    const localEndDate = utcToZonedTime(endDate, timeZone);

    let formattedDistanceToNow = '';

    const compareToStart = compareAsc(new Date(), localStartDate);
    const compareToEnd = compareAsc(new Date(), localEndDate);

    if (compareToStart === -1) {
        const startDistanceToNow = formatDistanceToNow(localStartDate, {
            includeSeconds: true,
            locale: locales[i18n.language],
        });
        formattedDistanceToNow = `${i18n.t('Date:STARTS_IN', { date: startDistanceToNow })} `;
    } else if (compareToStart >= 0) {
        if (compareToEnd === -1) {
            const endDistanceToNow = formatDistanceToNow(localEndDate, {
                includeSeconds: true,
                locale: locales[i18n.language],
            });
            formattedDistanceToNow = ` ${i18n.t('Date:LEFT', { date: endDistanceToNow })}`;
        } else if (compareToStart >= 0) {
            const endDistanceToNow = formatDistanceToNow(localEndDate, {
                includeSeconds: true,
                locale: locales[i18n.language],
            });
            formattedDistanceToNow = `${i18n.t('Date:ENDED', { date: endDistanceToNow })}`;
        }
    }

    return formattedDistanceToNow;
};

export const compareToTimeState = (startDateString: string, endDateString: string): TimeState => {
    const compare = compareNowToDates(startDateString, endDateString);
    if (compare === -1) {
        return 'awaiting';
    } else if (compare === 0) {
        return 'in_progress';
    } else {
        return 'ended';
    }
};

export const timeLeftTillNow = (startDateString: string, endDateString: string): string => {
    if (startDateString === '' || endDateString === '') {
        return 'undefinedLeft';
    }
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const endDate = parseJSON(endDateString);
    const localEndDate = utcToZonedTime(endDate, timeZone);

    const timeState = compareToTimeState(startDateString, endDateString);

    // in_progress
    if (timeState === 'in_progress') {
        const weeks = differenceInWeeks(localEndDate, new Date());
        if (weeks >= 1) {
            return 'weeksLeft';
        }

        const days = differenceInDays(localEndDate, new Date());
        if (days >= 1) {
            return 'daysLeft';
        }

        const hours = differenceInHours(localEndDate, new Date());
        if (hours >= 1) {
            return 'hoursLeft';
        }
    }

    return 'undefinedLeft';
};
