import React, { useCallback, useState } from 'react';
import { useConfig } from '../../../../context/ConfigContext';
import { useTimer } from '../../../../context/TimerContext';
import { IElement, IUserAnswer } from '../../../../models/Element';
import Axios from '../../../../utils/axios-instance';

interface FormFields {
    answer: string;
}

export type ReturnType = {
    handleSubmit: (formFields: FormFields) => Promise<void>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
};

const usePollFormHook = (
    initialValues: FormFields,
    deckId: number,
    element: IElement,
    userAnswered?: (element: IElement, userAnswer: IUserAnswer) => void,
): ReturnType => {
    const { config } = useConfig();
    const [isSaved, setIsSaved] = useState(!!initialValues.answer);
    const { state } = useTimer();

    const handleSubmit = useCallback(
        (formFields: FormFields) => {
            const answerTime = state.originalSeconds - state.seconds;

            const body = {
                deck_id: deckId,
                question_id: element.elementable_id,
                option_ids: formFields.answer,
                answer_time: answerTime,
                within_time: answerTime < state.originalSeconds,
            };

            return Axios.post(`${config.baseUrl}/questions_answer`, body).then((response) => {
                userAnswered?.(element, response.data as IUserAnswer);
                setIsSaved(true);
            });
        },
        [state],
    );

    return {
        handleSubmit,
        initialValues,
        isSaved,
        setIsSaved,
    };
};

export default usePollFormHook;
