import React, { FunctionComponent, ReactNode } from 'react';
import classes from './PageDecorator.module.scss';

type Props = {
    children?: ReactNode;
    centered?: boolean;
    contrast?: boolean;
};

const PageDecorator: FunctionComponent<Props> = (props: Props) => {
    return (
        <div
            id="page"
            className={`${classes.PageDecorator}`}
            style={{
                justifyContent: props.centered ? 'center' : '',
                color: props.contrast ? 'var(--text-color-contrast)' : '',
            }}
        >
            {props.children}
        </div>
    );
};

export default PageDecorator;
