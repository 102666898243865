import React, { Fragment, FunctionComponent } from 'react';
import Switch from 'react-bootstrap/esm/Switch';
import { Route, useRouteMatch } from 'react-router';
import { ModuleProvider } from '../../context/ModuleProvider';
import ModuleEditDetail from './ModuleEditDetail/ModuleEditDetail';

const Module: FunctionComponent = () => {
    const { path } = useRouteMatch();

    return (
        <Fragment>
            <Switch>
                <Route exact path={`${path}/create`}>
                    <ModuleProvider>
                        <ModuleEditDetail isCreate={true} />
                    </ModuleProvider>
                </Route>
                <Route exact path={`${path}/edit/:moduleId`}>
                    <ModuleProvider>
                        <ModuleEditDetail isCreate={false} />
                    </ModuleProvider>
                </Route>
            </Switch>
        </Fragment>
    );
};

export default Module;
