import { get } from 'lodash';
import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import HCErrorPage from '../HCErrorPage/HCErrorPage';

const HCErrorHandler: FunctionComponent = ({ children }) => {
    const history = useHistory();
    const location = useLocation();
    const statusCode = get(location.state, 'hcErrorStatusCode');

    useEffect(() => {
        if (statusCode != undefined) {
            history.replace({ state: {} });
        }
    }, []);

    // 403
    // 404
    // 418
    // 500
    // 503
    switch (statusCode) {
        case 403:
            return <HCErrorPage statusCode={statusCode} />;
        case 404:
            return <HCErrorPage statusCode={statusCode} />;
        case 418:
            return <HCErrorPage statusCode={statusCode} />;
        case 500:
            return <HCErrorPage statusCode={statusCode} />;
        case 503:
            return <HCErrorPage statusCode={statusCode} />;

        default:
            return <Fragment>{children}</Fragment>;
    }
};

export default HCErrorHandler;
