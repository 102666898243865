import React, { FunctionComponent } from 'react';

type SVGProps = {
    fill?: string;
};

const QuestionmarkIcon: FunctionComponent<SVGProps> = ({ fill = '#ffffff' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15.694" viewBox="0 0 8.88 15.694">
            <path
                id="Path_3144"
                data-name="Path 3144"
                d="M6.4-4.636a2.026,2.026,0,0,1,.608-1.406L8.4-7.543a4.028,4.028,0,0,0,1.235-2.622,3.686,3.686,0,0,0-.038-.779,3.9,3.9,0,0,0-4.142-3.268A4.68,4.68,0,0,0,.76-10.754l2.907.8a1.6,1.6,0,0,1,1.6-1.368A1.066,1.066,0,0,1,6.422-10.3a1.769,1.769,0,0,1-.57,1.2q-.6.684-1.368,1.482A3.848,3.848,0,0,0,3.325-4.883,5.351,5.351,0,0,0,3.5-3.572l2.983-.342A2.716,2.716,0,0,1,6.4-4.636ZM3.268-.475A1.947,1.947,0,0,0,5.244,1.482,1.947,1.947,0,0,0,7.22-.475,1.947,1.947,0,0,0,5.244-2.432,1.947,1.947,0,0,0,3.268-.475Z"
                transform="translate(-0.76 14.212)"
                fill={fill}
            />
        </svg>
    );
};

export default QuestionmarkIcon;
