import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './DeckEditHeader.module.scss';

interface DeckEditHeaderProps {
    isCreate: boolean;
    subTitle?: string;
    quests?: JSX.Element[];
}

const DeckEditHeader: FunctionComponent<DeckEditHeaderProps> = ({ isCreate, subTitle, quests }) => {
    const { t } = useTranslation();
    return (
        <div className={classes.DeckEditHeader}>
            <h1 className={`h1 wrap-text ${classes.Title}`}>
                {isCreate ? t('Deck:DECK_CREATE_TITLE') : t('Deck:DECK_EDIT_TITLE')}
            </h1>
            {!isCreate && subTitle && <div className={classes.Subtitle}>{subTitle}</div>}
            {quests && !subTitle && (
                <div className={classes.RelatedQuests}>
                    {`${t('Common:DECK_RELATED_QUESTS_DESCRIPTION')}`}
                    {quests}
                </div>
            )}
        </div>
    );
};

export default DeckEditHeader;
