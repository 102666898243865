import { faCircleNotch, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { IElement } from '../../models/Element';
import useTextElementService from '../../services/TextElementService';
import Button from '../Button/Button';
import { ElementType } from '../Card/ElementTypes/ElementTypes';
import ElementHeader from '../Elements/ElementHeader/ElementHeader';
import ElementIcon from '../Elements/Question/QuestionFormik/ElementIcon/ElementIcon';
import classes from './TextElement.module.scss';
import TextElementField from './TextElementField';
import useTextElementForm from './useTextElementForm';

type Props = {
    elementData: IElement;
    isEdit: boolean;
    isReorder: boolean;
    deleteHandler: (element: IElement) => void;
    loaded?: (element: IElement) => void;
    autoFocus?: boolean;
    index?: number;
    onChange?: (id: number, value: string) => void;
    updateElement?: (element: IElement) => void;
};

const TextElement: FunctionComponent<Props> = ({
    elementData,
    isEdit = false,
    isReorder,
    deleteHandler,
    autoFocus = false,
    index,
    updateElement,
}) => {
    const { t } = useTranslation();
    const [element, setElement] = useState<IElement>(elementData);
    const { get } = useTextElementService();
    const [loading, setLoading] = useState(true);
    const ref = useRef<HTMLDivElement>(null);
    const [hasFocus, setFocus] = useState(autoFocus);

    useEffect(() => {
        if (document.hasFocus() && ref.current?.contains(document.activeElement)) {
            setFocus(true);
        }
    }, []);

    useEffect(() => {
        setElement(elementData);
    }, [elementData]);

    const { initialValues, handleSubmit, handleDelete, isSaved, setIsSaved } = useTextElementForm({
        body: element.elementable?.body || '',
    });

    const handleOnChange = () => {
        setIsSaved(false);
    };

    const elementHeader = () => {
        return (
            <ElementHeader
                icon={<ElementIcon elementType={ElementType.Text}></ElementIcon>}
                label={<label>{t('Deck:MEDIA_ELEMENT_TEXT_HEADER_LABEL')}</label>}
            />
        );
    };

    return (
        <div
            className={`${classes.TextElement} ${hasFocus ? classes.isFocused : ''} ${
                isEdit ? classes['isEdit'] : ''
            } ${isReorder ? classes['isReorder'] : ''}`}
            ref={ref}
            tabIndex={index}
            onFocus={() => {
                setFocus(true);
            }}
        >
            {isEdit ? (
                isReorder ? (
                    elementHeader()
                ) : (
                    <Formik
                        onSubmit={(formValues, actions) => {
                            const newElementable = { ...element.elementable, body: formValues.body };
                            const newElement = { ...element, elementable: newElementable } as IElement;
                            updateElement?.(newElement);
                            handleSubmit(element, formValues, actions);
                        }}
                        initialValues={initialValues}
                    >
                        {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                            const disabledSubmit = isSubmitting || !(isValid && dirty);
                            return (
                                <form noValidate={true} onSubmit={handleSubmit}>
                                    <header className={classes.Header}>
                                        {elementHeader()}
                                        <div className={classes.FormButtons}>
                                            {!isSaved ? (
                                                <Button
                                                    className={classes.ElementSave}
                                                    type="submit"
                                                    text={t('Common:BUTTON_SAVE')}
                                                    alert={true}
                                                    disabled={disabledSubmit}
                                                    icon={
                                                        isSubmitting ? (
                                                            <FontAwesomeIcon
                                                                className={classes.ButtonLoadingIcon}
                                                                icon={faCircleNotch}
                                                                spin={true}
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                className={classes.SaveIcon}
                                                                icon={faSave}
                                                            />
                                                        )
                                                    }
                                                ></Button>
                                            ) : null}

                                            <Button
                                                className={classes.CardDelete}
                                                text={``}
                                                danger
                                                icon={
                                                    <FontAwesomeIcon className={classes.DeleteIcon} icon={faTrashAlt} />
                                                }
                                                iconSide="left"
                                                onClick={() => {
                                                    handleDelete(element).then(() => {
                                                        deleteHandler(element);
                                                    });
                                                }}
                                            ></Button>
                                        </div>
                                    </header>

                                    {hasFocus ? (
                                        <TextElementField
                                            element={element}
                                            onChange={handleOnChange}
                                        ></TextElementField>
                                    ) : (
                                        ReactHtmlParser(element.elementable?.body || '')
                                    )}
                                </form>
                            );
                        }}
                    </Formik>
                )
            ) : (
                ReactHtmlParser(element.elementable?.body || '')
            )}
        </div>
    );
};

export default TextElement;
