import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { addWeeks } from 'date-fns';
import { Formik } from 'formik';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import * as Yup from 'yup';
import Button from '../../../components/Button/Button';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { useConfig } from '../../../context/ConfigContext';
import { useQuest } from '../../../context/QuestContext';
import { IQuest } from '../../../models/Quest';
import AxiosInstance from '../../../utils/axios-instance';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import classes from './QuestEditDetail.module.scss';
import QuestEditDetailForm from './QuestEditDetailForm/QuestEditDetailForm';
import useQuestEditForm from './useQuestEditForm';

type QuestDetailProps = {
    questData?: IQuest;
    isCreate: boolean;
};

type ParamTypes = {
    questId: string;
};

export type ActionButtonState = 'start' | 'continue';

const QuestEditDetail: FunctionComponent<QuestDetailProps> = ({ isCreate }) => {
    const { config } = useConfig();
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const { questId } = useParams<ParamTypes>();
    const [questAPIUrl, setQuestAPIUrl] = useState<string>(() => {
        return questId;
    });
    const { response, loading } = useAxiosFetch<IQuest>(
        isCreate ? null : `${config.baseUrl}/quests/edit/${questAPIUrl}`,
    );
    const { quest, setQuest } = useQuest();

    const { initialValues, handleSubmit, isSaved } = useQuestEditForm(
        {
            title: quest.title,
            description: quest.description,
            description_full: quest.description_full,
            cover_background_size: quest.cover_background_size,
            cover_image_id: 0,
            is_timed: quest.is_timed,
            start_date: quest.start_date || new Date().toISOString(),
            end_date: quest.end_date || addWeeks(new Date(), 1).toISOString(),
            groups: quest.groups.length > 0 ? quest.groups.map((group) => group.id).toString() : '1',
            decks: quest.decks.map((deck) => deck.id).toString(),
        },
        isCreate,
    );

    const schema = Yup.object({
        title: Yup.string()
            // .max(16, t('Common:INPUT_ERROR_TITLE_MAX', { max: 16 }))
            .required(t('Common:INPUT_ERROR_TITLE_REQUIRED')),
        description: Yup.string()
            // .max(24, t('Common:INPUT_ERROR_DESCRIPTION_MAX', { max: 24 }))
            .required(t('Common:INPUT_ERROR_DESCRIPTION_REQUIRED')),
        // description_full: Yup.string()
        //     .max(80, t('Common:INPUT_ERROR_DESCRIPTION_FULL_MAX', { max: 80 }))
        //     .required(t('Common:INPUT_ERROR_DESCRIPTION_FULL_REQUIRED')),
        groups: Yup.string(),
        decks: Yup.string().required(t('Common:INPUT_ERROR_DECKS_REQUIRED')),
    });

    useEffect(() => {
        // Set questId so loading will start
        if (questId) {
            setQuestAPIUrl(questId);
        }

        // Set Quest from State
        // const quest = location.state as IQuest;
        // if (quest) {
        //     console.log('quest from state', quest);

        //     setQuest(quest);
        // }
    }, []);

    useEffect(() => {
        if (response) {
            const questData = response.data;

            setQuest(questData);
        }
    }, [response]);

    useEffect(() => {
        console.log('initialValues', initialValues);
    }, [initialValues]);

    useEffect(() => {
        console.log('quest start date formatted', quest.startDateFormatted);
        console.log('quest end date formatted', quest.endDateFormatted);
    }, [quest.startDateFormatted, quest.endDateFormatted]);

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    const cancelHandler = () => {
        history.goBack();
    };

    const deleteHandler = async () => {
        setOpen((o) => !o);
    };

    const notifyDeleted = () => toast.success(t('Common:TOAST_QUEST_DELETED_SUCCESS'));
    const notifyNotDeleted = () => toast.error(t('Common:TOAST_QUEST_DELETED_ERROR'));

    const permanentlyDeleteHandler = async () => {
        setOpen(false);

        try {
            await AxiosInstance.delete(`${config.baseUrl}/quests/${questAPIUrl}`);
            cancelHandler();
            notifyDeleted();
        } catch (error) {
            notifyNotDeleted();
        }
    };
    return (
        <Fragment>
            <div className={classes.QuestDetail}>
                <PageHeader
                    title={quest.title || t('Quests:CREATE_QUEST_TITLE')}
                    subtitle={quest.description || t('Quests:CREATE_QUEST_SUBTITLE')}
                />

                {loading && (
                    <div className={classes.Loader}>
                        <div className="spinner card Box"></div>
                    </div>
                )}

                {!loading && (
                    <Fragment>
                        <Formik
                            onSubmit={(formValues, actions) => handleSubmit(quest, formValues, actions)}
                            initialValues={initialValues}
                            validationSchema={schema}
                        >
                            {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                                const disabledSubmit = isSubmitting || !(isValid && dirty);

                                return (
                                    <QuestEditDetailForm
                                        isCreate={isCreate}
                                        handleFormSubmit={handleSubmit}
                                        isSubmitting={isSubmitting}
                                        disabledSubmit={disabledSubmit}
                                        isSaved={isSaved}
                                        deleteHandler={deleteHandler}
                                        cancelHandler={cancelHandler}
                                    />
                                );
                            }}
                        </Formik>
                    </Fragment>
                )}
            </div>

            <Dialog
                open={open}
                onClose={closeModal}
                className={classes.WarningDialog}
                PaperProps={{ className: `dialog` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:WARNING_HEADER')}</DialogTitle>
                <DialogContent>{t('Common:QUEST_DELETION_WARNING_MESSAGE', { title: quest.title })}</DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={closeModal} />
                    <Button alt text={t('Common:BUTTON_PERMANENTLY_DELETE')} onClick={permanentlyDeleteHandler} />
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default QuestEditDetail;
