import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    createStyles,
    FormControlLabel,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Switch,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useStylesDeleteButton } from '../../../../../../utils/muiStyles';
import { IMemberActionsProps } from '../MemberActionsPopover';

const useStyleListItem = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            gap: '1rem',
        },
    }),
);

const MemberActions: FunctionComponent<IMemberActionsProps> = (props) => {
    const { t } = useTranslation();
    const classesListItem = useStyleListItem();
    const classesDeleteButton = useStylesDeleteButton();
    const user = props.user;

    return (
        <List>
            <ListItem classes={{ root: classesListItem.root }}>
                <ListItemText
                    id="member-settings-is_active"
                    primary={user.is_active ? t('Common:USER_DEACTIVATE') : t('Common:USER_ACTIVATE')}
                />
                <ListItemSecondaryAction>
                    <Switch
                        id="is_active"
                        name="is_active"
                        edge="end"
                        checked={user.is_active}
                        onChange={() => props.onToggleActiveHandler(user)}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem
                button
                className={classesDeleteButton.root}
                alignItems="center"
                onClick={() => props.onDeleteHandler(user)}
            >
                <FontAwesomeIcon icon={faTrashAlt} />
                <ListItemText primary={t('Common:BUTTON_DELETE')} />
            </ListItem>
        </List>
    );
};

export default MemberActions;
