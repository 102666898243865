/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CancelTokenSource } from 'axios';
import { useConfig } from '../context/ConfigContext';
import { IDeck, IDeckResults } from '../models/Deck';
import http from '../utils/axios-instance';

export interface ICreateDeck {
    title: string;
    cover_image_id?: number;
    cover_background_size: string;
    is_published: boolean;
}
export interface IUpdateDeck {
    title: string;
    cover_image_id?: number;
    cover_background_size: string;
    is_published: boolean;
}

const useDeckService = () => {
    const { config } = useConfig();
    const serviceUrl = `${config.baseUrl}/decks`;

    const getAll = (source?: CancelTokenSource) => {
        return http.get<IDeck[]>(`${serviceUrl}`, { cancelToken: source?.token });
    };

    const get = (id: number, source?: CancelTokenSource) => {
        return http.get<IDeck>(`${serviceUrl}/${id}`, { cancelToken: source?.token });
    };

    const getResults = (id: number, source?: CancelTokenSource) => {
        return http.get<IDeckResults>(`${serviceUrl}/results/${id}`, { cancelToken: source?.token });
    };

    const create = (data: ICreateDeck, source?: CancelTokenSource) => {
        return http.post<IDeck>(`${serviceUrl}`, data, { cancelToken: source?.token });
    };

    const update = (id: number, data: IUpdateDeck, source?: CancelTokenSource) => {
        return http.put<IDeck>(`${serviceUrl}/${id}`, data, { cancelToken: source?.token });
    };

    const updateStart = (id: number, data: { start_card_id: number }, source?: CancelTokenSource) => {
        return http.post(`${serviceUrl}/startcard/${id}`, data, { cancelToken: source?.token });
    };

    const remove = (id: number, source?: CancelTokenSource) => {
        return http.delete(`${serviceUrl}/${id}`, { cancelToken: source?.token });
    };

    return {
        getAll,
        get,
        getResults,
        create,
        update,
        updateStart,
        remove,
    };
};

export default useDeckService;
