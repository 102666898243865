// export type ElementType = 'multiple_choice' | 'open' | 'poll' | 'rich_text' | 'video' | 'image' | 'file' | 'fork';

export enum ElementType {
    MultipleChoice = 'multiple_choice',
    Open = 'open',
    Poll = 'poll',
    Text = 'rich_text',
    Video = 'video',
    Image = 'image',
    Pdf = 'pdf',
    File = 'file',
    Fork = 'fork',
}

// export type ElementType = 'question' | 'rich_text' | 'video' | 'image' | 'file' | 'fork';

export interface IElementType {
    title: string;
    type: ElementType;
    showPublished: boolean;
}

export const elementTypes: IElementType[] = [
    {
        title: 'ELEMENT_TYPE_TEXT',
        type: ElementType.Text,
        showPublished: true,
    },
    {
        title: 'ELEMENT_TYPE_MULTIPLE_CHOICE',
        type: ElementType.MultipleChoice,
        showPublished: false,
    },
    {
        title: 'ELEMENT_TYPE_OPEN_QUESTION',
        type: ElementType.Open,
        showPublished: false,
    },
    {
        title: 'ELEMENT_TYPE_POLL',
        type: ElementType.Poll,
        showPublished: false,
    },
    {
        title: 'ELEMENT_TYPE_VIDEO',
        type: ElementType.Video,
        showPublished: true,
    },
    {
        title: 'ELEMENT_TYPE_IMAGE',
        type: ElementType.Image,
        showPublished: true,
    },
    {
        title: 'ELEMENT_TYPE_FILE',
        type: ElementType.File,
        showPublished: true,
    },
];
