import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeck } from '../../../../context/DeckContext';
import { useTimer } from '../../../../context/TimerContext';
import { IElement, IUserAnswer } from '../../../../models/Element';
import { IOption } from '../../../../models/Option';
import Button from '../../../Button/Button';
import CheckmarkIcon from '../../../Icons/CheckmarkIcon/CheckmarkIcon';
import Timer from '../../../Timer/Timer';
import TimerBar from '../../../TimerBar/TimerBar';
import Hint from '../../Hint/Hint';
import OptionList from './OptionList/OptionList';
import classes from './Poll.module.scss';
import usePollFormHook from './PollFormHook';

type PollOptions = {
    options: IOption[];
    element: IElement;
    user_answer?: IUserAnswer | null;
    onQuestionAnswered?: (element: IElement, userAnswer: IUserAnswer) => void;
    loaded?: (element: IElement) => void;
};

const Poll: FunctionComponent<PollOptions> = ({ options, user_answer, element, onQuestionAnswered }) => {
    const { t } = useTranslation();
    const { deck, isHistory } = useDeck();
    const [userAnswer, setUserAnswer] = useState(user_answer);
    const [answer, setAnswer] = useState(userAnswer?.options ? userAnswer?.options[0]?.id : undefined);
    const [timerHasStarted, setTimerHasStarted] = useState(false);
    const { state, setTimer, startTimer, stopTimer } = useTimer();

    const handleQuestionAnswered = (element: IElement, userAnswer: IUserAnswer) => {
        setUserAnswer(userAnswer);
        setAnswer(userAnswer?.options ? userAnswer?.options[0]?.id : undefined);
        onQuestionAnswered?.(element, userAnswer);
    };

    const { handleSubmit, isSaved, setIsSaved } = usePollFormHook(
        {
            answer: userAnswer?.options ? userAnswer?.options[0]?.id.toString() : '',
        },
        deck.id,
        element,
        handleQuestionAnswered,
    );

    const [ops, setOps] = useState<IOption[]>(
        options.map((option) => {
            if (answer == option.id) {
                option.is_selected = true;
            }
            return option;
        }),
    );

    useEffect(() => {
        if (element.elementable?.timer_enabled) setTimer(element.elementable?.timer);
    }, [element]);

    useEffect(() => {
        if (user_answer) {
            setIsSaved(true);
        }
    }, [user_answer]);

    const handleClick = async (answer: number) => {
        stopTimer();

        const changedOps = options.map((option) => {
            if (answer == option.id) {
                option.is_selected = true;
            }
            return option;
        });

        setOps(_.cloneDeep(changedOps));

        await handleSubmit({ answer: answer.toString() });
    };

    const handleTimeUp = () => {
        stopTimer();
        setIsSaved(true);

        handleSubmit({ answer: '' });
    };

    const feedback = userAnswer?.options && userAnswer?.options?.length > 0 && userAnswer?.options?.[0].feedback;

    const answerTime =
        user_answer != undefined && user_answer.answer_time
            ? user_answer.answer_time
            : state.originalSeconds - state.seconds;
    const answerSeconds = answerTime % 60;
    const answerMinutes = (answerTime - answerSeconds) / 60;

    const outOfTime = answerTime >= state.originalSeconds;
    return (
        <div className={`${classes.Poll}`}>
            <div className={classes.Header}>
                <h3 className={`h5 ${classes.Body}`}>{element.elementable?.body}</h3>
                {element.elementable?.timer_enabled && (
                    <Timer
                        timer={element.elementable?.timer}
                        isSaved={isSaved}
                        isHistory={isHistory}
                        timerHasStarted={timerHasStarted}
                        onTimeUp={handleTimeUp}
                    />
                )}

                {/* {isSaved && <div className={`${classes.OpinionOthers}`}>{t('Deck:OPINION_OTHERS')}</div>} */}
            </div>

            {!isHistory &&
                element.elementable?.timer_enabled &&
                timerHasStarted &&
                state.timerRunning &&
                element.elementable?.timer !== '00:00' &&
                !user_answer && (
                    <TimerBar
                        progress={state.originalSeconds}
                        start={timerHasStarted}
                        isRunning={state.timerRunning}
                        completed={false}
                        timeState={'awaiting'}
                        isSaved={isSaved}
                    ></TimerBar>
                )}

            {!isHistory &&
                !timerHasStarted &&
                element.elementable?.timer_enabled &&
                element.elementable?.timer !== '00:00' &&
                !state.timerRunning &&
                !user_answer && (
                    <Button
                        className={classes.StartButton}
                        text="start timer"
                        onClick={() => {
                            setTimerHasStarted(true);
                            startTimer();
                        }}
                    ></Button>
                )}

            <div
                className={`${classes.QuestionForm} ${
                    !isHistory &&
                    element.elementable?.timer_enabled &&
                    !timerHasStarted &&
                    element.elementable?.timer !== '00:00' &&
                    !state.timerRunning &&
                    !user_answer &&
                    classes.blur
                }`}
            >
                <OptionList
                    options={ops}
                    optionPercentages={userAnswer?.option_percentages}
                    handleClick={handleClick}
                    isSaved={isSaved}
                ></OptionList>
            </div>
            <div className={`${classes.formActions}`}>
                <Hint
                    isSaved={isSaved}
                    timerFeedback={
                        element.elementable != null && element.elementable.timer_enabled === true && !outOfTime
                    }
                    outOfTime={outOfTime}
                    minutes={answerMinutes}
                    seconds={answerSeconds}
                />
                {isSaved && (
                    <div className={classes.Saved}>
                        <CheckmarkIcon />
                        <span>{t('Common:SAVED')}</span>
                    </div>
                )}
            </div>
            {isSaved && element.elementable?.feedback_enabled === true && feedback && (
                <div className={`${classes.Feedback}`}>{feedback}</div>
            )}
        </div>
    );
};

export default Poll;
