import DOMPurify from 'dompurify';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
// import Logo from '../../components/Logo/Logo';
import logo from '../../assets/HC_Logo_Colour_Medium_OnDark.png';
import Button from '../../components/Button/Button';
import HCFooter from '../../components/HCFooter/HCFooter';
import PageDecorator from '../../components/UI/PageDecorator/PageDecorator';
import { formatUrl } from '../../utils/string';
import classes from './HCErrorPage.module.scss';

export interface Props {
    statusCode: number;
}

const HCErrorPage: FunctionComponent<Props> = ({ statusCode }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    // const subdomain = getSubdomain();

    const goBackHandler = () => {
        history.push('/');
        history.replace(location.pathname);
    };

    const buttonHandler = () => {
        window.open(`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`, '_brank');
    };

    return (
        <div className={classes.HCErrorPage}>
            <PageDecorator centered contrast>
                <div className={classes.Content}>
                    <img className={classes.LogoImage} src={logo} alt="Logo" />
                    {statusCode !== 404 && <p>{statusCode}</p>}

                    <p className="h6">{t(`Common:ERROR_STATUSCODE_HC_MESSAGE_${statusCode}`)}</p>

                    {statusCode === 418 && (
                        <img src="https://www.bleepstatic.com/content/posts/2018/05/29/Error418.jpg" alt="Teapot"></img>
                    )}

                    {statusCode === 404 && (
                        <>
                            <div
                                className={`${classes.Hostname} h2`}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(formatUrl(window.location.hostname)),
                                }}
                            ></div>
                            <Button
                                className={classes.HCButton}
                                alert
                                text={t(`Common:ERROR_STATUSCODE_HC_BUTTON_${statusCode}`)}
                                onClick={buttonHandler}
                            ></Button>
                        </>
                    )}
                </div>
            </PageDecorator>
            <HCFooter></HCFooter>
        </div>
    );
};

export default HCErrorPage;
