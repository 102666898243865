import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React, { Fragment, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';
import InputField from '../../../components/InputField/InputField';
import Message from '../../../components/Message/Message';
import BoxHeader from '../../../components/UI/Box/BoxHeader/BoxHeader';
import CountDown from '../../../components/UI/CountDown/CountDown';
import { useConfig } from '../../../context/ConfigContext';
import Axios from '../../../utils/axios-instance';
import classes from './PasswordReset.module.scss';

type ParamTypes = {
    token: string;
};

const PasswordReset: FunctionComponent = () => {
    const { config } = useConfig();
    const history = useHistory();
    const { token } = useParams<ParamTypes>();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation();

    const schema = Yup.object({
        password: Yup.string()
            .required(t('Common:INPUT_ERROR_PASSWORD_REQUIRED'))
            .min(8, t('Common:INPUT_ERROR_PASSWORD_MIN', { min: 8 }))
            .max(255, t('Common:INPUT_ERROR_PASSWORD_MAX', { max: 255 })),
        password_confirmation: Yup.string().when('password', {
            is: (val) => val && val.length > 0,
            then: Yup.string()
                .oneOf([Yup.ref('password')], t('Common:INPUT_ERROR_PASSWORD_CONFIRMATION'))
                .required(t('Common:INPUT_ERROR_PASSWORD_CONFIRMATION')),
        }),
        email: Yup.string().required(t('Common:INPUT_ERROR_EMAIL_REQUIRED')),
    });

    const toLogin = () => {
        history.replace('/login');
    };

    const form = (
        <Formik
            initialValues={{
                password: '',
                password_confirmation: '',
                token: token,
                email: '',
            }}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);

                try {
                    await Axios.post(`${config.baseUrl}/password/reset`, values);

                    setSubmitting(false);
                    setIsSubmitted(true);
                    setIsError(false);
                } catch (error) {
                    // No error should happen here.
                    setSubmitting(false);
                    setIsSubmitted(false);
                    setIsError(true);
                }
            }}
        >
            {(formikProps) => {
                const { isSubmitting, isValid, dirty, handleSubmit } = formikProps;

                const disabledSubmit = isSubmitting || !(isValid && dirty);

                return (
                    <form noValidate={true} onSubmit={handleSubmit} className={classes.form}>
                        <div className={`${classes.PasswordResetForm} Box`}>
                            <BoxHeader
                                title={t('Auth:PASSWORD_RESET_TITLE')}
                                subtitle={t('Auth:PASSWORD_RESET_SUBTITLE')}
                            ></BoxHeader>
                            <div className={classes.formGroup}>
                                <InputField
                                    id="email"
                                    name="email"
                                    type="email"
                                    label={t('Common:EMAIL_LABEL')}
                                    required
                                    aria-describedby="email"
                                    placeholder={t('Common:EMAIL_PLACEHOLDER')}
                                />
                            </div>
                            <div className={classes.formGroup}>
                                <InputField
                                    id="new-password"
                                    name="password"
                                    type="password"
                                    label={t('Common:NEW_PASSWORD_LABEL')}
                                    autoComplete="new-password"
                                    required
                                    aria-describedby="password-constraints"
                                    placeholder={t('Auth:PASSWORD_EXAMPLE_PLACEHOLDER')}
                                />

                                <div className={classes.passwordHint}>{t('Auth:PASSWORD_RESET_PASSWORD_HINT')}</div>
                            </div>
                            <div className={classes.formGroup}>
                                <label htmlFor="password_confirmation">{}</label>
                                <div className={classes.passwordWrapper}>
                                    <InputField
                                        id="password_confirmation"
                                        name="password_confirmation"
                                        type="password"
                                        label={t('Common:CONFIRMATION_PASSWORD_LABEL')}
                                        autoComplete="new-password"
                                        required
                                        placeholder={t('Auth:PASSWORD_EXAMPLE_PLACEHOLDER')}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={`${classes.formActions} ${classes.flexGap}`}>
                            <button
                                className={`${classes.submitButton} btn btn-flavour btn-flavour--alt`}
                                type="submit"
                                disabled={disabledSubmit}
                            >
                                {t('Auth:PASSWORD_RESET_BUTTON_CONFIRM')}
                            </button>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );

    const newPasswordIsSet = (
        <Fragment>
            <div className={`Box ${classes.PasswordIsSet}`}>
                <BoxHeader
                    title={t('Auth:PASSWORD_RESET_TITLE')}
                    subtitle={t('Auth:PASSWORD_RESET_SUCCESS_SUBTITLE')}
                ></BoxHeader>

                <p>
                    {t('Auth:RETURN_TO_LOGIN')} <CountDown seconds={5} callbackHandler={toLogin}></CountDown>
                </p>
            </div>
        </Fragment>
    );

    const error = <Message text={t('Auth:PASSWORD_RESET_ERROR')}></Message>;

    return <div className={classes.PasswordReset}>{isSubmitted ? newPasswordIsSet : isError ? error : form}</div>;
};

export default PasswordReset;
