import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover from '@material-ui/core/Popover';
import React, { FunctionComponent } from 'react';
import Button from '../../../../components/Button/Button';
import { IDeck } from '../../../../models/Deck';
import LibraryDeckActions from './LibraryDeckActions/LibraryDeckActions';

export interface ILibraryDeckActionsProps {
    deck: IDeck;
    onDownloadHandler: (deck: IDeck) => void;
    onDuplicateHandler: (deck: IDeck) => void;
    onEditHandler: (deck: IDeck) => void;
    onDeleteHandler: (deck: IDeck) => void;
}

const DeckActionsPopover: FunctionComponent<ILibraryDeckActionsProps> = ({
    deck,
    onDownloadHandler,
    onDuplicateHandler,
    onEditHandler,
    onDeleteHandler,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'deck-edit-menu-popover' : undefined;

    return (
        <div>
            <Button
                aria-controls="deck-edit-menu"
                aria-haspopup="true"
                text=""
                icon={<FontAwesomeIcon icon={faEllipsis} />}
                onClick={handleClick}
            />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {/* <QuestionSettings {...props}></QuestionSettings> */}
                <LibraryDeckActions
                    deck={deck}
                    onDownloadHandler={() => {
                        handleClose();
                        onDownloadHandler(deck);
                    }}
                    onDuplicateHandler={onDuplicateHandler}
                    onEditHandler={onEditHandler}
                    onDeleteHandler={onDeleteHandler}
                />
            </Popover>
        </div>
    );
};

export default DeckActionsPopover;
