import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import { User } from '../../../../../models/User';
import GroupMemberList from '../../GroupMemberList/GroupMemberList';
import classes from './MembersDialog.module.scss';

interface MembersDialogProps {
    open: boolean;
    users: User[];
    toggleOpen: () => void;
    onAddButtonHandler: (member: User) => void;
}

const MembersDialog: FunctionComponent<MembersDialogProps> = ({ open, toggleOpen, users, onAddButtonHandler }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={toggleOpen} maxWidth="sm" fullWidth>
            <DialogTitle disableTypography className={classes.DialogTitle}>
                <Typography variant="h6">{t('Common:POTENTIAL_GROUPS_HEADER')}</Typography>

                <Button
                    aria-label="close"
                    className={classes.closeButton}
                    alt
                    text=""
                    icon={<FontAwesomeIcon icon={faClose}></FontAwesomeIcon>}
                    onClick={toggleOpen}
                ></Button>
            </DialogTitle>

            <DialogContent>
                <GroupMemberList
                    members={users || []}
                    icon={<FontAwesomeIcon className={classes.PlusIcon} icon={faPlus} />}
                    buttonClassName={`${classes.EditButton} ${classes.AddButton}`}
                    onClick={onAddButtonHandler}
                    dangerButton={false}
                />
            </DialogContent>
        </Dialog>
    );
};

export default MembersDialog;
