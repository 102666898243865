import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './PublicationStateLabel.module.scss';

interface PublicationStateLabelProps {
    className?: string;
    isPublished: boolean;
    inline?: boolean;
}

const PublicationStateLabel: FunctionComponent<PublicationStateLabelProps> = ({ className, isPublished, inline }) => {
    const { t } = useTranslation();

    const publicationState = isPublished ? 'published' : 'concept';

    return (
        <div
            className={`${className} ${classes.PublicationStateLabel} ${classes[publicationState]} ${
                inline && classes['inline']
            }`}
        >
            <span className={classes.PublicationStateText}>
                {isPublished ? t('Common:CONTENT_PUBLISHED_STATE_LABEL') : t('Common:CONTENT_CONCEPT_STATE_LABEL')}
            </span>
        </div>
    );
};

export default PublicationStateLabel;
