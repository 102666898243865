import React, { FunctionComponent, useLayoutEffect, useRef } from 'react';
import classes from './ProgressBar.module.scss';
import { gsap } from 'gsap';
import { TimeState } from '../../models/Quest';

export interface ProgressBarProps {
    progressLabel?: string;
    upperBoundsLabel?: string;
    progress: number;
    completed: boolean;
    timeState: TimeState;
    styleClass?: string;
    styles?: {
        height?: string;
        width?: string;
        background?: string;
        backgroundGradient?: boolean;
        fill?: string;
        fillGradient?: boolean;
        outline?: boolean;
        borderWidth?: number;
    };
    delay?: number;
    ease?: string;
    isHover?: boolean;
}

const ProgressBar: FunctionComponent<ProgressBarProps> = ({
    progressLabel,
    upperBoundsLabel,
    progress,
    completed,
    timeState,
    styleClass,
    styles,
    delay,
    ease,
    isHover,
}) => {
    const hoverClass = isHover ? classes['hover'] : '';
    const ref = useRef<HTMLDivElement>(null);
    const animation = () => {
        gsap.to(ref.current, {
            duration: 1.5,
            left: `${progress * 100 - 100}%`,
            ease: ease || 'power2.inOut',
            delay: 0.5 + (delay || 0),
        });
    };

    useLayoutEffect(() => {
        animation();
    }, [progress]);

    return (
        <div className={`progressbar ${classes.ProgressBar}`} style={{ height: styles?.height }}>
            <div
                className={`${classes.Bar} ${
                    styles?.backgroundGradient ? 'quest-progress-background' : ''
                } ${hoverClass}`}
                style={{
                    background: styles?.background,
                    borderWidth: styles?.borderWidth,
                }}
            >
                <div
                    className={`${classes.Fill} ${styles?.outline ? classes.Outline : ''} ${
                        styleClass ? styleClass : ''
                    } ${classes[completed ? 'completed' : '']} ${classes[timeState]} ${hoverClass} `}
                    style={{ background: styles?.fill }}
                    ref={ref}
                ></div>
                <div className={classes.Content}>
                    <span className={`${classes.Label} ${hoverClass}`}>{progressLabel}</span>
                    <span className={classes.Label}>{upperBoundsLabel}</span>
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
