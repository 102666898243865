import React, { FunctionComponent } from 'react';
import { isDesktop } from 'react-device-detect';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { Permission } from '../../models/User';
import { getLocalStorageUser } from '../../utils/flavourstorage';

type PrivateRouteProps = {
    component: FunctionComponent;
    path: string;
    exact: boolean;
    permissions?: Permission[];
};

const PrivateRoute: FunctionComponent<PrivateRouteProps> = (props) => {
    const { hasPermission } = useAuth();
    const getAuthenticatedUser = () => {
        const loggedInUser = getLocalStorageUser();
        return loggedInUser;
    };

    const permissionCheck = () => {
        return props.permissions
            ?.map((permission) => {
                return hasPermission(permission) && isDesktop;
            })
            .includes(true);
    };

    // TODO check specific for manage and desktop
    const permission = props.permissions ? permissionCheck() : true;
    const condition = getAuthenticatedUser() && permission;

    return condition ? (
        <Route path={props.path} exact={props.exact} component={props.component} />
    ) : permission ? (
        <Redirect to={'/login'} />
    ) : (
        <Redirect to={'/'} />
    );
};
export default PrivateRoute;
