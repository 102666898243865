import { gsap } from 'gsap';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigContext';
import { QuestProvider } from '../../../context/QuestProvider';
import { IQuest, TimeState } from '../../../models/Quest';
import { compareNowToDates, nowToDates } from '../../../utils/date';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import QuestCover from '../../Home/Quests/QuestCover/QuestCover';
import classes from './QuestsDetail.module.scss';

const QuestsDetail: FunctionComponent = () => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const { hasPermission } = useAuth();
    const tl = gsap.timeline();
    const history = useHistory();
    const { response, loading } = useAxiosFetch<IQuest[]>(`${config.baseUrl}/user/quests`);
    const [quests, setQuests] = useState<IQuest[]>([]);
    const list = useRef<HTMLDivElement[]>([]);

    const editHandler = () => {
        history.push({ pathname: `/management/quests` });
    };

    useEffect(() => {
        if (response) {
            const questsData = response.data;
            setQuests(questsData);
        }
    }, [response]);

    const animateInList = (listReference: HTMLDivElement[]) => {
        tl.set(listReference, { alpha: 0, scale: 0.7 });
        tl.to(listReference, {
            duration: 0.25,
            alpha: 1,
            scale: 1,
            ease: 'back',
            stagger: {
                amount: 0.35,
                grid: [3, 3],
                // axis: 'y',
                // ease: 'none',
                // from: 'start',
            },
        });
    };

    const setRefs = (target: HTMLDivElement | null, listReference: HTMLDivElement[], itemIndex: number) => {
        if (target && listReference) {
            const ref = (list.current[itemIndex] = target);
            if (itemIndex === quests.length - 1) {
                // All refs are set for this list. So animation is possible.
                animateInList(listReference);
            }
            return ref;
        }
    };

    const sortBy: TimeState[] = ['in_progress', 'awaiting', 'ended'];

    const comparedTimeToState = (compare: number): TimeState => {
        if (compare === -1) {
            return 'awaiting';
        } else if (compare === 0) {
            return 'in_progress';
        } else {
            return 'ended';
        }
    };

    const completed = (quest: IQuest) => {
        const tasksIsCompleted = Boolean(!!quest.user_progress[0]?.is_completed);
        const teamQuestTotal = quest.quest_progress.totalTasks;
        const teamProgress = teamQuestTotal === 0 ? 0 : quest.quest_progress.completedTasks / teamQuestTotal;
        return tasksIsCompleted || teamProgress === 1;
    };

    const customSort = ({ data, sortBy }: { data: IQuest[]; sortBy: string[] }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const sortByObject: { [key: string]: any } = sortBy.reduce((obj, item, index) => {
            return {
                ...obj,
                [item]: index,
            };
        }, {});
        return data.sort((a, b) => {
            // Check if quests are completed
            const aIsCompleted = completed(a);
            const bIsCompleted = completed(b);

            if (aIsCompleted && !bIsCompleted) {
                return 1;
            }

            if (!aIsCompleted && bIsCompleted) {
                return -1;
            }

            // Check if both quests are without a date
            if (!a.start_date && !a.end_date && !b.start_date && !b.end_date) {
                return -1;
            }

            // Sort quests by date / or no date
            const aTime =
                a.start_date && a.end_date
                    ? comparedTimeToState(compareNowToDates(a.start_date, a.end_date))
                    : 'in_progress';
            const bTime =
                b.start_date && b.end_date
                    ? comparedTimeToState(compareNowToDates(b.start_date, b.end_date))
                    : 'in_progress';
            const sortIndex = sortByObject[aTime] - sortByObject[bTime];

            if (sortIndex != 0) {
                return sortIndex;
            }

            // Sort by diff to now
            const aDiff = a.start_date && a.end_date ? nowToDates(a.start_date, a.end_date) : 0;
            const bDiff = b.start_date && b.end_date ? nowToDates(b.start_date, b.end_date) : 0;
            if (aTime === 'awaiting') {
                if (aDiff > bDiff) return -1;
                if (aDiff < bDiff) return 1;
            }
            if (aTime === 'in_progress') {
                if (aDiff > bDiff) return -1;
                if (aDiff < bDiff) return 1;
            }
            if (aTime === 'ended') {
                if (aDiff > bDiff) return 1;
                if (aDiff < bDiff) return -1;
            }

            return sortIndex;
        });
    };

    // const questList = customSort({ data: quests, sortBy });

    const filteredQuestList = (filterBy: string): JSX.Element[] => {
        const filteredList = quests.filter((quest: IQuest) => {
            const active_state =
                quest.is_timed && quest.start_date && quest.end_date
                    ? comparedTimeToState(compareNowToDates(quest.start_date, quest.end_date))
                    : 'in_progress';

            return active_state === filterBy;
        });

        return filteredList.map((quest: IQuest, i: number) => {
            return <QuestItem key={quest.id} setRefs={setRefs} list={list} quest={quest} index={i} />;
        });
    };

    const activeQuestList = filteredQuestList('in_progress');
    const upcomingQuestList = filteredQuestList('awaiting');
    const expiredQuestList = filteredQuestList('ended');

    return (
        <div className={classes.GroupsDetail}>
            <PageHeader
                title={t('Quests:OVERVIEW_TITLE')}
                subtitle={t('Quests:OVERVIEW_SUBTITLE')}
                editHandler={hasPermission('quest.manage') && isDesktop ? editHandler : undefined}
            />

            {loading && (
                <div className={classes.Loader}>
                    <div className="spinner card Box"></div>
                </div>
            )}

            {activeQuestList.length > 0 && !loading && (
                <div className={classes.ActiveQuests}>
                    <header className={classes.Header}>
                        <h2 className={`$h2 ${classes['title']}`}>{t('Quests:ACTIVE_QUESTS_TITLE')}</h2>
                    </header>

                    <div className={`card-group`}>{activeQuestList}</div>
                </div>
            )}

            {upcomingQuestList.length > 0 && !loading && (
                <div className={classes.UpcomingQuests}>
                    <header className={classes.Header}>
                        <h2 className={`$h2 ${classes['title']}`}>{t('Quests:UPCOMING_QUESTS_TITLE')}</h2>
                    </header>

                    <div className={`card-group`}>{upcomingQuestList}</div>
                </div>
            )}

            {expiredQuestList.length > 0 && !loading && (
                <div className={classes.ExpiredQuests}>
                    <header className={classes.Header}>
                        <h2 className={`$h2 ${classes['title']}`}>{t('Quests:EXPIRED_QUESTS_TITLE')}</h2>
                    </header>

                    <div className={`card-group`}>{expiredQuestList}</div>
                </div>
            )}
        </div>
    );
};

type QuestItemProps = {
    setRefs: (
        target: HTMLDivElement | null,
        listReference: HTMLDivElement[],
        itemIndex: number,
    ) => HTMLDivElement | undefined;
    list: React.MutableRefObject<HTMLDivElement[]>;
    quest: IQuest;
    index: number;
};

const QuestItem: FunctionComponent<QuestItemProps> = ({ setRefs, list, quest, index }) => {
    return (
        <div
            ref={(target) => {
                setRefs(target, list.current, index);
            }}
            className={classes.QuestCoverReferenceContainer}
        >
            <QuestProvider questOverride={quest}>
                <QuestCover></QuestCover>
            </QuestProvider>
        </div>
    );
};

export default QuestsDetail;
