import { faPencil, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogActions, DialogContent, DialogTitle, TableCell, TableHead, TableRow } from '@material-ui/core';
import { differenceInMinutes } from 'date-fns';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../../../components/Button/Button';
import ExtendedMuiList from '../../../components/ExtendedMuiList/ExtendedMuiList';
import ExtendedMuiTable from '../../../components/ExtendedMuiTable/ExtendedMuiTable';
import { useConfig } from '../../../context/ConfigContext';
import { IAccessCode } from '../../../models/AccessCode';
import axiosInstance from '../../../utils/axios-instance';
import { dateToLocal, formatDateToLocal } from '../../../utils/date';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import ManagementHeader from '../ManagementHeader/ManagementHeader';
import AccessCodeActionsPopover from './AccessCodeActionsPopover/AccessCodeActionsPopover';
import classes from './ManagementAccessCodes.module.scss';

const ManagementAccessCodes: FunctionComponent = () => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const { response, loading } = useAxiosFetch<IAccessCode[]>(`${config.baseUrl}/accesscodes`);
    const [accessCodes, setAccessCodes] = useState<IAccessCode[]>([]);
    const history = useHistory();

    useEffect(() => {
        if (response) {
            const accessCodesData = response.data;

            accessCodesData.map((accessCode) => {
                if (accessCode.expires_at && accessCode.expires_at != '') {
                    accessCode.expires_at = formatDateToLocal(accessCode.expires_at);
                }
            });
            setAccessCodes(accessCodesData);
        }
    }, [response]);

    const onCreateAccessCodeHandler = () => {
        history.push(`/accesscodes/create`);
    };

    const filterDataBySearch = (searchValues: string[]) => {
        return accessCodes.filter((item) => {
            const title = item.title;

            const searchableValues = { title };
            const searchableValuesToString = Object.values(searchableValues);

            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    const [open, setOpen] = useState(false);
    const closeModal = () => {
        setOpen(false);
    };

    const notifyDeleted = () => toast.success(t('Common:TOAST_ACCESS_CODE_DELETED_SUCCESS'));

    const editClickHandler = (accessCode: IAccessCode) => {
        history.push(`/accesscodes/edit/${accessCode.id}`);
    };

    const [accessCodeToDelete, setAccessCodeToDelete] = useState<IAccessCode | undefined>();
    const deleteClickHandler = (accessCode: IAccessCode) => {
        if (accessCodeToDelete != undefined) return;
        setAccessCodeToDelete(accessCode);
        setOpen(true);
    };

    const cancelDeleteClickHandler = () => {
        setAccessCodeToDelete(undefined);
        closeModal();
    };

    const permanentlyDeleteHandler = () => {
        if (accessCodeToDelete === undefined) return;
        axiosInstance.delete(`${config.baseUrl}/accesscodes/${accessCodeToDelete.id}`);

        const newAccessCodes = _.cloneDeep(accessCodes);
        const accessCodeIndex = newAccessCodes.findIndex((a) => a.id === accessCodeToDelete.id);
        if (accessCodeIndex != -1) {
            newAccessCodes.splice(accessCodeIndex, 1);
            setAccessCodes(newAccessCodes);
        }

        notifyDeleted();
        setAccessCodeToDelete(undefined);
        closeModal();
    };

    const renderTableHeaders = (
        <TableRow>
            <TableCell>
                <div className={`table-header`}>{t('Common:TITLE')}</div>
            </TableCell>
            <TableCell>
                <div className={`table-header`}>{t('Common:EXPIRES_AT')}</div>
            </TableCell>
            <TableCell>
                <div className={`table-header`}>{t('Common:ACCESS_CODE_LIST_USERS')}</div>
            </TableCell>
            <TableCell>
                <div className={`table-header`}>{t('Common:ACCESS_CODE')}</div>
            </TableCell>
            <TableCell></TableCell>
        </TableRow>
    );

    const renderItem = (accessCode: IAccessCode) => {
        let hasExpired = false;
        if (accessCode.expires_at || accessCode.expires_at === '') {
            const localDate = new Date(accessCode.expires_at);
            const diff = differenceInMinutes(localDate, Date.now());
            hasExpired = diff <= 0;
        }

        return (
            <TableRow key={`accesscode_${accessCode.id}`}>
                <TableCell>
                    <div className={classes.AccessCodeListTitle}>{accessCode.title}</div>
                </TableCell>

                <TableCell>
                    <div className={`${classes.AccessCodeListExpiresAt} ${hasExpired && classes['expired']}`}>
                        {accessCode.expires_at}
                    </div>
                </TableCell>

                <TableCell>
                    <div
                        className={`${classes.AccessCodeListUsers} ${
                            accessCode.user_count >= accessCode.max_users && classes['expired']
                        }`}
                    >{`${accessCode.user_count} / ${accessCode.max_users}`}</div>
                </TableCell>

                <TableCell>{accessCode.code}</TableCell>

                <TableCell>
                    <div className={classes.AccessCodeListActions}>
                        <AccessCodeActionsPopover
                            accessCode={accessCode}
                            onEditHandler={editClickHandler}
                            onDeleteHandler={deleteClickHandler}
                        />
                        {/* <Button
                            text=""
                            border
                            icon={<FontAwesomeIcon icon={faPencil} />}
                            onClick={() => editClickHandler(accessCode)}
                        />
                        <Button
                            text=""
                            icon={<FontAwesomeIcon icon={faTrashAlt} />}
                            danger
                            onClick={() => deleteClickHandler(accessCode)}
                        /> */}
                    </div>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <div className={classes.ManagementAccessCodes}>
            <ManagementHeader
                title={t('Common:ACCESS_CODES_HEADER')}
                buttonText={t('Common:BUTTON_CREATE_ACCESS_CODE')}
                onClick={onCreateAccessCodeHandler}
                permission="user.manage"
            />

            <div className={`Box`}>
                <ExtendedMuiTable
                    loading={loading}
                    filterDataBySearch={filterDataBySearch}
                    searchInputLabel={t('Common:ACCESS_CODES_SEARCH_INPUT_LABEL')}
                    noItemsMessage={t('Common:LIST_MESSAGE_NO_ACCESS_CODES')}
                    noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_ACCESS_CODES')}
                    items={accessCodes}
                    renderTableHeaders={renderTableHeaders}
                    renderItem={renderItem}
                    itemsListClasses={classes.AccessCodeList}
                />
            </div>

            <Dialog
                open={open}
                onClose={closeModal}
                className={classes.WarningDialog}
                PaperProps={{ className: `dialog` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:DELETE_ACCESS_CODE_TITLE')}</DialogTitle>
                <DialogContent>{t('Common:DELETE_ACCESS_CODE_MESSAGE')}</DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={cancelDeleteClickHandler} />
                    <Button alt text={t('Common:BUTTON_CONFIRM')} onClick={permanentlyDeleteHandler} />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ManagementAccessCodes;
