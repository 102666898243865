import React, { FunctionComponent } from 'react';
import { IOption } from '../../../../../models/Option';
import { getColor } from '../../../../../utils/colors';
import ProgressBar from '../../../../ProgressBar/Progressbar';
import classes from './Option.module.scss';

// export interface IOption {
//     id: number;
//     question_id: number;
//     body: string;
//     feedback: string;
//     is_correct: number;
//     is_selected: boolean;
// }

export interface IAnswer {
    id: string;
    is_correct: number;
}

type Props = {
    option: IOption;
    optionNumber: number;
    optionPercentage?: number;
    text: string;
    handleClick: (option: IOption) => void;
    selected: boolean;
    isSaved: boolean;
};

const Option: FunctionComponent<Props> = ({
    option,
    optionNumber,
    optionPercentage,
    text,
    handleClick,
    selected,
    isSaved,
}) => {
    const selectedClass = selected ? `${classes.selected}` : isSaved ? `${classes.notselected}` : ``;
    const isSavedClass = isSaved ? `${classes.disabled}` : ``;
    const progressBarColor = getColor('--brand-color-18');

    const handleOptionClick = () => {
        handleClick(option);
    };

    return (
        <div className={`${classes.Option} ${isSavedClass}`} onClick={() => handleOptionClick()}>
            <div className={`${classes.OptionNumber} ${selectedClass}`}>{optionNumber}</div>
            <div className={`${classes.OptionDetails}`}>
                <div className={`${classes.OptionText}`}>{text}</div>
                <div className={classes.ProgressBar}>
                    <ProgressBar
                        progress={optionPercentage || 0}
                        completed={optionPercentage ? true : false}
                        timeState={'ended'}
                        styles={{ background: progressBarColor }}
                    ></ProgressBar>
                    <div className={`${classes.OthersPercentage}`}>
                        {optionPercentage != undefined ? `${Math.round(optionPercentage * 100)}%` : ''}{' '}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Option;
