import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover from '@material-ui/core/Popover';
import React, { FunctionComponent } from 'react';
import Button from '../../../../../components/Button/Button';
import { InvitedUser } from '../../../../../models/InvitedUser';
import InviteActions from './InviteActions/InviteActions';
import classes from './InviteActionsPopover.module.scss';

export interface IInviteActionsProps {
    invitedUser: InvitedUser;
    onResendHandler: (invitedUser: InvitedUser) => void;
    onDeleteHandler: (invitedUser: InvitedUser) => void;
}

const InviteActionsPopover: FunctionComponent<IInviteActionsProps> = ({
    invitedUser,
    onResendHandler,
    onDeleteHandler,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'deck-edit-menu-popover' : undefined;

    return (
        <div className={classes.TagActionsPopover}>
            <Button
                aria-controls="deck-edit-menu"
                aria-haspopup="true"
                text=""
                icon={<FontAwesomeIcon icon={faEllipsis} />}
                onClick={handleClick}
            />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <InviteActions
                    invitedUser={invitedUser}
                    onResendHandler={onResendHandler}
                    onDeleteHandler={onDeleteHandler}
                />
                {/* <AccessCodeActions
                    accessCode={accessCode}
                    onEditHandler={onEditHandler}
                    onDeleteHandler={() => {
                        handleClose();
                        onDeleteHandler(accessCode);
                    }}
                /> */}
            </Popover>
        </div>
    );
};

export default InviteActionsPopover;
