import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/PageHeader/PageHeader';
import classes from './TagEditDetail.module.scss';
import useTagsEditForm from './useTagsEditForm';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router';
import { Formik } from 'formik';
import TagEditDetailForm from './TagEditDetailForm/TagEditDetailForm';
import toast from 'react-hot-toast';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import { useConfig } from '../../../context/ConfigContext';
import { useTag } from '../../../context/TagContext';
import { ITag } from '../../../models/Tag';

interface ITagProps {
    isCreate: boolean;
}

type ParamTypes = {
    tagId: string;
};

const TagEditDetail: FunctionComponent<ITagProps> = ({ isCreate }) => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const history = useHistory();
    const { tag, setTag } = useTag();
    const { tagId } = useParams<ParamTypes>();
    const { response, loading } = useAxiosFetch<ITag>(isCreate ? null : `${config.baseUrl}/tags/${tagId}`);

    const { initialValues, handleSubmit } = useTagsEditForm(
        tag,
        {
            title: tag.title,
        },
        isCreate,
    );

    const schema = Yup.object({
        title: Yup.string()
            .required(t('Common:INPUT_ERROR_TITLE_REQUIRED'))
            .max(50, t('Common:INPUT_ERROR_TITLE_MAX_CHARACTERS', { max: 50 })),
    });

    useEffect(() => {
        if (response) {
            setTag(response.data);
        }
    }, [response]);

    const cancelHandler = () => {
        history.goBack();
    };

    const notifyCreated = () => toast.success(t('Common:TOAST_SUCCESS_TAG_CREATED'));
    const notifyDuplicateTitleError = () => toast.error(t('Common:TOAST_ERROR_TAG_TITLE'));
    const notifyError = () => toast.error(t('Common:TOAST_ERROR_TAG'));

    return (
        <div className={classes.TagEditDetail}>
            <PageHeader title={t('Common:TAGS_HEADER')} />

            {!loading && (
                <Formik
                    onSubmit={(formValues, actions) =>
                        handleSubmit(tag, formValues, actions)
                            .then(() => {
                                notifyCreated();
                                cancelHandler();
                            })
                            .catch((error) => {
                                const { data } = error?.response as {
                                    data: any;
                                };

                                if (data.errors.title?.length >= 1) {
                                    notifyDuplicateTitleError();
                                } else {
                                    notifyError();
                                }
                            })
                    }
                    initialValues={initialValues}
                    validationSchema={schema}
                >
                    {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                        const disabledSubmit = isSubmitting || !(isValid && dirty);

                        return (
                            <TagEditDetailForm
                                isCreate={isCreate}
                                handleSubmit={handleSubmit}
                                disabledSubmit={disabledSubmit}
                                cancelHandler={cancelHandler}
                            />
                        );
                    }}
                </Formik>
            )}
        </div>
    );
};

export default TagEditDetail;
