import React, { useState, FunctionComponent } from 'react';
import { IModule } from '../models/Module';
import { CtxProvider } from './ModuleContext';

type ModuleProviderProps = {
    overrides?: Partial<IModule>;
};

export const ModuleProvider: FunctionComponent<ModuleProviderProps> = ({ children, overrides }) => {
    const defaultModule: IModule = {
        title: '', // WWWWWWWWWWWWWWWW
        description: '', // WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW
        order: 0,
        id: 0,
        created_at: '',
        updated_at: '',
        quest_id: 0,
        category_id: 0,
        progress: [],
        user_progress: [],
    };

    const [module, setModule] = useState<IModule>({
        ...defaultModule,
        ...overrides,
    });

    const updateModule = (module: IModule) => {
        setModule((current) => {
            return { ...current, ...module };
        });
    };

    const [isDataChanged, setIsDataChanged] = useState(false);

    return (
        <CtxProvider value={{ module, setModule, updateModule, isDataChanged, setIsDataChanged }}>
            {children}
        </CtxProvider>
    );
};
