import React, { createRef, Fragment, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../context/ConfigContext';
import { getFilePathByName, IFile } from '../../models/File';
import AxiosInstance from '../../utils/axios-instance';
import Button from '../Button/Button';
import ButtonLoadingIcon from '../Icons/ButtonLoadingIcon/ButtonLoadingIcon';
import TrashIcon from '../Icons/TrashIcon/TrashIcon';
import UploadIcon from '../Icons/UploadIcon/UploadIcon';
import classes from './PDFUploader.module.scss';

type ButtonProps = {
    originalFile?: IFile | null;
    onChange: (file: IFile | undefined) => void;
};

const PDFUploader: FunctionComponent<ButtonProps> = ({ originalFile = null, onChange }) => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const [uploadedFile, setUploadedFile] = useState<IFile | null>(originalFile);
    const [loading, setLoading] = useState(false);

    const ref = createRef<HTMLInputElement>();

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const fileToUpload = event?.target?.files ? event.target.files[0] : null;

        if (fileToUpload) {
            const formData = new FormData();
            formData.append('file', fileToUpload);
            setLoading(true);
            const response = await AxiosInstance.post(`${config.baseUrl}/files`, formData);
            setUploadedFile(response.data);
            setLoading(false);
            onChange(response.data);
        }
    };

    // Programatically click hidden file input element
    const handleClick = () => {
        ref.current?.click();
    };

    const handleDeleteClick = async () => {
        if (uploadedFile) {
            await AxiosInstance.delete(`${config.baseUrl}/files/${uploadedFile.id}`);

            // Clear values
            if (ref.current?.files) ref.current.value = '';

            setUploadedFile(null);
            onChange(undefined);
        }
    };

    return (
        <Fragment>
            <div className={classes.FileDetails}>
                <div className={classes.FileNameWrapper}>
                    <a
                        className={`wrap-text ${classes.FileName}`}
                        href={uploadedFile?.url || getFilePathByName(uploadedFile?.name || originalFile?.name)}
                        target={'_blank'}
                        rel={'noreferrer'}
                    >
                        {uploadedFile
                            ? uploadedFile.title
                            : originalFile
                            ? originalFile.name
                            : t('Common:DEFAULT_PDF_NOTICE')}
                    </a>
                </div>

                <div className={classes.FileActions}>
                    <button
                        className={`${classes.UploadButton} btn btn-flavour`}
                        onClick={handleClick}
                        type="button"
                        disabled={loading}
                    >
                        {loading ? <ButtonLoadingIcon /> : <UploadIcon />}
                        {t('Common:BUTTON_UPLOAD')}
                    </button>
                    {uploadedFile && (
                        <Button
                            className={classes.IconButton}
                            text=""
                            icon={<TrashIcon />}
                            danger
                            onClick={handleDeleteClick}
                        />
                    )}

                    <input id="file-upload" type="file" ref={ref} onChange={handleChange} style={{ display: 'none' }} />
                </div>
            </div>
        </Fragment>
    );
};

export default PDFUploader;
