import { faPencil, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem, ListItemText } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useStylesDeleteButton, useStylesRegularButton } from '../../../../../utils/muiStyles';
import { IQuestActionsProps } from '../QuestActionsPopover';
import classes from './QuestActions.module.scss';

const QuestActions: FunctionComponent<IQuestActionsProps> = (props) => {
    const { t } = useTranslation();
    const classesRegularButton = useStylesRegularButton();
    const classesDeleteButton = useStylesDeleteButton();
    const quest = props.quest;

    return (
        <List>
            <ListItem
                button
                className={classesRegularButton.root}
                alignItems="center"
                onClick={() => props.onEditHandler(quest)}
            >
                <FontAwesomeIcon icon={faPencil} />
                <ListItemText primary={t('Common:BUTTON_EDIT')} />
            </ListItem>
            <ListItem
                button
                className={classesDeleteButton.root}
                alignItems="center"
                onClick={() => props.onDeleteHandler(quest)}
            >
                <FontAwesomeIcon icon={faTrashAlt} />
                <ListItemText primary={t('Common:BUTTON_DELETE')} />
            </ListItem>
        </List>
    );
};

export default QuestActions;
