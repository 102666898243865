import { Chip, FormControl, Input, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, { MultiValue } from 'react-select';
import { SelectOption } from '../../../components/GroupSelect/GroupSelect';
import { useConfig } from '../../../context/ConfigContext';
import { ITag } from '../../../models/Tag';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import classes from './TagFilter.module.scss';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        margin: '0px',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface TagFilterProps {
    handleChange: (value: MultiValue<SelectOption>) => void;
}

const TagFilter: FunctionComponent<TagFilterProps> = ({ handleChange }) => {
    const { config } = useConfig();
    const { t } = useTranslation();

    const { response, loading } = useAxiosFetch<ITag[]>(`${config.baseUrl}/tags`);
    const [tags, setTags] = useState<ITag[]>([]);
    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        if (response) {
            setTags(response.data);
        }
    }, [response]);

    useEffect(() => {
        if (tags) {
            const optionData: SelectOption[] = tags.map((tag) => {
                return { value: tag.id, label: tag.title };
            });
            setOptions(optionData);
        }
    }, [tags]);

    return (
        <div className={classes.TagFilter}>
            {!loading && (
                <ReactSelect
                    id={'tags'}
                    options={options}
                    // defaultValue={values.tags}
                    placeholder={t('Common:TAGS_FILTER_PLACEHOLDER')}
                    noOptionsMessage={() => t('Common:TAGS_SELECT_NO_MORE_OPTIONS')}
                    onChange={(option) => {
                        if (option) {
                            handleChange(option);
                        }
                    }}
                    isMulti={true}
                    className={`react-select-container`}
                    classNamePrefix="react-select"
                />
            )}
        </div>
    );
};

export default TagFilter;
