import React, { Fragment, FunctionComponent } from 'react';
import BoxHeader from '../../../components/UI/Box/BoxHeader/BoxHeader';

interface WelcomeProps {
    title: string;
    text: string;
    callForAction: string;
}

const WelcomeBox: FunctionComponent<WelcomeProps> = (props: WelcomeProps) => {
    return (
        <Fragment>
            <BoxHeader title={props.title} subtitle={props.text}></BoxHeader>
            <p>
                <strong>{props.callForAction}</strong>
            </p>
        </Fragment>
    );
};

export default WelcomeBox;
