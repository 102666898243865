// import { useSpring, animated } from 'react-spring';
import { animated, useSpring } from '@react-spring/web';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CoverImage from '../../../components/CoverImage/CoverImage';
import { useConfig } from '../../../context/ConfigContext';
import { useProfile } from '../../../context/ProfileContext';
import useHover from '../../../hooks/useHover';
import { getFilePathByName, getFilePathForDefaultImage } from '../../../models/File';
import classes from './ProfileCover.module.scss';

export interface ProfileProps {
    editMode?: boolean;
    delay?: number;
    className?: string;
}

const ProfileCover: FunctionComponent<ProfileProps> = ({ editMode = false, className }) => {
    const { config, loading, loadingConfig } = useConfig();
    const { t } = useTranslation();
    const { profile } = useProfile();
    const ref = useRef<HTMLDivElement>(null);
    const isHover = useHover(ref);

    const [defaultProfileAvatar, setDefaultProfileAvatar] = useState('');

    useEffect(() => {
        if (!loadingConfig) {
            const customFile = config.tenant.images['profile-avatar.png']?.find((file) => file.is_default == false);
            const defaultFile = config.tenant.images['profile-avatar.png']?.find((file) => file.is_default == true);
            setDefaultProfileAvatar(
                getFilePathByName(customFile?.file?.name) || getFilePathForDefaultImage(defaultFile?.file?.name) || '',
            );
        }
    }, [loadingConfig]);

    useEffect(() => {
        set.start({ xys: [0, 0, 1] });
    }, []);

    // useEffect(() => {
    //     setDefaultProfileAvatar(getFilePathByName(profile.profile_picture?.name) || defaultProfileAvatar);
    // }, [profile.profile_picture]);

    const [animating, setAnimating] = useState(false);

    const calc = (x: number, y: number, width: number, height: number) => {
        const rot = 40;
        return [-(y - height / 2) / rot, (x - width / 2) / rot, 1.04];
    };

    const [props, set] = useSpring(() => ({
        xys: [0, 0, 1],
        config: { mass: 3, tension: 550, friction: 40 },
        onStart: () => {
            setAnimating(true);
        },
        onRest: () => {
            setAnimating(false);
        },
    }));

    const trans = (x: number, y: number, s: number) => {
        const perspective = `perspective(700px) rotateX(${-x}deg) rotateY(${-y}deg) scale(${s})`;
        if (!animating) {
            if (isHover) {
                return perspective;
            } else {
                return `scale(${s})`;
            }
        }
        return perspective;
    };

    return (
        <div className={`${classes.ProfileCover} ${className}`}>
            <animated.div
                ref={ref}
                className={`card Box ${editMode && classes.NoInteraction} ${classes.ProfileCoverCard}`}
                onMouseMove={(e) => {
                    const node = e.target as HTMLElement;
                    const rect = node.getBoundingClientRect();
                    const x = e.clientX - rect.left;
                    const y = e.clientY - rect.top;
                    set.start({ xys: calc(x, y, rect.width, rect.height) });
                }}
                onMouseLeave={() => {
                    set.start({ xys: [0, 0, 1] });
                }}
                // onClick={profileClickHandler}
                style={{ transform: props?.xys?.to(trans) }}
            >
                <div className={classes.HitArea}>
                    <div className={`${classes.RoleBar} ${classes.TopCardSection}`}>
                        {t(`Common:${profile.roles[0]?.name}`)}
                    </div>
                    <div className={classes.Content}>
                        <div className={`${classes.CoverImage} `}>
                            <CoverImage
                                src={getFilePathByName(profile.profile_picture?.name) || defaultProfileAvatar}
                                backgroundSize={profile.profile_picture_background_size}
                            ></CoverImage>
                        </div>
                        <h3 className={`h4 wrap-text-overflow-2 ${classes.Name}`}>
                            {profile.name || t('Common:COVER_NONAME_TITLE')}
                        </h3>
                        <div className={`${classes.JobTitle}`}>
                            {profile.job_title || t('Common:COVER_NOJOB_TITLE')}
                        </div>
                        {!!profile.show_email && (
                            <a className={`${classes.Email}`} href={`mailto:${profile.email}`}>
                                {profile.email}
                            </a>
                        )}
                    </div>
                </div>
            </animated.div>
        </div>
    );
};

export default ProfileCover;
