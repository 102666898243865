import React, { FunctionComponent } from 'react';
import { IOption } from '../../../../../models/Option';
import Option from '../Option/Option';
import classes from './OptionList.module.scss';

type OptionProps = {
    options: IOption[];
    optionPercentages?: number[];
    handleClick: (optionId: number) => void;
    isSaved: boolean;
};

const OptionList: FunctionComponent<OptionProps> = ({ options, handleClick, optionPercentages, isSaved }) => {
    const optionList = options.map((option, index) => {
        return (
            <Option
                key={index}
                optionNumber={index + 1}
                optionPercentage={optionPercentages?.[index]}
                option={option}
                text={option.body}
                handleClick={(option) => handleClick(option.id)}
                selected={option.is_selected}
                isSaved={isSaved}
            ></Option>
        );
    });

    return <div className={classes.OptionList}>{optionList}</div>;
};

export default OptionList;
