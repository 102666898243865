import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeck } from '../../../../context/DeckContext';
import { useTimer } from '../../../../context/TimerContext';
import { IElement, IUserAnswer } from '../../../../models/Element';
import { IOption } from '../../../../models/Option';
import Button from '../../../Button/Button';
import CheckmarkIcon from '../../../Icons/CheckmarkIcon/CheckmarkIcon';
import TimerBar from '../../../TimerBar/TimerBar';
import classes from './MultipleChoice.module.scss';
import useMultipleChoiceQuestionFormHook from './MultipleChoiceFormHook';
import OptionList from './OptionList/OptionList';
import { gsap } from 'gsap';
import Timer from '../../../Timer/Timer';
import Hint from '../../Hint/Hint';

type MultipleChoiceProps = {
    options: IOption[];
    user_answers?: IOption[];
    elementData: IElement;
    onQuestionAnswered?: (element: IElement, userAnswer: IUserAnswer) => void;
    loaded?: (element: IElement) => void;
    sessionAnswer?: IUserAnswer | null;
};

const MultipleChoice: FunctionComponent<MultipleChoiceProps> = ({
    options,
    user_answers,
    elementData,
    onQuestionAnswered,
    sessionAnswer,
}) => {
    const [element, setElement] = useState<IElement>(elementData);
    const { t } = useTranslation();
    const { deck, setCurrentCardEdge, isHistory } = useDeck();
    const { state: timerState, setTimer, startTimer, pauseTimer, stopTimer, resetTimer } = useTimer();
    const [timerHasStarted, setTimerHasStarted] = useState(false);
    // const timerLabelRef = useRef<HTMLDivElement>(null);
    // const tlTimerDanger = useRef<gsap.core.Timeline>();

    const outOfTime =
        (sessionAnswer && !sessionAnswer.within_time) ||
        timerState.originalSeconds - timerState.seconds >= timerState.originalSeconds;

    const answerTime =
        sessionAnswer != undefined && sessionAnswer.answer_time
            ? sessionAnswer.answer_time
            : timerState.originalSeconds - timerState.seconds;
    const answerSeconds = answerTime % 60;
    const answerMinutes = (answerTime - answerSeconds) / 60;

    useEffect(() => {
        setElement(elementData);
        if (elementData.elementable?.timer) setTimer(elementData.elementable?.timer);
    }, [elementData]);

    useEffect(() => {
        if (sessionAnswer && user_answers?.length === 0) {
            setIsSaved(true);
        }
    }, [sessionAnswer]);

    const [answers, setAnswers] = useState<number[]>(user_answers ? user_answers.map((answer) => answer.id) : []);
    const [feedback, setFeedback] = useState(user_answers ? _.cloneDeep(user_answers) : []);
    const [ops, setOps] = useState<IOption[]>(
        options.map((option) => {
            const found = answers.find((answer) => answer === option.id);
            option.is_selected = found ? true : false;

            return option;
        }),
    );

    const multipleAnswersPossible = ops.length > 1;
    const multipleCorrectAnswersPossible =
        multipleAnswersPossible &&
        ops.filter((option) => {
            return option.is_correct;
        }).length > 1;

    const handleQuestionAnswered = (element: IElement, userAnswer: IUserAnswer) => {
        const options = userAnswer.options;
        setAnswers(options ? options?.map((answer) => answer.id) : []);
        onQuestionAnswered?.(element, userAnswer);
    };

    const { handleSubmit, isSaved, setIsSaved } = useMultipleChoiceQuestionFormHook(
        { answers: answers },
        deck.id,
        element,
        multipleAnswersPossible,
    );
    const disabledSubmit = answers.length === 0;

    const handleClick = (optionId: number) => {
        let changedOps = _.cloneDeep(ops);

        changedOps = options.map((option) => {
            if (multipleCorrectAnswersPossible) {
                if (option.id === optionId) {
                    return { ...option, is_selected: !option.is_selected };
                } else {
                    return option;
                }
            } else {
                option.is_selected = option.id === optionId;

                if (option.is_selected) {
                    // Select new card edge
                    if (option.action_of_edges.length > 0) {
                        setCurrentCardEdge(option.action_of_edges[0]);
                    }
                }

                return { ...option };
            }
        });

        setOps(_.cloneDeep(changedOps));

        // Save selected options as answers
        const answers = _.cloneDeep(changedOps.filter((option) => option.is_selected));

        setAnswers(answers.map((option) => option.id));
        setFeedback(answers);
    };

    const handleSubmitEvent = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        if (elementData.elementable?.timer !== '00:00') {
            stopTimer();
        }

        e.preventDefault();

        await handleSubmit({ answers }).then((response) => {
            handleQuestionAnswered(element, response.data as IUserAnswer);
            setIsSaved(true);
        });
    };

    const handleTimeUp = () => {
        setIsSaved(true);

        handleSubmit({ answers: answers }).then((response) => {
            handleQuestionAnswered(element, response.data as IUserAnswer);
        });
    };

    return (
        <div className={`${classes.MultipleChoiceQuestion}`}>
            <>
                <div className={classes.Header}>
                    <h3 className={`h5 ${classes.Body}`}>{element.elementable?.body}</h3>

                    {elementData.elementable?.timer_enabled && (
                        <Timer
                            timer={elementData.elementable?.timer}
                            isSaved={isSaved}
                            isHistory={isHistory}
                            timerHasStarted={timerHasStarted}
                            onTimeUp={handleTimeUp}
                        />
                    )}
                </div>

                {!isHistory &&
                    elementData.elementable?.timer_enabled &&
                    timerHasStarted &&
                    timerState.timerRunning &&
                    elementData.elementable?.timer !== '00:00' && (
                        <TimerBar
                            progress={timerState.originalSeconds}
                            start={timerHasStarted}
                            isRunning={timerState.timerRunning}
                            completed={false}
                            timeState={'awaiting'}
                            isSaved={isSaved}
                        ></TimerBar>
                    )}

                {!isHistory &&
                    elementData.elementable?.timer_enabled &&
                    !timerHasStarted &&
                    elementData.elementable?.timer !== '00:00' &&
                    !timerState.timerRunning &&
                    !sessionAnswer && (
                        <Button
                            className={classes.StartButton}
                            text="start timer"
                            onClick={() => {
                                setTimerHasStarted(true);
                                startTimer();
                            }}
                        ></Button>
                    )}
                <form
                    onSubmit={handleSubmitEvent}
                    className={`${classes.QuestionForm} ${
                        !isHistory &&
                        elementData.elementable?.timer_enabled &&
                        !timerHasStarted &&
                        elementData.elementable?.timer !== '00:00' &&
                        !timerState.timerRunning &&
                        !sessionAnswer &&
                        classes.blur
                    }`}
                >
                    {
                        <OptionList
                            options={ops}
                            handleClick={handleClick}
                            multipleAnswersPossible={multipleAnswersPossible}
                            disabled={isSaved}
                            isSaved={isSaved}
                            correctEnabled={element.elementable?.is_correct_enabled}
                            outOfTime={outOfTime && (answers.length === 0 || user_answers?.length == 0)}
                        ></OptionList>
                    }
                    <div className={`${classes.formActions}`}>
                        <Hint
                            isSaved={isSaved}
                            timerFeedback={
                                element.elementable != null && element.elementable.timer_enabled === true && !outOfTime
                            }
                            outOfTime={outOfTime}
                            minutes={answerMinutes}
                            seconds={answerSeconds}
                        />

                        {!isSaved ? (
                            <button
                                className={`${classes.submitButton} btn btn-flavour btn-flavour--danger`}
                                type="submit"
                                disabled={disabledSubmit}
                            >
                                {t('Common:SUBMIT')}
                            </button>
                        ) : (
                            <div className={classes.Saved}>
                                <CheckmarkIcon></CheckmarkIcon>
                                <span>{t('Common:SAVED')}</span>
                            </div>
                        )}
                    </div>
                </form>
                {isSaved && element.elementable?.feedback_enabled === true && feedback.length > 0 && (
                    <div className={`${classes.Feedback}`}>
                        {element.elementable?.is_correct_enabled === true && (
                            <h2 className={`${feedback[0].is_correct ? classes.correct : classes.incorrect}`}>
                                {feedback[0].is_correct ? t('Deck:CORRECT') : t('Deck:INCORRECT')}
                            </h2>
                        )}

                        {feedback[0].feedback && <span>{feedback[0].feedback}</span>}
                    </div>
                )}
            </>
        </div>
    );
};

export default MultipleChoice;
