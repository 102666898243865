import {
    faAlignLeft,
    faClipboard,
    faFilePdf,
    faImage,
    faListCheck,
    faPlusCircle,
    faQuestion,
    faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Menu, MenuItem, useTheme, withStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeck } from '../../../../context/DeckContext';
import Button from '../../../Button/Button';
import { ElementType, elementTypes } from '../../ElementTypes/ElementTypes';
import classes from './ElementAddButton.module.scss';

interface ElementAddButtonProps {
    addElementButtonHandler: (type: ElementType) => void;
}

const ElementAddButton: FunctionComponent<ElementAddButtonProps> = (props) => {
    const { t } = useTranslation();
    const { deck } = useDeck();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const theme = useTheme();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickElementTypeButton = (type: ElementType) => {
        props.addElementButtonHandler(type);
        handleClose();
    };

    const elementTypesList = elementTypes.map((elementType, index) => {
        return (
            <MenuItem
                key={index}
                onClick={() => onClickElementTypeButton(elementType.type)}
                disabled={deck.is_published && !elementType.showPublished}
            >
                {/* <FontAwesomeIcon icon={faPlusCircle} /> */}

                {t(`Common:${elementType.title}`)}
            </MenuItem>
        );
    });

    const MenuGroupItem = withStyles({
        root: {
            paddingLeft: theme.spacing(2),

            // opacity: 0.5,
        },
    })(MenuItem);

    const MenuGroupNameBase = (props) => <MenuItem {...props} disabled />;

    const MenuGroupName = withStyles({
        root: {
            fontWeight: theme.typography.fontWeightBold,
            opacity: '1 !important',
            // backgroundColor: theme.palette.primary.main,
            // color: theme.palette.primary.contrastText,
        },
    })(MenuGroupNameBase);

    const MenuGroupDivider = withStyles({
        root: {
            height: '2px !important',
            backgroundColor: 'rgba(0, 0, 0, 0.32)',
            marginBlock: '0.5rem 0.5rem',
        },
    })(Divider);

    return (
        <>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className={classes.ElementAddButton}
                text={t('Common:ADD_ELEMENT_BUTTON_LABEL')}
                alt
                icon={<FontAwesomeIcon icon={faPlusCircle} />}
                iconSide="left"
                onClick={handleClick}
            ></Button>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {/* {elementTypesList} */}
                <div>
                    <MenuGroupName>Media</MenuGroupName>
                </div>
                <div>
                    <MenuGroupItem
                        className={classes.MenuItem}
                        onClick={() => onClickElementTypeButton(ElementType.Text)}
                    >
                        <FontAwesomeIcon icon={faAlignLeft} />
                        {t(`Common:ELEMENT_TYPE_${ElementType.Text.toUpperCase()}`)}
                    </MenuGroupItem>
                </div>
                <div>
                    <MenuGroupItem
                        className={classes.MenuItem}
                        onClick={() => onClickElementTypeButton(ElementType.Pdf)}
                    >
                        <FontAwesomeIcon icon={faFilePdf} />
                        {t(`Common:ELEMENT_TYPE_${ElementType.Pdf.toUpperCase()}`)}
                    </MenuGroupItem>
                </div>
                <div>
                    <MenuGroupItem
                        className={classes.MenuItem}
                        onClick={() => onClickElementTypeButton(ElementType.Image)}
                    >
                        <FontAwesomeIcon icon={faImage} />
                        {t(`Common:ELEMENT_TYPE_${ElementType.Image.toUpperCase()}`)}
                    </MenuGroupItem>
                </div>
                <div>
                    <MenuGroupItem
                        className={classes.MenuItem}
                        onClick={() => onClickElementTypeButton(ElementType.Video)}
                    >
                        <FontAwesomeIcon icon={faVideo} />
                        {t(`Common:ELEMENT_TYPE_${ElementType.Video.toUpperCase()}`)}
                    </MenuGroupItem>
                </div>
                <MenuGroupDivider className={classes.MenuGroupDivider} />
                <div>
                    <MenuGroupName className={classes.MenuGroupName}>
                        {/* <FontAwesomeIcon icon={faQuestion} /> */}
                        User input
                    </MenuGroupName>
                </div>
                <div>
                    <MenuGroupItem
                        className={classes.MenuItem}
                        onClick={() => onClickElementTypeButton(ElementType.Open)}
                        disabled={deck.is_published}
                    >
                        <FontAwesomeIcon icon={faQuestion} />
                        {t(`Common:ELEMENT_TYPE_${ElementType.Open.toUpperCase()}`)}
                    </MenuGroupItem>
                </div>
                <div>
                    <MenuGroupItem
                        className={classes.MenuItem}
                        onClick={() => onClickElementTypeButton(ElementType.MultipleChoice)}
                        disabled={deck.is_published}
                    >
                        <FontAwesomeIcon icon={faListCheck} />
                        {t(`Common:ELEMENT_TYPE_${ElementType.MultipleChoice.toUpperCase()}`)}
                    </MenuGroupItem>
                </div>
                <div>
                    <MenuGroupItem
                        className={classes.MenuItem}
                        onClick={() => onClickElementTypeButton(ElementType.Poll)}
                        disabled={deck.is_published}
                    >
                        <FontAwesomeIcon icon={faClipboard} />
                        {t(`Common:ELEMENT_TYPE_${ElementType.Poll.toUpperCase()}`)}
                    </MenuGroupItem>
                </div>
            </Menu>
        </>
    );
};

export default ElementAddButton;
