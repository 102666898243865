import { faCircleNotch, faCodeBranch, faPlusCircle, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../../../components/Button/Button';
import { CardCompleted } from '../../../components/Deck/DeckDetails';
import { useAuth } from '../../../context/AuthContext';
import { useDeck } from '../../../context/DeckContext';
import useSticky from '../../../hooks/useSticky';
import { ICard } from '../../../models/Card';
import { IDeck } from '../../../models/Deck';
import classes from './DeckActions.module.scss';

interface Props {
    isCreate: boolean;
    isPublished: boolean;
    isSubmitting: boolean;
    currentCardProgress: CardCompleted;
    deckCompleted: boolean;
    clickViewHandler?: () => void;
    clickPublishHandler: (publish: boolean) => void;
    gotoAndViewHandler: (deck: IDeck, currentCardIndex: number) => void;
    onAddCardHandler: (title?: string) => Promise<ICard | undefined>;
    openCardFlow: () => void;
}

const DeckActions: React.FC<Props> = ({
    isCreate,
    isPublished,
    isSubmitting,
    clickPublishHandler,
    gotoAndViewHandler,
    onAddCardHandler,
    openCardFlow,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isSticky, trackStickyEvent] = useSticky(ref);
    const { deck, currentCardIndex } = useDeck();
    const { t } = useTranslation();
    const { submitForm } = useFormikContext();
    const { hasPermission } = useAuth();
    const canManage = hasPermission('library.manage') && isDesktop;
    const history = useHistory();

    useEffect(() => {
        trackStickyEvent();
    }, [currentCardIndex, deck]);

    const onViewDeckClickHandler = () => {
        handleSubmit();
        history.push({ pathname: `/deck/${deck.id}` });
        // gotoAndViewHandler(deck, currentCardIndex);
    };

    const handleSubmit = () => {
        submitForm();
    };

    const viewButton = (
        <>
            {!isCreate && (
                <Button
                    className={`${classes.ViewButton}`}
                    text={`${t('Common:DECK')}`}
                    alt={true}
                    disabled={isSubmitting}
                    icon={
                        isSubmitting ? (
                            <FontAwesomeIcon className={classes.ButtonLoadingIcon} icon={faCircleNotch} spin={true} />
                        ) : (
                            <FontAwesomeIcon className={classes.PlusIcon} icon={faUpRightFromSquare} />
                        )
                    }
                    iconSide="left"
                    onClick={onViewDeckClickHandler}
                />
            )}
        </>
    );

    const publishButton = (
        <>
            {!isPublished && (
                <Button
                    className={`${classes.PublishButton}`}
                    text={t('Common:BUTTON_PUBLISH')}
                    danger={true}
                    disabled={isSubmitting}
                    iconSide="left"
                    onClick={() => clickPublishHandler(true)}
                />
            )}
        </>
    );

    const publishedStateLabel = (
        <div className={classes.PublishedStateLabel}>
            ({isPublished ? t('Common:DECK_PUBLISED_STATE_LABEL') : t('Common:DECK_CONCEPT_STATE_LABEL')})
        </div>
    );

    const infoContainer = (
        <div className={`${classes.InfoContainer} ${canManage && classes.canManage}`}>
            <div className={classes.TitleContainer}>{publishedStateLabel}</div>
        </div>
    );

    const viewFlowButton = (
        <Button
            className={`${classes.ViewFlowButton}`}
            text={t('Common:BUTTON_VIEW_FLOW')}
            alt
            icon={<FontAwesomeIcon icon={faCodeBranch} />}
            iconSide="left"
            onClick={openCardFlow}
        />
    );

    const addCardButton = (
        <Button
            className={`${classes.NewCardButton}`}
            text={t('Common:CARD')}
            alt={true}
            disabled={isSubmitting}
            icon={
                isSubmitting ? (
                    <FontAwesomeIcon className={classes.ButtonLoadingIcon} icon={faCircleNotch} spin={true} />
                ) : (
                    <FontAwesomeIcon className={classes.PlusIcon} icon={faPlusCircle} />
                )
            }
            iconSide="left"
            onClick={() => onAddCardHandler()}
        />
    );

    const actionbarButtons = (
        <>
            <div className={classes.LeftActions}>{infoContainer}</div>

            <div className={classes.RightActions}>
                {viewFlowButton}
                {publishButton}
                {viewButton}
                {!deck.is_published && addCardButton}
            </div>
        </>
    );

    const actionbarSticky = (
        <div
            className={`${classes.ActionbarSticky} ${isSticky && classes.isSticky} ${
                isPublished ? classes.published : classes.concept
            }`}
        >
            {actionbarButtons}
        </div>
    );

    const actionbar = (
        <div
            className={`${classes.Actionbar} ${isSticky && classes.hidden} ${
                isPublished ? classes.published : classes.concept
            }`}
        >
            {actionbarButtons}
        </div>
    );

    return (
        <div className={`${classes.DeckActions}`} ref={ref}>
            {actionbar}
            {actionbarSticky}
        </div>
    );
};

export default DeckActions;
