import React, { FunctionComponent } from 'react';
import { IElement, IUserAnswer } from '../../../models/Element';
import ImageElement from './ImageElement/ImageElement';
import PDFElement from './PDFElement/PDFElement';
import VideoElement from './VideoElement/VideoElement';

export interface QuestionElementProps {
    element: IElement;
    isEdit: boolean;
    isReorder: boolean;
    onQuestionAnswered?: (element: IElement, userAnswer: IUserAnswer) => void;
    updateElement?: (element: IElement) => void;
    deleteHandler: (element: IElement) => void;
    loaded?: (element: IElement) => void;
}

const MediaElement: FunctionComponent<QuestionElementProps> = ({
    element,
    isEdit,
    isReorder,
    deleteHandler,
    loaded,
    updateElement,
}) => {
    switch (element.elementable?.type) {
        case 'video':
            return (
                <VideoElement
                    elementData={element}
                    deleteHandler={deleteHandler}
                    isEdit={isEdit}
                    isReorder={isReorder}
                    loaded={loaded}
                    updateElement={(element: IElement) => updateElement?.(element)}
                ></VideoElement>
            );
        case 'image':
            return (
                <ImageElement
                    elementData={element}
                    deleteHandler={deleteHandler}
                    isEdit={isEdit}
                    isReorder={isReorder}
                    loaded={loaded}
                    updateElement={(element: IElement) => updateElement?.(element)}
                ></ImageElement>
            );
        case 'pdf':
            return (
                <PDFElement
                    elementData={element}
                    deleteHandler={deleteHandler}
                    isEdit={isEdit}
                    isReorder={isReorder}
                ></PDFElement>
            );
        default:
            return <div>{`This is an unknown media element: ${element.elementable?.type}`}</div>;
    }
};

export default MediaElement;
