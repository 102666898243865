import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover from '@material-ui/core/Popover';
import React, { FunctionComponent } from 'react';
import Button from '../../../../components/Button/Button';
import { IGroup } from '../../../../models/Group';
import GroupActions from './GroupActions/GroupActions';
import classes from './GroupActionsPopover.module.scss';

export interface IGroupActionsProps {
    group: IGroup;
    onEditHandler: (group: IGroup) => void;
    onDeleteHandler: (group: IGroup) => void;
}

const GroupActionsPopover: FunctionComponent<IGroupActionsProps> = ({ group, onEditHandler, onDeleteHandler }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'deck-edit-menu-popover' : undefined;

    return (
        <div className={classes.TagActionsPopover}>
            <Button
                aria-controls="deck-edit-menu"
                aria-haspopup="true"
                text=""
                icon={<FontAwesomeIcon icon={faEllipsis} />}
                onClick={handleClick}
            />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <GroupActions group={group} onEditHandler={onEditHandler} onDeleteHandler={onDeleteHandler} />
                {/* <TagActions
                    tag={tag}
                    onEditHandler={onEditHandler}
                    onDeleteHandler={() => {
                        handleClose();
                        onDeleteHandler(tag);
                    }}
                /> */}
            </Popover>
        </div>
    );
};

export default GroupActionsPopover;
