import { FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useConfig } from '../../../context/ConfigContext';
import { UserRole } from '../../../models/User';
import axiosInstance from '../../../utils/axios-instance';

interface FormFields {
    name: string;
}

export type ReturnType = {
    handleSubmit: (role: UserRole, formFields: FormFields, actions: FormikHelpers<FormFields>) => Promise<void>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
};

const useRoleEditForm = (initialValues: FormFields): ReturnType => {
    const { config } = useConfig();
    const [isSaved, setIsSaved] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();

    const notifyCreated = () => toast.success(t('Common:TOAST_ROLE_CREATED_SUCCESS'));

    const handleSubmit = useCallback((role: UserRole, formFields: FormFields, actions: FormikHelpers<FormFields>) => {
        return axiosInstance.post(`${config.baseUrl}/roles`, { ...formFields }).then(() => {
            setIsSaved(true);
            actions.resetForm({ values: formFields });
            history.goBack();
            notifyCreated();
        });
    }, []);

    return {
        handleSubmit,
        initialValues,
        isSaved,
        setIsSaved,
    };
};

export default useRoleEditForm;
