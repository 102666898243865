import { faFileLines, faFont, faImages, faPalette } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gsap } from 'gsap';
import React, { Fragment, FunctionComponent, useRef, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
// Icons
import PageHeader from '../../components/PageHeader/PageHeader';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import { useAuth } from '../../context/AuthContext';
import classes from './Customization.module.scss';
import CustomizationCopy from './CustomizationCopy/CustomizationCopy';
import CustomizationCover, { IncrementedRef } from './CustomizationCover/CustomizationCover';
import CustomizationFonts from './CustomizationFonts/CustomizationFonts';
import CustomizationImages from './CustomizationImages/CustomizationImages';

const Customization: FunctionComponent = () => {
    const { path } = useRouteMatch();
    const { t } = useTranslation();
    const { hasPermission } = useAuth();
    const tl = gsap.timeline();
    const list = useRef<IncrementedRef[]>([]);
    const [refOnce, setRefOnce] = useState(false);

    const setRefs = (target: IncrementedRef | null, listReference: IncrementedRef[], itemIndex: number) => {
        if (target && listReference && !refOnce) {
            const ref = (list.current[itemIndex] = target);
            if (itemIndex === 3) {
                // All refs are set for this list. So animation is possible.
                animateInList(listReference);
                setRefOnce(true);
            }
            return ref;
        }
    };

    const animateInList = (listReference: IncrementedRef[]) => {
        const l = listReference.map((el) => el.current);
        tl.set(l, { alpha: 0, scale: 0.7 });
        tl.to(l, {
            duration: 0.25,
            alpha: 1,
            scale: 1,
            ease: 'back',
            stagger: {
                amount: 0.35,
            },
        });
    };

    return (
        <Fragment>
            <div className={classes.Customization}>
                <PageHeader title={t('Common:CUSTOMIZATION_PAGE_HEADER')} />

                <nav className={classes.Navigation}>
                    {hasPermission('tenant.customise') && isDesktop && (
                        <NavLink className={`${classes.NavLink}`} to="/custom/fonts">
                            <CustomizationCover
                                label={t('Common:CUSTOMIZATION_FONTS_HEADER')}
                                ref={(target) => {
                                    setRefs(target, list.current, 0);
                                }}
                            >
                                <FontAwesomeIcon className={classes.Icon} icon={faFont} />
                            </CustomizationCover>
                        </NavLink>
                    )}

                    {hasPermission('tenant.customise') && isDesktop && (
                        <NavLink className={`${classes.NavLink}`} to="/custom/colors">
                            <CustomizationCover
                                label={t('Common:CUSTOMIZATION_COLORS_HEADER')}
                                ref={(target) => {
                                    setRefs(target, list.current, 0);
                                }}
                            >
                                <FontAwesomeIcon className={classes.Icon} icon={faPalette} />
                            </CustomizationCover>
                        </NavLink>
                    )}

                    {hasPermission('tenant.customise') && isDesktop && (
                        <NavLink className={`${classes.NavLink}`} to="/custom/images">
                            <CustomizationCover
                                label={t('Common:CUSTOMIZATION_IMAGES_HEADER')}
                                ref={(target) => {
                                    setRefs(target, list.current, 0);
                                }}
                            >
                                <FontAwesomeIcon className={classes.Icon} icon={faImages} />
                            </CustomizationCover>
                        </NavLink>
                    )}

                    {hasPermission('tenant.customise') && isDesktop && (
                        <NavLink className={`${classes.NavLink}`} to="/custom/copy">
                            <CustomizationCover
                                label={t('Common:CUSTOMIZATION_TEXTS_HEADER')}
                                ref={(target) => {
                                    setRefs(target, list.current, 0);
                                }}
                            >
                                <FontAwesomeIcon className={classes.Icon} icon={faFileLines} />
                            </CustomizationCover>
                        </NavLink>
                    )}
                </nav>
            </div>
            <Switch>
                <PrivateRoute
                    path={`${path}/fonts`}
                    component={CustomizationFonts}
                    exact={false}
                    permissions={['tenant.customise']}
                />
                <PrivateRoute
                    path={`${path}/colors`}
                    component={CustomizationFonts}
                    exact={false}
                    permissions={['tenant.customise']}
                />
                <PrivateRoute
                    path={`${path}/images`}
                    component={CustomizationImages}
                    exact={false}
                    permissions={['tenant.customise']}
                />
                <PrivateRoute
                    path={`${path}/copy`}
                    component={CustomizationCopy}
                    exact={false}
                    permissions={['tenant.customise']}
                />

                <Redirect from={path} to={`${path}/fonts`} />
            </Switch>
        </Fragment>
    );
};

export default Customization;
