import { AxiosResponse } from 'axios';
import { FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { useDeck } from '../../../context/DeckContext';
import { IFork } from '../../../models/Element';
import { IOption } from '../../../models/Option';
import useForkService from '../../../services/ForkService';

export interface FormFields {
    navigation_type: string;
    body: string;
    options?: IOption[];
}

export type ReturnType = {
    handleSubmit: (formFields: FormFields, actions: FormikHelpers<FormFields>, fork?: IFork) => Promise<IFork>;
    handleDelete: (fork: IFork) => Promise<AxiosResponse<any>>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
    isSubmitting: boolean;
};

const useForkEditForm = (initialValues: FormFields): ReturnType => {
    const [isSaved, setIsSaved] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { create, update, remove } = useForkService();
    const { currentCard } = useDeck();

    const handleSubmit = useCallback((formFields: FormFields, actions: FormikHelpers<FormFields>, fork?: IFork) => {
        // setIsSubmitting(true);

        const jsonString = JSON.stringify(
            formFields.options?.map((option) => {
                const op = { ...option };
                op.is_correct = null;
                return op;
            }),
        );

        const body = {
            ...formFields,
            options: jsonString,
        };

        if (fork) {
            return update(fork.id, body).then((response) => {
                setIsSaved(true);
                // setIsSubmitting(false);

                return response.data;
            });
        } else {
            if (currentCard) {
                return create({
                    card_id: currentCard.id,
                    navigation_type: 'next',
                    body: formFields.body,
                    options: jsonString,
                }).then((response) => {
                    setIsSaved(true);
                    return response.data;
                });
            } else {
                setIsSaved(true);
                return Promise.reject('No current card found');
            }
        }
    }, []);

    const handleDelete = useCallback((fork: IFork) => {
        return remove(fork.id);
    }, []);

    return {
        handleSubmit,
        handleDelete,
        initialValues,
        isSaved,
        setIsSaved,
        isSubmitting,
    };
};

export default useForkEditForm;
