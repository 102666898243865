import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';
import { IDeck } from '../../models/Deck';
import { TimeState } from '../../models/Quest';
import RedoCircleIcon from '../Icons/RedoCircleIcon/RedoCircleIcon';
import PublicationStateLabel from '../PublicationStateLabel/PublicationStateLabel';
import classes from './TaskItem.module.scss';
export interface TaskItemProps {
    task: IDeck;
    index: number;
    timeState: TimeState;
    toContinue: boolean;
    clickHandler: (task: IDeck) => void;
}

const TaskItem: FunctionComponent<TaskItemProps> = ({ task, timeState, toContinue, clickHandler }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const isEnded = timeState === 'ended';

    const taskCompleted = task.deckSession?.is_complete;
    const isReplay = task.deckSession?.is_replay;

    const { hasPermission } = useAuth();
    const canManage = hasPermission('library.manage');

    const clickable = canManage || taskCompleted || (timeState === 'in_progress' && !taskCompleted && toContinue);

    return (
        <div className={classes.TaskItemContainer}>
            <div
                className={`${classes.TaskItem} ${clickable && classes['clickable']} ${isEnded && classes['ended']} ${
                    !task.is_published && classes['concept']
                } ${taskCompleted && classes['completed']}  ${
                    !taskCompleted && (toContinue ? classes['toContinue'] : classes['noContinue'])
                } `}
                ref={containerRef}
                onClick={() => clickable && clickHandler(task)}
            >
                <div className={`${classes.LabelAndBar}`}>
                    <div className={classes.LabelAndState}>
                        <label className={`${classes.TaskLabel} ${taskCompleted && classes['completed']}`}>
                            <span className={classes.TaskTitle}>{task.title}</span>
                            {hasPermission('concept.view') && (
                                <PublicationStateLabel isPublished={task.is_published} inline={true} />
                            )}
                        </label>
                    </div>
                    {!taskCompleted ? (
                        <div className={classes.TaskProgress}>
                            {isReplay ? (
                                <RedoCircleIcon></RedoCircleIcon>
                            ) : toContinue ? (
                                <FontAwesomeIcon className={classes.CheckMarkIcon} icon={faChevronCircleRight} />
                            ) : (
                                <FontAwesomeIcon className={classes.CheckMarkIcon} icon={faCircle} />
                            )}
                        </div>
                    ) : (
                        <FontAwesomeIcon className={classes.CheckMarkIcon} icon={faCheckCircle} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default TaskItem;
