import { RefObject, useEffect, useLayoutEffect, useState } from 'react';

const useSticky = <T extends HTMLElement = HTMLElement>(elementRef: RefObject<T>): [boolean, () => void] => {
    const [isSticky, setIsSticky] = useState<boolean>(false);
    const [top, setTop] = useState(0);
    const [height, setHeight] = useState(0);
    const [c, setC] = useState(0);

    const trackStickyEvent = () => {
        const { top, height } = elementRef.current?.getBoundingClientRect() || { top: 0, height: 0 };
        setTop(top);
        setHeight(top);

        const c = top + height - window.innerHeight;
        setC(c);
    };

    useEffect(() => {
        trackStickyEvent();
        if (c > 0) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    }, [top, height, c]);

    useEffect(() => {
        const node = elementRef.current;
        if (node && node.getBoundingClientRect()) {
            window.addEventListener('scroll', trackStickyEvent);
        }

        return () => {
            window.removeEventListener('scroll', trackStickyEvent);
        };
    }, [elementRef]);

    useLayoutEffect(() => {
        trackStickyEvent();
    }, [elementRef]);

    return [isSticky, trackStickyEvent];
};

export default useSticky;
