import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '../../../components/Button/Button';
import ButtonLoadingIcon from '../../../components/Icons/ButtonLoadingIcon/ButtonLoadingIcon';
import InputField from '../../../components/InputField/InputField';
import Message from '../../../components/Message/Message';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigContext';
import { getFilePathByName, getFilePathForDefaultImage } from '../../../models/File';
import { User } from '../../../models/User';
import { default as Axios, default as axiosInstance } from '../../../utils/axios-instance';
import { getColor } from '../../../utils/colors';
import { setLocalStorageUser } from '../../../utils/flavourstorage';
import { VerificationData } from '../../EmailValidation/EmailValidation';
import GoogleButton from '../GoogleButton/GoogleButton';
import Welcome from '../Welcome/Welcome';
import classes from './LoginForm.module.scss';

export interface LoginResponse {
    user: User;
    token: string;
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LoginForm: FunctionComponent = () => {
    const { config, loadingConfig, loading } = useConfig();
    const history = useHistory();
    const location = useLocation<VerificationData>();
    const { autoLogin } = useAuth();
    const [isError, setIsError] = useState(false);
    const [errorStatus, setErrorStatus] = useState<number | undefined>();
    const { t } = useTranslation();
    const buttonLoadingIconColor = getColor('--brand-color-12');
    const [defaultWelcome, setDefaultWelcome] = useState('');
    const [verificationData, setVerificationData] = useState<VerificationData | null>();

    const googleId = useQuery().get('google_id');

    useEffect(() => {
        if (googleId != null) {
            axiosInstance.post(`${config.baseUrl}/login/google`, { google_id: googleId }).then((response) => {
                const loginResponse: LoginResponse = response.data;
                loginUser(loginResponse);
            });
        }
    }, []);

    useEffect(() => {
        if (!loadingConfig) {
            const customFile = config.tenant.images['welcome.png']?.find((file) => file.is_default == false);
            const defaultFile = config.tenant.images['welcome.png']?.find((file) => file.is_default == true);
            setDefaultWelcome(
                getFilePathByName(customFile?.file?.name) || getFilePathForDefaultImage(defaultFile?.file?.name) || '',
            );
        }
    }, [loadingConfig]);

    useEffect(() => {
        if (location.state?.verificationData) {
            setVerificationData(location.state);
        }
    }, [location]);

    const schema = Yup.object({
        email: Yup.string()
            .email(t('Common:INPUT_ERROR_EMAIL_INVALID'))
            .required(t('Common:INPUT_ERROR_EMAIL_REQUIRED'))
            .max(255, t('Common:INPUT_ERROR_EMAIL_MAX', { max: 255 })),
        password: Yup.string().required(t('Common:INPUT_ERROR_PASSWORD_REQUIRED')),
    });

    const error =
        errorStatus == 403 ? (
            <div className={classes.formFeedback}>
                <Message text={t('Common:ACCOUNT_INACTIVE_MESSAGE')}></Message>
            </div>
        ) : (
            <div className={classes.formFeedback}>
                <Message text={t('Auth:INPUT_ERROR_CREDENTIAL_MISMATCH')}></Message>
            </div>
        );

    const notify = () => toast.success(t('Common:TOAST_LOGIN_SUCCESS'));

    const registerButtonHandler = () => {
        history.push('/register');
    };

    const accessCodeButtonHandler = () => {
        history.push('/register/accesscode');
    };

    const loginUser = (loginResponse: LoginResponse) => {
        if (loginResponse) {
            const user: User = {
                ...loginResponse.user,
                token: loginResponse.token,
            };

            setLocalStorageUser(user);
            autoLogin(user);

            Axios.defaults.headers = { Authorization: `Bearer ${loginResponse.token}` };

            if (user.email_verified) {
                notify();
                history.replace('/home');
            } else {
                if (verificationData?.verificationData) {
                    const { userId, token, expires, signature } = verificationData.verificationData;
                    history.replace(`/email/validation/${signature}/${userId}/${token}/${expires}`);
                } else {
                    history.replace(`/email/validation/000/000/000/000`);
                }
            }
        }
    };

    const onLoginSubmit = (values, setSubmitting) => {
        setSubmitting(true);
        Axios.post(`${config.baseUrl}/login`, values)
            .then((response) => {
                setSubmitting(false);
                setIsError(false);
                setErrorStatus(undefined);

                const loginResponse: LoginResponse = response.data;
                loginUser(loginResponse);
            })
            .catch((error) => {
                const { status } = error?.response as {
                    status: number;
                    statusText: string;
                    data: any;
                };

                setErrorStatus(status);

                setSubmitting(false);
                setIsError(true);
            });
    };

    return (
        <>
            <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting }) => {
                    onLoginSubmit(values, setSubmitting);
                }}
            >
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        isValid,
                        dirty,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    } = props;

                    const emailErrorClassName = !!errors.email && !!touched.email ? classes.error : '';
                    const passwordErrorClassName = !!errors.password && !!touched.password ? classes.error : '';
                    const disabledSubmit = isSubmitting || !(isValid && dirty);

                    return (
                        <form className={classes.Form} noValidate={true} onSubmit={handleSubmit}>
                            <div className={`${classes.Login} Box`}>
                                <img src={defaultWelcome} alt="loginImage" />
                                <div className={classes.LoginContent}>
                                    <Welcome></Welcome>

                                    <div className={`${classes.LoginForm}`}>
                                        <div className={classes.formGroup}>
                                            <InputField
                                                id="email"
                                                name="email"
                                                type="email"
                                                label="Email"
                                                autoComplete="username"
                                                required
                                                autoFocus
                                                autoCorrect="off"
                                                autoCapitalize="none"
                                                placeholder={t('Common:EMAIL_PLACEHOLDER')}
                                            />
                                        </div>

                                        <div className={classes.formGroup}>
                                            <InputField
                                                id="password"
                                                name="password"
                                                type="password"
                                                label="Password"
                                                autoComplete="current-password"
                                                required
                                                placeholder={t('Common:PASSWORD_PLACEHOLDER')}
                                            />
                                        </div>

                                        {isError && error}

                                        <button
                                            className={`${classes.submitButton} btn btn-flavour btn-flavour--alert`}
                                            type="submit"
                                            disabled={disabledSubmit}
                                        >
                                            {isSubmitting && <ButtonLoadingIcon fill={buttonLoadingIconColor} />}
                                            {t('Login:BUTTON_LOGIN')}
                                        </button>

                                        <NavLink className={`${classes.NavLink}`} to="/password/request">
                                            {t('Login:FORGOT_PASSWORD')}
                                        </NavLink>
                                    </div>

                                    <div className={classes.OrSeparator}>
                                        <span className={classes.OrText}>{t('Common:OR')}</span>
                                    </div>

                                    <Button
                                        className={`btn btn-flavour btn-flavour--border btn-flavour--login-alt`}
                                        type="button"
                                        text={t('Common:BUTTON_REGISTER_ACCESS_CODE')}
                                        icon={<FontAwesomeIcon icon={faKey} />}
                                        iconSide={'left'}
                                        onClick={accessCodeButtonHandler}
                                    />

                                    {!loading && config.tenant.is_public && (
                                        <>
                                            {/* <GoogleButton /> */}

                                            <div className={classes.PublicAndRegister}>
                                                <NavLink className={`${classes.NavLink}`} to="/register">
                                                    {t('Login:BUTTON_GO_TO_REGISTER')}
                                                </NavLink>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className={`${classes.formActions} ${classes.flexGap}`}></div>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
};

export default LoginForm;
