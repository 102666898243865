import React, { FunctionComponent, useEffect, useLayoutEffect, useRef } from 'react';
import classes from './TimerBar.module.scss';
import { gsap } from 'gsap';
import { TimeState } from '../../models/Quest';

export interface TimerBarProps {
    progressLabel?: string;
    upperBoundsLabel?: string;
    progress: number;
    start: boolean;
    isRunning: boolean;
    completed: boolean;
    timeState: TimeState;
    styleClass?: string;
    styles?: {
        height?: string;
        width?: string;
        background?: string;
        backgroundGradient?: boolean;
        fill?: string;
        fillGradient?: boolean;
        outline?: boolean;
        borderWidth?: number;
    };
    delay?: number;
    ease?: string;
    isHover?: boolean;
    isSaved: boolean;
}

const TimerBar: FunctionComponent<TimerBarProps> = ({
    progressLabel,
    upperBoundsLabel,
    progress,
    start,
    isRunning,
    completed,
    timeState,
    styleClass,
    styles,
    delay,
    ease,
    isHover,
    isSaved,
}) => {
    const hoverClass = isHover ? classes['hover'] : '';
    const ref = useRef<HTMLDivElement>(null);
    const anniRef = useRef<gsap.core.Tween>();

    const startPosition = () => {
        anniRef.current?.kill();

        anniRef.current = gsap.to(ref.current, {
            duration: 0,
            left: `${1 * 100 - 100}%`,
        });
    };

    const animation = () => {
        anniRef.current = gsap.to(ref.current, {
            duration: progress,
            left: `${0 * 100 - 100}%`,
            ease: ease || 'linear',
            delay: 0,
        });
    };

    useLayoutEffect(() => {
        startPosition();
        // animation();
    }, []);

    // useEffect(() => {
    //     if (start) {
    //     }
    // }, [start]);

    useEffect(() => {
        if (isRunning) {
            animation();
        } else {
            startPosition();
        }
    }, [isRunning]);

    return (
        <div className={`progressbar ${classes.TimerBar}`} style={{ height: styles?.height }}>
            <div
                className={`${classes.Bar} ${
                    styles?.backgroundGradient ? 'quest-progress-background' : ''
                } ${hoverClass}`}
                style={{
                    background: styles?.background,
                    borderWidth: styles?.borderWidth,
                }}
            >
                <div
                    className={`${classes.Fill} ${styles?.outline ? classes.Outline : ''} ${
                        styleClass ? styleClass : ''
                    } ${classes[completed ? 'completed' : '']} ${classes[timeState]} ${hoverClass} `}
                    style={{ background: styles?.fill }}
                    ref={ref}
                ></div>
                <div className={classes.Content}>
                    <span className={`${classes.Label} ${hoverClass}`}>{progressLabel}</span>
                    <span className={classes.Label}>{upperBoundsLabel}</span>
                </div>
            </div>
        </div>
    );
};

export default TimerBar;
