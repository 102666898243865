import React, { FunctionComponent, useState } from 'react';
import { ITag } from '../models/Tag';
import { CtxProvider } from './TagContext';

export const TagProvider: FunctionComponent = ({ children }) => {
    const defaultTag: ITag = {
        id: 0,
        title: '',
    };

    const [tag, updateTag] = useState<ITag>(defaultTag);

    const setTag = (newTag: ITag) => {
        updateTag((current) => {
            return { ...current, ...newTag };
        });
    };

    return <CtxProvider value={{ tag, setTag }}>{children}</CtxProvider>;
};
