import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { FC } from 'react';
import { EdgeProps, getBezierPath, getEdgeCenter, getMarkerEnd, MarkerType } from 'react-flow-renderer/nocss';
import Button from '../../Button/Button';
import { EdgeDataType } from '../CardFlowWIP';
import classes from './CustomEdge.module.scss';

function useHover<T extends SVGPathElement = SVGPathElement>(elementRef: RefObject<T>): boolean {
    const [value, setValue] = useState<boolean>(false);

    const handleMouseOver = () => setValue(true);

    const handleMouseOut = () => setValue(false);
    const canHover = matchMedia('(hover: hover)').matches;

    useEffect(() => {
        const node = elementRef?.current;

        if (node && canHover) {
            node.addEventListener('mouseover', handleMouseOver);

            node.addEventListener('mouseout', handleMouseOut);

            return () => {
                node.removeEventListener('mouseover', handleMouseOver);

                node.removeEventListener('mouseout', handleMouseOut);
            };
        }
    }, [elementRef]);

    return !!value && canHover;
}

const CustomEdge: FC<EdgeProps<EdgeDataType>> = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    markerEnd,
    data,
    style,
    curvature,
    selected,
}) => {
    const offsetX = targetX - 10;
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX: offsetX,
        targetY,
        targetPosition,
        curvature,
    });

    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX,
        sourceY,
        targetX,
        targetY,
    });

    const foreignObjectSize = 40;

    const edgeRef = useRef<SVGPathElement>(null);
    const isHover = useHover(edgeRef);
    const hoverClass = isHover ? classes['hover'] : '';
    const selectedClass = selected ? classes['selected'] : '';

    return (
        <>
            <defs>
                <marker
                    id="markerArrow"
                    className={`${classes.MarkerArrow}`}
                    markerWidth="8"
                    markerHeight="8"
                    refX="0"
                    refY="4"
                    orient="auto"
                >
                    <polygon points="0 0, 8 4, 0 8" />
                </marker>

                <marker
                    id="markerRedArrow"
                    className={`${classes.MarkerArrow}`}
                    markerWidth="8"
                    markerHeight="8"
                    refX="0"
                    refY="4"
                    orient="auto"
                    style={{ stroke: 'red', fill: 'red' }}
                >
                    <polygon points="0 0, 8 4, 0 8" />
                </marker>
            </defs>

            <path
                ref={edgeRef}
                style={style}
                className={classes.EdgePathSelector}
                d={edgePath}
                markerEnd={markerEnd}
                fillRule="evenodd"
            />

            <path
                id={id}
                className={`${classes.EdgePath} ${hoverClass} ${selectedClass} react-flow__edge-path`}
                style={style}
                d={edgePath}
                fillRule={'evenodd'}
            />

            <path
                className={`${classes.EdgePathMarker}`}
                style={style}
                d={edgePath}
                markerEnd={getMarkerEnd(MarkerType.ArrowClosed, isHover || selected ? 'markerRedArrow' : 'markerArrow')}
            />

            {selected && (
                <foreignObject
                    width={foreignObjectSize}
                    height={foreignObjectSize}
                    x={edgeCenterX - foreignObjectSize / 2}
                    y={edgeCenterY - foreignObjectSize / 2}
                    className={classes.ForeignObject}
                    requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                    <div className={classes.ForeignObjectContainer}>
                        <Button
                            className={`${classes.DeleteEdgeButton}`}
                            text={``}
                            danger
                            icon={<FontAwesomeIcon className={classes.DeleteIcon} icon={faTrashAlt}></FontAwesomeIcon>}
                            iconSide="left"
                            onClick={(event) => {
                                event.stopPropagation();
                                data?.onRemove && data.onRemove(id);
                            }}
                        ></Button>
                    </div>
                </foreignObject>
            )}
        </>
    );
};

export default CustomEdge;
