import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import classes from './RegisterAccessCode.module.scss';
import RegisterAccessCodeForm from './RegisterAccessCodeForm/RegisterAccessCodeForm';
import useRegisterAccessCodeForm from './useRegisterAccessCodeForm';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { LoginResponse } from '../Login/LoginForm/LoginForm';
import { User } from '../../models/User';
import { setLocalStorageUser } from '../../utils/flavourstorage';
import { useAuth } from '../../context/AuthContext';
import Axios from 'axios';

const RegisterAccessCode: FunctionComponent = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { autoLogin } = useAuth();

    const { initialValues, handleSubmit } = useRegisterAccessCodeForm({
        access_code: '',
        name: '',
    });

    const schema = Yup.object({
        access_code: Yup.string()
            .max(6, t('Common:INPUT_ERROR_ACCESS_CODE_MAX_LENGTH', { max: 6 }))
            .required(t('Common:INPUT_ERROR_ACCESS_CODE_REQUIRED')),
    });

    const cancelHandler = () => {
        history.goBack();
    };

    const notifyAccountCreated = () => toast.success(t('Common:TOAST_ACCOUNT_CREATED_SUCCESS'));
    const notifyAccountNotCreated = (error: string) => toast.error(t(`Common:${error}`));

    const login = (loginResponse: LoginResponse) => {
        notifyAccountCreated();

        const user: User = {
            ...loginResponse.user,
            token: loginResponse.token,
        };

        setLocalStorageUser(user);
        autoLogin(user);

        Axios.defaults.headers = { Authorization: `Bearer ${loginResponse.token}` };

        history.push('/home');
    };

    return (
        <div className={classes.RegisterAccessCode}>
            <Formik
                onSubmit={(formValues, actions) =>
                    handleSubmit(formValues, actions)
                        .then((response) => {
                            const loginResponse: LoginResponse = response.data;
                            if (loginResponse) login(loginResponse);
                        })
                        .catch((err) => {
                            const { data } = err?.response as {
                                data: any;
                            };
                            const { error } = data as {
                                error: string;
                            };

                            notifyAccountNotCreated(error);
                        })
                }
                initialValues={initialValues}
                validationSchema={schema}
            >
                {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                    const disabledSubmit = isSubmitting || !(isValid && dirty);

                    return (
                        <RegisterAccessCodeForm
                            handleSubmit={handleSubmit}
                            disabledSubmit={disabledSubmit}
                            cancelHandler={cancelHandler}
                        />
                    );
                }}
            </Formik>
        </div>
    );
};

export default RegisterAccessCode;
