import {
    faCircleNotch,
    faPlusCircle,
    faSave,
    faTrashAlt,
    faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, Formik } from 'formik';
import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useDeck } from '../../../context/DeckContext';
import { ICard } from '../../../models/Card';
import { IEdge } from '../../../models/Edge';
import { IFork } from '../../../models/Element';
import { IOption } from '../../../models/Option';
import Button from '../../Button/Button';
import CardSelect from '../../CardSelect/CardSelect';
import InputField from '../../InputField/InputField';
import classes from './ForkElement.module.scss';
import useForkEditForm, { FormFields } from './useForkEditForm';

interface ForkProps {
    fork?: IFork;
    deleteHandler: () => void;
    updateFork?: (fork: IFork) => void;
    addNewCardHandler: (title: string) => Promise<ICard | undefined>;
    navigationChanged: () => void;
    navigateToCardHandler: (cardId: number) => void;
}

const ForkElement: FunctionComponent<ForkProps> = ({
    fork,
    deleteHandler,
    updateFork,
    addNewCardHandler,
    navigationChanged,
    navigateToCardHandler,
}) => {
    const { t } = useTranslation();
    const elementRef = useRef<HTMLDivElement>(null);
    const [formFields, setFormFields] = useState<FormFields>();
    const [addedOption, setAddedOption] = useState<boolean>(false);
    const { deck, currentCard } = useDeck();

    const defaultOption: IOption = {
        id: 0,
        question_id: 0,
        body: '',
        feedback: '',
        is_correct: null,
        is_selected: false,
        action_of_edges: [],
    };

    const defaultOptions = [
        {
            id: 99998,
            question_id: 99999,
            body: `${t('Common:MC_OPTION_EXAMPLE')} 1`,
            feedback: '',
            is_correct: null,
            is_selected: false,
            action_of_edges: currentCard?.source_of_edges || [],
        },
        {
            id: 99999,
            question_id: 99999,
            body: `${t('Common:MC_OPTION_EXAMPLE')} 2`,
            feedback: '',
            is_correct: null,
            is_selected: false,
            action_of_edges: [],
        },
    ];

    const schema = Yup.object().shape({
        body: Yup.string().required(t('Common:FORK_TITLE_INPUT_REQUIRED')),

        options: Yup.array()
            .of(
                Yup.object({
                    action_of_edges: Yup.array().required(t('Common:FORK_OPTION_REQUIRED')),
                }),
            )
            .min(2, t('Common:FORK_MIN_OPTIONS_2')),
    });

    useEffect(() => {
        setFormFields({
            navigation_type: fork?.navigation_type || 'next',
            body: fork?.question?.body || '',
            options: fork?.question?.options || defaultOptions,
        });
    }, [fork]);

    const { handleSubmit, handleDelete, isSaved, setIsSaved } = useForkEditForm({
        navigation_type: fork?.navigation_type || 'next',
        body: fork?.question?.body || '',
        options: fork?.question?.options || defaultOptions,
    });

    const inputChangeHandler = () => {
        setIsSaved(false);
    };

    const cardSelectChangeHandler = (newEdge: IEdge | undefined) => {
        setIsSaved(false);
    };

    return (
        <div className={`${classes.ForkElement}`} ref={elementRef}>
            {formFields && (
                <Formik
                    onSubmit={(formValues, actions) => {
                        handleSubmit(formValues, actions, fork).then((response) => {
                            updateFork?.(response);
                            actions.setSubmitting(false);
                        });
                    }}
                    initialValues={formFields}
                    enableReinitialize
                    validationSchema={schema}
                >
                    {({ values, isSubmitting, handleSubmit }) => {
                        return (
                            <form className={classes.ForkElementForm} noValidate={true} onSubmit={handleSubmit}>
                                <header className={classes.Header}>
                                    <label>{t('Common:NAVIGATION_ELEMENT_LABEL')}</label>
                                    <div className={classes.ElementActions}>
                                        {!isSaved ? (
                                            <Button
                                                type="submit"
                                                text={t('Common:BUTTON_SAVE')}
                                                alert
                                                icon={
                                                    isSubmitting ? (
                                                        <FontAwesomeIcon
                                                            className={classes.ButtonLoadingIcon}
                                                            icon={faCircleNotch}
                                                            spin={true}
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon className={classes.SaveIcon} icon={faSave} />
                                                    )
                                                }
                                            />
                                        ) : null}
                                        {!deck.is_published && (
                                            <Button
                                                text=""
                                                danger
                                                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                                                onClick={() => {
                                                    if (fork) {
                                                        handleDelete(fork).then(() => {
                                                            deleteHandler();
                                                        });
                                                    } else {
                                                        deleteHandler();
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                </header>

                                <Fragment>
                                    <div className={`${classes.formGroup}`}>
                                        <div className={classes.BodyInput}>
                                            <InputField
                                                name="body"
                                                type="text"
                                                label={t('Common:TITLE')}
                                                autoFocus
                                                placeholder={t('Common:QUESTION_OPTION_BODY_INPUT_PLACEHOLDER')}
                                                onChange={inputChangeHandler}
                                            />
                                        </div>
                                    </div>

                                    <FieldArray
                                        name="options"
                                        render={(arrayHelpers) => (
                                            <div className={classes.Options}>
                                                {values.options?.map((option, index) => (
                                                    <div className={classes.Option} key={`${option.id}_${index}`}>
                                                        <div className={classes.OptionInput}>
                                                            <div className={classes.OptionHeader}>
                                                                <label
                                                                    htmlFor={`options.${index}.is_correct`}
                                                                    className={classes.OptionSwitch}
                                                                >
                                                                    {t('Common:OPTION_LABEL') + ' ' + (index + 1)}
                                                                </label>

                                                                {!deck.is_published && values.options && (
                                                                    <div className={classes.FormButtons}>
                                                                        {option.action_of_edges &&
                                                                            option.action_of_edges.length > 0 && (
                                                                                <Button
                                                                                    text=""
                                                                                    disabled={!isSaved}
                                                                                    icon={
                                                                                        <FontAwesomeIcon
                                                                                            icon={faUpRightFromSquare}
                                                                                        />
                                                                                    }
                                                                                    onClick={() =>
                                                                                        navigateToCardHandler(
                                                                                            option.action_of_edges[0]
                                                                                                .target_id,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        {values.options?.length > 2 && (
                                                                            <Button
                                                                                className={classes.OptionDelete}
                                                                                text={``}
                                                                                danger
                                                                                tabIndex={-1}
                                                                                icon={
                                                                                    <FontAwesomeIcon
                                                                                        className={classes.DeleteIcon}
                                                                                        icon={faTrashAlt}
                                                                                    ></FontAwesomeIcon>
                                                                                }
                                                                                iconSide="left"
                                                                                onClick={() => {
                                                                                    arrayHelpers.remove(index);
                                                                                    setIsSaved(false);
                                                                                }}
                                                                            ></Button>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className={classes.OptionInputs}>
                                                                <InputField
                                                                    name={`options[${index}].body`}
                                                                    type="text"
                                                                    label="Title"
                                                                    autoFocus={addedOption}
                                                                    onChange={inputChangeHandler}
                                                                />

                                                                <div className={classes.CardSelect}>
                                                                    <CardSelect
                                                                        name={`options[${index}].action_of_edges`}
                                                                        index={index}
                                                                        actionId={option.id}
                                                                        actionModelType={'option'}
                                                                        edges={option.action_of_edges}
                                                                        onChange={(edge) => {
                                                                            cardSelectChangeHandler(edge);
                                                                        }}
                                                                        addNewCardHandler={addNewCardHandler}
                                                                        isDisabled={deck.is_published}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {values.options && values.options.length - 1 !== index && (
                                                            <div className={classes.Divider} />
                                                        )}
                                                    </div>
                                                ))}
                                                {!deck.is_published && (
                                                    <Button
                                                        className={classes.AddOption}
                                                        type="button"
                                                        text={t('Common:FORK_ADD_OPTION_LABEL')}
                                                        icon={<FontAwesomeIcon icon={faPlusCircle} />}
                                                        iconSide="left"
                                                        onClick={() => {
                                                            const option = {
                                                                ...defaultOption,
                                                                question_id: fork?.question?.id || 0,
                                                                body: `${t('Common:MC_OPTION_EXAMPLE')} ${
                                                                    (values.options?.length || 0) + 1
                                                                }`,
                                                            };
                                                            arrayHelpers.push(option);
                                                            setIsSaved(false);
                                                            setAddedOption(true);
                                                            navigationChanged();
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    />
                                    {deck.is_published && <div>{t('Common:FORK_ELEMENT_PUBLISHED_MESSAGE')}</div>}
                                </Fragment>
                            </form>
                        );
                    }}
                </Formik>
            )}
        </div>
    );
};

export default ForkElement;
