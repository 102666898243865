import React from 'react';
import classes from './BoxHeader.module.scss';

interface Props {
    title: string;
    subtitle?: string;
}

const BoxHeader = (props: Props) => {
    const subtitle = props.subtitle ? <p>{props.subtitle}</p> : null;

    return (
        <header className={classes.Header}>
            <h1 className={classes.Title}>{props.title}</h1>
            {subtitle}
        </header>
    );
};

export default React.memo(BoxHeader);
