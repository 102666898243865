import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import Button from '../../../../components/Button/Button';
import { ICategory } from '../../../../models/Categories';
import CategoryActions from './CategoryActions/CategoryActions';
import classes from './CategoryActionsPopover.module.scss';

export interface ICategoryActionsProps {
    category: ICategory;
    onEditHandler: (category: ICategory) => void;
    onDeleteHandler: (category: ICategory) => void;
}

const CategoryActionsPopover: FunctionComponent<ICategoryActionsProps> = ({
    category,
    onEditHandler,
    onDeleteHandler,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'deck-edit-menu-popover' : undefined;

    return (
        <div className={classes.CategoryActionsPopover}>
            <Button
                aria-controls="deck-edit-menu"
                aria-haspopup="true"
                text=""
                icon={<FontAwesomeIcon icon={faEllipsis} />}
                onClick={handleClick}
            />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <CategoryActions category={category} onEditHandler={onEditHandler} onDeleteHandler={onDeleteHandler} />
            </Popover>
        </div>
    );
};

export default CategoryActionsPopover;
