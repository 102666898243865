import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { IGroup } from '../models/Group';
import { User } from '../models/User';
import { CtxProvider } from './GroupContext';

type GroupProviderProps = {
    override?: Partial<IGroup>;
};

export type JoinState = 'joined' | 'open_to_join' | 'request_to_join';
export type ActionButtonState = 'leave' | 'join' | 'request';

export const GroupProvider: FunctionComponent<GroupProviderProps> = ({ children, override }) => {
    const defaultGroup: IGroup = {
        id: 0,
        name: '',
        description: '',
        avatar: '',
        created_at: '',
        updated_at: '',
        users: [],
        group_statistics: {
            quests_played: 0,
            cards_played: 0,
            correct_ratio: 0,
        },
        quests: [],
        potential_members: [],
        private: true,
        visible: true,
        users_count: 0,
        is_member: false,
    };

    const getDefaultGroup = (override: Partial<IGroup>) => {
        return { ...defaultGroup, ...override };
    };

    const [group, updateGroup] = useState<IGroup>(() => {
        if (override != undefined) {
            return getDefaultGroup(override);
        }

        return { ...defaultGroup };
    });

    const setGroup = (newGroup: IGroup) => {
        updateGroup((current) => {
            return { ...current, ...newGroup };
        });
    };

    const joinState: JoinState = group.is_member ? 'joined' : 'open_to_join';

    const actionButtonState: ActionButtonState =
        joinState === 'joined' ? 'leave' : joinState === 'open_to_join' ? 'join' : 'request';

    const addUser = (user: User) => {
        const newGroupUsers: User[] = _.cloneDeep(group.users || []);
        newGroupUsers.push(user);

        const newPotentialMembers: User[] = _.cloneDeep(group.potential_members);
        newPotentialMembers.splice(
            newPotentialMembers.findIndex((findUser) => {
                return user.id === findUser.id;
            }),
            1,
        );

        const newGroup = { ...group, users: newGroupUsers, potential_members: newPotentialMembers };
        setGroup(newGroup);
    };

    const removeUser = (user: User) => {
        const newGroupUsers: User[] = _.cloneDeep(group.users || []);
        newGroupUsers.splice(
            newGroupUsers.findIndex((findUser) => {
                return user.id === findUser.id;
            }),
            1,
        );

        const newPotentialMembers: User[] = _.cloneDeep(group.potential_members);
        newPotentialMembers.push(user);

        const newGroup = { ...group, users: newGroupUsers, potential_members: newPotentialMembers };
        setGroup(newGroup);
    };

    return (
        <CtxProvider value={{ group, setGroup, joinState, actionButtonState, addUser, removeUser }}>
            {children}
        </CtxProvider>
    );
};
