import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogActions, DialogContent, DialogTitle, TableCell, TableRow } from '@material-ui/core';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../../../components/Button/Button';
import ExtendedMuiTable from '../../../components/ExtendedMuiTable/ExtendedMuiTable';
import { useConfig } from '../../../context/ConfigContext';
import { IGroup, IGroupsResponse } from '../../../models/Group';
import axiosInstance from '../../../utils/axios-instance';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import ManagementHeader from '../ManagementHeader/ManagementHeader';
import GroupActionsPopover from './GroupActionsPopover/GroupActionsPopover';
import classes from './ManagementGroups.module.scss';

const ManagementGroups: FunctionComponent = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { config } = useConfig();
    const { response, loading } = useAxiosFetch<IGroupsResponse>(`${config.baseUrl}/groups/admin`);
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [groupToDelete, setGroupToDelete] = useState<IGroup | undefined>();
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    useEffect(() => {
        if (response) {
            const groupsResponse = response.data;

            const myGroups: IGroup[] = _.cloneDeep(groupsResponse.my_groups);
            const otherGroups: IGroup[] = _.cloneDeep(groupsResponse.other_groups);
            const mergedGroups: IGroup[] = [...myGroups, ...otherGroups];

            setGroups(mergedGroups);
        }
    }, [response]);

    const editClickHandler = (group: IGroup) => {
        history.push({ pathname: `/groups/edit/${group.id}`, state: group });
    };

    const onAddGroupHandler = () => {
        history.push({ pathname: `/groups/create` });
    };

    const notifyDeleted = () => toast.success(t('Common:TOAST_GROUP_DELETE_SUCCESS'));

    const deleteClickHandler = (group: IGroup) => {
        setGroupToDelete(group);
        setOpen(true);
    };

    const cancelDeleteClickHandler = () => {
        setGroupToDelete(undefined);
        closeModal();
    };

    const permanentlyDeleteHandler = () => {
        if (groupToDelete === undefined) return;

        axiosInstance.delete(`${config.baseUrl}/groups/${groupToDelete.id}`).then(() => notifyDeleted());

        const newGroups = [...groups];
        const groupIndex = newGroups.findIndex((g) => g.id === groupToDelete.id);

        if (groupIndex != -1) {
            newGroups.splice(groupIndex, 1);
            setGroups(newGroups);
        }

        setGroupToDelete(undefined);
        closeModal();
    };

    const renderTableHeaders = (
        <TableRow>
            <TableCell>
                <div className={`table-header`}>{t('Common:GROUP_TITLE_HEADER')}</div>
            </TableCell>
            <TableCell>
                <div className={`table-header`}>{t('Common:GROUP_ACCESS_HEADER')}</div>
            </TableCell>
            <TableCell>
                <div className={`table-header`}>{t('Common:GROUP_VISIBLE_HEADER')}</div>
            </TableCell>
            <TableCell></TableCell>
        </TableRow>
    );
    const renderGroupItem = (group: IGroup) => {
        return (
            <TableRow key={`group_${group.id}`}>
                <TableCell>
                    <div className={classes.GroupName}>
                        <FontAwesomeIcon icon={faUsers} />
                        {group.name}
                    </div>
                </TableCell>

                <TableCell>{group.private ? t('Common:PRIVATE') : t('Common:PUBLIC')}</TableCell>
                <TableCell>{group.visible ? t('Common:VISIBLE') : t('Common:INVISIBLE')}</TableCell>
                <TableCell>
                    <div className={classes.GroupActions}>
                        <GroupActionsPopover
                            group={group}
                            onEditHandler={editClickHandler}
                            onDeleteHandler={deleteClickHandler}
                        />
                    </div>
                </TableCell>
            </TableRow>
        );
    };

    const filterDataBySearch = (searchValues: string[]) => {
        return groups.filter((item) => {
            const name = item.name;

            const searchableValues = { name };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    return (
        <div className={classes.ManagementGroups}>
            <ManagementHeader
                title={t('Groups:GROUPS_HEADER')}
                buttonText={t('Groups:ADD_GROUP_BUTTON')}
                onClick={onAddGroupHandler}
                permission="user.manage"
            />

            <div className={`Box`}>
                <ExtendedMuiTable
                    loading={loading}
                    searchInputLabel={t('Common:GROUPS_SEARCH_INPUT_LABEL')}
                    searchAutoFocus={true}
                    filterDataBySearch={filterDataBySearch}
                    noItemsMessage={t('Common:LIST_MESSAGE_NO_GROUPS')}
                    noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_GROUPS')}
                    items={groups}
                    renderTableHeaders={renderTableHeaders}
                    renderItem={renderGroupItem}
                />
            </div>

            <Dialog
                open={open}
                onClose={closeModal}
                PaperProps={{ className: `dialog` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:DELETE_GROUP_DIALOG_TITLE')}</DialogTitle>
                <DialogContent>{t('Common:GROUP_DELETION_WARNING')}</DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={cancelDeleteClickHandler} />
                    <Button alt text={t('Common:BUTTON_CONFIRM')} onClick={permanentlyDeleteHandler} />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ManagementGroups;
