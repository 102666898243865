import {
    faFolder,
    faKey,
    faLandmark,
    faMap,
    faMapLocation,
    faMapLocationDot,
    faTag,
    faTags,
    faUser,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gsap } from 'gsap';
import React, { Fragment, FunctionComponent, useRef, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch, useHistory, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';

import PageHeader from '../../components/PageHeader/PageHeader';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import { useAuth } from '../../context/AuthContext';
import { getColor } from '../../utils/colors';
import classes from './Management.module.scss';
import ManagementAccessCodes from './ManagementAccessCodes/ManagementAccessCodes';
import ManagementCategories from './ManagementCategories/ManagementCategories';
import { IncrementedRef } from './ManagementCover/ManagementCover';
import ManagementGroups from './ManagementGroups/ManagementGroups';
import ManagementLibrary from './ManagementLibrary/ManagementLibrary';
import ManagementMembers from './ManagementMembers/ManagementMembers';
import ManagementQuests from './ManagementQuests/ManagementQuests';
import ManagementRoles from './ManagementRoles/ManagementRoles';
import ManagementTags from './ManagementTags/ManagementTags';

const Management: FunctionComponent = () => {
    // const location = useLocation();
    const { path } = useRouteMatch();
    const { t } = useTranslation();
    const { hasPermission } = useAuth();
    const tl = gsap.timeline();
    const list = useRef<IncrementedRef[]>([]);
    const [refOnce, setRefOnce] = useState(false);
    const iconColor = getColor('--brand-color-5');
    const history = useHistory();

    const setRefs = (target: IncrementedRef | null, listReference: IncrementedRef[], itemIndex: number) => {
        if (target && listReference && !refOnce) {
            const ref = (list.current[itemIndex] = target);
            if (itemIndex === 3) {
                // All refs are set for this list. So animation is possible.
                animateInList(listReference);
                setRefOnce(true);
            }
            return ref;
        }
    };

    const animateInList = (listReference: IncrementedRef[]) => {
        const l = listReference.map((el) => el.current);
        tl.set(l, { alpha: 0, scale: 0.7 });
        tl.to(l, {
            duration: 0.25,
            alpha: 1,
            scale: 1,
            ease: 'back',
            stagger: {
                amount: 0.35,
            },
        });
    };

    return (
        <Fragment>
            <div className={classes.Management}>
                <PageHeader title={t('Management:PAGE_HEADER')} />

                <nav className={classes.Navigation}>
                    {hasPermission('quest.manage') && isDesktop && (
                        <NavLink
                            className={`${classes.NavLink} btn btn-flavour btn-flavour--alt`}
                            to="/management/quests"
                        >
                            <FontAwesomeIcon icon={faMapLocationDot} />
                            {t('Common:MANAGEMENT_QUEST_HEADER')}
                        </NavLink>
                    )}

                    {(hasPermission('library.manage') || hasPermission('export.deck.answers')) && isDesktop && (
                        <NavLink
                            className={`${classes.NavLink} btn btn-flavour btn-flavour--alt`}
                            to="/management/library"
                        >
                            <FontAwesomeIcon icon={faUser} />
                            {t('Common:MANAGEMENT_LIBRARY_HEADER')}
                        </NavLink>
                    )}

                    {hasPermission('user.manage') && isDesktop && (
                        <NavLink
                            className={`${classes.NavLink} btn btn-flavour btn-flavour--alt`}
                            to="/management/members"
                        >
                            <FontAwesomeIcon icon={faUser} />
                            {t('Common:MANAGEMENT_USERS_HEADER')}
                        </NavLink>
                    )}

                    {hasPermission('group.manage') && isDesktop && (
                        <NavLink
                            className={`${classes.NavLink} btn btn-flavour btn-flavour--alt`}
                            to="/management/groups"
                        >
                            <FontAwesomeIcon icon={faUsers} />
                            {t('Common:MANAGEMENT_GROUPS_HEADER')}
                        </NavLink>
                    )}

                    {hasPermission('user.manage') && isDesktop && (
                        <NavLink
                            className={`${classes.NavLink} btn btn-flavour btn-flavour--alt`}
                            to="/management/accesscodes"
                        >
                            <FontAwesomeIcon icon={faKey} />
                            {t('Common:MANAGEMENT_ACCESS_CODES_HEADER')}
                        </NavLink>
                    )}

                    {hasPermission('library.manage') && isDesktop && (
                        <NavLink
                            className={`${classes.NavLink} btn btn-flavour btn-flavour--alt`}
                            to="/management/categories"
                        >
                            <FontAwesomeIcon icon={faFolder} />
                            {t('Common:MANAGEMENT_CATEGORIES_HEADER')}
                        </NavLink>
                    )}

                    {hasPermission('library.manage') && isDesktop && (
                        <NavLink
                            className={`${classes.NavLink} btn btn-flavour btn-flavour--alt`}
                            to="/management/tags"
                        >
                            <FontAwesomeIcon icon={faTags} />
                            {t('Common:MANAGEMENT_TAGS_HEADER')}
                        </NavLink>
                    )}
                </nav>
            </div>
            <Switch>
                <PrivateRoute
                    path={`${path}/quests`}
                    component={ManagementQuests}
                    exact={false}
                    permissions={['quest.manage']}
                />

                <PrivateRoute
                    path={`${path}/library`}
                    component={ManagementLibrary}
                    exact={false}
                    permissions={['library.manage', 'export.deck.answers']}
                />

                <PrivateRoute
                    path={`${path}/members`}
                    component={ManagementMembers}
                    exact={false}
                    permissions={['user.manage']}
                />
                <PrivateRoute
                    path={`${path}/groups`}
                    component={ManagementGroups}
                    exact={false}
                    permissions={['group.manage']}
                />

                <PrivateRoute
                    path={`${path}/roles`}
                    component={ManagementRoles}
                    exact={false}
                    permissions={['role.manage']}
                />

                <PrivateRoute
                    path={`${path}/accesscodes`}
                    component={ManagementAccessCodes}
                    exact={false}
                    permissions={['user.manage']}
                />

                <PrivateRoute
                    path={`${path}/categories`}
                    component={ManagementCategories}
                    exact={false}
                    permissions={['library.manage']}
                />

                <PrivateRoute
                    path={`${path}/tags`}
                    component={ManagementTags}
                    exact={false}
                    permissions={['library.manage']}
                />

                <Redirect from={path} to={`${path}/quests`} />
            </Switch>
        </Fragment>
    );
};

export default Management;
