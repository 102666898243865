import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useConfig } from '../../../../context/ConfigContext';
import { useTimer } from '../../../../context/TimerContext';
import { IElement } from '../../../../models/Element';
import Axios from '../../../../utils/axios-instance';

interface FormFields {
    answers: number[];
}

export type ReturnType = {
    handleSubmit: (formFields: FormFields) => Promise<AxiosResponse<any>>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
};

const useMultipleChoiceQuestionFormHook = (
    initialValues: FormFields,
    deckId: number,
    element: IElement,
    multipleAnswersPossible: boolean,
): ReturnType => {
    const { config } = useConfig();
    const [isSaved, setIsSaved] = useState(initialValues.answers.length != 0);
    const { state } = useTimer();

    const handleSubmit = useCallback((formFields: FormFields) => {
        const answerTime = state.originalSeconds - state.seconds;

        const body = {
            deck_id: deckId,
            question_id: element.elementable_id,
            option_ids: multipleAnswersPossible ? formFields.answers.join() : formFields.answers[0],
            answer_time:  answerTime,
            within_time: answerTime < state.originalSeconds
        };
        return Axios.post(`${config.baseUrl}/questions_answer`, body);
    }, [state]);

    return {
        handleSubmit,
        initialValues,
        isSaved,
        setIsSaved,
    };
};

export default useMultipleChoiceQuestionFormHook;
