import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import ExtendedMuiList from '../../../../components/ExtendedMuiList/ExtendedMuiList';
import { RolePermission } from '../../../../models/User';
import classes from './RolePermissionsList.module.scss';

interface RolePermissionsListProps {
    permissions: RolePermission[];
    defaultRowsPerPage?: number;
    icon: JSX.Element;
    loading: boolean;
    buttonClassName: string;
    onClick: (permission: RolePermission) => void;
}

const RolePermissionsList: FunctionComponent<RolePermissionsListProps> = ({
    permissions,
    icon,
    buttonClassName,
    onClick,
}) => {
    const { t } = useTranslation();

    const renderPermissionItem = (permission: RolePermission) => {
        return (
            <div className={classes.PermissionItem}>
                <div className={classes.PermissionName}>{permission.name}</div>
                <div className="flex-grow"></div>
                <Button
                    className={`${buttonClassName} btn btn-flavour`}
                    text=""
                    icon={icon}
                    onClick={() => onClick(permission)}
                />
            </div>
        );
    };

    const filterDataBySearch = (searchValues: string[]) => {
        return permissions.filter((item) => {
            const name = item.name;

            const searchableValues = { name };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };
    return (
        <ExtendedMuiList
            loading={false}
            filterDataBySearch={filterDataBySearch}
            searchInputLabel={t('Common:PERMISSIONS_SEARCH_INPUT_LABEL')}
            items={permissions}
            renderItem={renderPermissionItem}
            noItemsMessage={t('Common:LIST_MESSAGE_NO_PERMISSIONS')}
            noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_PERMISSIONS')}
            itemsListClasses={`${classes.RolePermissionsList}`}
        />
    );
};

export default RolePermissionsList;
