import { RolePermission, UserRole } from '../models/User';
import { createCtx } from './createCtx';

export type RoleContextType = {
    role: UserRole;
    setRole: (role: UserRole) => void;
    addPermission: (permission: RolePermission) => void;
    removePermission: (permission: RolePermission) => void;
};

export const [useRole, CtxProvider] = createCtx<RoleContextType>();
