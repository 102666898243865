import { Tenant } from '../models/Tenant';
import { createCtx } from './createCtx';

export type Config = {
    baseUrl: string;
    googleRedirectUrl: string;
    tenant: Tenant;
};

export type ConfigContextType = {
    config: Config;
    setConfig: (config: Config) => void;
    loading: boolean;
    loadingConfig: boolean;
};

export const [useConfig, CtxProvider] = createCtx<ConfigContextType>();
