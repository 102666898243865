import Axios from 'axios';
import { history } from '../index';
import { getLocalStorageUser } from './flavourstorage';

export const api_scheme = process.env.REACT_APP_ENV === 'local' ? 'http' : 'https';
const axiosInstance = Axios.create({
    baseURL: `${api_scheme}://${process.env.REACT_APP_API_DOMAIN}/`,
});

const MAX_REQUESTS_COUNT = 1;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

export const gotoLoginAndForceLogout = (): void => {
    history.replace('/login', { data: { forecLogout: true } });
};

axiosInstance.interceptors.request.use(
    (requestConfig) => {
        // For specific request allow only 1 at a time.
        const requestProgressCards = requestConfig.method === 'post' && requestConfig.url?.includes('/cards/complete');
        if (requestProgressCards) {
            return new Promise((resolve) => {
                const interval = setInterval(() => {
                    if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                        PENDING_REQUESTS++;
                        clearInterval(interval);
                        resolve(requestConfig);
                    }
                }, INTERVAL_MS);
            });
        }

        return Promise.resolve(requestConfig);
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        const responseProgressCards =
            response.config.method === 'post' && response.config.url?.includes('/cards/complete');
        if (responseProgressCards) {
            PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
        }

        return Promise.resolve(response);
    },
    (error) => {
        const errorResponseInfo = error.config?.method === 'get' && error.config?.url?.includes('/info');
        if (errorResponseInfo) {
            const { status, statusText, data } = error?.response as { status: number; statusText: string; data: any };

            history.push(history.location.pathname, {
                hcErrorStatusCode: status,
            });

            return Promise.reject(error);
        }

        const responseProgressCards = error.config?.method === 'post' && error.config?.url?.includes('/cards/complete');
        if (responseProgressCards) {
            PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
        }

        // 500
        if (error.message === 'Network Error' && !error.response) {
            history.push(history.location.pathname, {
                errorStatusCode: 500,
            });
            return Promise.reject(error);
        }

        if (error.message === undefined && !error.response) {
            return Promise.reject(error);
        }

        const { status, statusText, data } = error?.response as { status: number; statusText: string; data: any };

        if (data) {
            const { error, error_description, hint, message } = data as {
                error: string;
                error_description: string;
                hint: string;
                message: string;
            };
            if (error === 'invalid_request') {
                gotoLoginAndForceLogout();
                return Promise.reject(error);
            }
        }

        // 401
        if (status === 401) {
            gotoLoginAndForceLogout();
            return Promise.reject(error);

            // if (statusText != 'Unauthorized') {
            //     gotoLoginAndForceLogout();
            //     return Promise.reject(error);
            // } else {
            //     // Check localstored user
            //     const loggedInUser = getLocalStorageUser();
            //     if (!loggedInUser) {
            //         gotoLoginAndForceLogout();
            //         return Promise.reject(error);
            //     }
            // }
        }

        // 403
        if (status === 403) {
            return Promise.reject(error);
        }

        // Redirects on statusCode. Gets handled by ErrorHandler
        // 404, 500, 503

        if (status === 404) {
            history.replace('/home');
            return Promise.reject(error);
        }

        history.push(history.location.pathname, {
            errorStatusCode: status,
        });

        return Promise.reject(error);
    },
);

export default axiosInstance;
