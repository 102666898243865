/* eslint-disable @typescript-eslint/no-explicit-any */
import Plyr, { APITypes } from 'plyr-react';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import API from '../../utils/api';
import classes from './PlyrComponent.module.scss';
export interface Props {
    url: string;
}

export const youtubeSrcRegex =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
export const vimeoSrcRegex =
    /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;
export const combinedRegex =
    /(^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+))$|^(((?:https?:)?\/\/)?((?:www|m)\.)?((?:vimeo\.com))\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?))$/;

export type Provider = 'html5' | 'youtube' | 'vimeo';

const PlyrComponent: FunctionComponent<Props> = ({ url }) => {
    const [videoId, setVideoId] = useState<string | null>(null);
    const [provider, setProvider] = useState<Provider>('youtube');

    const ref = useRef<APITypes>(null);

    const extractVideoID = (url: string) => {
        if (!url || url === '') {
            return ['youtube', ''];
        }
        const match = url.match(combinedRegex);
        let provider: Provider = 'youtube';
        let id = '';
        if (match) {
            if (match[4] && match[4].match(/(youtube|youtu.be)/)) {
                provider = 'youtube';
                if (match[6] && match[6].length == 11) {
                    id = match[6];
                }
            } else if (match[10] && match[10].match(/(vimeo)/)) {
                provider = 'vimeo';
                id = match[12];
            }
        }
        return [provider, id];
    };

    useEffect(() => {
        if (url !== '') {
            const [provider, id] = extractVideoID(url);
            if (id) {
                setVideoId(id);
                setProvider(provider as Provider);
            }
        }
    }, [url]);

    const desktopControls = [
        'play-large', // The large play button in the center
        // "restart", // Restart playback
        // "rewind", // Rewind by the seek time (default 10 seconds)
        'play', // Play/pause playback
        // "fast-forward", // Fast forward by the seek time (default 10 seconds)
        'progress', // The progress bar and scrubber for playback and buffering
        'current-time', // The current time of playback
        'duration', // The full duration of the media
        'mute', // Toggle mute
        'volume', // Volume control
        // "captions", // Toggle captions
        // "settings", // Settings menu
        // "pip", // Picture-in-picture (currently Safari only)
        // "airplay", // Airplay (currently Safari only)
        // "download", // Show a download button with a link to either the current source or a custom URL you specify in your options
        'fullscreen', // Toggle fullscreen
        // 'autoplay', // Toggle autoplay
    ];

    const mobileIOSControls = ['play-large', 'play', 'progress', 'mute', 'volume', 'pip', 'airplay'];

    const mobileControls = ['play-large', 'play', 'progress', 'mute', 'volume'];

    let videoControls: string[] = [];
    if (!isMobile) {
        videoControls = desktopControls;
    }

    if (isMobile && !isIOS) {
        videoControls = mobileControls;
    }

    if (isMobile && isIOS) {
        videoControls = mobileIOSControls;
    }

    const videoOptions: Plyr.Options = {
        controls: videoControls,
        autoplay: false,
        fullscreen: {
            fallback: true,
            enabled: true,
            iosNative: true,
        },
        muted: false,
        storage: { enabled: false },
        youtube: { origin: API.getOriginUrl() },
        // loadSprite: false,
    };

    const plyrVideo =
        videoId && provider ? (
            <Plyr
                ref={ref}
                source={{
                    type: 'video',
                    sources: [
                        {
                            src: videoId,
                            provider: provider,
                        },
                    ],
                }}
                options={videoOptions}
            />
        ) : null;

    return <div className={classes.PlyrComponent}>{plyrVideo}</div>;
};

export default React.memo(PlyrComponent);
