import React, { FunctionComponent } from 'react';
import classes from './AvatarIcon.module.scss';

type SVGProps = {
    fill?: string;
    className?: string;
};

const AvatarIcon: FunctionComponent<SVGProps> = ({ className }) => {
    return (
        <svg
            className={`${classes.Icon} ${className}`}
            xmlns="http://www.w3.org/2000/svg"
            width="14.148"
            height="18.042"
            viewBox="0 0 14.148 18.042"
        >
            <path
                d="M-8.676-40.883a4.534,4.534,0,0,1-1.792-.364,4.727,4.727,0,0,1-1.469-1A4.481,4.481,0,0,1-12.92-43.7a4.534,4.534,0,0,1-.364-1.792,4.534,4.534,0,0,1,.364-1.792,4.538,4.538,0,0,1,.984-1.469,4.687,4.687,0,0,1,1.469-.984A4.534,4.534,0,0,1-8.676-50.1a4.534,4.534,0,0,1,1.792.364,4.687,4.687,0,0,1,1.469.984,4.538,4.538,0,0,1,.984,1.469,4.534,4.534,0,0,1,.364,1.792A4.534,4.534,0,0,1-4.431-43.7a4.481,4.481,0,0,1-.984,1.455,4.727,4.727,0,0,1-1.469,1A4.534,4.534,0,0,1-8.676-40.883ZM-1.6-32.058H-15.75v-2.277a5.024,5.024,0,0,1,.391-1.927,5.068,5.068,0,0,1,1.051-1.563,5.011,5.011,0,0,1,1.577-1.064,4.913,4.913,0,0,1,1.927-.377h4.258a4.913,4.913,0,0,1,1.927.377,4.806,4.806,0,0,1,1.563,1.064,5.11,5.11,0,0,1,1.064,1.563A5.024,5.024,0,0,1-1.6-34.335Z"
                transform="translate(15.75 50.1)"
                fill="currentColor"
            />
        </svg>
    );
};

export default AvatarIcon;
