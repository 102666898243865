// import i18n from '../i18n';

export const compactNumber = (number: number): string => {
    return new Intl.NumberFormat('en-GB', {
        // return new Intl.NumberFormat(i18n.language, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        notation: 'compact',
        compactDisplay: 'short',
    }).format(number);
};
