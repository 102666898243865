import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router';
import Button from '../../../components/Button/Button';
import DeckDetails from '../../../components/Deck/DeckDetails';
import HideIcon from '../../../components/Icons/HideIcon/HideIcon';
import ShowIcon from '../../../components/Icons/ShowIcon/ShowIcon';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { useAuth } from '../../../context/AuthContext';
import { useCategory } from '../../../context/CategoryContext';
import { useConfig } from '../../../context/ConfigContext';
import { DeckProvider } from '../../../context/DeckProvider';
import { ICategory } from '../../../models/Categories';
import { IDeck } from '../../../models/Deck';
import { IModule } from '../../../models/Module';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import classes from './CategoryDetail.module.scss';
import Module from './Module/Module';

type CategoryDetailProps = {
    categoryData?: ICategory;
};

type ParamTypes = {
    categoryId: string;
};

const CategoryDetail: FunctionComponent<CategoryDetailProps> = () => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const location = useLocation();
    const { hasPermission } = useAuth();
    const { path } = useRouteMatch();
    const history = useHistory();
    const { categoryId } = useParams<ParamTypes>();
    const [categoryAPIUrl, setCategoryAPIUrl] = useState<string>(() => {
        return categoryId;
    });
    const { response, loading, sendRequest } = useAxiosFetch<ICategory>(
        `${config.baseUrl}/categories/${categoryAPIUrl}`,
    );
    const { category, setCategory, isDataChanged, setIsDataChanged } = useCategory();
    const [showClosed, setShowClosed] = useState(false);
    const [resetKey, setResetKey] = useState(0);

    useEffect(() => {
        // Set categoryId so loading will start
        if (categoryId) {
            setCategoryAPIUrl(categoryId);
        }

        // Set Category from State
        const category = location.state as ICategory;
        if (category) {
            setCategory(category);
            // setBreadCrumb({ title: category.title, path: location.pathname });
        }
    }, []);

    useEffect(() => {
        if (isDataChanged) {
            sendRequest();
            setIsDataChanged(false);
        }
    }, [location, isDataChanged]);

    useEffect(() => {
        if (response) {
            const categoryData = response.data;
            setCategory(categoryData);
        }
    }, [response]);

    const compareModuleOrder = (a: IModule, b: IModule): number => {
        if (a.order < b.order) {
            return -1;
        }
        if (a.order > b.order) {
            return 1;
        }
        return 0;
    };

    const editHandler = () => {
        history.push({ pathname: `/category/edit/${category.id}`, state: category });
    };

    const showClosedHandler = () => {
        setShowClosed(!showClosed);
    };

    // const modulesList = category.modules?.sort(compareModuleOrder).map((module) => {
    //     return (
    //         <div key={module.id} className={classes.Module}>
    //             <div className={classes.ModuleHeader}>
    //                 <div>
    //                     <h2 className={`h4 ${classes.ModuleTitle}`}>{module.title}</h2>
    //                     <div className={`${classes.ModuleSubtitle}`}>{module.description}</div>
    //                 </div>
    //             </div>

    //             <Module module={module} showClosed={!showClosed}></Module>
    //         </div>
    //     );
    // });

    const deckCount = () => {
        return category.decks?.length || 0;
    };

    const cardCount = () => {
        let count = 0;
        category.decks?.map((deck: IDeck) => {
            count += deck.cards_count;
        });

        return count;
    };

    const reset = () => {
        setResetKey((val) => val + 1);
    };

    return (
        <Fragment>
            <Switch>
                <Route exact path={`${path}`}>
                    <div className={classes.CategoryDetail}>
                        <PageHeader
                            title={category.title}
                            subtitle={category.description}
                            rightAlignedText={t('Categories:CONTENT_AMOUNT_SUBTITLE', {
                                cards: cardCount(),
                                decks: deckCount(),
                            })}
                            editHandler={hasPermission('library.manage') && isDesktop ? editHandler : undefined}
                        />

                        {loading && (
                            <div className={classes.Loader}>
                                <div className="spinner card Box"></div>
                            </div>
                        )}

                        {!loading && (
                            <Fragment>
                                <div className={classes.DisplayControls}>
                                    <Button
                                        text={
                                            !showClosed
                                                ? t('Categories:BUTTON_HIDE_CLOSED_DECKS')
                                                : t('Categories:BUTTON_SHOW_CLOSED_DECKS')
                                        }
                                        alert
                                        icon={!showClosed ? <HideIcon /> : <ShowIcon />}
                                        iconSide="left"
                                        onClick={showClosedHandler}
                                    />
                                </div>

                                {/* <div className={`module-group`}>{modulesList}</div> */}
                            </Fragment>
                        )}
                    </div>
                </Route>

                <Route path={`${path}/deck/:deckId`}>
                    {/* View Deck */}
                    <DeckProvider>
                        <DeckDetails key={`deckdetail_${resetKey}`} resetDeck={reset}></DeckDetails>
                    </DeckProvider>
                </Route>
            </Switch>
        </Fragment>
    );
};

export default CategoryDetail;
