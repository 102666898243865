import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gsap } from 'gsap';
import React, { createRef, FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import { useConfig } from '../../../context/ConfigContext';
import { QuestProvider } from '../../../context/QuestProvider';
import { IQuest, TimeState } from '../../../models/Quest';
import { compareNowToDates, nowToDates } from '../../../utils/date';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import QuestCover from './QuestCover/QuestCover';
import classes from './Quests.module.scss';

const Quests: FunctionComponent = () => {
    const { config } = useConfig();
    const { response, loading } = useAxiosFetch<IQuest[]>(`${config.baseUrl}/user/quests/active`);

    const [quests, setQuests] = useState<IQuest[]>([]);

    const containerRef = createRef<HTMLDivElement>();
    const perspective = 2000;
    const tl = gsap.timeline();
    const list = useRef<HTMLDivElement[]>([]);
    const { t } = useTranslation();
    const [hideCompleted, setHideCompleted] = useState(false);
    const [hasCompletedQuests, setHasCompletedQuests] = useState(false);

    useEffect(() => {
        tl.set(containerRef.current, { perspective: perspective, perspectiveOrigin: 'center' });
    }, [containerRef]);

    useEffect(() => {
        if (response) {
            const questsData = response.data;
            setQuests(questsData);

            questsData.map((q) => {
                if (completed(q)) {
                    setHasCompletedQuests(true);
                }
            });
        }
        return () => {
            setQuests([]);
        };
    }, [response]);

    // 0 = in_progress
    // -1 = awaiting
    // 1 = ended
    const sortBy: TimeState[] = ['in_progress', 'awaiting', 'ended'];

    const comparedTimeToState = (compare: number): TimeState => {
        if (compare === -1) {
            return 'awaiting';
        } else if (compare === 0) {
            return 'in_progress';
        } else {
            return 'ended';
        }
    };

    const completed = (quest: IQuest) => {
        const tasksIsCompleted = Boolean(!!quest.user_progress[0]?.is_completed);
        const teamQuestTotal = quest.quest_progress.totalTasks;
        const teamProgress = teamQuestTotal === 0 ? 0 : quest.quest_progress.completedTasks / teamQuestTotal;
        return tasksIsCompleted || teamProgress === 1;
    };

    const customSort = ({ data, sortBy }: { data: IQuest[]; sortBy: string[] }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const sortByObject: { [key: string]: any } = sortBy.reduce((obj, item, index) => {
            return {
                ...obj,
                [item]: index,
            };
        }, {});
        return data.sort((a, b) => {
            // Check if quests are completed
            const aIsCompleted = completed(a);
            const bIsCompleted = completed(b);

            if (aIsCompleted && !bIsCompleted) {
                return 1;
            }

            if (!aIsCompleted && bIsCompleted) {
                return -1;
            }

            // Check if both quests are without a date
            if (!a.start_date && !a.end_date && !b.start_date && !b.end_date) {
                return -1;
            }

            // Sort quests by date / or no date
            const aTime =
                a.start_date && a.end_date
                    ? comparedTimeToState(compareNowToDates(a.start_date, a.end_date))
                    : 'in_progress';
            const bTime =
                b.start_date && b.end_date
                    ? comparedTimeToState(compareNowToDates(b.start_date, b.end_date))
                    : 'in_progress';
            const sortIndex = sortByObject[aTime] - sortByObject[bTime];

            if (sortIndex != 0) {
                return sortIndex;
            }

            // Sort by diff to now
            const aDiff = a.start_date && a.end_date ? nowToDates(a.start_date, a.end_date) : 0;
            const bDiff = b.start_date && b.end_date ? nowToDates(b.start_date, b.end_date) : 0;
            if (aTime === 'awaiting') {
                if (aDiff > bDiff) return -1;
                if (aDiff < bDiff) return 1;
            }
            if (aTime === 'in_progress') {
                if (aDiff > bDiff) return -1;
                if (aDiff < bDiff) return 1;
            }
            if (aTime === 'ended') {
                if (aDiff > bDiff) return 1;
                if (aDiff < bDiff) return -1;
            }

            return sortIndex;
        });
    };

    const animateInList = (listReference: HTMLDivElement[]) => {
        tl.set(listReference, { alpha: 0, scale: 0.7 });
        tl.to(listReference, {
            duration: 0.25,
            alpha: 1,
            scale: 1,
            ease: 'back',
            stagger: {
                amount: 0.35,
                grid: [3, 3],
                // axis: 'y',
                // ease: 'none',
                // from: 'start',
            },
        });
    };

    const setRefs = (target: HTMLDivElement | null, listReference: HTMLDivElement[], itemIndex: number) => {
        if (target && listReference) {
            const ref = (list.current[itemIndex] = target);
            if (itemIndex === quests.length - 1) {
                // All refs are set for this list. So animation is possible.
                animateInList(listReference);
            }
            return ref;
        }
    };

    const questsList = quests.map((quest, i) => {
        if (!hideCompleted || !quest.user_progress[0]?.is_completed) {
            return (
                // TODO: Change to forwardRef for QuestCover instead of container div ref.
                <div
                    ref={(target) => {
                        setRefs(target, list.current, i);
                    }}
                    key={quest.id}
                    className={classes.QuestCoverReferenceContainer}
                >
                    <QuestProvider questOverride={quest}>
                        <QuestCover></QuestCover>
                    </QuestProvider>
                </div>
            );
        }
    });

    const showCompletedButtonClickHandler = () => {
        setHideCompleted(!hideCompleted);
    };

    return (
        <div
            className={`${classes.Quests} ${quests.length == 0 && !loading ? classes['no-content'] : ''}`}
            ref={containerRef}
        >
            {(quests.length > 0 || loading) && (
                <header className={classes.Header}>
                    <h2 className={`$h2 ${classes['title']}`}>{t('Common:HOMEPAGE_QUESTS_HEADER')}</h2>
                    {hasCompletedQuests && (
                        <Button
                            text={
                                hideCompleted
                                    ? t('Common:QUESTS_SHOW_COMPLETED_BUTTON')
                                    : t('Common:QUESTS_HIDE_COMPLETED_BUTTON')
                            }
                            onClick={showCompletedButtonClickHandler}
                            icon={<FontAwesomeIcon icon={hideCompleted ? faEye : faEyeSlash} />}
                            iconSide="left"
                            alert
                        />
                    )}
                </header>
            )}

            {loading && (
                <div className={classes.Loader}>
                    <div className="spinner card Box"></div>
                </div>
            )}

            {!loading && <div className={`card-group`}>{questsList}</div>}
        </div>
    );
};

export default Quests;
