import React, { FunctionComponent } from 'react';

type SVGProps = {
    fill?: string;
};

const TrashIcon: FunctionComponent<SVGProps> = ({ fill = '#ffffff' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14.999" viewBox="0 0 15 14.999">
            <g id="TrashClear" transform="translate(0.246 0.316)">
                <rect
                    id="Rectangle_509"
                    data-name="Rectangle 509"
                    width="15"
                    height="1"
                    transform="translate(-0.246 2.684)"
                    fill={fill}
                />
                <path
                    id="Subtraction_5"
                    data-name="Subtraction 5"
                    d="M12528.754,10350.683h-11v-15h11v15Zm-3-9v6h1v-6Zm-3,0v6h1v-6Zm-3,0v6h1v-6Z"
                    transform="translate(-12515.999 -10336)"
                    fill={fill}
                />
            </g>
        </svg>
    );
};

export default TrashIcon;
