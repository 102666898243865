import React, { FunctionComponent } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import AccessCodeEditDetail from './AccessCodeEditDetail/AccessCodeEditDetail';

const AccessCodes: FunctionComponent = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/create`} exact>
                <AccessCodeEditDetail isCreate={true} />
            </Route>
            <Route path={`${path}/edit/:accessCodeId`} exact>
                <AccessCodeEditDetail isCreate={false} />
            </Route>
        </Switch>
    );
};

export default AccessCodes;
