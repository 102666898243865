import React, { FunctionComponent } from 'react';

type SVGProps = {
    fill?: string;
};

const EditIcon: FunctionComponent<SVGProps> = ({ fill = '#ffffff' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12.358" height="12.323" viewBox="0 0 12.358 12.323">
            <g id="Group_3188" data-name="Group 3188" transform="translate(0 0)">
                <rect
                    id="Rectangle_405"
                    data-name="Rectangle 405"
                    width="8.037"
                    height="3.98"
                    transform="translate(1.734 7.809) rotate(-45)"
                    fill={fill}
                />
                <rect
                    id="Rectangle_406"
                    data-name="Rectangle 406"
                    width="2.124"
                    height="3.98"
                    transform="translate(8.042 1.502) rotate(-45)"
                    fill={fill}
                />
                <path
                    id="Path_2968"
                    data-name="Path 2968"
                    d="M74.282,78.5,77.1,81.314l-.61.61-3.314.464.535-3.314Z"
                    transform="translate(-73.172 -70.066)"
                    fill={fill}
                />
            </g>
        </svg>
    );
};

export default EditIcon;
