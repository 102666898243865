import { FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useConfig } from '../../../context/ConfigContext';
import { ICategory } from '../../../models/Categories';
import AxiosInstance from '../../../utils/axios-instance';

interface FormFields {
    title: string;
    description: string;
    cover_background_size: string;

    // Hidden
    cover_image_id: number;
}

export type ReturnType = {
    handleSubmit: (category: ICategory, formFields: FormFields, actions: FormikHelpers<FormFields>) => Promise<void>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
};

const useCategoryEditForm = (initialValues: FormFields, isCreate: boolean): ReturnType => {
    const { config } = useConfig();
    const [isSaved, setIsSaved] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();

    const notifyCreated = () => toast.success(t('Common:TOAST_THEME_CREATED_SUCCESS'));
    const notifySaved = () => toast.success(t('Common:TOAST_THEME_SAVED_SUCCESS'));

    const handleSubmit = useCallback(
        (category: ICategory, formFields: FormFields, actions: FormikHelpers<FormFields>) => {
            if (isCreate) {
                return AxiosInstance.post(`${config.baseUrl}/categories`, { ...formFields }).then(() => {
                    setIsSaved(true);
                    actions.resetForm({ values: formFields });
                    history.goBack();
                    notifyCreated();
                });
            } else {
                return AxiosInstance.put(`${config.baseUrl}/categories/${category.id}`, { ...formFields }).then(() => {
                    setIsSaved(true);
                    actions.resetForm({ values: formFields });
                    history.goBack();
                    notifySaved();
                });
            }
        },
        [],
    );

    return {
        handleSubmit,
        initialValues,
        isSaved,
        setIsSaved,
    };
};

export default useCategoryEditForm;
