import React, { FunctionComponent } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
// import { NavLink } from 'react-router-dom';
// import BoxHeader from '../../components/UI/Box/BoxHeader/BoxHeader';
import Categories from './Categories/Categories';
import Invite from './Members/Invite/Invite';
import Members from './Members/Members';
import classes from './Settings.module.scss';

const Settings: FunctionComponent = () => {
    const { path } = useRouteMatch();

    return (
        <div className={classes.Settings}>
            {/* <div className={`${classes.Header} Box`}>
                <BoxHeader title="Settings" subtitle=""></BoxHeader>
                <nav>
                    <ul>
                        <li>
                            <NavLink to={`${url}`} exact>
                                Settings
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`${url}/categories`}>Categories</NavLink>
                        </li>
                        <li>
                            <NavLink to={`${url}/members`}>Members</NavLink>
                        </li>
                    </ul>
                </nav>
            </div> */}
            <Switch>
                <Route path={`${path}/categories`} component={Categories} exact />
                <Route path={`${path}/members`} component={Members} exact />
                <Route path={`${path}/members/invite`} component={Invite} />
            </Switch>
        </div>
    );
};

export default Settings;
