import { faExclamation, faImages, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import AnimatedNumber from '../../../components/AnimatedNumber/AnimatedNumber';
import ExtendedMuiList from '../../../components/ExtendedMuiList/ExtendedMuiList';
import AvatarIcon from '../../../components/Icons/AvatarIcon/AvatarIcon';
import ButtonLoadingIcon from '../../../components/Icons/ButtonLoadingIcon/ButtonLoadingIcon';
import CircleIcon from '../../../components/Icons/CircleIcon/CircleIcon';
import NavigationIcon from '../../../components/Icons/NavigationIcon/NavigationIcon';
import PageHeader from '../../../components/PageHeader/PageHeader';
import ProgressBar from '../../../components/ProgressBar/Progressbar';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigContext';
// import { useAuth } from '../../../context/AuthContext';
import { useGroup } from '../../../context/GroupContext';
import { IGroup } from '../../../models/Group';
import { IQuest } from '../../../models/Quest';
import { User } from '../../../models/User';
// import GroupStatistic from './GroupStatistic/GroupStatistic';
import AxiosInstance from '../../../utils/axios-instance';
import { getColor } from '../../../utils/colors';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import classes from './GroupDetail.module.scss';

type QuestDetailProps = {
    questData?: IQuest;
};

type ParamTypes = {
    groupId: string;
};

const GroupDetail: FunctionComponent<QuestDetailProps> = () => {
    const { config } = useConfig();
    const location = useLocation();
    const { t } = useTranslation();

    const history = useHistory();
    const { auth, hasPermission } = useAuth();
    const { groupId } = useParams<ParamTypes>();
    const [groupAPIUrl, setGroupAPIUrl] = useState<string>(() => {
        return groupId;
    });
    const { response, loading } = useAxiosFetch<IGroup>(`${config.baseUrl}/groups/${groupAPIUrl}`);
    const { group, setGroup, actionButtonState } = useGroup();

    const actionButtonText =
        actionButtonState === 'join'
            ? t('Groups:JOIN')
            : actionButtonState === 'leave'
            ? t('Groups:LEAVE')
            : actionButtonState === 'request' && t('Groups:REQUEST_ACCESS');

    const [isSubmitting, setIsSubmitting] = useState(false);

    const buttonLoadingIconColor = getColor('--brand-color-12');

    const onActionButtonHandler = async () => {
        setIsSubmitting(true);
        const values = {
            group_id: group.id,
            user_id: auth.currentUser?.id,
        };

        switch (actionButtonState) {
            case 'join':
                try {
                    const response = await AxiosInstance.post(`${config.baseUrl}/groups/user_add`, values);
                    const groupData: IGroup = response.data;
                    setGroup(groupData);
                    setIsSubmitting(false);
                } catch (error) {
                    setIsSubmitting(false);
                }
                break;
            case 'leave':
                try {
                    const response = await AxiosInstance.post(`${config.baseUrl}/groups/user_remove`, values);
                    const groupData: IGroup = response.data;
                    setGroup(groupData);
                    setIsSubmitting(false);
                } catch (error) {
                    setIsSubmitting(false);
                }
                break;
            case 'request':
            default:
        }
    };

    useEffect(() => {
        // Set questId so loading will start
        if (groupId) {
            setGroupAPIUrl(groupId);
        }

        // Set Quest from State
        const group = location.state as IGroup;
        if (group) {
            setGroup(group);
        }
    }, []);

    useEffect(() => {
        if (response) {
            const questData = response.data;
            setGroup(questData);
        }

        return () => {
            // TODO: Reset group to default or null;
        };
    }, [response]);

    const renderUserItem = (user: User) => {
        return (
            <div className={classes.MemberItem} key={`user_${user.id}`}>
                <div className={classes.MemberAvatar}>
                    <NavigationIcon>
                        <AvatarIcon className={classes.MemberAvatarIcon}></AvatarIcon>
                    </NavigationIcon>
                </div>
                <div className={classes.MemberName}>{user.name}</div>
                <div className="flex-grow"></div>
                <div className={classes.MemberCards}>{user.user_statistics?.cards_played}</div>
            </div>
        );
    };

    const filterDataBySearch = (searchValues: string[]) => {
        const users = group.users || [];

        return users.filter((item) => {
            const name = item.name;

            const searchableValues = { name };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    const groupMembers = (
        <div className={`Box ${classes.GroupMembers}`}>
            <div className={`${classes.GroupMembersHeader}`}>
                <h2 className={`h5 ${classes.GroupMembersTitle}`}>{t('Groups:MEMBERS_HEADER')}</h2>
                <div className={classes.GroupMembersStat}>
                    <AvatarIcon className={classes.GroupMembersIcon}></AvatarIcon>

                    <span className={classes.GroupMembersAmount}>{group.users?.length}</span>
                </div>
                <div className="flex-grow"></div>
                <span className={classes.GroupMembersCards}>{t('Common:CARDS')}</span>
            </div>

            <ExtendedMuiList
                loading={loading}
                filterDataBySearch={filterDataBySearch}
                searchInputLabel={t('Common:MEMBERS_SEARCH_INPUT_LABEL')}
                noItemsMessage={t('Common:LIST_MESSAGE_NO_MEMBERS')}
                noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_MEMBERS')}
                items={group.users || []}
                renderItem={renderUserItem}
            />
        </div>
    );

    const mapGroupToList = (quest: IQuest) => {
        const groupProgress = quest.group_progress;
        const groupTaskProgress = groupProgress.completedTasks / groupProgress.totalTasks;
        return (
            <div className={classes.QuestProgress} key={`${quest.id}`}>
                <div className={`${classes.QuestHeader}`}>
                    <label className={`h5 ${classes.QuestLabel}`}>{quest.title}</label>
                </div>
                <div className={classes.QuestProgressBar}>
                    <ProgressBar
                        progressLabel={`${groupProgress.completedTasks} ${t('Common:TASKS_DONE')}`}
                        completed={groupTaskProgress === 1}
                        timeState={quest.time_state}
                        upperBoundsLabel={`${groupProgress.totalTasks}`}
                        styleClass={quest.time_state}
                        progress={groupTaskProgress}
                        styles={{
                            height: '40px',
                        }}
                    ></ProgressBar>
                </div>
            </div>
        );
    };

    const questsProgressList = group.quests.map(mapGroupToList);

    const editHandler = () => {
        history.push({ pathname: `/groups/edit/${group.id}`, state: group });
    };

    return (
        <Fragment>
            <div className={classes.QuestDetail}>
                <PageHeader
                    title={group.name}
                    subtitle={group.description}
                    editHandler={hasPermission('group.manage') && isDesktop ? editHandler : undefined}
                />

                {loading && (
                    <div className={classes.Loader}>
                        <div className="spinner card Box"></div>
                    </div>
                )}

                {!loading && (
                    <div className={classes.GroupDetailContent}>
                        <div className={classes.Container}>
                            <div className={`Box ${classes.GroupStatistics}`}>
                                <label className={`h5 ${classes.GroupStatisticsLabel}`}>
                                    {t('Groups:STATISTICS_HEADER')}
                                </label>
                                <div className={classes.Statistics}>
                                    <div className={classes.GroupStatistic}>
                                        <CircleIcon className={classes.CardsIcon}>
                                            <FontAwesomeIcon icon={faImages} />
                                        </CircleIcon>
                                        <div className={classes.GroupStatisticContent}>
                                            <div className={classes.GroupStatisticsHeader}>
                                                <h3 className={classes.GroupStatisticsTitle}>
                                                    {t('Groups:STATISTICS_TITLE_CARDS')}
                                                </h3>
                                                <div className={classes.GroupStatisticsSubtitle}>
                                                    {t('Groups:STATISTICS_SUBTITLE_CARDS')}
                                                </div>
                                            </div>
                                            <div className={classes.GroupStatisticsValue}>
                                                <AnimatedNumber
                                                    value={group.group_statistics?.cards_played}
                                                    fixed={true}
                                                ></AnimatedNumber>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.GroupStatistic}>
                                        <CircleIcon className={classes.QuestIcon}>
                                            <FontAwesomeIcon icon={faMapMarkedAlt} />
                                        </CircleIcon>
                                        <div className={classes.GroupStatisticContent}>
                                            <div className={classes.GroupStatisticsHeader}>
                                                <h3 className={classes.GroupStatisticsTitle}>
                                                    {t('Groups:STATISTICS_TITLE_QUESTS')}
                                                </h3>
                                                <div className={classes.GroupStatisticsSubtitle}>
                                                    {t('Groups:STATISTICS_SUBTITLE_QUESTS')}
                                                </div>
                                            </div>
                                            <div className={classes.GroupStatisticsValue}>
                                                <AnimatedNumber
                                                    value={group.group_statistics.quests_played}
                                                    fixed={true}
                                                ></AnimatedNumber>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.GroupStatistic}>
                                        <CircleIcon className={classes.QuestionsCorrectIcon}>
                                            <FontAwesomeIcon icon={faExclamation} />
                                        </CircleIcon>
                                        <div className={classes.GroupStatisticContent}>
                                            <div className={classes.GroupStatisticsHeader}>
                                                <h3 className={classes.GroupStatisticsTitle}>
                                                    {t('Groups:STATISTICS_TITLE_QUESTIONS')}
                                                </h3>
                                                <div className={classes.GroupStatisticsSubtitle}>
                                                    {t('Groups:STATISTICS_SUBTITLE_QUESTION')}
                                                </div>
                                            </div>
                                            <div className={classes.GroupStatisticsValue}>
                                                <AnimatedNumber
                                                    value={group.group_statistics.correct_ratio}
                                                    fixed={true}
                                                ></AnimatedNumber>
                                                %
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Quest Groups Progress */}
                            {questsProgressList.length > 0 && (
                                <div className={`Box ${classes.GroupsProgress}`}>
                                    <div className={classes.MyGroups}>{questsProgressList}</div>
                                </div>
                            )}

                            {!config.tenant.is_public && !group.private && groupMembers}

                            {!group.private && (
                                <div className={classes.GroupActions}>
                                    <button
                                        className={`btn btn-flavour btn-flavour--danger ${classes.GroupActionButton} ${classes[actionButtonState]}`}
                                        type="button"
                                        onClick={onActionButtonHandler}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting && <ButtonLoadingIcon fill={buttonLoadingIconColor} />}
                                        {actionButtonText}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default GroupDetail;
