import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import ButtonLoadingIcon from '../../../../components/Icons/ButtonLoadingIcon/ButtonLoadingIcon';
import TrashIcon from '../../../../components/Icons/TrashIcon/TrashIcon';
import InputField from '../../../../components/InputField/InputField';
import TextAreaField from '../../../../components/TextAreaField/TextAreaField';
import { useModule } from '../../../../context/ModuleContext';
import { getColor } from '../../../../utils/colors';

import classes from './ModuleEditDetailForm.module.scss';

type FormProps = {
    isCreate: boolean;
    handleFormSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    isSubmitting: boolean;
    disabledSubmit: boolean;
    isSaved: boolean;
    deleteHandler: () => void;
    cancelHandler: () => void;
};

const ModuleEditDetailForm: FunctionComponent<FormProps> = ({
    isCreate,
    handleFormSubmit,
    deleteHandler,
    cancelHandler,
    isSubmitting,
    disabledSubmit,
}) => {
    const { t } = useTranslation();
    const { module, setModule } = useModule();
    const buttonLoadingIconColor = getColor('--brand-color-10');

    const titleChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setModule({ ...module, title: event.target.value });
    };

    const descriptionChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setModule({ ...module, description: event.target.value });
    };

    return (
        <form noValidate={true} onSubmit={handleFormSubmit}>
            <div className={classes.formGroup}>
                <div className={classes.ModuleDetail}>
                    <div className={classes.InputContainer}>
                        <div className={`Box ${classes.InputContent}`}>
                            <div className={`${classes.formGroup}`}>
                                <label htmlFor="title">{t('Modules:MODULE_TITLE_INPUT_LABEL')}</label>
                                <InputField
                                    name="title"
                                    type="text"
                                    placeholder={t('Modules:MODULE_TITLE_INPUT_PLACEHOLDER')}
                                    // maxLength={16}
                                    tabIndex={1}
                                    onChange={titleChangeHandler}
                                ></InputField>
                            </div>
                            <div className={`${classes.formGroup}`}>
                                <label htmlFor="description">{t('Modules:MODULE_DESCRIPTION_INPUT_LABEL')}</label>
                                <TextAreaField
                                    name="description"
                                    type="text"
                                    placeholder={t('Modules:MODULE_DESCRIPTION_INPUT_PLACEHOLDER')}
                                    // maxLength={80}
                                    tabIndex={2}
                                    onChange={descriptionChangeHandler}
                                ></TextAreaField>
                            </div>
                        </div>

                        <div className={`${classes.formActions} ${classes.formGap}`}>
                            {!isCreate && (
                                <Button
                                    className={classes.formDelete}
                                    text={t('Common:BUTTON_DELETE')}
                                    danger
                                    icon={<TrashIcon />}
                                    iconSide="left"
                                    tabIndex={5}
                                    onClick={deleteHandler}
                                ></Button>
                            )}

                            <Button
                                text={t('Common:BUTTON_CANCEL')}
                                alt={true}
                                border={true}
                                onClick={cancelHandler}
                                tabIndex={4}
                            ></Button>

                            <button
                                className={`${classes.submitButton} btn btn-flavour btn-flavour--alt`}
                                type="submit"
                                // onSubmit={onSubmit}
                                disabled={disabledSubmit}
                                tabIndex={3}
                            >
                                {isSubmitting && <ButtonLoadingIcon fill={buttonLoadingIconColor} />}
                                {isCreate ? t('Common:BUTTON_CREATE') : t('Common:BUTTON_SAVE')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ModuleEditDetailForm;
