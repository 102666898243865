import { faAngleDown, faAngleUp, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { Fragment, FunctionComponent, useState } from 'react';
import Button from '../../../../components/Button/Button';
import { UserRole } from '../../../../models/User';
import classes from './RoleItem.module.scss';

interface RoleItemProps {
    role: UserRole;
    onDeleteHandler: (role: UserRole) => void;
}

const RoleItem: FunctionComponent<RoleItemProps> = ({ role, onDeleteHandler }) => {
    const [open, setOpen] = useState<boolean>(false);
    const toggle = () => {
        setOpen(!open);
    };

    return (
        <Fragment>
            <ListItem button onClick={toggle}>
                <ListItemText primary={role.name} className={classes.RoleName} />
                <div className={classes.CollapsableListControls}>
                    {!role.is_default && (
                        <Button
                            text=""
                            icon={<FontAwesomeIcon icon={faTrashAlt} />}
                            onClick={() => onDeleteHandler(role)}
                        />
                    )}
                    {open ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                </div>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className={classes.PermissionsList}>
                    {role.permissions?.map((permission) => {
                        return (
                            <ListItem key={`permission_${permission.id}`} button className={classes.nested}>
                                <ListItemText primary={permission.name} />
                            </ListItem>
                        );
                    })}
                </List>
            </Collapse>
        </Fragment>
    );
};

export default RoleItem;
