import React, { FunctionComponent } from 'react';
import classes from './InputError.module.scss';

interface Props {
    error: string | undefined;
}

const InputError: FunctionComponent<Props> = ({ error }) => {
    return <div className={classes.errorMessage}>{error}</div>;
};

export default InputError;
