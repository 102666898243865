import _ from 'lodash';
import React, { Dispatch, FunctionComponent, useReducer } from 'react';
import { createCtx } from '../../context/createCtx';
import { useDeck } from '../../context/DeckContext';
import { ICard } from '../../models/Card';
import { IElement, IQuestionType } from '../../models/Element';
// import { ElementableType, IElement } from '../../models/Element';

export type ElementContextType = {
    state: ElementState;
    dispatch: Dispatch<ElementActionTypes>;
};

export const [useElement, CtxProvider] = createCtx<ElementContextType>();

export enum ActionTypes {
    UPDATE_ELEMENT = 'UPDATE_ELEMENT',
    TYPE_CHANGED = 'TYPE_CHANGED',
    FIELD_CHANGED = 'FIELD_CHANGED',
    ORDER_CHANGED = 'ORDER_CHANGED',
}

export type UpdateElementAction = {
    type: typeof ActionTypes.UPDATE_ELEMENT;
    payload: IElement;
};

export type TypeChangedAction = {
    type: typeof ActionTypes.TYPE_CHANGED;
    payload: IQuestionType;
};

export type FieldChangedAction = {
    type: typeof ActionTypes.FIELD_CHANGED;
    fieldName: string;
    payload: any;
};

export type OrderChangedAction = {
    type: typeof ActionTypes.ORDER_CHANGED;
    payload: number;
};

export type ElementActionTypes = UpdateElementAction | FieldChangedAction | OrderChangedAction | TypeChangedAction;

export type ElementProviderProps = {
    elementData?: IElement;
};

export type ElementState = IElement;

const defaultState: ElementState = {
    id: 0,
    elementable_id: 0,
    elementable_type: 'question_element',
    order: 0,
    created_at: '',
    updated_at: '',
    //
    // elementable: {
    //     id: 0,
    //     created_at: '',
    //     updated_at: '',

    //     // TEXT
    //     body: '',
    //     // QUESTION // TODO: remove question specific fields, because we can use question? for same data
    //     options: [],
    //     sessionAnswer: null,
    //     user_answers: [],
    //     has_been_answered: false,
    //     // MEDIA
    //     type: 'image',
    //     src: '',
    //     // MEDIA VIDEO
    //     provider: 'youtube',
    //     // MEDIA IMAGE
    //     // FORK
    //     navigation_type: 'next',
    // },
};

export const ElementProvider: FunctionComponent<ElementProviderProps> = ({ children, elementData }) => {
    const { currentCard, updateCard } = useDeck();
    const initialState: ElementState = { ...(elementData || defaultState) };

    const reducer = (state: ElementState, action: ElementActionTypes) => {
        switch (action.type) {
            case 'UPDATE_ELEMENT':
                // Also update card within deck context for now until deck context also uses a reducer.
                // if (currentCard) {
                //     const updatedElements = _.cloneDeep(currentCard.elements)?.map((element) => {
                //         if (element.id === action.payload.id) {
                //             return { ...action.payload };
                //         }
                //         return element;
                //     });

                //     if (updatedElements) {
                //         const updatedCardWithElement: ICard = {
                //             ...currentCard,
                //             elements: [...updatedElements],
                //         };
                //         updateCard(updatedCardWithElement);
                //     }
                // }

                // Return new state with updated element.
                return { ...action.payload };
            // case 'TYPE_CHANGED':
            //     console.log('TYPE_CHANGED', action.payload);
            //     return {
            //         ...state,
            //         elementable: {
            //             ...state.elementable,
            //             question_type_id: action.payload.id,
            //             question_type: action.payload,
            //         },
            //     };
            // case 'FIELD_CHANGED':
            //     return {
            //         ...state,
            //         [action.fieldName]: action.payload,
            //     };
            // case 'ORDER_CHANGED':
            //     return {
            //         ...state,
            //         order: action.payload,
            //     };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    return <CtxProvider value={{ state, dispatch }}>{children}</CtxProvider>;
};
