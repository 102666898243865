import _ from 'lodash';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { CardCompleted } from '../components/Deck/DeckDetails';
import { ICard } from '../models/Card';
import { IDeck } from '../models/Deck';
import { IEdge } from '../models/Edge';
import { CtxProvider } from './DeckContext';

type DeckProviderProps = {
    overrides?: Partial<IDeck>;
};

export const DeckProvider: FunctionComponent<DeckProviderProps> = ({ children, overrides }) => {
    const defaultDeck: IDeck = {
        id: 0,
        title: '',
        created_at: '',
        updated_at: '',
        author_id: 0,
        user_progress: [],
        deckSession: {
            id: 0,
            last_card: null,
            is_complete: false,
            cards_completed: 0,
            cards_total: 0,
            is_replay: false,
            edges: [],
        },
        cover_background_size: 'contain',
        cards: [
            {
                id: 0,
                order: 0,
                title: '',
                created_at: '',
                updated_at: '',
                deckSessionProgress: {
                    id: 9999,
                    user_id: 9999,
                    card_id: 9999,
                    completed: 9999,
                    total: 9999,
                    is_completed: true,
                    created_at: '',
                    updated_at: '',
                },
                deck_id: 0,
                end_card: true,
                start_card: false,
                source_of_edges: [],
                target_of_edges: [],
            },
        ],
        cards_count: 0,
        is_published: false,
        allow_history: false,
        show_endings: true,
    };

    /* useStates */

    ///
    /// Deck related states
    ///

    // Deck
    const [deck, updateDeck] = useState<IDeck>(() => {
        return { ...defaultDeck };
    });

    // Cards
    const [cards, setCards] = useState<ICard[]>([]);

    // DeckProgress
    // const [deckProgress, setDeckProgress] = useState<CardCompleted[]>([]);
    // const [deckCompleted, setDeckCompleted] = useState<boolean>(false);
    const history = useHistory();

    // Current card dialog
    const [openCardDialog, setOpenCardDialog] = useState(false);

    // Current deck history edges
    const [edges, setEdges] = useState<IEdge[]>([]);
    const [historyCardIds, setHistoryCardIds] = useState<number[]>([]);
    const [currentHistoryIndex, setCurrentHistoryIndex] = useState(-1);

    ///
    /// Card related states
    ///

    // Current card index within deck
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    // Current card edge
    const [currentCardEdge, setCurrentCardEdge] = useState<IEdge>();
    // Current card previous edge index
    const [previousEdgeIndex, setPreviousEdgeIndex] = useState(-1);

    /* Constants */

    const deckProgress = useMemo(() => {
        const prog: CardCompleted[] = cards.map((card) => {
            const progress = card?.deckSessionProgress;
            let progressBool = false;
            if (progress !== undefined) {
                progressBool = progress.is_completed;
            }
            return { id: card.id, completed: progressBool };
        });

        return prog;
    }, [cards]);

    // That change based on currentcard?
    // These constants dont trigger a re-render when they alter right?
    // But will trigger a useEffect.

    const currentCard = useMemo(() => {
        return cards.find((localCard) => localCard.id === deck.cards?.[currentCardIndex]?.id);
    }, [cards, deck.cards, currentCardIndex]);
    const isHistory = previousEdgeIndex < edges.length - 1;
    const currentCardProgress = deckProgress[currentCardIndex];
    const currentCardCompleted = Boolean(currentCard?.deckSessionProgress?.is_completed);
    const previousButtonEnabled = deck.allow_history;

    /* Functions */

    const setDeck = (newDeck: IDeck) => {
        updateDeck((current) => {
            setCards(newDeck.cards || []);

            const updatedDeck = { ...current, ...newDeck };
            return updatedDeck;
        });
    };

    const addEdge = (edge: IEdge) => {
        if (!deck.deckSession) return;

        const newEdges: IEdge[] = [...edges];
        newEdges.push(edge);

        setEdges(newEdges);
    };

    const addCard = (card: ICard) => {
        updateDeck((current) => {
            // callback of setDeck
            if (current.cards) {
                const newCards = [...current.cards];
                newCards.push(card);
                setCards(newCards);
                const updatedDeck = { ...current, cards: newCards };
                return updatedDeck;
            } else {
                const updatedDeck = { ...current, cards: [card] };
                setCards([card]);
                return updatedDeck;
            }
        });
    };

    const removeCard = (card: ICard) => {
        const filteredArray = cards.filter((item) => item.id !== card.id);

        card.target_of_edges.map((edge) => {
            const sourceCardIndex = filteredArray.findIndex((c) => c.id === edge.source_id);
            const sourceCard = filteredArray[sourceCardIndex];

            if (sourceCard.fork && sourceCard.fork?.question.options.length - 1 <= 1) {
                sourceCard.fork = undefined;
            }

            const newEdges = sourceCard.source_of_edges.filter((e) => e.id !== edge.id);
            sourceCard.source_of_edges = newEdges;

            filteredArray[sourceCardIndex] = sourceCard;
        });

        card.source_of_edges.map((edge) => {
            const targetCardIndex = filteredArray.findIndex((c) => c.id === edge.target_id);
            const targetCard = filteredArray[targetCardIndex];

            const newTargetOfEdges = [...targetCard.target_of_edges];
            const filteredEdges = newTargetOfEdges.filter((e) => e.id !== edge.id);

            const newTargetCard = {
                ...targetCard,
                target_of_edges: filteredEdges,
            };

            filteredArray[targetCardIndex] = newTargetCard;
        });

        setDeck({ ...deck, cards: filteredArray });
    };

    const updateCard = (card: ICard) => {
        updateDeck((current) => {
            // callback of setDeck
            if (current.cards) {
                // Find card to update
                const cardIndex = current.cards.findIndex((c) => c.id === card.id);
                const newCards = _.cloneDeep(current.cards);
                newCards[cardIndex] = { ...card };

                setCards(newCards);
                const updatedDeck = { ...current, cards: newCards };

                return updatedDeck;
            } else {
                const updatedDeck = { ...current, cards: [card] };
                setCards([card]);
                return updatedDeck;
            }
        });
    };

    const updateCards = (cards: ICard[]) => {
        updateDeck((current) => {
            const updatedDeck = { ...current, cards };
            setCards(cards);
            return updatedDeck;
        });
    };

    const nextCard = (nextIndex: number) => {
        setCurrentCardIndex((previousValue) => {
            if (cards) {
                const max = cards.length - 1;
                return nextIndex !== -1 && nextIndex <= max ? nextIndex : previousValue;
            } else {
                return previousValue;
            }
        });
    };

    const previousCard = () => {
        setCurrentCardIndex((previousValue) => {
            if (cards && edges && previousEdgeIndex !== -1) {
                const prevEdge = edges[previousEdgeIndex];
                const prevIndex = cards.findIndex((card) => card.id === prevEdge?.source_id);
                return prevIndex !== -1 ? prevIndex : previousValue;
            }

            return previousValue;
        });

        setPreviousEdgeIndex((previousValue) => {
            if (edges) {
                const prevIndex = previousValue - 1;
                const min = -1;
                const max = edges.length - 1;
                return Math.min(max, Math.max(min, prevIndex));
            }

            return previousValue;
        });

        setCurrentHistoryIndex((previousValue) => {
            return previousValue - 1;
        });
    };

    const setProgressWhereToContinue = (
        deckData: IDeck,
        initial = false,
        deckState?: { deck: IDeck; currentCardIndex: number; currentCardId: number },
        queryCardNum?: string | null,
    ) => {
        console.log('here', deckData, initial, deckState, queryCardNum);
        // If the user already made progress, set start index to the next card they have to complete
        let startIndex = deckData.cards?.findIndex(
            (card) => card.id === _.last(deckData.deckSession?.edges)?.target_id,
        );

        if (startIndex === undefined) startIndex = -1;

        // If a cardNumber has been specified in the url, override startIndex.
        if (initial && queryCardNum) {
            if (queryCardNum && parseInt(queryCardNum) <= (deckData.cards?.length || 0) - 1) {
                startIndex = parseInt(queryCardNum);
            }
        }

        // If a card has been specified by index in the location state, override startIndex.
        // const props = location.state as { deck: IDeck; currentCardIndex: number; currentCardId: number };

        if (deckState && deckState.currentCardIndex >= 0) {
            startIndex = deckState.currentCardIndex;
            history.replace({ state: {} });
        }

        // If a card has been specified by id in the location state, override startIndex.
        if (deckState && deckState.currentCardId >= 0) {
            const cardIndex = deckData.cards?.findIndex((localCard) => localCard.id === deckState.currentCardId);

            if (cardIndex && cardIndex >= 0) {
                startIndex = cardIndex;
                setHistoryCardIds([deckState.currentCardId]);
                setCurrentHistoryIndex(0);
                history.replace({ state: {} });
            }
        }

        // If no progress has been made and no cards have been specified, start at the beginning.
        if (startIndex === -1) {
            // Add start card to history card ids if we did not have any history yet
            if (deckData.deckSession?.edges && deckData.deckSession.edges.length === 0) {
                const startCard = deckData.cards?.find((c) => c.start_card);
                if (startCard) {
                    setHistoryCardIds([startCard.id]);
                    setCurrentHistoryIndex(0);
                }
            }

            startIndex = deckData.cards?.findIndex((card) => card.start_card) || 0;
        }

        setCurrentCardIndex(startIndex);
    };

    const currentAnswerFromSession = () => {
        if (currentCard) {
            let historyAnswerIndex = 0;

            _.forEach(historyCardIds, (cardId, index) => {
                if (cardId === currentCard.id) historyAnswerIndex += 1;
                if (index === currentHistoryIndex) return false;
            });

            const currentSessionAnswerIndex = historyAnswerIndex - 1;
            const currentAnswer = currentCard?.fork?.question.sessionAnswers[currentSessionAnswerIndex];

            if (!isHistory) {
                // If we are on current card
                const sessionAnswersLength = currentCard?.fork?.question.sessionAnswers.length || 0;

                if (sessionAnswersLength < historyAnswerIndex) {
                    return undefined;
                }
            }

            if (currentAnswer) return [currentAnswer];
            else return [];
        }
    };

    const hasBranchingEndOnCard = (card?: ICard) => {
        if (!card) return false;
        return !Boolean(card?.source_of_edges.length > 0);
    };

    const nextButtonEnabled = currentCard?.fork
        ? Boolean(currentAnswerFromSession()) && currentCardCompleted && !hasBranchingEndOnCard(currentCard)
        : currentCardCompleted && !hasBranchingEndOnCard(currentCard);

    const isDeckCompleted = useMemo(() => {
        const lastHistoryCard = _.last(historyCardIds);

        if (lastHistoryCard) {
            const card = cards.find((card) => card.id === lastHistoryCard);
            const hasBranchingEnd = hasBranchingEndOnCard(card);

            return hasBranchingEnd && Boolean(card?.deckSessionProgress?.is_completed);
        } else {
            return false;
        }
    }, [historyCardIds, cards]);

    useEffect(() => {
        if (currentCard && currentCard.source_of_edges.length > 0) {
            if (isHistory) {
                setCurrentCardEdge(edges[previousEdgeIndex + 1]);
            } else {
                // if fork has sessionAnswer ? but

                if (currentCard?.fork) {
                    setCurrentCardEdge(currentCard?.fork?.question.sessionAnswer?.options?.[0]?.action_of_edges?.[0]);
                } else {
                    setCurrentCardEdge(currentCard?.source_of_edges[0]);
                }
            }
        } else {
            setCurrentCardEdge(undefined);
        }
    }, [currentCard]);

    return (
        <CtxProvider
            value={{
                deck,
                setDeck,
                cards,
                updateCards,
                addCard,
                updateCard,
                removeCard,
                edges,
                setEdges,
                addEdge,
                previousEdgeIndex,
                setPreviousEdgeIndex,
                historyCardIds,
                setHistoryCardIds,
                currentHistoryIndex,
                setCurrentHistoryIndex,
                isHistory,
                setProgressWhereToContinue,
                currentCardIndex,
                setCurrentCardIndex,
                currentCardEdge,
                setCurrentCardEdge,
                currentCard,
                currentCardProgress,
                currentAnswerFromSession,
                deckProgress,
                isDeckCompleted,
                nextCard,
                previousCard,
                previousButtonEnabled,
                nextButtonEnabled,
                currentCardCompleted,
                openCardDialog,
                setOpenCardDialog,
            }}
        >
            {children}
        </CtxProvider>
    );
};
