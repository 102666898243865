import { Formik } from 'formik';
import _ from 'lodash';
import React, { createRef, Fragment, FunctionComponent, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import * as Yup from 'yup';
import { useConfig } from '../../context/ConfigContext';
import { useDeck } from '../../context/DeckContext';
import { CardProps, CardStatus, ICard } from '../../models/Card';
import { IDeck } from '../../models/Deck';
import { IElement, IFork, IUserAnswer, QuestionTypes } from '../../models/Element';
import { IProgress } from '../../models/Progress';
import useElementsService from '../../services/ElementsService';
import AxiosInstance from '../../utils/axios-instance';
import { isAnswerError, IUserAnswerError } from '../Elements/Question/OpenQuestion/OpenQuestionFormHook';
import InputField from '../InputField/InputField';
import ReadProgressBar from '../ReadProgressBar/ReadProgressBar';
import CardDeleteButton from './Buttons/CardDeleteButton/CardDeleteButton';
import CardSaveButton from './Buttons/CardSaveButton/CardSaveButton';
import classes from './Card.module.scss';
import CardStatusIndicator from './CardStatusIndicator/CardStatusIndicator';
import ElementsList from './ElementsList/ElementsList';
import ForkQuestion from './ForkQuestion/ForkQuestion';
import useCardForm, { FormFields } from './useCardFrom';
// import { IQuestionAnswerResponse } from './ForkQuestion/useForkQuestionForm';

const Card: FunctionComponent<CardProps> = (props) => {
    // hooks
    const { config } = useConfig();
    const { t } = useTranslation();
    const {
        cards,
        deck,
        setDeck,
        updateCard,
        currentCard,
        isHistory,
        historyCardIds,
        currentHistoryIndex,
        currentAnswerFromSession,
        isDeckCompleted,
    } = useDeck();
    const { createElement } = useElementsService();
    const { initialValues, handleSubmit, isSaved, setIsSaved } = useCardForm({
        title: props.cardData?.title || '',
        end_card: props.cardData?.end_card || true,
    });
    // useStates
    const [elements, setElements] = useState(() => props.cardData?.elements || []);
    const [reOrderedElements, setReOrderedElements] = useState(props.cardData?.elements || []);
    const [lastAddedElementId, setLastAddedElementId] = useState<number | null>(null);

    const isCardCompleted = useMemo(() => {
        if (currentCard?.deckSessionProgress?.is_completed) {
            return true;
        }
        if (isDeckCompleted) {
            return true;
        }
        return false;
    }, [currentCard, isDeckCompleted]);

    const [inViewRef, inView] = useInView({
        initialInView: isCardCompleted,
        skip: isCardCompleted,
        triggerOnce: true,
        threshold: [1],
    });
    // variables
    const hasQuestions = elements.filter((el) => el.elementable_type === 'question_element').length != 0;
    const isCurrent = props.current ? 'is-current' : 'isnot-current';
    const schema = Yup.object({
        title: Yup.string().required(t('Common:INPUT_ERROR_TITLE_REQUIRED')),
    });
    // refs
    const cardRef = createRef<HTMLDivElement>();
    const cardContentRef = createRef<HTMLDivElement>();

    // useEffects
    useLayoutEffect(() => {
        if (props.current) {
            if (!props.isEdit) {
                props.layoutEffect?.();
                const completedCard = checkCardCompletion(elements);

                if (completedCard) {
                    updateCard({ ...completedCard });
                }
            }
        }
    }, [props.current]);

    useEffect(() => {
        if (props.current && !props.isEdit && inView) {
            const completedCard = checkCardCompletion(elements);
            if (completedCard) {
                updateCard({ ...completedCard });
            }
        }
    }, [inView]);

    useEffect(() => {
        if (!props.isReorder) {
            setElements(reOrderedElements);
        }
    }, [props.isReorder]);

    useEffect(() => {
        if (props.isAddElementType && currentCard) {
            createElement(props.isAddElementType, currentCard).then((response) => {
                addElement(response.data);
            });
        }
    }, [props.isAddElementType]);

    useEffect(() => {
        // Scrolls to bottom in CardDialog
        if (lastAddedElementId) {
            props.onElementAddedTriggered?.();
        }
    }, [lastAddedElementId]);

    useEffect(() => {
        console.log('useEffect elements changed', elements);

        let completedCard: ICard | undefined = undefined;

        if (!props.isEdit) {
            completedCard = checkCardCompletion(elements);
        }

        updateDeck(elements, completedCard);
    }, [elements]);

    // Functions

    const addElement = (element: IElement) => {
        console.log('Card - addElement');
        const newElements: IElement[] = _.cloneDeep(elements);
        newElements.push(element);
        setLastAddedElementId(element.id);
        setElements(newElements);
        setReOrderedElements(newElements);
    };

    const removeElement = (element: IElement) => {
        console.log('Card - removeElement');
        const filteredArray = elements.filter((item) => item.id !== element.id);
        setElements(filteredArray);
        setReOrderedElements(filteredArray);
    };

    const updateElement = (element: IElement) => {
        const updatedElements = _.cloneDeep(elements)?.map((element) => {
            if (element.id === element.id) {
                return { ...element };
            }
            return element;
        });

        setElements([...updatedElements]);
    };

    const hasUserAnswers = () => {
        if (hasQuestions) {
            const questions = elements.filter((el) => el.elementable_type === 'question_element');
            const questionsAnswered = questions?.map((el) => el.elementable?.sessionAnswer !== null).filter(Boolean);
            const hasAnswers = questionsAnswered && questionsAnswered.length > 0;

            return hasAnswers;
        } else {
            return false;
        }
    };

    const allQuestionsAnswered = (newElements: IElement[]) => {
        if (hasQuestions) {
            const questions = newElements.filter((el) => el.elementable_type === 'question_element');
            const questionsAnswered = questions
                ?.map((el) => {
                    return el.elementable?.sessionAnswer && el.elementable?.sessionAnswer !== null;
                })
                .filter(Boolean);
            const allQuestionsAnswered =
                questions && questionsAnswered && questionsAnswered.length === questions.length;

            if (allQuestionsAnswered) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    const [cardStatus, setCardStatus] = useState(() => {
        let cardStatus: CardStatus = 'new';

        if (!props.isEdit) {
            const progress = currentCard?.deckSessionProgress;
            if (progress != null) {
                cardStatus = 'in_progress';
                const isCompleted = progress.is_completed;
                if (isCompleted) {
                    cardStatus = 'completed';
                }
            } else {
                // Check for element questions with userprogress
                if (hasUserAnswers()) {
                    cardStatus = 'in_progress';

                    if (allQuestionsAnswered(elements)) {
                        cardStatus = 'completed';
                    }
                }
            }
        }

        return cardStatus;
    });

    const completeCard = () => {
        if (!isCardCompleted && currentCard) {
            const updatedDeckSession = _.cloneDeep(currentCard);

            let updatedProgress: IProgress;
            if (updatedDeckSession.deckSessionProgress) {
                updatedProgress = _.cloneDeep(updatedDeckSession.deckSessionProgress);
                updatedProgress.is_completed = true;
            } else {
                updatedProgress = {
                    id: 9999,
                    user_id: 9999,
                    card_id: 9999,
                    completed: 9999,
                    total: 9999,
                    is_completed: true,
                    created_at: '',
                    updated_at: '',
                };
            }

            setCardStatus('completed');

            if (props.current) {
                if (!props.isEdit) {
                    if (!isCardCompleted) {
                        AxiosInstance.post<IProgress>(`${config.baseUrl}/cards/complete/${currentCard.id}`);
                    }
                }
            }

            return { ...currentCard, deckSessionProgress: updatedProgress };
        }
    };

    const checkCardCompletion = (newElements: IElement[], forkHasBeenAnswered = false) => {
        const hasQuestions = newElements.filter((el) => el.elementable_type === 'question_element');
        if (hasQuestions.length === 0) {
            if (currentCard?.fork ? (forkHasBeenAnswered || isForkAnswered()) && inView : inView) {
                return completeCardBasedOnDeckSession();
            } else {
                setCardStatus('in_progress');
            }
        } else {
            const allAnswered = allQuestionsAnswered(newElements);

            if (currentCard?.fork ? (forkHasBeenAnswered || isForkAnswered()) && allAnswered : allAnswered) {
                return completeCardBasedOnDeckSession();
            } else {
                setCardStatus('in_progress');
            }
        }
    };

    const completeCardBasedOnDeckSession = () => {
        const progress = currentCard?.deckSessionProgress;
        if (progress != null && !isCardCompleted) {
            const isCompleted = progress.is_completed;
            if (!isCompleted) {
                return completeCard();
            }
        } else if (progress != null && isCardCompleted) {
        } else {
            return completeCard();
        }
    };

    const onAnswered = (element: IElement, userAnswer: IUserAnswer | IUserAnswerError) => {
        props.onQuestionAnswered?.(element, userAnswer);
        console.log('userAnswer', userAnswer);
        console.log('element', element);
        if (isAnswerError(userAnswer)) {
            return;
        }

        setElements((previousElements) => {
            const elementable = previousElements.find((el) => el.id === element.id)?.elementable;
            if (elementable) {
                const newElementable = { ...elementable };
                newElementable.sessionAnswer = userAnswer;

                const newElement = { ...element, elementable: newElementable };
                const elementIndex = previousElements.findIndex((el) => {
                    return el.id === newElement.id;
                });
                const newElements: IElement[] = _.cloneDeep(previousElements);
                newElements[elementIndex] = newElement;

                return newElements;
            }

            return _.cloneDeep(previousElements);
        });
    };

    const onForkQuestionAnswered = (fork: IFork, userAnswer: IUserAnswer, wasAlreadyAnswered: boolean | undefined) => {
        if (currentCard) {
            const newCard = _.cloneDeep(currentCard);

            // Update sessionAnswers
            const newSessionAnswers = _.cloneDeep(currentCard?.fork?.question.sessionAnswers) || [];
            if (wasAlreadyAnswered === false) {
                newSessionAnswers.push(userAnswer);
            } else {
                const lastFoundIndex = Math.max(0, newSessionAnswers.length - 1);
                newSessionAnswers[lastFoundIndex] = userAnswer;
            }

            const newQuestion = { ...fork.question, sessionAnswer: userAnswer, sessionAnswers: newSessionAnswers };
            newCard.fork = { ...fork, question: newQuestion };

            // Update deckSessionProgress

            const allAnswered = allQuestionsAnswered(elements);

            // if (allAnswered) {
            let updatedProgress: IProgress;
            if (newCard.deckSessionProgress) {
                updatedProgress = _.cloneDeep(newCard.deckSessionProgress);
                updatedProgress.is_completed = allAnswered;
            } else {
                updatedProgress = {
                    id: 9999,
                    user_id: 9999,
                    card_id: 9999,
                    completed: 9999,
                    total: 9999,
                    is_completed: allAnswered,
                    created_at: '',
                    updated_at: '',
                };
            }

            // Update card withing deckprovider
            updateCard({ ...newCard, deckSessionProgress: updatedProgress });

            if (allAnswered) {
                if (props.current) {
                    if (!props.isEdit) {
                        setCardStatus('completed');

                        if (!isCardCompleted) {
                            AxiosInstance.post<IProgress>(`${config.baseUrl}/cards/complete/${currentCard.id}`);
                        }
                    }
                }
            }
        }
    };

    const onElementDeleteHandler = (element: IElement) => {
        removeElement(element);
    };

    const onElementUpdated = (element: IElement) => {
        // Update state elements
        const elementIndex = elements.findIndex((el) => {
            return el.id === element.id;
        });

        const newElements: IElement[] = _.cloneDeep(elements);
        newElements[elementIndex] = element;

        setElements(newElements);
    };

    const updateDeck = (updatedElements: IElement[], completedCard?: ICard) => {
        const foundCard = cards.find((c) => {
            return c.id === currentCard?.id;
        });

        if (foundCard) {
            const updatedCard: ICard = { ...foundCard, ...completedCard, elements: updatedElements };

            const elementsAreNotEqual = !_.isEqual(foundCard?.elements, updatedCard.elements);

            if (elementsAreNotEqual) {
                // Update deck because elements are not equal
                const updatedCards = cards.map((card) => {
                    if (card.id === updatedCard.id) {
                        return updatedCard;
                    } else {
                        return card;
                    }
                });
                const updatedDeck: IDeck = { ...deck, cards: updatedCards };
                setDeck(updatedDeck);
            }
        }
    };

    const titleChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsSaved(false);
    };

    const handleAfterSubmitUpdateDeck = (formValues: FormFields) => {
        if (currentCard) {
            if (deck.cards) {
                const updatedCard: ICard = { ...currentCard, title: formValues.title };
                const updatedCards = cards.map((card) => {
                    if (card.id === updatedCard.id) {
                        return updatedCard;
                    } else {
                        return card;
                    }
                });
                const updatedDeck: IDeck = { ...deck, cards: updatedCards };
                setDeck({ ...updatedDeck });
            }
        }
    };

    const isForkAnswered = () => {
        if (currentCard?.fork) {
            return Boolean(currentAnswerFromSession());
        }
        return false;
    };

    const getHistoryAnswers = () => {
        if (currentCard) {
            let count = 0;

            _.forEach(historyCardIds, (cardId, index) => {
                if (cardId === currentCard.id) count += 1;
                if (index === currentHistoryIndex) return false;
            });

            const currentSessionAnswerIndex = count - 1;
            const currentHistoryAnswer = currentCard?.fork?.question.sessionAnswers[currentSessionAnswerIndex];
            if (currentHistoryAnswer) {
                return [currentHistoryAnswer];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    };

    return currentCard ? (
        <div
            key={currentCard.id}
            className={`${!props.current ? 'card' : ''} ${props.propClasses} Box ${classes.Card} ${
                classes[props.stateClass || '']
            } ${classes[props.filterClass || '']}`}
            style={props.style}
            ref={cardRef}
        >
            <div style={props.overlayStyle} className={`${classes.Overlay} ${classes[isCurrent]}`}></div>

            {props.stateClass === 'card-current' && (
                <Fragment>
                    <Formik
                        onSubmit={(formValues, actions) => {
                            handleSubmit(currentCard, formValues, actions).then(() => {
                                handleAfterSubmitUpdateDeck(formValues);
                            });
                        }}
                        initialValues={initialValues}
                        validationSchema={schema}
                    >
                        {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                            const disabledSubmit = isSubmitting || !(isValid && dirty);
                            return (
                                <div className={`${classes.Header} ${props.isEdit && classes.edit}`}>
                                    <form noValidate={true} onSubmit={handleSubmit}>
                                        {props.isEdit ? (
                                            <div className={`${classes.formGroup}`}>
                                                <label>{t('Deck:CARD_TITLE_INPUT_LABEL')}</label>
                                                <div className={classes.TitleInput}>
                                                    <InputField
                                                        id={`title`}
                                                        name={`title`}
                                                        type="text"
                                                        placeholder={t('Deck:CARD_TITLE_INPUT_PLACEHOLDER')}
                                                        onChange={titleChangeHandler}
                                                    ></InputField>
                                                    {!isSaved && (
                                                        <CardSaveButton
                                                            isSubmitting={isSubmitting}
                                                            disabledSubmit={disabledSubmit}
                                                        ></CardSaveButton>
                                                    )}

                                                    {props.enableDeleteCardButton && (
                                                        <CardDeleteButton
                                                            card={currentCard}
                                                            deleteHandler={props.deleteHandler}
                                                        ></CardDeleteButton>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <div className={classes.TitleContainer}>
                                                    <h2 className={`h2 ${classes.Title}`}>{currentCard.title}</h2>
                                                    {cardStatus === 'completed' && (
                                                        <CardStatusIndicator status={cardStatus} />
                                                    )}
                                                </div>

                                                <ReadProgressBar attachTo={cardRef} init={true} status={cardStatus} />
                                            </>
                                        )}
                                    </form>
                                </div>
                            );
                        }}
                    </Formik>
                    <div className={classes.Content} ref={cardContentRef}>
                        {elements.length != 0 && (
                            <ElementsList
                                elements={elements}
                                isEdit={props.isEdit}
                                isReorder={props.isReorder || false}
                                lastAddedElementId={lastAddedElementId}
                                onReorderElements={(elements) => setReOrderedElements(elements)}
                                onQuestionAnswered={onAnswered}
                                updateElement={onElementUpdated}
                                deleteElement={onElementDeleteHandler}
                                deleteHandler={onElementDeleteHandler}
                                addNewCardHandler={props.addNewCardHandler}
                                onNavigateToCardById={props.onNavigateToCardById}
                            ></ElementsList>
                        )}
                    </div>

                    {!props.isEdit && currentCard.fork && (
                        <ForkQuestion
                            card={currentCard}
                            fork={currentCard.fork}
                            body={currentCard.fork.question.body}
                            options={currentCard.fork.question.options}
                            user_answers={isHistory ? getHistoryAnswers() : currentAnswerFromSession()}
                            onQuestionAnswered={onForkQuestionAnswered}
                        ></ForkQuestion>
                    )}

                    <div ref={inViewRef} />
                </Fragment>
            )}
        </div>
    ) : (
        <div></div>
    );
};

export default Card;
