import React, { Fragment, FunctionComponent } from 'react';
import InvitedList from './InvitedList/InvitedList';
import MembersList from './MembersList/MembersList';

export type PaginationProps = {
    current_page: number;
    last_page: number;
    total: number;
};

const ManagementMembers: FunctionComponent = () => {
    return (
        <Fragment>
            <MembersList />
            <InvitedList />
        </Fragment>
    );
};

export default ManagementMembers;
