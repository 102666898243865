import React, { useState, FunctionComponent } from 'react';
import { ICategory } from '../models/Categories';
import { CtxProvider } from './CategoryContext';

type CategoryProviderProps = {
    overrides?: Partial<ICategory>;
};

export const CategoryProvider: FunctionComponent<CategoryProviderProps> = ({ children, overrides }) => {
    const defaultCategory: ICategory = {
        title: '', // WWWWWWWWWWWWWWWW
        description: '', // WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW
        order: 0,
        id: 0,
        created_at: '',
        updated_at: '',
        user_progress: [],
        cover_background_size: 'contain',
    };

    const [category, setCategory] = useState<ICategory>({
        ...defaultCategory,
        ...overrides,
    });

    const updateCategory = (category: ICategory) => {
        setCategory((current) => {
            return { ...current, ...category };
        });
    };

    const [isDataChanged, setIsDataChanged] = useState(false);

    return (
        <CtxProvider value={{ category, setCategory, updateCategory, isDataChanged, setIsDataChanged }}>
            {children}
        </CtxProvider>
    );
};
