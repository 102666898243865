import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { ReportType } from '../ReportForm';

interface ReportTypeSelectProps {
    onReportTypeChangedHandler: (type: ReportType) => void;
}

export interface IReportType {
    label: string;
    value: ReportType;
}

const ReportTypeSelect: FunctionComponent<ReportTypeSelectProps> = ({ onReportTypeChangedHandler }) => {
    const { t } = useTranslation();

    const reportTypes: IReportType[] = [
        {
            label: t('Common:REPORT_TYPE_CONTENT'),
            value: 'content',
        },
        {
            label: t('Common:REPORT_TYPE_BUG'),
            value: 'bug',
        },
        {
            label: t('Common:REPORT_TYPE_REQUEST'),
            value: 'request',
        },
    ];

    return (
        <ReactSelect
            name="report-type"
            defaultValue={reportTypes[0]}
            options={reportTypes}
            onChange={(type) => {
                if (type) {
                    onReportTypeChangedHandler(type.value);
                }
            }}
        />
    );
};

export default ReportTypeSelect;
