import React, { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import ButtonLoadingIcon from '../../components/Icons/ButtonLoadingIcon/ButtonLoadingIcon';
import BoxHeader from '../../components/UI/Box/BoxHeader/BoxHeader';
import { useAuth } from '../../context/AuthContext';
import { useConfig } from '../../context/ConfigContext';
import { BackgroundSize } from '../../models/ImageUpload';
import { User } from '../../models/User';
import http from '../../utils/axios-instance';
import { getColor } from '../../utils/colors';
import { getLocalStorageUser, setLocalStorageUser } from '../../utils/flavourstorage';
import classes from './EmailValidation.module.scss';

export type VerificationParamTypes = {
    signature: string;
    userId: string;
    token: string;
    expires: string;
};

export type VerificationData = {
    verificationData?: VerificationParamTypes;
};

export type EmailVerifyResponse = {
    user: {
        id: number;
        name: string;
        email: string;
        email_verified_at: string;
        email_verified: boolean;
        show_email: number; // boolean
        job_title: string;
        description: string;
        profile_picture_id: number;
        created_at: string;
        updated_at: string;
        profile_picture_background_size: BackgroundSize;
    };
};

const EmailValidation: FunctionComponent = () => {
    const { config, loading: loadingConfig } = useConfig();
    const history = useHistory();
    const { auth, autoLogin } = useAuth();
    const { userId, token, expires, signature } = useParams<VerificationParamTypes>();
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEmailSend, setIsEmailSend] = useState(false);
    const [isVerificationDataRequired, setIsVerificationDataRequired] = useState(false);
    const [isLoginRequired, setIsLoginRequired] = useState(false);
    const { t } = useTranslation();
    const buttonLoadingIconColor = getColor('--brand-color-10');
    const [accountVerified, setAccountVerified] = useState(false);

    useEffect(() => {
        if (!loadingConfig) {
            const data = userId && token && expires && signature;
            const verificationData =
                data && userId != '000' && token != '000' && expires != '000' && signature != '000';

            if (!auth.currentUser?.token && !verificationData) {
                setIsLoginRequired(true);
                setIsVerificationDataRequired(true);
            }

            if (!auth.currentUser?.token && verificationData) {
                setIsLoginRequired(true);
                setIsVerificationDataRequired(false);
            }

            if (auth.currentUser?.token && !verificationData) {
                setIsLoginRequired(false);
                setIsVerificationDataRequired(true);
            }

            if (auth.currentUser?.token && verificationData) {
                setIsLoginRequired(false);
                setIsVerificationDataRequired(false);

                verifyAccountCall();
            }
        }
    }, [loadingConfig, userId, token, expires, signature, auth.currentUser?.token]);

    useEffect(() => {
        if (accountVerified) {
            notifyAccountVerified();
            history.replace('/home');
        }
    }, [accountVerified]);

    const backToLoginHandler = () => {
        const verificationData: VerificationData = { verificationData: { userId, token, expires, signature } };
        history.replace({ pathname: '/login', state: verificationData });
    };

    const backToLoginNoDataHandler = () => {
        history.replace('/login');
    };

    const sendEmailVerificationHandler = async () => {
        try {
            setIsSubmitting(true);
            await http.post(`${config.baseUrl}/email/verify/resend`);

            setIsSubmitting(false);
            setIsEmailSend(true);
        } catch (error) {
            setIsSubmitting(false);
        }
    };

    const verifyAccountCall = async () => {
        try {
            const response = await http.get(
                `${config.baseUrl}/email/verify/${userId}/${token}?expires=${expires}&signature=${signature}`,
            );

            const emailVerifyResponse: EmailVerifyResponse = response.data;

            const localUser = getLocalStorageUser();

            if (!localUser) {
                history.replace('/login');
            } else {
                const user: User = JSON.parse(localUser);
                const alteredUser: User = {
                    ...user,
                    email_verified: emailVerifyResponse.user.email_verified,
                    email_verified_at: emailVerifyResponse.user.email_verified_at,
                };
                setLocalStorageUser(alteredUser);
                autoLogin(alteredUser);

                setAccountVerified(true);
            }

            setLoading(false);
        } catch (error) {
            // TODO: something is wrong with account verification!
            setLoading(false);
        }
    };

    const notifyAccountVerified = () => toast.success(t('Common:TOAST_ACCOUNT_VERIFIED_SUCCESS'));

    return (
        <div className={classes.EmailValidation}>
            {loading && (
                <div className={classes.Loader}>
                    <div className="spinner card Box"></div>
                </div>
            )}

            {!loading && isLoginRequired && isVerificationDataRequired && (
                <div className={classes.AccountVerified}>
                    <div className="Box">
                        <BoxHeader title="isLoginRequired AND isVerificationDataRequired" subtitle=""></BoxHeader>
                        <button
                            className={`${classes.submitButton} btn btn-flavour btn-flavour--alert`}
                            type="button"
                            onClick={backToLoginNoDataHandler}
                        >
                            {t('Auth:RETURN_TO_LOGIN')}
                        </button>
                    </div>
                </div>
            )}

            {!loading && !isLoginRequired && isVerificationDataRequired && !isEmailSend && (
                <div className={classes.AccountVerified}>
                    <div className="Box">
                        <BoxHeader title={t('Auth:ACCOUNT_VERIFY_DATA_REQUIRED_TITLE')} subtitle=""></BoxHeader>

                        <p>{t('Auth:ACCOUNT_VERIFY_DATA_REQUIRED_DESCRIPTION')}</p>
                        <button
                            className={`${classes.submitButton} btn btn-flavour btn-flavour--alert`}
                            type="button"
                            onClick={sendEmailVerificationHandler}
                        >
                            {isSubmitting && <ButtonLoadingIcon fill={buttonLoadingIconColor} />}
                            {t('Auth:ACCOUNT_VERIFY_DATA_REQUIRED_BUTTON')}
                        </button>
                    </div>
                </div>
            )}

            {!loading && !isLoginRequired && isVerificationDataRequired && isEmailSend && (
                <div className={classes.AccountVerified}>
                    <div className="Box">
                        <BoxHeader
                            title={t('Auth:ACCOUNT_VERIFY_TITLE')}
                            subtitle={t('Auth:ACCOUNT_VERIFY_SUBTITLE')}
                        ></BoxHeader>
                        <p>{t('Auth:ACCOUNT_VERIFY_DESCRIPTION')}</p>
                    </div>
                </div>
            )}

            {!loading && isLoginRequired && !isVerificationDataRequired && (
                <div className={classes.AccountVerified}>
                    <div className="Box">
                        <BoxHeader
                            title={t('Auth:ACCOUNT_LOGIN_REQUIRED_TITLE')}
                            subtitle={t('Auth:ACCOUNT_LOGIN_REQUIRED_SUBTITLE')}
                        ></BoxHeader>

                        <button
                            className={`${classes.submitButton} btn btn-flavour btn-flavour--alert`}
                            type="button"
                            onClick={backToLoginHandler}
                        >
                            {t('Auth:RETURN_TO_LOGIN')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmailValidation;
