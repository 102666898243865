import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import classes from './ButtonLoadingIcon.module.scss';

type SVGProps = {
    fill?: string;
};

const ButtonLoadingIcon: FunctionComponent<SVGProps> = ({ fill = '#ffffff' }) => {
    return <FontAwesomeIcon className={classes.ButtonLoadingIcon} icon={faCircleNotch} color={fill} spin={true} />;
};

export default ButtonLoadingIcon;
