import React, { FunctionComponent } from 'react';
import { TimerProvider } from '../../../context/TimerProvider';
import { ICard } from '../../../models/Card';
import { IElement, IUserAnswer } from '../../../models/Element';
import { IElementProps } from '../../Card/ElementsList/ElementsList';
import { ElementProvider } from '../ElementProvider';
import MultipleChoiceEditDetail from './MultipleChoiceQuestion/MultipleChoiceEditDetail/MultipleChoiceEditDetail';
import OpenQuestionEdit from './OpenQuestion/OpenQuestionEdit/OpenQuestionEdit';
import { IUserAnswerError } from './OpenQuestion/OpenQuestionFormHook';
import PollEditDetail from './Poll/PollEditDetail/PollEditDetail';
import QuestionElement from './QuestionElement';
import { QuestionProvider } from './QuestionProvider';

export interface QuestionElementProps extends IElementProps {
    element: IElement;
    isEdit: boolean;
    isReorder: boolean;
    // onQuestionAnswered?: (element: IElement, userAnswer: IUserAnswer | IUserAnswerError) => void;
    // updateElement?: (element: IElement) => void;
    // loaded?: (element: IElement) => void;
    // deleteHandler: (element: IElement) => void;
    // addNewCardHandler: (title: string) => Promise<ICard | undefined>;
    // onNavigateToCardById: (cardId: number | undefined) => void;
}

const EditQuestionElement: FunctionComponent<QuestionElementProps> = (props) => {
    return (
        <ElementProvider elementData={props.element}>
            {/* <QuestionProvider questionData={props.element.elementable.question}> */}
            <TimerProvider>
                <QuestionElement {...props}></QuestionElement>
            </TimerProvider>
            {/* </QuestionProvider> */}
        </ElementProvider>
    );
    // switch (element.elementable.question_type.type) {
    //     case 'open_question':
    //         return (
    //             <OpenQuestionEdit
    //                 elementData={element}
    //                 deleteHandler={deleteHandler}
    //                 isEdit={isEdit}
    //                 isReorder={isReorder}
    //                 onQuestionAnswered={onQuestionAnswered}
    //                 loaded={loaded}
    //                 updateElement={(element: IElement) => {
    //                     updateElement?.(element);
    //                 }}
    //             ></OpenQuestionEdit>
    //         );
    //     case 'multiple_choice':
    //         return (
    //             <MultipleChoiceEditDetail
    //                 elementData={element}
    //                 deleteHandler={deleteHandler}
    //                 isEdit={isEdit}
    //                 isReorder={isReorder}
    //                 onQuestionAnswered={onQuestionAnswered}
    //                 loaded={loaded}
    //                 updateElement={(element: IElement) => updateElement?.(element)}
    //                 addNewCardHandler={addNewCardHandler}
    //                 onNavigateToCardById={onNavigateToCardById}
    //             ></MultipleChoiceEditDetail>
    //         );
    //     case 'poll':
    //         return (
    //             <PollEditDetail
    //                 //options={element.elementable.options}
    //                 elementData={element}
    //                 deleteHandler={deleteHandler}
    //                 isEdit={isEdit}
    //                 isReorder={isReorder}
    //                 onQuestionAnswered={onQuestionAnswered}
    //                 loaded={loaded}
    //                 updateElement={(element: IElement) => updateElement?.(element)}
    //             ></PollEditDetail>
    //         );
    //     default:
    //         return <div>{`This is an unknown element: ${element.elementable.question_type.type}`}</div>;
    // }
};

export default React.memo(EditQuestionElement);
