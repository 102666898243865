import { CancelTokenSource } from 'axios';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ElementType } from '../components/Card/ElementTypes/ElementTypes';
import { useConfig } from '../context/ConfigContext';
import { ICard } from '../models/Card';
import { IElement } from '../models/Element';
import http from '../utils/axios-instance';
import useMediaElementService from './MediaElementService';
import useQuestionElementService from './QuestionElementService';
import useTextElementService from './TextElementService';
export interface IOrderElements {
    ordered_elements: string;
}

const useElementsService = () => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const serviceUrl = `${config.baseUrl}/elements`;

    const { create: createTextElement } = useTextElementService();
    const { create: createMediaElement } = useMediaElementService();
    const { create: createQuestionElement } = useQuestionElementService();

    const remove = (id: number, source?: CancelTokenSource) => {
        return http.delete(`${serviceUrl}/${id}`, { cancelToken: source?.token });
    };

    const createMultipleChoiceQuestion = async (card: ICard) => {
        return createQuestionElement({
            body: `${t('Common:MULTIPLE_CHOICE_QUESTION_EXAMPLE')}`,
            card_id: card.id,
            options: `[{"body":"${t('Common:MC_OPTION_EXAMPLE')} 1", "is_correct":"1", "feedback": ""}, {"body":"${t(
                'Common:MC_OPTION_EXAMPLE',
            )} 2", "is_correct":"0", "feedback": ""}]`,
            feedback_enabled: false,
            is_correct_enabled: true,
            timer_enabled: false,

            question_type_id: 1,
        });
    };

    const createOpenQuestion = async (card: ICard) => {
        return createQuestionElement({
            body: `${t('Common:OPEN_QUESTION_EXAMPLE')}`,
            card_id: card.id,
            question_type_id: 2,
            feedback_enabled: false,
            is_correct_enabled: false,
            timer_enabled: false,
        });
    };

    const createPollQuestion = async (card: ICard) => {
        return createQuestionElement({
            body: `${t('Common:POLL_QUESTION_EXAMPLE')}`,
            card_id: card.id,
            options: `[{"body":"${t('Common:POLL_OPTION_EXAMPLE')} 1"}, {"body":"${t(
                'Common:POLL_OPTION_EXAMPLE',
            )} 2"}]`,
            feedback_enabled: false,
            is_correct_enabled: false,
            timer_enabled: false,

            question_type_id: 3,
        });
    };

    const createVideoMedia = async (card: ICard) => {
        return createMediaElement({
            type: 'video',
            src: '',
            provider: 'youtube',
            model_type: `card`, // TODO: Maybe change this so backend can do this.
            model_id: card.id,
        });
    };

    const createImageMedia = async (card: ICard) => {
        return createMediaElement({
            type: 'image',
            src: '',
            provider: '',
            model_type: `card`,
            model_id: card.id,
        });
    };

    const createFileMedia = async (card: ICard) => {
        return createMediaElement({
            type: 'pdf',
            src: '',
            provider: '',
            model_type: `card`,
            model_id: card.id,
        });
    };

    const createRichText = async (card: ICard) => {
        return createTextElement({
            body: '',
            model_type: `card`,
            model_id: card.id,
        });
    };

    const createElement = useCallback((type: ElementType, card: ICard) => {
        switch (type) {
            case ElementType.Text:
                return createRichText(card);
            case ElementType.Image:
                return createImageMedia(card);
            case ElementType.Video:
                return createVideoMedia(card);
            case ElementType.Pdf:
                return createFileMedia(card);
            case ElementType.File:
                return createFileMedia(card);
            case ElementType.Open:
                return createOpenQuestion(card);
            case ElementType.MultipleChoice:
                return createMultipleChoiceQuestion(card);
            case ElementType.Poll:
                return createPollQuestion(card);
            default:
                return Promise.reject();
        }
    }, []);

    const order = (data: IOrderElements, source?: CancelTokenSource) => {
        return http.post<IElement[]>(`${serviceUrl}/order`, data, { cancelToken: source?.token });
    };

    return {
        createMultipleChoiceQuestion,
        createOpenQuestion,
        createPollQuestion,
        createVideoMedia,
        createImageMedia,
        createFileMedia,
        createRichText,
        createElement,
        order,
        remove,
    };
};

export default useElementsService;
