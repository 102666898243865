import { useFormikContext } from 'formik';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../Button/Button';

type ReportSubmitButtonProps = {
    onCloseHandler: () => void;
    disabled: boolean;
};
const ReportSubmitButton: FunctionComponent<ReportSubmitButtonProps> = ({ onCloseHandler, disabled }) => {
    const { t } = useTranslation();
    const { submitForm } = useFormikContext();

    const handleSubmit = () => {
        submitForm();
        onCloseHandler();
    };

    return <Button text={t('Common:BUTTON_SUBMIT')} onClick={handleSubmit} disabled={disabled} />;
};

export default ReportSubmitButton;
