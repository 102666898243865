import { animated, useSpring } from '@react-spring/web';
import React, { CSSProperties, FunctionComponent } from 'react';
import { CardStatus } from '../../../models/Card';
import CheckmarkIcon from '../../Icons/CheckmarkIcon/CheckmarkIcon';
import ExclamationIcon from '../../Icons/ExclamationIcon/ExclamationIcon';
import QuestionmarkIcon from '../../Icons/QuestionmarkIcon/QuestionmarkIcon';
import classes from './CardStatusIndicator.module.scss';

type Props = { status: CardStatus };

const statusElement: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
};

const CardStatusIndicator: FunctionComponent<Props> = ({ status }) => {
    const { x } = useSpring({
        from: { x: 3 },
        x: 1,
        config: { mass: 1, tension: 180, friction: 12 },
    });

    const { opacity } = useSpring({
        from: { opacity: 0 },
        opacity: 1,
        config: { duration: 240 },
    });
    const progressIcon = () => {
        switch (status) {
            case 'new':
                return <QuestionmarkIcon />;
            case 'in_progress':
                return <ExclamationIcon />;
            case 'completed':
                return <CheckmarkIcon />;
            default:
                break;
        }
    };

    return (
        <div style={statusElement}>
            <animated.div
                className={`${classes.CardStatusIndicator} ${classes[status]}`}
                style={{
                    opacity: opacity,
                    scale: x,
                }}
            >
                {progressIcon()}
            </animated.div>
        </div>
    );
};

export default CardStatusIndicator;
