import { faCircleNotch, faPlusCircle, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeck } from '../../../context/DeckContext';
import { IOption } from '../../../models/Option';
import useElementsService from '../../../services/ElementsService';
import useQuestionElementService from '../../../services/QuestionElementService';
import Button from '../../Button/Button';
import InputField from '../../InputField/InputField';
import { useElement } from '../ElementProvider';
import classes from './QuestionEdit.module.scss';
import QuestionElementLabel from './QuestionFormik/QuestionElementLabel/QuestionElementLabel';
import Switch from 'react-switch';
import useQuestionForm from './useQuestionForm';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { QuestionElementProps } from './QuestionElementModel';
import QuestionSettingsPopover from './QuestionSettings/QuestionSettingsPopover';
import TimerInput from '../../TimerInput/TimerInput';
import { parse } from 'date-fns';
import ElementIcon from './QuestionFormik/ElementIcon/ElementIcon';
import { ElementType } from '../../Card/ElementTypes/ElementTypes';
import ElementHeader from '../ElementHeader/ElementHeader';

type QuestionEditProps = QuestionElementProps;

const QuestionEdit: FunctionComponent<QuestionEditProps> = (props) => {
    const { t } = useTranslation();
    // const { config } = useConfig();
    const { deck } = useDeck();
    const { state, dispatch } = useElement();
    const { remove } = useQuestionElementService();
    const { remove: removeElement } = useElementsService();
    const { initialValues, handleSubmit, isSaved, setIsSaved } = useQuestionForm();
    const [addedOption, setAddedOption] = useState(false);
    const [open, setOpen] = useState(false);
    // const [feedbackEnabled, setFeedbackEnabled] = useState(false);
    // const [rightWrongEnabled, setRightWrongEnabled] = useState(false);

    const defaultOption: IOption = {
        id: 0,
        question_id: 0,
        body: '', // minimal 1 char, no spaces
        feedback: '',
        is_correct: false,
        is_selected: false,
        // actions: [],
        action_of_edges: [],
    };

    const inputChangedHandler = () => {
        setIsSaved(false);
    };

    const closeModal = () => {
        setOpen(false);
    };

    const permanentlyDeleteHandler = () => {
        props.deleteElement?.(state);

        if (state.elementable) {
            remove(state.elementable.id);
        } else {
            removeElement(state.id);
        }
    };

    const handleDelete = () => {
        setOpen(true);
    };

    const handleFeedback = (enabled: boolean) => {
        inputChangedHandler();
    };

    const handleRightWrong = (enabled: boolean) => {
        inputChangedHandler();
    };

    const handleTimer = (enabled: boolean) => {
        inputChangedHandler();
    };

    const onTimedInputChange = (checked: boolean, time?: string) => {
        // console.log('onTimedInputChange', checked, time);
        setIsSaved(false);
        // if (time) {
        //     console.log(parse(time, 'mm:ss', new Date()));
        // }
    };

    const elementHeader = () => {
        const getElementTypeBasedOnQuestionType = () => {
            switch (state.elementable?.question_type?.type) {
                case 'open_question':
                    return ElementType.Open;
                case 'multiple_choice':
                    return ElementType.MultipleChoice;
                case 'poll':
                    return ElementType.Poll;
            }
        };

        return (
            <ElementHeader
                icon={<ElementIcon elementType={getElementTypeBasedOnQuestionType()}></ElementIcon>}
                label={
                    <QuestionElementLabel questionType={state.elementable?.question_type?.type}></QuestionElementLabel>
                }
            />
        );
    };

    return (
        <>
            {props.isReorder ? (
                elementHeader()
            ) : (
                <Formik
                    onSubmit={(formValues, actions) => {
                        handleSubmit(state, formValues, actions).then((el) => {
                            props.updateElement?.(el);
                            actions.setSubmitting(false);
                        });
                    }}
                    initialValues={initialValues}
                    enableReinitialize
                >
                    {({ values, dirty, isValid, isSubmitting, setFieldValue, handleSubmit }) => {
                        const disabledSubmit = isSubmitting || !isValid;
                        return (
                            <form className={classes.Form} noValidate={true} onSubmit={handleSubmit}>
                                <header className={classes.Header}>
                                    {elementHeader()}
                                    <div className={classes.FormButtons}>
                                        {!isSaved ? (
                                            <Button
                                                className={classes.ElementSave}
                                                type="submit"
                                                text={t('Common:BUTTON_SAVE')}
                                                alert={true}
                                                disabled={disabledSubmit}
                                                icon={
                                                    isSubmitting ? (
                                                        <FontAwesomeIcon
                                                            className={classes.ButtonLoadingIcon}
                                                            icon={faCircleNotch}
                                                            spin={true}
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon className={classes.SaveIcon} icon={faSave} />
                                                    )
                                                }
                                            ></Button>
                                        ) : null}
                                        {!deck.is_published && (
                                            <QuestionSettingsPopover
                                                onFeedbackHandler={handleFeedback}
                                                onRightWrongHandler={handleRightWrong}
                                                onTimerHandler={handleTimer}
                                                onDeleteHandler={handleDelete}
                                            ></QuestionSettingsPopover>
                                        )}

                                        {/* {defaultOptions && defaultOptions.length > 0 ? (
                                    <div>
                                        <label htmlFor="question_type_id">
                                            {t('Common:QUESTION_SELECT_TYPE_LABEL')}
                                        </label>

                                        <Field
                                            className="formikQuestionTypeSelect"
                                            name="question_type_id"
                                            options={defaultOptions?.map((option) => {
                                                return {
                                                    value: option.id,
                                                    label: option.body,
                                                };
                                            })}
                                            component={FormikQuestionTypeSelect}
                                            placeholder="Select a type..."
                                            onChange={handleChangeQuestionType}
                                        />
                                    </div>
                                ) : null} */}
                                    </div>
                                </header>

                                <div className={`${classes.formGroup}`}>
                                    <div className={classes.BodyInput}>
                                        <InputField
                                            name="body"
                                            type="text"
                                            label={t('Common:ELEMENT_BODY_INPUT_LABEL')}
                                            autoFocus
                                            placeholder={t('Deck:OPEN_QUESTION_ELEMENT_BODY_INPUT_PLACEHOLDER')}
                                            onChange={inputChangedHandler}
                                        />

                                        {values.timer_enabled && (
                                            <TimerInput checked={true} onChange={onTimedInputChange} />
                                        )}
                                    </div>

                                    {(state.elementable?.question_type?.type === 'multiple_choice' ||
                                        state.elementable?.question_type?.type === 'poll') && (
                                        <>
                                            <div className={classes.Divider} />
                                            <FieldArray
                                                name="options"
                                                render={(arrayHelpers) => (
                                                    <div className={classes.Options}>
                                                        {values.options?.map((option, index) => (
                                                            <div
                                                                key={`option_${index}_${option.id}`}
                                                                className={classes.FormGroup}
                                                            >
                                                                <div className={classes.OptionInput}>
                                                                    <div className={classes.OptionHeader}>
                                                                        {(state.elementable?.question_type?.type ===
                                                                            'multiple_choice' ||
                                                                            state.elementable?.question_type?.type ===
                                                                                'poll') && (
                                                                            <label
                                                                                htmlFor={`options.${index}.is_correct`}
                                                                                className={classes.OptionSwitch}
                                                                            >
                                                                                {state.elementable?.question_type
                                                                                    ?.type === 'multiple_choice' &&
                                                                                    values.is_correct_enabled &&
                                                                                    option.is_correct !== null && (
                                                                                        <Switch
                                                                                            offColor="#fa5b7b"
                                                                                            onColor="#00baa9"
                                                                                            onChange={(checked) => {
                                                                                                setFieldValue(
                                                                                                    `options.${index}.is_correct`,
                                                                                                    checked,
                                                                                                );
                                                                                                inputChangedHandler();
                                                                                            }}
                                                                                            checked={
                                                                                                option.is_correct !=
                                                                                                null
                                                                                                    ? option.is_correct
                                                                                                    : false
                                                                                            }
                                                                                            disabled={deck.is_published}
                                                                                        />
                                                                                    )}

                                                                                {t('Common:OPTION_LABEL') +
                                                                                    ' ' +
                                                                                    (index + 1)}
                                                                            </label>
                                                                        )}
                                                                        {!deck.is_published &&
                                                                            values.options &&
                                                                            values.options?.length > 2 && (
                                                                                <div className={classes.FormButtons}>
                                                                                    <Button
                                                                                        className={classes.OptionDelete}
                                                                                        text={``}
                                                                                        danger
                                                                                        tabIndex={-1}
                                                                                        icon={
                                                                                            <FontAwesomeIcon
                                                                                                className={
                                                                                                    classes.DeleteIcon
                                                                                                }
                                                                                                icon={faTrashAlt}
                                                                                            ></FontAwesomeIcon>
                                                                                        }
                                                                                        iconSide="left"
                                                                                        onClick={() => {
                                                                                            arrayHelpers.remove(index);
                                                                                            setIsSaved(false);
                                                                                        }}
                                                                                    ></Button>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                    <InputField
                                                                        name={`options[${index}].body`}
                                                                        type="text"
                                                                        label="Title"
                                                                        autoFocus={addedOption}
                                                                        onChange={inputChangedHandler}
                                                                    />
                                                                </div>
                                                                {values.feedback_enabled && option.feedback !== null && (
                                                                    <div className={classes.FeedbackInput}>
                                                                        <InputField
                                                                            name={`options[${index}].feedback`}
                                                                            type="text"
                                                                            label={t(
                                                                                'Deck:MC_ELEMENT_FEEDBACK_INPUT_LABEL',
                                                                            )}
                                                                            placeholder={t(
                                                                                'Deck:MC_ELEMENT_FEEDBACK_INPUT_PLACEHOLDER',
                                                                            )}
                                                                            onChange={inputChangedHandler}
                                                                        />
                                                                    </div>
                                                                )}
                                                                {values.options &&
                                                                    values.options.length - 1 !== index && (
                                                                        <div className={classes.Divider} />
                                                                    )}
                                                            </div>
                                                        ))}

                                                        {!deck.is_published && (
                                                            <Button
                                                                type="button"
                                                                className={classes.AddOptionButton}
                                                                text={t('Deck:MC_ELEMENT_ADD_OPTION_LABEL')}
                                                                icon={<FontAwesomeIcon icon={faPlusCircle} />}
                                                                iconSide="left"
                                                                onClick={() => {
                                                                    const option = {
                                                                        ...defaultOption,
                                                                        body: `${t('Common:MC_OPTION_EXAMPLE')} ${
                                                                            (values.options?.length || 0) + 1
                                                                        }`,
                                                                    };
                                                                    arrayHelpers.push(option);
                                                                    setIsSaved(false);
                                                                    setAddedOption(true);
                                                                }}
                                                            ></Button>
                                                        )}
                                                    </div>
                                                )}
                                            ></FieldArray>
                                        </>
                                    )}
                                </div>
                                {deck.is_published && <div>{t('Common:OPEN_QUESTION_ELEMENT_PUBLISHED_MESSAGE')}</div>}
                            </form>
                        );
                    }}
                </Formik>
            )}

            <Dialog
                open={open}
                onClose={closeModal}
                className={classes.WarningDialog}
                PaperProps={{ className: `dialog` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:WARNING_HEADER')}</DialogTitle>
                <DialogContent>
                    {t('Common:ELEMENT_DELETION_WARNING_MESSAGE', {
                        title: state.elementable?.body || state.elementable?.question_type?.name || 'Element',
                    })}
                </DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={closeModal} />
                    <Button alt text={t('Common:BUTTON_PERMANENTLY_DELETE')} onClick={permanentlyDeleteHandler} />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default QuestionEdit;
