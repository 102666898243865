import { createCtx } from './createCtx';

export interface ITimerState {
    seconds: number;
    originalSeconds: number;
    formattedTime: string;
    originalTime: string;
    timerRunning: boolean;
}

export type TimerContextType = {
    state: ITimerState;
    setTimer: (timer: string) => void;
    startTimer: () => void;
    pauseTimer: () => void;
    stopTimer: () => void;
    resetTimer: () => void;
};

export const [useTimer, CtxProvider] = createCtx<TimerContextType>();
