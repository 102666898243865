import React, { RefObject, useEffect, useLayoutEffect, useState } from 'react';
import { CardStatus } from '../../models/Card';
import classes from './ReadProgressBar.module.scss';

interface ReadProgessBarProps {
    attachTo: RefObject<HTMLDivElement>;
    init: boolean;
    status: CardStatus;
}

const ReadProgressBar: React.FC<ReadProgessBarProps> = (props) => {
    const [progress, setProgress] = useState(-100);

    const trackScrollEvent = () => {
        const { top, height } = props.attachTo.current?.getBoundingClientRect() || { top: 0, height: 0 };
        const progress = (top / (height - window.innerHeight)) * 100;
        const translateValue = progress < -100 ? 0 : -100 - progress;
        if (height - window.innerHeight < 0) {
            setProgress(0);
        } else {
            setProgress(translateValue < -100 ? -100 : top > 0 ? 0 : translateValue);
        }
    };
    useEffect(() => {
        if (props.attachTo && props.attachTo.current && props.attachTo.current.getBoundingClientRect()) {
            window.addEventListener('scroll', trackScrollEvent);
        }
        return () => {
            window.removeEventListener('scroll', trackScrollEvent);
        };
    }, [props.attachTo]);

    useEffect(() => {
        if (props.init) {
            trackScrollEvent();
        }
    }, [props.init]);

    useLayoutEffect(() => {
        trackScrollEvent();
    }, []);

    return (
        <div className={`${classes.ReadProgressBarContainer}`}>
            <div
                className={`${classes.Bar} ${props.status === 'completed' && classes['completed']}`}
                style={{ transform: `translateX(${progress}%)` }}
            />
        </div>
    );
};

export default ReadProgressBar;
