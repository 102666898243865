import { useFormikContext } from 'formik';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { useConfig } from '../../context/ConfigContext';
import useAxiosFetch from '../../utils/useAxiosFetch';
import classes from './RoleSelect.module.scss';

export interface SelectOption {
    value: number;
    label: string;
}

export interface RoleSelectProps {
    name: string;
    options: SelectOption[];
    value: SelectOption;
    error: string;
    touched: boolean;
    onChange: (e: SelectOption | null) => void;
    onBlur: (event: React.FocusEvent<HTMLElement>) => void;
    isMulti?: boolean;
}

const RoleSelect: FunctionComponent<RoleSelectProps> = (props) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const { setFieldValue } = useFormikContext();
    const { config } = useConfig();
    const { response, loading } = useAxiosFetch<{ id: number; name: string }[]>(`${config.baseUrl}/roles`);
    const [options, setOptions] = useState<SelectOption[]>([]);

    const className = props.error && props.touched ? classes.error : '';

    useEffect(() => {
        if (response) {
            const roles = response.data;
            const optionsData: SelectOption[] = roles.map((role) => {
                return { value: role.id, label: role.name };
            });
            setOptions(optionsData);
        }
    }, [response]);

    return (
        <div className={classes.FormRoles}>
            <div className={classes.FormFieldHeader}>
                <h2 className={`h5 wrap-text ${classes.FormFieldTitle}`}>{t('Members:ROLE_SELECT_TITLE')}</h2>
            </div>
            <div className={`${classes.RoleSelect}`}>
                {!loading && (
                    <ReactSelect
                        id={props.name}
                        ref={ref}
                        defaultValue={props.value}
                        placeholder={t('Members:ROLE_SELECT_PLACEHOLDER')}
                        noOptionsMessage={() => t('Members:ROLE_SELECT_NO_MORE_OPTIONS')}
                        options={options}
                        isMulti={props.isMulti}
                        onBlur={props.onBlur}
                        onChange={(option) => {
                            if (option) {
                                setFieldValue(props.name, option);
                            }
                        }}
                        value={props.value}
                        className={`react-select-container ${className}`}
                        classNamePrefix="react-select"
                    ></ReactSelect>
                )}

                <div className={classes.BelowField}>
                    {props.error && props.touched && <div className={classes.errorMessage}>{props.error}</div>}
                </div>
            </div>
        </div>
    );
};

export default RoleSelect;
