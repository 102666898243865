import React, { FunctionComponent } from 'react';

type SVGProps = {
    fill?: string;
};

const CheckmarkIcon: FunctionComponent<SVGProps> = ({ fill = '#ffffff' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.068" height="11.919" viewBox="0 0 15.068 11.919">
            <path
                id="Path_3643"
                data-name="Path 3643"
                d="M.547-7.034,3.74-3.877,9.3-9.416l3.13,3.268L3.74,2.5l-6.375-6.38Z"
                transform="translate(2.635 9.416)"
                fill={fill}
            />
        </svg>
    );
};

export default CheckmarkIcon;
