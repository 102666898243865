import { faCalendarAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animated, useSpring } from '@react-spring/web';
import { gsap } from 'gsap';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import CoverImage from '../../../../components/CoverImage/CoverImage';
import CheckmarkIcon from '../../../../components/Icons/CheckmarkIcon/CheckmarkIcon';
import { useConfig } from '../../../../context/ConfigContext';
import { useQuest } from '../../../../context/QuestContext';
import useHover from '../../../../hooks/useHover';
import { getFilePathByName, getFilePathForDefaultImage } from '../../../../models/File';
import { compactNumber } from '../../../../utils/compactNumber';
import { timeLeftTillNow } from '../../../../utils/date';
import PersonalProgressBar from './PersonalProgressBar/PersonalProgressBar';
import classes from './QuestCover.module.scss';

export type ActionButtonState = 'show' | 'start' | 'continue';

const QuestCover: FunctionComponent = () => {
    const { loading, config, loadingConfig } = useConfig();
    const history = useHistory();
    const { quest } = useQuest();
    const ref = useRef(null);
    const isHover = useHover(ref);
    const refActionButton = useRef(null);
    const isActionButtonHover = useHover(refActionButton);
    const [defaultCover, setDefaultCover] = useState('');
    const tl = gsap.timeline();
    const containerRef = useRef(null);

    useEffect(() => {
        if (!loadingConfig) {
            const customFile = config.tenant.images['quest.png']?.find((file) => file.is_default == false);
            const defaultFile = config.tenant.images['quest.png']?.find((file) => file.is_default == true);
            setDefaultCover(
                getFilePathByName(customFile?.file?.name) || getFilePathForDefaultImage(defaultFile?.file?.name) || '',
            );
        }
    }, [loadingConfig]);

    const questClickHandler = () => {
        history.push({ pathname: `quests/${quest.id}`, state: quest });
    };

    useEffect(() => {
        set.start({ xys: [0, 0, isActionButtonHover ? 1.04 : 1] });
    }, [isActionButtonHover]);

    const [animating, setAnimating] = useState(false);

    const calc = (x: number, y: number, width: number, height: number) => {
        const rot = 40;
        return [-(y - height / 2) / rot, (x - width / 2) / rot, 1.04];
    };

    const [props, set] = useSpring(() => ({
        xys: [0, 0, 1],
        config: { mass: 3, tension: 550, friction: 40 },
        onStart: () => {
            setAnimating(true);
        },
        onRest: () => {
            setAnimating(false);
        },
    }));

    const trans = (x: number, y: number, s: number) => {
        const perspective = `perspective(700px) rotateX(${-x}deg) rotateY(${-y}deg) scale(${s})`;
        if (!animating) {
            if (isHover) {
                return perspective;
            } else {
                return `scale(${s})`;
            }
        }
        return perspective;
    };

    const hoverClass = isHover ? classes['hover'] : '';
    // const timeLeftClasses = ['weeksLeft', 'daysLeft', 'hoursLeft'];
    const timeLeftClass =
        quest.is_timed && quest.start_date && quest.end_date ? timeLeftTillNow(quest.start_date, quest.end_date) : 0;

    // decks_count is used here because we're not providing any decks
    const tasksTotal = quest.decks_count;
    const questHasProgress = quest.user_progress[0] !== undefined;
    const tasksCompleted = questHasProgress ? quest.user_progress[0].completed : 0;
    const tasksLeft = tasksTotal - tasksCompleted;
    const tasksIsCompleted = questHasProgress ? !!quest.user_progress[0].is_completed : false;
    const tasksProgress = tasksTotal > 0 ? Math.min(Math.max(tasksCompleted / tasksTotal, 0), 1) : 0;

    const questHasTeamProgress = quest.quest_progress !== undefined;
    const teamQuestTotal = questHasTeamProgress ? quest.quest_progress.totalTasks : 0;
    const teamQuestProgress = questHasTeamProgress ? quest.quest_progress.completedTasks : 0;
    const teamProgress = teamQuestTotal === 0 ? 0 : teamQuestProgress / teamQuestTotal;

    const taskBarClass = () => {
        switch (quest.time_state) {
            case 'awaiting':
                return `${classes['awaiting']}`;
            case 'in_progress':
                if (tasksIsCompleted || teamProgress === 1) {
                    return `${classes['completed']}`;
                }
                return `${classes[quest.time_state]} ${classes[timeLeftClass]}`;
            case 'ended':
                if (teamProgress === 1) {
                    return `${classes['completed']}`;
                } else {
                    return `${classes['failed']}`;
                }
        }
    };

    // const tasks = () => {
    //     switch (quest.time_state) {
    //         case 'awaiting':
    //             return t('Quests:COMING_SOON');
    //         case 'in_progress':
    //             if (!questHasProgress) {
    //                 return `${t('Quests:NEW_QUEST')} ${
    //                     tasksLeft != 1
    //                         ? t('Quests:TASKS_AWAIT_PLURAL', { count: tasksTotal })
    //                         : t('Quests:TASKS_AWAIT', { count: tasksTotal })
    //                 }`;
    //             } else {
    //                 if (tasksCompleted === 0) {
    //                     return `${t('Quests:NEW_QUEST')} ${
    //                         tasksLeft != 1
    //                             ? t('Quests:TASKS_AWAIT_PLURAL', { count: tasksTotal })
    //                             : t('Quests:TASKS_AWAIT', { count: tasksTotal })
    //                     }`;
    //                 }
    //                 if (tasksCompleted === tasksTotal) {
    //                     return `${t('Quests:TASKS_COMPLETE')}`;
    //                 }
    //                 return `${
    //                     tasksLeft != 1
    //                         ? t('Quests:TASKS_LEFT_PLURAL', { count: tasksLeft })
    //                         : t('Quests:TASKS_LEFT', { count: tasksLeft })
    //                 }`;
    //             }
    //         case 'ended':
    //             if (teamProgress === 1) {
    //                 return `${t('Quests:SUCCESS')}`;
    //             } else {
    //                 return `${t('Quests:FAILED')}`;
    //             }
    //     }
    // };

    const tasksLeftStatus = () => {
        if (tasksCompleted === 0) {
            return 'new';
        }
        if (tasksLeft === 0 || tasksIsCompleted) {
            return 'completed';
        }

        return 'inprogress';
    };

    const tasksIndicator = () => {
        switch (tasksLeftStatus()) {
            case 'new':
            // return <span>{`new`}</span>;
            case 'inprogress':
                return <span>{`${tasksLeft}`}</span>;
            case 'completed':
                return <CheckmarkIcon></CheckmarkIcon>;
        }
    };

    return (
        <div className={classes.QuestCover} ref={containerRef}>
            <animated.div
                ref={ref}
                className={`card Box ${classes.QuestCoverCard}`}
                onMouseMove={(e) => {
                    const node = e.target as HTMLElement;
                    const rect = node.getBoundingClientRect();
                    const x = e.clientX - rect.left;
                    const y = e.clientY - rect.top;
                    set.start({ xys: calc(x, y, rect.width, rect.height) });
                }}
                onMouseLeave={() => {
                    set.start({ xys: [0, 0, 1] });
                }}
                onClick={questClickHandler}
                style={{ transform: props?.xys?.to(trans) }}
            >
                {!tasksIsCompleted && (
                    <div className={`${classes.TasksLeft} ${classes[tasksLeftStatus()]} ${hoverClass}`}>
                        {tasksIndicator()}
                    </div>
                )}
                <div className={classes.HitArea}>
                    <div className={`${classes.TitleBar} ${taskBarClass()}`}>
                        <div className={`${classes.Title} wrap-text-overflow-2`}>{quest.title}</div>
                    </div>
                    <div className={`${classes.CoverImage} ${classes[quest.time_state]}`}>
                        {tasksIsCompleted && (
                            <div className={`${classes.CheckMark} ${classes[tasksLeftStatus()]} ${hoverClass}`}>
                                <div className={`${classes.CheckMarkBackground}`}></div>
                                <CheckmarkIcon></CheckmarkIcon>
                            </div>
                        )}
                        <CoverImage
                            src={getFilePathByName(quest.cover_image?.name) || defaultCover}
                            backgroundSize={quest.cover_background_size || 'cover'}
                        ></CoverImage>
                    </div>
                    <div className={`${classes.Content}`}>
                        {quest.time_state != 'awaiting' && (
                            <PersonalProgressBar
                                progress={tasksProgress}
                                timeState={quest.time_state}
                                quest={quest}
                            ></PersonalProgressBar>
                        )}
                        <div className={`${classes.Description} wrap-text-overflow-3`}>{quest.description}</div>
                    </div>
                    <div className={`${classes.QuestStats}`}>
                        <div className={`${classes.QuestStatsLeft}`}>
                            {quest.time_state === 'in_progress' &&
                                quest.is_timed &&
                                quest.start_date &&
                                quest.end_date &&
                                !tasksIsCompleted && (
                                    <span
                                        className={`${classes.QuestTime} ${classes[quest.time_state]} ${
                                            classes[timeLeftClass]
                                        }`}
                                    >
                                        <FontAwesomeIcon className={classes.CalendarIcon} icon={faCalendarAlt} />
                                        {`${quest.distanceToNowFormatted}`}
                                    </span>
                                )}
                        </div>
                        <div className={`${classes.QuestStatsRight}`}>
                            <div className={`${classes.QuestStat} btn btn-flavour btn-flavour--round`}>
                                <FontAwesomeIcon className={classes.PeopleIcon} icon={faUser} />
                                <span className={`${classes.QuestStatLabel}`}>
                                    {compactNumber(quest.quest_progress.totalParticipants)}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* <div className={`${classes.TaskBar} ${taskBarClass()}`}>{tasks()}</div>
                    <div className={`${classes.CoverImage} ${classes[quest.time_state]}`}>
                        <CoverImage
                            src={getFilePathByName(quest.cover_image?.name) || defaultCover}
                            backgroundSize={quest.cover_background_size || 'cover'}
                        ></CoverImage>
                    </div>
                    <Content
                        isParentHover={isHover}
                        timeLeftClass={`${timeLeftClass}`}
                        timeStateClass={quest.time_state}
                    ></Content>
                    {quest.time_state != 'awaiting' && (
                        <TeamProgressBar
                            progress={teamProgress}
                            timeState={quest.time_state}
                            quest={quest}
                        ></TeamProgressBar>
                    )}
                    <span
                        className={`${classes.QuestTime} ${classes[quest.time_state]} ${classes[timeLeftClass]}`}
                    >{`${quest.distanceToNowFormatted}`}</span> */}
                </div>
            </animated.div>
        </div>
    );
};

export default QuestCover;
