import { faFolder } from '@fortawesome/free-regular-svg-icons';
import {
    faAddressCard,
    faBars,
    faBuildingColumns,
    faGears,
    faHouseUser,
    faKey,
    faLandmark,
    faMapLocationDot,
    faMapMarkedAlt,
    faRightFromBracket,
    faTags,
    faTimes,
    faUserFriends,
    faUserGear,
    faUserPlus,
    faUsersGear,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import Popup from 'reactjs-popup';
// import Logo from '../Logo/Logo';
import { useAuth } from '../../context/AuthContext';
import { useConfig } from '../../context/ConfigContext';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { getFilePathByName, getFilePathForDefaultImage } from '../../models/File';
import AxiosInstance from '../../utils/axios-instance';
import Button from '../Button/Button';
import DropDown, { DropDownSection, ItemLocation, ItemType } from '../DropDown/DropDown';
import Logo from '../Logo/Logo';
import NavButton from '../NavButton/NavButton';
import NavDropDown from '../NavDropDown/NavDropDown';
import classes from './Navigation.module.scss';

const Navigation: FunctionComponent = () => {
    const { config, loading, loadingConfig } = useConfig();
    const history = useHistory();
    const location = useLocation();
    const { auth, logout, hasPermission } = useAuth();
    const { t } = useTranslation();
    const isDesktopMediaQuery = useMediaQuery('(min-width: 576px)');
    const [logo, setLogo] = useState('');
    const [defaultLogo, setDefaultLogo] = useState('');

    useEffect(() => {
        if (!loadingConfig) {
            const customFile = config.tenant.images['logo.png']?.find((file) => file.is_default == false);
            const defaultFile = config.tenant.images['logo.png']?.find((file) => file.is_default == true);
            setDefaultLogo(
                getFilePathByName(customFile?.file?.name) || getFilePathForDefaultImage(defaultFile?.file?.name) || '',
            );
        }
    }, [loadingConfig]);

    const onSignOutClick = async () => {
        await AxiosInstance.post(`${config.baseUrl}/logout`, {});

        // No need to wait for API logout, clean up storage, set authcontext and goto login
        localStorage.clear();
        logout();
        history.replace('/login');
    };

    const sections: DropDownSection[] = [
        {
            id: 0,
            value: t('Common:NAV_LINK_SETTINGS'),
            type: ItemType.Section,
            permission: 'permission.manage',
            items: [
                {
                    id: 0,
                    value: t('Common:NAV_LINK_SETTINGS_CUSTOMISATION'),
                    type: ItemType.Link,
                    link: '/custom',
                    permissions: ['permission.manage'],
                    icon: faGears,
                },
                // {
                //     id: 1,
                //     value:  t('Common:NAV_LINK_SETTINGS_LANGUAGE'),
                //     type: ItemType.Link,
                //     link: '/language',
                // },
                // {
                //     id: 2,
                //     value:  t('Common:NAV_LINK_SETTINGS_DOMAINS'),
                //     type: ItemType.Link,
                //     link: '/domains',
                // },
            ],
        },
        {
            id: 1,
            value: t('Common:NAV_LINK_MANAGEMENT'),
            type: ItemType.Section,
            permission: 'permission.manage',
            items: [
                {
                    id: 0,
                    value: t('Common:NAV_LINK_MANAGEMENT_QUESTS'),
                    type: ItemType.Link,
                    link: '/management/quests',
                    permissions: ['quest.manage'],
                    icon: faMapLocationDot,
                },
                {
                    id: 1,
                    value: t('Common:NAV_LINK_MANAGEMENT_LIBRARY'),
                    type: ItemType.Link,
                    link: '/management/library',
                    permissions: ['library.manage', 'export.deck.answers'],
                    icon: faBuildingColumns,
                },
                {
                    id: 2,
                    value: t('Common:NAV_LINK_MANAGEMENT_MEMBERS'),
                    type: ItemType.Link,
                    link: '/management/members',
                    permissions: ['user.manage'],
                    icon: faUserGear,
                },
                {
                    id: 3,
                    value: t('Common:NAV_LINK_MANAGEMENT_GROUPS'),
                    type: ItemType.Link,
                    link: '/management/groups',
                    permissions: ['group.manage'],
                    icon: faUsersGear,
                },
                // {
                //     id: 4,
                //     value: t('Common:NAV_LINK_MANAGEMENT_ROLES'),
                //     type: ItemType.Link,
                //     link: '/management/roles',
                //     permission: 'role.manage',
                //     icon: faUniversalAccess,
                // },
                {
                    id: 4,
                    value: t('Common:NAV_LINK_ACCESS_CODES'),
                    type: ItemType.Link,
                    link: '/management/accesscodes',
                    permissions: ['user.manage'],
                    icon: faKey,
                },
                {
                    id: 5,
                    value: t('Common:NAV_LINK_CATEGORIES'),
                    type: ItemType.Link,
                    permissions: ['library.manage'],
                    icon: faFolder,
                    link: '/management/categories',
                },
                {
                    id: 6,
                    value: t('Common:NAV_LINK_TAGS'),
                    type: ItemType.Link,
                    link: '/management/tags',
                    permissions: ['library.manage'],
                    icon: faTags,
                },
            ],
        },
        {
            id: 2,
            value: '',
            type: ItemType.Section,
            permission: 'permission.manage',
            items: [
                {
                    id: 0,
                    value: t('Common:NAV_LINK_INVITE_MEMBERS'),
                    type: ItemType.Link,
                    link: '/settings/members/invite/add_invites',
                    permissions: ['user.manage'],
                    icon: faUserPlus,
                },
            ],
        },
        {
            id: 3,
            value: '',
            type: ItemType.Section,
            permission: 'user.view',
            items: [
                {
                    id: 0,
                    value: t('Common:SIGN_OUT'),
                    type: ItemType.Button,
                    location: ItemLocation.End,
                    onClick: onSignOutClick,
                    icon: faRightFromBracket,
                },
            ],
        },
    ];

    const [dropDownItems, setDropDownItems] = useState<DropDownSection[]>([]);

    useEffect(() => {
        if (auth && auth.isLoggedIn) {
            const sectionsPermitted = sections.map((section) => {
                const items = section.items.filter((item) => {
                    // NO permission needed
                    if (!item.permissions) {
                        return true;
                    }
                    // Permission needed
                    const permissionCheck = item.permissions.map((permission) => {
                        return hasPermission(permission) && isDesktop;
                    });

                    return permissionCheck.includes(true);
                });
                return { ...section, items: items };
            });

            setDropDownItems(sectionsPermitted.filter((section) => section.items.length > 0));
        }
    }, [auth]);

    const onHomeClickHandler = () => {
        history.replace('/home');
    };

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    // useDisableBodyScroll(open);

    const desktopNavigation = (
        <div className={classes.NavigationGroup}>
            <NavButton to="/quests" label={t('Common:NAV_LINK_QUESTS_LABEL')}>
                <FontAwesomeIcon className={classes.QuestsIcon} icon={faMapMarkedAlt} />
            </NavButton>

            <NavButton to="/library" label={t('Common:NAV_LINK_LIBRARY_LABEL')}>
                <FontAwesomeIcon className={classes.LibraryIcon} icon={faLandmark} />
            </NavButton>

            <NavButton to="/groups" label={t('Common:NAV_LINK_GROUPS_LABEL')}>
                <FontAwesomeIcon className={classes.GroupsIcon} icon={faUserFriends} />
            </NavButton>

            <NavButton to="/profile" label={t('Common:NAV_LINK_PROFILE_LABEL')}>
                <FontAwesomeIcon className={classes.ProfileIcon} icon={faAddressCard} />
            </NavButton>

            <NavDropDown items={dropDownItems}></NavDropDown>
        </div>
    );

    const mobileNavigation = (
        <div className={classes.NavigationGroup}>
            <Button
                className={classes.NavigationButton}
                text={``}
                icon={<FontAwesomeIcon icon={faBars} />}
                onClick={() => setOpen((o) => !o)}
            ></Button>
        </div>
    );

    const navigationPopup = (
        <Popup
            className={'navigation-popup'}
            open={open && !isDesktopMediaQuery}
            modal
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${classes.NavigationPopup}`}>
                <div className={`${classes.NavigationTop}`}>
                    <Button
                        className={classes.ClosePopupButton}
                        text={``}
                        icon={<FontAwesomeIcon icon={faTimes} />}
                        onClick={closeModal}
                    ></Button>
                </div>

                <div className={classes.NavigationGroupMobile}>
                    <NavButton to="/quests" label={t('Common:NAV_LINK_QUESTS_LABEL')} onClick={closeModal}>
                        <FontAwesomeIcon className={classes.QuestsIcon} icon={faMapMarkedAlt} />
                    </NavButton>

                    <NavButton to="/library" label={t('Common:NAV_LINK_LIBRARY_LABEL')} onClick={closeModal}>
                        <FontAwesomeIcon className={classes.LibraryIcon} icon={faLandmark} />
                    </NavButton>

                    <NavButton to="/groups" label={t('Common:NAV_LINK_GROUPS_LABEL')} onClick={closeModal}>
                        <FontAwesomeIcon className={classes.GroupsIcon} icon={faUserFriends} />
                    </NavButton>

                    <NavButton to="/profile" label={t('Common:NAV_LINK_PROFILE_LABEL')} onClick={closeModal}>
                        <FontAwesomeIcon className={classes.ProfileIcon} icon={faAddressCard} />
                    </NavButton>
                </div>

                <DropDown
                    items={dropDownItems}
                    isOpen={true}
                    isDesktop={isDesktopMediaQuery}
                    onClose={closeModal}
                ></DropDown>
            </div>
        </Popup>
    );

    return (
        <nav className={classes.Navigation}>
            <ul className={classes.NavList}>
                <li>
                    {location.pathname === '/home' ||
                    location.pathname === '/login' ||
                    location.pathname.includes('/invite') ||
                    location.pathname.includes('/password') ? (
                        <button
                            className={`btn nav-button ${classes.LogoButton} ${classes.Logo}`}
                            onClick={onHomeClickHandler}
                        >
                            <Logo />
                        </button>
                    ) : (
                        <NavButton
                            to="/home"
                            label={isDesktopMediaQuery ? t('Common:NAV_LINK_HOME_LABEL') : ''}
                            onClick={closeModal}
                        >
                            <FontAwesomeIcon className={classes.HomeIcon} icon={faHouseUser} />
                        </NavButton>
                    )}
                </li>
                {auth.isLoggedIn &&
                    auth.currentUser?.email_verified &&
                    (isDesktopMediaQuery ? desktopNavigation : mobileNavigation)}
            </ul>
            {navigationPopup}
        </nav>
    );
};

export default Navigation;
