import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import ExtendedMuiList from '../../../../components/ExtendedMuiList/ExtendedMuiList';
import AvatarIcon from '../../../../components/Icons/AvatarIcon/AvatarIcon';
import NavigationIcon from '../../../../components/Icons/NavigationIcon/NavigationIcon';
import { User } from '../../../../models/User';
import classes from './GroupMemberList.module.scss';

interface GroupMemberListProps {
    members: User[];
    defaultRowsPerPage?: number;
    buttonClassName: string;
    icon: JSX.Element;
    dangerButton?: boolean;
    onClick: (member: User) => void;
}

const GroupMemberList: FunctionComponent<GroupMemberListProps> = ({ members, dangerButton = true, icon, onClick }) => {
    const { t } = useTranslation();

    const renderUserItem = (user: User) => {
        return (
            <div className={classes.MemberItem} key={`user_${user.id}`}>
                <div className={classes.MemberAvatar}>
                    <NavigationIcon>
                        <AvatarIcon></AvatarIcon>
                    </NavigationIcon>
                </div>
                <div className={classes.MemberName}>{user.name}</div>
                <div className="flex-grow"></div>
                <Button danger={dangerButton} text="" icon={icon} onClick={() => onClick(user)}></Button>
            </div>
        );
    };

    const filterDataBySearch = (searchValues: string[]) => {
        return members.filter((item) => {
            const name = item.name;

            const searchableValues = { name };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    return (
        <ExtendedMuiList
            loading={false}
            filterDataBySearch={filterDataBySearch}
            searchInputLabel={t('Common:MEMBERS_SEARCH_INPUT_LABEL')}
            items={members}
            renderItem={renderUserItem}
            noItemsMessage={t('Common:MESSAGE_NO_MEMBERS')}
            noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_GROUPS')}
            itemsListClasses={`${classes.GroupMembersList}`}
        />
    );
};

export default GroupMemberList;
