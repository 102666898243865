import { IGroup } from '../models/Group';
import { IQuest } from '../models/Quest';
import { createCtx } from './createCtx';

export type QuestContextType = {
    quest: IQuest;
    setQuest: (quest: IQuest) => void;
    addGroup: (group: IGroup) => void;
    removeGroup: (group: IGroup) => void;
};

export const [useQuest, CtxProvider] = createCtx<QuestContextType>();
