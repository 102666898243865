import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import InputCheckbox from '../../InputCheckbox/InputCheckbox';
import TextAreaField from '../../TextAreaField/TextAreaField';
import { IReport } from '../ReportDialog';
import classes from './ReportForm.module.scss';
import ReportTypeSelect from './ReportTypeSelect/ReportTypeSelect';

export type ReportType = 'content' | 'bug' | 'request';

type ReportFormProps = {
    handleFormSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    report: IReport;
    setReport: (report: IReport) => void;
};

const ReportForm: FunctionComponent<ReportFormProps> = ({ handleFormSubmit, report, setReport }) => {
    const { t } = useTranslation();

    const onBodyChangedHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReport({ ...report, body: event.target.value });
    };

    const onReportTypeChangedHandler = (type: ReportType) => {
        setReport({ ...report, type });
    };

    const onReportConsentChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReport({ ...report, consent: event.target.value === 'true' });
    };

    return (
        <div className={classes.ReportForm}>
            <form noValidate={true} onSubmit={handleFormSubmit}>
                <div className={classes.FormGroup}>
                    <label htmlFor={`report-type`}>{t('Common:REPORT_TYPE_SELECT_LABEL')}</label>
                    <ReportTypeSelect onReportTypeChangedHandler={onReportTypeChangedHandler} />
                </div>

                <div className={classes.FormGroup}>
                    <label htmlFor={`body`}>{t('Common:REPORT_BODY_INPUT_LABEL')}</label>
                    <TextAreaField
                        id={`body`}
                        name={`body`}
                        type="text"
                        placeholder={t('Common:REPORT_BODY_INPUT_PLACEHOLDER')}
                        onChange={onBodyChangedHandler}
                    />
                </div>

                <div className={classes.FormGroup}>
                    <InputCheckbox
                        id="consent"
                        name="consent"
                        type="checkbox"
                        label={<label htmlFor="consent">{t('Common:REPORT_CONSENT_LABEL')}</label>}
                        onChange={onReportConsentChangedHandler}
                    />
                </div>
            </form>
        </div>
    );
};

export default ReportForm;
