import { TextField } from '@material-ui/core';
import React, { Fragment, FunctionComponent, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import classes from './App.module.scss';
import AdminWarningMessage from './components/AdminWarningMessage/AdminWarningMessage';
import CustomToast from './components/CustomToast/CustomToast';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import PublicRoute from './components/PublicRoute/PublicRouteGuard';
import StyleSheet from './components/StyleSheet/StyleSheet';
import PageDecorator from './components/UI/PageDecorator/PageDecorator';
import { AuthProvider } from './context/AuthContextProvider';
import { useConfig } from './context/ConfigContext';
import { ThemeProvider } from './context/ThemeProvider';
import ScrollToTop from './hooks/scrollToTop';
import AccessCodes from './pages/AccessCodes/AccessCodes';
import Category from './pages/Category/Category';
import Customization from './pages/Customization/Customization';
import Deck from './pages/Deck/Deck';
import EmailValidation from './pages/EmailValidation/EmailValidation';
import ErrorHandler from './pages/ErrorHandler/ErrorHandler';
import Groups from './pages/Groups/Groups';
import Home from './pages/Home/Home';
import InviteAccept from './pages/Invite/InviteAccept/InviteAccept';
import Library from './pages/Library/Library';
import GoogleCallback from './pages/Login/GoogleCallback/GoogleCallback';
import Login from './pages/Login/Login';
import Management from './pages/Management/Management';
import Module from './pages/Module/Module';
import PasswordRequest from './pages/Password/PasswordRequest/PasswordRequest';
import PasswordReset from './pages/Password/PasswordReset/PasswordReset';
import Profile from './pages/Profile/Profile';
import Quests from './pages/Quests/Quests';
import Register from './pages/Register/Register';
import RegisterAccessCode from './pages/RegisterAccessCode/RegisterAccessCode';
import Roles from './pages/Roles/Roles';
import Settings from './pages/Settings/Settings';
import Tags from './pages/Tags/Tags';

export const App: FunctionComponent = () => {
    const { loadingConfig, loading, config } = useConfig();
    return (
        <>
            {/* <CssBaseline /> reset for MUI, but breaks our reset*/}
            {!loadingConfig && (
                <ThemeProvider>
                    {loading && (
                        <div className="app-loading">
                            <div className="general-spinner">
                                <div className="spinner card Box"></div>
                                <h2 className="loading-text h6">Loading hero HQ</h2>
                            </div>
                        </div>
                    )}

                    <StyleSheet></StyleSheet>

                    <div id="app">
                        <div className={`${classes.App} ${loading ? classes.Loading : classes.Loaded}`}>
                            <Suspense
                                fallback={
                                    <Fragment>
                                        <div className="spinner card Box"></div>
                                    </Fragment>
                                }
                            >
                                <ErrorHandler>
                                    <AuthProvider>
                                        <ScrollToTop />

                                        <Header></Header>

                                        <PageDecorator>
                                            {!loading && (
                                                <Switch>
                                                    <PublicRoute path="/register" component={Register} public exact />
                                                    <Route
                                                        path="/register/accesscode"
                                                        component={RegisterAccessCode}
                                                        exact
                                                    />
                                                    <Route path="/login" component={Login} exact />
                                                    <Route path="/auth/google" component={GoogleCallback} />
                                                    <Route path="/password/request" component={PasswordRequest} exact />
                                                    <Route
                                                        path="/password/reset/:token"
                                                        component={PasswordReset}
                                                        exact
                                                    />

                                                    <Route
                                                        path="/invite/accept/:token"
                                                        component={InviteAccept}
                                                        exact
                                                    />

                                                    <Route
                                                        path="/email/validation/:signature/:userId/:token/:expires"
                                                        component={EmailValidation}
                                                        exact={false}
                                                    />

                                                    <PrivateRoute path="/settings" component={Settings} exact={false} />
                                                    <PrivateRoute
                                                        path="/categories"
                                                        component={Category}
                                                        exact={false}
                                                    />
                                                    <PrivateRoute path="/module" component={Module} exact={false} />
                                                    <PrivateRoute path="/deck" component={Deck} exact={false} />
                                                    <PrivateRoute path="/quests" component={Quests} exact={false} />
                                                    <PrivateRoute path="/groups" component={Groups} exact={false} />
                                                    <PrivateRoute path="/roles" component={Roles} exact={false} />
                                                    <PrivateRoute
                                                        path="/accesscodes"
                                                        component={AccessCodes}
                                                        exact={false}
                                                    />
                                                    <PrivateRoute path="/tags" component={Tags} exact={false} />
                                                    <PrivateRoute
                                                        path="/management"
                                                        component={Management}
                                                        exact={false}
                                                    />
                                                    <PrivateRoute
                                                        path="/custom"
                                                        component={Customization}
                                                        exact={false}
                                                    />
                                                    <PrivateRoute path="/profile" component={Profile} exact={false} />
                                                    <PrivateRoute path="/library" component={Library} exact={false} />
                                                    <PrivateRoute path="/home" component={Home} exact />
                                                    <Redirect from="/" to="/home" />
                                                </Switch>
                                            )}
                                        </PageDecorator>
                                        <Footer></Footer>
                                        <CustomToast />
                                        <AdminWarningMessage></AdminWarningMessage>
                                    </AuthProvider>
                                </ErrorHandler>
                            </Suspense>
                        </div>
                    </div>
                </ThemeProvider>
            )}
        </>
    );
};

export default App;
