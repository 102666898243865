import DateFnsUtils from '@date-io/date-fns';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Editor } from '@tinymce/tinymce-react';
import { addWeeks, parseJSON } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import DOMPurify from 'dompurify';
import { useFormikContext } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import CoverImage from '../../../../components/CoverImage/CoverImage';
import CoverImageUpload from '../../../../components/CoverImageUpload/CoverImageUpload';
import ButtonLoadingIcon from '../../../../components/Icons/ButtonLoadingIcon/ButtonLoadingIcon';
import TrashIcon from '../../../../components/Icons/TrashIcon/TrashIcon';
import InputCheckbox from '../../../../components/InputCheckbox/InputCheckbox';
import InputField from '../../../../components/InputField/InputField';
import { useConfig } from '../../../../context/ConfigContext';
import { useQuest } from '../../../../context/QuestContext';
import { getFilePathByName, getFilePathForDefaultImage, IFile } from '../../../../models/File';
import { SelectOption } from '../../../../models/ImageUpload';
import { getColor } from '../../../../utils/colors';
import { FormFields } from '../useQuestEditForm';
import Decks from './Decks/Decks';
import Groups from './Groups/Groups';
import classes from './QuestEditDetailForm.module.scss';

type FormProps = {
    isCreate: boolean;
    handleFormSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    isSubmitting: boolean;
    disabledSubmit: boolean;
    isSaved: boolean;
    deleteHandler: () => void;
    cancelHandler: () => void;
};

const QuestEditDetailForm: FunctionComponent<FormProps> = ({
    isCreate,
    handleFormSubmit,
    deleteHandler,
    cancelHandler,
    disabledSubmit,
    isSubmitting,
}) => {
    const { config, loadingConfig } = useConfig();
    const { t } = useTranslation();
    // const { auth } = useAuth();
    const { quest, setQuest } = useQuest();
    const { setFieldValue } = useFormikContext<FormFields>();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [defaultCover, setDefaultCover] = useState('');

    useEffect(() => {
        if (!loadingConfig) {
            const customFile = config.tenant.images['quest.png']?.find((file) => file.is_default == false);
            const defaultFile = config.tenant.images['quest.png']?.find((file) => file.is_default == true);
            setDefaultCover(
                getFilePathByName(customFile?.file?.name) || getFilePathForDefaultImage(defaultFile?.file?.name) || '',
            );
        }
    }, [loadingConfig]);

    const buttonLoadingIconColor = getColor('--brand-color-10');

    useEffect(() => {
        setFieldValue('cover_image_id', quest.cover_image?.id);
    }, [quest.cover_image]);

    useEffect(() => {
        setFieldValue('cover_background_size', quest.cover_background_size);
    }, [quest.cover_background_size]);

    useEffect(() => {
        setFieldValue('start_date', quest.start_date);
    }, [quest.start_date]);

    useEffect(() => {
        setFieldValue('end_date', quest.end_date);
    }, [quest.end_date]);

    const titleChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setQuest({ ...quest, title: event.target.value });
    };

    const descriptionChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setQuest({ ...quest, description: event.target.value });
    };

    const backgroundSizeChangeHandler = (option: SelectOption | null) => {
        if (option) {
            setQuest({ ...quest, cover_background_size: option.value });
        }
    };

    const onUploadImageHandler = (file: IFile | undefined) => {
        setQuest({ ...quest, cover_image: file });
    };

    const [startDate, setStartDate] = useState<Date>(quest.start_date ? parseJSON(quest.start_date) : new Date());
    const [endDate, setEndDate] = useState<Date>(quest.end_date ? parseJSON(quest.end_date) : addWeeks(new Date(), 1));

    const calendar = (
        <div className={`${classes.Calendar}`}>
            <h2 className={classes.CalendarTitle}>{t('Quests:QUEST_PERIOD_TITLE')}</h2>
            <div className={classes.CalendarContent}>
                <FontAwesomeIcon className={classes.CalendarIcon} icon={faCalendarAlt} />
                <div className={`h7 ${classes.QuestDates}`}>
                    <div className={`${classes.StartDate}`}>
                        <span>{`${t('Common:FROM')}:`}</span> <span>{` ${quest.startDateFormatted}`}</span>
                    </div>
                    <div className={`${classes.EndDate}`}>
                        <span>{`${t('Common:UNTIL')}: `}</span>
                        <span>{`${quest.endDateFormatted}`}</span>
                    </div>
                </div>
            </div>
        </div>
    );

    const handleEditorChange = (e) => {
        const sanitized = DOMPurify.sanitize(e.target.getContent());
        setFieldValue('description_full', sanitized);
    };

    const enableTimeQuestChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuest({ ...quest, is_timed: event.target.checked });
    };

    useEffect(() => {
        if (quest.start_date != undefined) {
            const date = parseJSON(quest.start_date);
            const localDate = utcToZonedTime(date, timeZone);
            setStartDate(localDate);
        }

        if (quest.end_date != undefined) {
            const date = parseJSON(quest.end_date);
            const localDate = utcToZonedTime(date, timeZone);
            setEndDate(localDate);
        }
    }, [quest.start_date, quest.end_date]);

    const handleStartDateChange = (date: MaterialUiPickersDate | null) => {
        if (date) {
            setStartDate(date);

            const utcDate = zonedTimeToUtc(date, timeZone);
            setFieldValue('start_date', utcDate.toISOString());
            setQuest({ ...quest, start_date: utcDate.toISOString() });
        }
    };

    const handleEndDateChange = (date: MaterialUiPickersDate | null) => {
        if (date) {
            setEndDate(date);

            const utcDate = zonedTimeToUtc(date, timeZone);
            setFieldValue('end_date', utcDate.toISOString());
            setQuest({ ...quest, end_date: utcDate.toISOString() });
        }
    };

    return (
        <form noValidate={true} onSubmit={handleFormSubmit}>
            <div className={classes.formGroup}>
                <div className={classes.QuestDetail}>
                    <div className={classes.InputContainer}>
                        <div className={`Box ${classes.InputContent}`}>
                            <div className={classes.formInput}>
                                <div className={`${classes.formGroup}`}>
                                    <InputField
                                        name="title"
                                        type="text"
                                        label={t('Categories:CATEGORY_TITLE_INPUT_LABEL')}
                                        placeholder={t('Categories:CATEGORY_TITLE_INPUT_PLACEHOLDER')}
                                        onChange={titleChangeHandler}
                                    ></InputField>
                                </div>
                                <div className={`${classes.formGroup}`}>
                                    <InputField
                                        name="description"
                                        type="text"
                                        label={t('Categories:CATEGORY_DESCRIPTION_INPUT_LABEL')}
                                        placeholder={t('Categories:CATEGORY_DESCRIPTION_INPUT_PLACEHOLDER')}
                                        onChange={descriptionChangeHandler}
                                    ></InputField>
                                </div>

                                <div className={`${classes.formGroup}`}>
                                    <label htmlFor="description_full" className={classes.EditorLabel}>
                                        {t('Categories:CATEGORY_DESCRIPTION_FULL_INPUT_LABEL')}
                                    </label>

                                    <div
                                        className={`${classes.EditorInput} MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl`}
                                    >
                                        <Editor
                                            apiKey="ujlfgzlc89rbjmaeo8z4uz2row57k2a90t5675o7sre0mbv2"
                                            initialValue={quest.description_full}
                                            init={{
                                                min_height: 300,
                                                branding: false,
                                                inline: true,
                                                // auto_focus: true,
                                                menubar: false,
                                                relative_urls: false,
                                                toolbar:
                                                    'formatselect | bold italic forecolor | alignment | link image visualblocks | bullist numlist | outdent indent',
                                                setup: function (editor) {
                                                    editor.ui.registry.addGroupToolbarButton('alignment', {
                                                        icon: 'align-left',
                                                        tooltip: 'Alignment',
                                                        items: 'alignleft aligncenter alignright | alignjustify',
                                                    });
                                                },
                                                plugins: [
                                                    'advlist autolink autoresize lists link image imagetools',
                                                    'print preview anchor',
                                                    'searchreplace visualblocks',
                                                    'table paste wordcount',
                                                ],
                                                paste_block_drop: false,
                                                toolbar_mode: 'floating',
                                            }}
                                            onChange={handleEditorChange}
                                        />
                                        <fieldset className="MuiPrivateNotchedOutlineRoot MuiOutlinedInput-notchedOutline">
                                            <legend className="MuiPrivateNotchedOutlineLegendLabelled MuiPrivateNotchedOutlineLegendNotched">
                                                <span>{t('Categories:CATEGORY_DESCRIPTION_FULL_INPUT_LABEL')}</span>
                                            </legend>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className={`checkbox ${classes.EnableTimedQuest}`}>
                                    <InputCheckbox
                                        id="is_timed"
                                        name="is_timed"
                                        type="checkbox"
                                        label={
                                            <label htmlFor="is_timed">{t('Common:EDIT_QUEST_IS_TIMED_LABEL')}</label>
                                        }
                                        onChange={enableTimeQuestChangeHandler}
                                    ></InputCheckbox>
                                </div>

                                {quest.is_timed && (
                                    <div className={classes.DateRange}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DateTimePicker
                                                id="start_date"
                                                name="start_date"
                                                label={t('Common:START_DATE_LABEL')}
                                                InputLabelProps={{ shrink: true }}
                                                value={startDate}
                                                animateYearScrolling
                                                ampm={false}
                                                format="dd MMMM yyyy HH:mm"
                                                views={['date']}
                                                onChange={handleStartDateChange}
                                            />
                                        </MuiPickersUtilsProvider>

                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DateTimePicker
                                                id="end_date"
                                                name="end_date"
                                                label={t('Common:END_DATE_LABEL')}
                                                InputLabelProps={{ shrink: true }}
                                                value={endDate}
                                                animateYearScrolling
                                                ampm={false}
                                                format="dd MMMM yyyy HH:mm"
                                                views={['date']}
                                                onChange={handleEndDateChange}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={`${classes.formGroup}`}>
                            <CoverImageUpload
                                onChange={onUploadImageHandler}
                                originalFile={quest.cover_image}
                                defaultBackgroundSize={quest.cover_background_size}
                                onChangeBackgroundSize={backgroundSizeChangeHandler}
                            />
                        </div>

                        <div className={`${classes.formGroup}`}>
                            <Groups isCreate={isCreate}></Groups>
                        </div>

                        <div className={`${classes.formGroup}`}>
                            <Decks isCreate={isCreate}></Decks>
                        </div>

                        <div className={`${classes.formActions}`}>
                            {!isCreate && (
                                <Button
                                    className={classes.formDelete}
                                    text={t('Common:BUTTON_DELETE')}
                                    danger
                                    icon={<TrashIcon />}
                                    iconSide="left"
                                    onClick={deleteHandler}
                                ></Button>
                            )}

                            <Button
                                text={t('Common:BUTTON_CANCEL')}
                                alt={true}
                                border={true}
                                onClick={cancelHandler}
                            ></Button>

                            <button
                                className={`${classes.submitButton} btn btn-flavour btn-flavour--alt`}
                                type="submit"
                                disabled={disabledSubmit}
                            >
                                {isSubmitting && <ButtonLoadingIcon fill={buttonLoadingIconColor} />}
                                {isCreate ? t('Common:BUTTON_CREATE') : t('Common:BUTTON_SAVE')}
                            </button>
                        </div>
                    </div>
                    <div className={classes.CategoryCover}>
                        <div className={`Box ${classes[quest.time_state]} ${classes.QuestCoverCard}`}>
                            <div className={`${classes.CoverImage}`}>
                                <CoverImage
                                    src={getFilePathByName(quest.cover_image?.name) || defaultCover}
                                    backgroundSize={quest.cover_background_size}
                                ></CoverImage>
                            </div>

                            {quest.is_timed && calendar}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default QuestEditDetailForm;
