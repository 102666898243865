import React, { FunctionComponent, InputHTMLAttributes } from 'react';
import { useField } from 'formik';
import classes from './TextAreaField.module.scss';
import CharactersLeft from '../CharactersLeft/CharactersLeft';

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
    name: string;
    type: string;
    placeholder: string;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextAreaField: FunctionComponent<Props> = (props) => {
    const [field, { error, touched }] = useField({
        name: props.name,
        type: props.name,
    });

    const className = error && touched ? classes.error : '';
    return (
        <div className={`${classes.TextAreaField} ${error && classes.error}`}>
            <textarea
                {...field}
                {...props}
                onChange={(e) => {
                    field.onChange(props.name)(e.target.value);
                    props.onChange?.(e);
                }}
                className={`form-control ${className}`}
            />
            <div className={classes.BelowField}>
                {error && touched && <div className={classes.errorMessage}>{error}</div>}
                {props.maxLength && (
                    <CharactersLeft max={props.maxLength} current={field.value.length}></CharactersLeft>
                )}
            </div>
        </div>
    );
};

export default TextAreaField;
