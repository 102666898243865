import { addWeeks } from 'date-fns';
import _ from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { IGroup } from '../models/Group';
import { IQuest, TimeState } from '../models/Quest';
import { compareToTimeState, formatDateDistance, formatDateDistanceToNow, formatDateToLocal } from '../utils/date';
import { CtxProvider } from './QuestContext';

type QuestProviderProps = {
    questOverride?: Partial<IQuest>;
};

export const QuestProvider: FunctionComponent<QuestProviderProps> = ({ children, questOverride }) => {
    const defaultQuest: IQuest = {
        title: '', // WWWWWWWWWWWWWWWW
        description: '', // WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW
        description_full: '', // WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW
        start_date: new Date().toISOString(),
        end_date: addWeeks(new Date(), 1).toISOString(),
        style: '{"cover_image": ["url" : "", "background_size": "cover"]}',
        order: 0,
        id: 0,
        created_at: '',
        updated_at: '',
        potential_groups: [],
        potential_decks: [],
        current_groups: [],
        //
        decks: [],
        user_progress: [],
        quest_progress: {
            totalTasks: 0,
            completedTasks: 0,
            completedQuests: 0,
            totalParticipants: 0,
            group_progress: [],
        },
        group_progress: {
            totalTasks: 0,
            completedTasks: 0,
            totalParticipants: 0,
            quest_completed: 0,
        },
        elements: [],
        groups: [],

        //
        is_timed: true,
        //
        time_state: 'awaiting',
        is_ended: false,
        progress_state: 'in_progress',
        is_completed: 0,
        startDateFormatted: '',
        endDateFormatted: '',
        totalDateLengthFormatted: '',
        distanceToNowFormatted: '',

        cover_background_size: 'cover',
    };

    const getCalculatedQuest = (quest: Partial<IQuest>) => {
        const start = quest.start_date;
        const end = quest.end_date;
        if (start && end) {
            return {
                startDateFormatted: formatDateToLocal(start, 'dd MMMM yyyy'),
                endDateFormatted: formatDateToLocal(end, 'dd MMMM yyyy'),
                totalDateLengthFormatted: formatDateDistance(start, end),
                distanceToNowFormatted: formatDateDistanceToNow(start, end),
                time_state: quest.is_timed ? compareToTimeState(start, end) : 'in_progress',
            };
        }

        const returnObject: {
            time_state: TimeState;
        } = {
            time_state: 'in_progress',
        };

        return returnObject;
    };

    const getDefaultQuest = (override: Partial<IQuest>) => {
        const calculatedQuest = getCalculatedQuest(override);
        return { ...defaultQuest, ...override, ...calculatedQuest };
    };

    const [quest, updateQuest] = useState<IQuest>(() => {
        if (questOverride != undefined) {
            return getDefaultQuest(questOverride);
        }
        const calculatedQuest = getCalculatedQuest(defaultQuest);

        return { ...defaultQuest, ...calculatedQuest };
    });

    const setQuest = (newQuest: IQuest) => {
        updateQuest((current) => {
            if (newQuest.start_date === null) {
                newQuest.start_date = current.start_date;
            }

            if (newQuest.end_date === null) {
                newQuest.end_date = current.end_date;
            }
            const calculatedQuest = getCalculatedQuest(newQuest);
            return { ...current, ...newQuest, ...calculatedQuest };
        });
    };

    const addGroup = (group: IGroup) => {
        const newGroups: IGroup[] = _.cloneDeep(quest.groups);
        newGroups.push(group);

        const newPotentialGroups: IGroup[] = _.cloneDeep(quest.potential_groups);
        newPotentialGroups.splice(
            newPotentialGroups.findIndex((findGroup) => {
                return group.id === findGroup.id;
            }),
            1,
        );

        const newQuest = { ...quest, groups: newGroups, potential_groups: newPotentialGroups };
        setQuest(newQuest);
    };

    const removeGroup = (group: IGroup) => {
        const newGroups: IGroup[] = _.cloneDeep(quest.groups);
        newGroups.splice(
            newGroups.findIndex((findGroup) => {
                return group.id === findGroup.id;
            }),
            1,
        );

        const newPotentialGroups: IGroup[] = _.cloneDeep(quest.potential_groups);
        newPotentialGroups.push(group);

        const newQuest = { ...quest, groups: newGroups, potential_groups: newPotentialGroups };
        setQuest(newQuest);
    };

    return <CtxProvider value={{ quest, setQuest, addGroup, removeGroup }}>{children}</CtxProvider>;
};
