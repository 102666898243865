export {};

console.log(
    `%c %cHero Center%cv${process.env.REACT_APP_VERSION}%c${process.env.REACT_APP_ENV}%c `,
    'display: block; margin: 1rem;',
    'color: #264567; font-weight: bold; font-family:sans-serif; font-size: 20px',
    'color: white; font-size: 10px;' +
        'display: inline-block; background-color: #fa5b7b; border-radius: 3px;' +
        'padding: 4px 6px; margin: 2px 0.5rem;',
    'color: white; font-size: 10px;' +
        'display: inline-block; background-color: #ffa466; border-radius: 3px;' +
        'padding: 4px 6px; margin: 2px; text-transform: capitalize',
    'display: block; margin: 0.5rem;',
);

console.group(
    '%c-- settings --',
    'display: block; color: white; padding: 2px 16px; background-color: #264567; border-radius: 3px; text-align: center; text-transform: uppercase;',
);
