import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import Loader from '../../../components/Loader/Loader';
import { useConfig } from '../../../context/ConfigContext';
import { useDeck } from '../../../context/DeckContext';
import { IDeck } from '../../../models/Deck';
import { IModule } from '../../../models/Module';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import DeckEditDetailFormik from '../DeckEditDetailForm/DeckEditDetailFormik';
import classes from './DeckEditDetail.module.scss';

type DeckEditDetailProps = {
    isCreate: boolean;
};

type ParamTypes = {
    deckId: string;
    cardId?: string;
};

const DeckEditDetail: FunctionComponent<DeckEditDetailProps> = (props) => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const history = useHistory();

    // Props
    const [isCreate, setIsCreate] = useState(props.isCreate);

    // Params
    const { deckId, cardId } = useParams<ParamTypes>();

    // State from location
    const location = useLocation();

    // Module
    const [moduleId, setModuleId] = useState(0);

    // Deck
    const { deck, setDeck, setCurrentCardIndex, cards } = useDeck();
    // const [startCardIndex, setStartCardIndex] = useState(0);

    // Deck request
    const { response, loading } = useAxiosFetch<IDeck>(isCreate ? null : `${config.baseUrl}/decks/${deckId}`);

    // Deck Form

    useEffect(() => {
        if (!isCreate) {
            const props = location.state as { deck: IDeck; currentCardIndex: number };
            if (props?.deck) {
                setCurrentCardIndex(props.currentCardIndex);
            }
        }
    }, []);

    useEffect(() => {
        if (response) {
            const deckData = response.data;
            setDeck(deckData);

            const props = location.state as { deck: IDeck; currentCardIndex: number };
            if (!isCreate && !props?.deck && deckData.cards) {
                setCurrentCardIndex(deckData.cards.findIndex((card) => card.id.toString() === cardId));
            }
        }
    }, [response]);

    const deckCreatedResponseHandler = () => {
        setIsCreate(false);
    };

    return (
        <>
            <div className={classes.EditDetailContainer}>
                {loading ? (
                    <Loader></Loader>
                ) : (
                    <>
                        <DeckEditDetailFormik
                            isCreate={isCreate}
                            moduleId={moduleId}
                            deck={deck}
                            cardId={cardId}
                            deckCreatedResponseHandler={deckCreatedResponseHandler}
                        ></DeckEditDetailFormik>
                    </>
                )}
            </div>
        </>
    );
};

export default DeckEditDetail;
