import { animated, useSpring } from '@react-spring/web';
import React, { FunctionComponent } from 'react';
import classes from './AnimatedNumber.module.scss';

export type Percentage = number;

export interface AnimatedNumberProps {
    value: number | Percentage;
    delay?: number;
    fixed?: boolean;
}

const AnimatedNumber: FunctionComponent<AnimatedNumberProps> = ({ value, delay = 500, fixed = false }) => {
    const props = useSpring({ number: value, from: { number: 0 }, delay: delay });
    const valueNumber = props.number.to((x) => `${!fixed ? x : x.toFixed(0)}`);

    return (
        <div className={classes.AnimatedNumber}>
            <animated.span>{valueNumber}</animated.span>
        </div>
    );
};

export default AnimatedNumber;
