import React, { FC } from 'react';
import { ConnectionLineComponentProps, getBezierPath, getMarkerEnd, MarkerType } from 'react-flow-renderer/nocss';
import classes from './ConnectionLine.module.scss';

const ConnectionLine: FC<ConnectionLineComponentProps> = ({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    connectionLineStyle,
}) => {
    const offsetX = targetX - 10;
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX: offsetX,
        targetY,
        targetPosition,
    });

    return (
        <>
            <defs>
                <marker
                    id="connectionMarkerArrow"
                    markerWidth="8"
                    markerHeight="8"
                    refX="0"
                    refY="4"
                    orient="auto"
                    style={{ stroke: 'red', fill: 'red' }}
                >
                    <polygon points="0 0, 8 4, 0 8" />
                </marker>
            </defs>

            <path
                className={`${classes.EdgePath} animated`}
                style={connectionLineStyle}
                d={edgePath}
                fillRule={'evenodd'}
            />

            <path
                className={`${classes.EdgePathMarker}`}
                style={connectionLineStyle}
                d={edgePath}
                markerEnd={getMarkerEnd(MarkerType.ArrowClosed, 'connectionMarkerArrow')}
            />

            {/* <path
                fill="none"
                stroke="#FF0000"
                strokeWidth={5}
                className="animated"
                d={edgePath}
                // markerEnd={getMarkerEnd(MarkerType.ArrowClosed, 'markerArrow')}
            />

            <path
                fill="none"
                stroke="#FF0000"
                strokeWidth={1.5}
                // className="animated"
                d={edgePath}
                markerEnd={getMarkerEnd(MarkerType.ArrowClosed, 'markerArrow')}
            /> */}
        </>
    );
};

export default ConnectionLine;
