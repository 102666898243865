import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../../../components/Button/Button';
import ExtendedMuiTable from '../../../components/ExtendedMuiTable/ExtendedMuiTable';

import PublicationStateLabel from '../../../components/PublicationStateLabel/PublicationStateLabel';
import TagLabel from '../../../components/TagLabel/TagLabel';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigContext';
import { ICategory } from '../../../models/Categories';
import { IDeck } from '../../../models/Deck';
import { ITag } from '../../../models/Tag';
import axiosInstance from '../../../utils/axios-instance';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import ManagementHeader from '../ManagementHeader/ManagementHeader';
import DeckActionsPopover from './DeckActionsPopover/DeckActionsPopover';
import classes from './ManagementLibrary.module.scss';

const ManagementLibrary: FunctionComponent = () => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const history = useHistory();
    const { response, loading, sendRequest } = useAxiosFetch<IDeck[]>(`${config.baseUrl}/decks`);
    const [decks, setDecks] = useState<IDeck[]>([]);
    const [categories, setCategories] = useState<ICategory[]>();
    const [deckToRemove, setDeckToRemove] = useState<IDeck>();
    const { hasPermission } = useAuth();

    useEffect(() => {
        if (response) {
            setDecks(response.data);
        }
    }, [response]);

    const onAddDeckHandler = () => {
        history.push({ pathname: `/deck/create` });
    };

    const editDeckClickHandler = (deck: IDeck) => {
        history.push({ pathname: `/deck/edit/${deck.id}`, state: { deck, currentCardIndex: 0 } });
    };

    const downloadResultClickHandler = (deck: IDeck) => {
        axiosInstance
            .get(`${config.baseUrl}/decks/answers/${deck.id}`, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                const timestamp = format(new Date(), 'MM-dd-yyyy');
                link.setAttribute('download', `deck_export_${deck.id}_${timestamp}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((response) => {
                console.error('Could not download the excel export from the backend', response);
            });
    };

    const duplicateClickHandler = async (deck: IDeck) => {
        const response = await axiosInstance.get(`${config.baseUrl}/decks/duplicate/${deck.id}`);
        const newDeck: IDeck = response.data;
        history.push({ pathname: `/deck/edit/${newDeck.id}`, state: newDeck });
    };

    const [open, setOpen] = useState(false);
    const closeModal = () => {
        setDeckToRemove(undefined);
        setOpen(false);
    };

    const deleteHandler = async (deck: IDeck) => {
        setDeckToRemove(deck);
        setOpen((o) => !o);
    };

    const notifyDeleted = () => toast.success(t('Common:TOAST_DECK_DELETED_SUCCESS'));
    const notifyNotDeleted = () => toast.error(t('Common:TOAST_DECK_DELETED_ERROR'));

    const permanentlyDeleteHandler = async () => {
        setOpen(false);

        if (deckToRemove === undefined) return;

        try {
            await axiosInstance.delete(`${config.baseUrl}/decks/${deckToRemove?.id}`);
            sendRequest();

            notifyDeleted();
        } catch (error) {
            notifyNotDeleted();
        }
    };

    const categoriesList = (categories: ICategory[] | undefined) => {
        return categories && categories.length > 0 ? (
            categories?.map((category) => category.title).join(', ')
        ) : (
            <p>-</p>
        );
    };

    const tagsList = (tags: ITag[] | undefined) => {
        return tags && tags.length > 0 ? tags.map((tag) => <TagLabel key={`tag_${tag.id}`} tag={tag} />) : <p>-</p>;
    };

    const filterDataBySearch = (searchValues: string[]) => {
        return decks.filter((item) => {
            const name = item.title;

            const searchableValues = { name };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    const renderTableHeaders = (
        <TableRow className={classes.DecksListHeader}>
            <TableCell>
                <div className={`table-header`}>{t('Common:DECKS_TITLE_HEADER')}</div>
            </TableCell>
            <TableCell>
                <div className={`table-header`}>{t('Common:DECKS_CATEGORIES_HEADER')}</div>
            </TableCell>
            <TableCell>
                <div className={`table-header`}>{t('Common:DECKS_TAGS_HEADER')}</div>
            </TableCell>
            <TableCell align="right"></TableCell>
        </TableRow>
    );

    const renderItem = (deck: IDeck) => {
        return (
            <TableRow className={classes.DeckItem} key={`deck_${deck.id}`}>
                <TableCell>
                    <div className={classes.DeckDetails}>
                        <div className={classes.DeckTitle}>{`${deck.title}`}</div>
                        <PublicationStateLabel isPublished={deck.is_published} />
                    </div>
                </TableCell>

                <TableCell>
                    <div className={classes.DeckCategories}>{categoriesList(deck.categories)}</div>
                </TableCell>

                <TableCell>
                    <div className={classes.DeckTags}>{tagsList(deck.tags)}</div>
                </TableCell>

                <TableCell align="right" className={classes.DeckControls}>
                    <div className={classes.DeckControls}>
                        <DeckActionsPopover
                            deck={deck}
                            onDownloadHandler={downloadResultClickHandler}
                            onDuplicateHandler={duplicateClickHandler}
                            onEditHandler={editDeckClickHandler}
                            onDeleteHandler={deleteHandler}
                        />
                    </div>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <div className={classes.ManagementLibrary}>
            <ManagementHeader
                title={t('Common:MANAGEMENT_LIBRARY_TITLE')}
                permission="library.manage"
                buttonText={t('Common:BUTTON_ADD_DECK')}
                onClick={onAddDeckHandler}
            />

            <div className={`Box ${classes.DecksListContainer}`}>
                <ExtendedMuiTable
                    loading={loading}
                    searchInputLabel={t('Common:DECKS_SEARCH_INPUT_LABEL')}
                    searchAutoFocus={true}
                    filterDataBySearch={filterDataBySearch}
                    noItemsMessage={t('Common:LIST_MESSAGE_NO_DECKS')}
                    noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_DECKS')}
                    items={decks}
                    renderTableHeaders={renderTableHeaders}
                    renderItem={renderItem}
                />
            </div>

            <Dialog
                open={open}
                onClose={closeModal}
                className={classes.WarningDialog}
                PaperProps={{ className: `dialog` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:WARNING_HEADER')}</DialogTitle>
                <DialogContent>{t('Deck:DELETION_WARNING_MESSAGE', { title: deckToRemove?.title })}</DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={closeModal} />
                    <Button alt text={t('Common:BUTTON_PERMANENTLY_DELETE')} onClick={permanentlyDeleteHandler} />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ManagementLibrary;
