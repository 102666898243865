import { faCircleExclamation, faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, memo } from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer/nocss';
import { NodeDataType } from '../../CardFlowWIP';
import classes from './CardNode.module.scss';

// const CardNode = ({ data, isConnectable }) => {
const CardNode: FC<NodeProps<NodeDataType>> = ({ data, isConnectable }) => {
    return (
        <div className={`${classes.CardNode} CardNode`}>
            {data.isStartCard && <FontAwesomeIcon className={classes.StartCardIcon} icon={faHouse} />}
            {data.isEndCard && <FontAwesomeIcon className={classes.EndCardIcon} icon={faCircleExclamation} />}
            <div className={`${classes.Title} wrap-text-overflow-1`}>{data.label}</div>

            <div>
                <Handle
                    className={`${classes.Handle} ${classes.Left}`}
                    type="target"
                    position={Position.Left}
                    // id="i__data"
                    onConnect={(params) => console.log('handle onConnect', params)}
                    isConnectable={isConnectable}
                >
                    <div className={classes.HandleVisual}></div>
                </Handle>
                <Handle
                    className={`${classes.Handle} ${classes.Right}`}
                    type="source"
                    position={Position.Right}
                    // id="o__data"
                    onConnect={(params) => console.log('handle onConnect', params)}
                    isConnectable={isConnectable}
                >
                    <div className={classes.HandleVisual}></div>
                </Handle>
            </div>
        </div>
    );
};

export default memo(CardNode);
