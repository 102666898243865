import classes from './Library.module.scss';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useAxiosFetch from '../../utils/useAxiosFetch';
import { useConfig } from '../../context/ConfigContext';
import { gsap } from 'gsap';
import { useAuth } from '../../context/AuthContext';
import { ICategory } from '../../models/Categories';
import { isDesktop } from 'react-device-detect';
import { IDeck } from '../../models/Deck';
import DeckCover, { IncrementedRef } from '../Category/CategoryDetail/DeckCover/DeckCover';
import TagFilter from './TagFilter/TagFilter';
import { MultiValue } from 'react-select';
import { SelectOption } from '../../components/GroupSelect/GroupSelect';
import _ from 'lodash';

const Library: FunctionComponent = () => {
    const { t } = useTranslation();
    const { config } = useConfig();
    const { hasPermission } = useAuth();
    const history = useHistory();
    const { response, loading } = useAxiosFetch<ICategory[]>(`${config.baseUrl}/categories`);
    const [categories, setCategories] = useState<ICategory[]>([]);
    // const tl = gsap.timeline();
    // const list = useRef<IncrementedRef[]>([]);
    // const [refOnce, setRefOnce] = useState(false);
    const [filteredResults, setFilteredResults] = useState<ICategory[]>([]);

    useEffect(() => {
        if (response) {
            const categoriesData = response.data;
            setCategories(categoriesData);
        }
    }, [response]);

    // const animateInList = (listReference: IncrementedRef[]) => {
    //     const l = listReference.map((el) => el.current);
    //     tl.set(l, { alpha: 0, scale: 0.7 });
    //     tl.to(l, {
    //         duration: 0.25,
    //         alpha: 1,
    //         scale: 1,
    //         ease: 'back',
    //         stagger: {
    //             amount: 0.35,
    //         },
    //     });
    // };

    // const setRefs = (target: IncrementedRef | null, listReference: IncrementedRef[], itemIndex: number) => {
    //     if (target && listReference && !refOnce) {
    //         const ref = (list.current[itemIndex] = target);
    //         if (itemIndex === categories.length - 1) {
    //             // All refs are set for this list. So animation is possible.
    //             animateInList(listReference);
    //             setRefOnce(true);
    //         }
    //         return ref;
    //     }
    // };

    const editHandler = () => {
        history.push('/management/library');
    };

    const handleFilterChange = (newValue: MultiValue<SelectOption>) => {
        const searchValues = newValue.map((val) => val.label);
        setFilteredResults(filterDataBySearch(searchValues));
    };

    const filterDataBySearch = (searchValues: string[]) => {
        const searchedCategories = _.cloneDeep(categories);

        searchedCategories.map((category) => {
            category.decks = category.decks?.filter((deck) => {
                // Specific data / array to searchable string
                const tags = deck.tags?.map((tag) => tag.title).join('') || '';

                //
                const searchableValues = { tags };
                const searchableValuesToString = Object.values(searchableValues);
                ///
                const searched = searchValues.every((searchValue) => {
                    return searchableValuesToString.some((item) =>
                        item.toLowerCase().includes(searchValue.toLowerCase()),
                    );
                });

                return searched;
            });
        });

        return searchedCategories;
    };

    const searchedItems = filteredResults.length > 1 ? filteredResults : categories;

    const categoriesList = searchedItems?.map((category) => {
        return (
            category.decks &&
            category.decks.length > 0 && (
                <div key={`category_${category.id}`} className={classes.CategoryItem}>
                    <div className={`${classes.CategoryHeader}`}>
                        <div className={`h4 ${classes.CategoryTitle}`}>
                            {category.id === null ? t('Common:CATEGORY_UNCATEGORIZED_TITLE') : category.title}
                        </div>
                        <div className={classes.CategoryDescription}>{category.description}</div>
                    </div>

                    <div className={classes.DecksList}>
                        {category.decks?.map((deck, i) => {
                            return (
                                <div key={`deck_${deck.id}`} className={classes.DeckItem}>
                                    <DeckCover
                                        deck={deck}
                                        key={deck.id}
                                        // ref={(target) => {
                                        //     setRefs(target, list.current, i);
                                        // }}
                                    ></DeckCover>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )
        );
    });

    return (
        <div className={classes.LibraryContainer}>
            <PageHeader
                title={t('Common:LIBRARY_TITLE')}
                subtitle={t('Common:LIBRARY_SUBTITLE')}
                editHandler={hasPermission('library.manage') && isDesktop ? editHandler : undefined}
            />

            {loading && (
                <div className={classes.Loader}>
                    <div className="spinner card Box"></div>
                </div>
            )}

            {!loading && (
                <div className={classes.LibraryItems}>
                    <div className={classes.LibraryControls}>
                        <TagFilter handleChange={handleFilterChange} />
                    </div>

                    <div className={`${classes.CategoriesList} card-group`}>{categoriesList}</div>
                </div>
            )}
        </div>
    );
};

export default Library;
