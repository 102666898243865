import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { ICard } from '../../../../models/Card';
import Button from '../../../Button/Button';
import classes from './CardDeleteButton.module.scss';

interface CardDeleteButtonProps {
    card: ICard;
    deleteHandler?: (card: ICard) => void;
}

const CardDeleteButton: FunctionComponent<CardDeleteButtonProps> = (props) => {
    return (
        <Button
            className={classes.CardDeleteButton}
            text={``}
            danger
            icon={<FontAwesomeIcon className={classes.DeleteIcon} icon={faTrashAlt} />}
            iconSide="left"
            onClick={() => props.deleteHandler?.(props.card)}
        ></Button>
    );
};

export default CardDeleteButton;
