import React, { ButtonHTMLAttributes, ForwardRefRenderFunction } from 'react';
import classes from './Button.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    className?: string;
    alt?: boolean;
    border?: boolean;
    danger?: boolean;
    alert?: boolean;
    icon?: JSX.Element;
    iconSide?: string;
    tabIndex?: number | undefined;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (props, ref) => {
    const buttonClass = props.danger
        ? 'btn-flavour btn-flavour--danger'
        : props.alert
        ? 'btn-flavour btn-flavour--alert'
        : props.alt
        ? props.border
            ? 'btn-flavour btn-flavour--alt-border'
            : 'btn-flavour btn-flavour--alt'
        : props.border
        ? 'btn-flavour btn-flavour--border'
        : 'btn-flavour';
    const buttonOnlyIcon = props.text === '' ? `${classes.onlyIcon} btn-flavour--round` : '';

    const side = props.iconSide === 'right' || props.iconSide === undefined ? classes.iconRight : classes.iconLeft;
    const onlyIcon = props.text === '' ? classes.onlyIcon : side;

    const iconComp = props.icon && <div className={`${classes.Icon} ${onlyIcon} icon`}>{props.icon}</div>;
    return (
        <button
            ref={ref}
            type={props.type || 'button'}
            tabIndex={props.tabIndex}
            className={`btn ${props.className} ${buttonClass} ${buttonOnlyIcon} ${classes.Button}`}
            onClick={props.onClick}
            disabled={props.disabled || undefined}
        >
            {props.iconSide === 'left' && iconComp}
            {props.text}
            {(props.iconSide === 'right' || props.iconSide === undefined) && iconComp}
        </button>
    );
};

export default React.forwardRef(Button);
