import React, { FunctionComponent } from 'react';
import { IOption } from '../../../../../models/Option';
import classes from './Option.module.scss';

// export interface IOption {
//     id: number;
//     question_id: number;
//     body: string;
//     feedback: string;
//     is_correct: number;
//     is_selected: boolean;
// }

export interface IAnswer {
    id: number;
    is_correct: number;
}

type Props = {
    option: IOption;
    text: string;
    handleClick: (option: IOption) => void;
    selected: boolean;
    multipleAnswersPossible: boolean;
    disabled: boolean;
    isSaved: boolean;
    correctEnabled?: boolean;
    outOfTime?: boolean;
};

const Option: FunctionComponent<Props> = ({
    option,
    text,
    handleClick,
    selected,
    multipleAnswersPossible,
    disabled,
    isSaved,
    correctEnabled,
    outOfTime,
}) => {
    const isSavedClass = isSaved ? `${classes.saved}` : `${classes.notsaved}`;
    const selectedClass = selected ? `${classes.selected}` : isSaved ? `${classes.notselected}` : ``;
    const showIsCorrectAnswerClass =
        isSaved && option.is_correct != null && correctEnabled === true && !outOfTime
            ? option.is_correct
                ? `${classes.correct}`
                : `${classes.incorrect}`
            : '';
    const whileSavedClass = selected
        ? isSaved
            ? `${classes.answered} ${showIsCorrectAnswerClass}`
            : `${classes.notanswered} `
        : ``;

    const handleOptionClick = () => {
        // Check for one answer
        if (!multipleAnswersPossible) {
            if (selected) {
                // Click will not be handled any longer
                return;
            }
        }
        //
        handleClick(option);
    };

    return (
        <button
            className={`${classes.Option} ${selectedClass} ${isSavedClass} ${whileSavedClass} ${showIsCorrectAnswerClass} btn btn-flavour btn-flavour--question-option`}
            type="button"
            onClick={() => handleOptionClick()}
            disabled={disabled}
        >
            {text}
            {/* {`option.is_correct: ${option.is_correct}`} */}
        </button>
    );
};

export default Option;
