import { FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useConfig } from '../../../context/ConfigContext';
import { IAccessCode } from '../../../models/AccessCode';
import axiosInstance from '../../../utils/axios-instance';

export interface FormFields {
    title: string;
    expires_at?: string;
    max_users: number;
}

export type ReturnType = {
    handleSubmit: (
        accessCode: IAccessCode,
        formFields: FormFields,
        actions: FormikHelpers<FormFields>,
    ) => Promise<void>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
};

const useAccessCodeEditForm = (initialValues: FormFields, isCreate: boolean): ReturnType => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const history = useHistory();
    const [isSaved, setIsSaved] = useState(false);

    const notifyCreated = () => toast.success(t('Common:TOAST_ACCESS_CODE_CREATED_SUCCESS'));
    const notifyUpdated = () => toast.success(t('Common:TOAST_ACCESS_CODE_UPDATED_SUCCESS'));

    const handleSubmit = useCallback(
        (accessCode: IAccessCode, formFields: FormFields, actions: FormikHelpers<FormFields>) => {
            if (isCreate) {
                return axiosInstance
                    .post(`${config.baseUrl}/accesscodes`, {
                        ...formFields,
                    })
                    .then(() => {
                        setIsSaved(true);
                        actions.resetForm({ values: formFields });
                        history.goBack();
                        notifyCreated();
                    });
            } else {
                return axiosInstance
                    .put(`${config.baseUrl}/accesscodes/${accessCode.id}`, { ...formFields })
                    .then(() => {
                        setIsSaved(true);
                        actions.resetForm({ values: formFields });
                        history.goBack();
                        notifyUpdated();
                    });
            }
        },
        [],
    );

    return {
        handleSubmit,
        initialValues,
        isSaved,
        setIsSaved,
    };
};

export default useAccessCodeEditForm;
