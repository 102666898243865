import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import { useDeck } from '../../context/DeckContext';
import { ICard } from '../../models/Card';
import { IEdge } from '../../models/Edge';
import { IOption } from '../../models/Option';
import classes from './CardSelect.module.scss';

export interface SelectOption {
    value: number;
    label: string;
}

export interface CardSelectProps {
    name: string;
    index: number;
    actionId: number;
    titleLabel?: string;
    actionModelType?: string;
    edges?: IEdge[];
    isDisabled?: boolean;
    // error: string;
    // touched: boolean;
    onChange: (edge: IEdge | undefined, targetCard?: ICard) => void;
    // onBlur: (event: React.FocusEvent<HTMLElement>) => void;
    // isMulti?: boolean;
    addNewCardHandler: (title: string) => Promise<ICard | undefined>;
    // refSelectContainer: React.MutableRefObject<null>;
}

export interface FormFields {
    options: IOption[];
}

const CardSelect: FunctionComponent<CardSelectProps> = (props) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const { setFieldValue, getFieldMeta, setFieldTouched } = useFormikContext<FormFields>();
    const [isLoading, setIsLoading] = useState(false);

    const className = '';
    const { cards, currentCardIndex, currentCard } = useDeck();

    const generateOptions = (cards: ICard[]) => {
        const cardsWithoutCurrent = _.cloneDeep(cards);
        cardsWithoutCurrent.splice(currentCardIndex, 1);

        return cardsWithoutCurrent.map((card) => {
            return { value: card.id, label: card.title };
        });
    };

    const [options, setOptions] = useState<SelectOption[]>(() => generateOptions(cards));
    const findOptionById = () => {
        const foundOption = options.find((option) => {
            const foundId = _.last(props.edges)?.target_id;
            return option.value === foundId;
        });

        return foundOption;
    };

    const [value, setValue] = useState<SelectOption | undefined>(findOptionById);

    useEffect(() => {
        if (cards) {
            // const newCards = cards.map((card) => {
            //     return { value: card.id, label: card.title };
            // });

            const newOptions = generateOptions(cards);
            setOptions(newOptions);
        }
    }, [cards]);

    const createOrUpdateAction = (cardId: number, option: SelectOption, card?: ICard) => {
        if (currentCard === undefined) return;
        const edge: IEdge = {
            id: 9999,
            source_id: currentCard.id,
            source_model_type: 'card',
            action_id: props.actionId,
            action_model_type: props.actionModelType || 'option',
            target_id: cardId,
            target_model_type: 'card',
        };

        setValue(option);
        setFieldValue(props.name, [edge]);
        props.onChange?.(edge, card);
    };

    const handleCreate = (inputValue: string) => {
        setIsLoading(true);
        props.addNewCardHandler?.(inputValue)?.then((result) => {
            if (result) {
                const newOption: SelectOption = { value: result.id, label: result.title };
                createOrUpdateAction(result.id, newOption, result);
            }

            setIsLoading(false);
        });
    };

    const error = getFieldMeta(props.name).error;
    const touched = getFieldMeta(props.name).touched;
    const isError = Boolean(error) && touched;

    return (
        <div className={classes.FormCards}>
            {props.titleLabel && (
                <div className={classes.FormFieldHeader}>
                    <h2 className={`h5 wrap-text ${classes.FormFieldTitle}`}>{props.titleLabel}</h2>
                </div>
            )}

            <div className={`${classes.CardSelect}`}>
                <CreatableSelect
                    id={props.name}
                    ref={ref}
                    // defaultValue={optionByCard()}
                    placeholder={t('Common:CARD_SELECT_PLACEHOLDER')}
                    noOptionsMessage={() => t('Common:CARD_SELECT_NO_MORE_OPTIONS')}
                    isLoading={isLoading}
                    options={options}
                    isMulti={false}
                    isClearable
                    // menuPortalTarget={document.body}
                    // menuPortalTarget={props.refSelectContainer.current}
                    // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    styles={{
                        menuPortal: (base) => ({
                            ...base,
                            position: 'static',
                            zIndex: 9999,
                        }),
                        control: (base) => ({
                            ...base,
                            height: 56,
                            minHeight: 56,
                            border: `1px solid ${isError ? 'var(--brand-danger-color)' : '#ccc'}`,
                        }),
                    }}
                    maxMenuHeight={300}
                    menuShouldBlockScroll={true}
                    menuPlacement={'top'}
                    onBlur={() => {
                        setFieldTouched(props.name, true);
                    }}
                    onChange={(option) => {
                        if (option) {
                            createOrUpdateAction(option.value, option);
                        } else {
                            setValue(undefined);
                            // TODO: do not clear the whole array but just the nav actions
                            setFieldValue(props.name, []);
                            props.onChange?.(undefined);
                        }

                        setFieldTouched(props.name, true);
                    }}
                    onCreateOption={handleCreate}
                    value={value}
                    className={`react-select-container ${className}`}
                    classNamePrefix="react-select"
                    isDisabled={props.isDisabled}
                ></CreatableSelect>

                <div className={classes.BelowField}>
                    {isError && <div className={classes.errorMessage}>{error}</div>}
                </div>
            </div>
        </div>
    );
};

export default CardSelect;
