import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Formik } from 'formik';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import * as Yup from 'yup';
import Button from '../../../components/Button/Button';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { useConfig } from '../../../context/ConfigContext';
import { useProfile } from '../../../context/ProfileContext';
import { IProfile } from '../../../models/User';
import AxiosInstance from '../../../utils/axios-instance';
import { formatDateToLocal } from '../../../utils/date';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import classes from './ProfileEditDetail.module.scss';
import ProfileEditDetailForm from './ProfileEditDetailForm/ProfileEditDetailForm';
import useProfileEditForm from './useProfileEditForm';

type ProfileEditDetailProps = {
    isAuthUser?: boolean;
    profileData?: IProfile;
};

type ParamTypes = {
    profileId: string;
};

const ProfileEditDetail: FunctionComponent<ProfileEditDetailProps> = ({ isAuthUser = true }) => {
    const { config } = useConfig();
    const location = useLocation();
    const history = useHistory();
    const { profileId } = useParams<ParamTypes>();
    const [profileAPIUrl, setProfileAPIUrl] = useState<string>(() => {
        return profileId;
    });
    const { response, loading, sendRequest } = useAxiosFetch<IProfile>(
        isAuthUser ? `${config.baseUrl}/user` : `${config.baseUrl}/users/${profileAPIUrl}`,
    );
    const { profile, setProfile, isDataChanged, setIsDataChanged } = useProfile();
    const [updatedAt, setUpdatedAt] = useState<string>('');
    const { t } = useTranslation();

    const schema = Yup.object({
        name: Yup.string().required(t('Common:INPUT_ERROR_NAME_REQUIRED')),
        job_title: Yup.string(),
        show_email: Yup.boolean(),
        description: Yup.string().max(191, t('Common:INPUT_ERROR_DESCRIPTION_MAX', { max: 191 })),
    });

    const { initialValues, handleSubmit, isSaved } = useProfileEditForm({
        name: profile.name,
        job_title: profile.job_title,
        profile_picture_background_size: profile.profile_picture_background_size,
        profile_picture_id: 0,
        email: profile.email,
        show_email: !!profile.show_email,
        description: profile.description,
    });

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    useEffect(() => {
        // Set profileId so loading will start
        if (profileId) {
            setProfileAPIUrl(profileId);
        }

        // Set Profile from State
        const profile = location.state as IProfile;
        if (profile) {
            setProfile(profile);
        }
    }, []);

    useEffect(() => {
        if (isDataChanged) {
            sendRequest();
            setIsDataChanged(false);
        }
    }, [location, isDataChanged]);

    useEffect(() => {
        if (response) {
            const profileData: IProfile = response.data;
            if (profileData.access_code_id != null) history.push('/profile');
            if (profileData) {
                setProfile(profileData);
                if (profileData.updated_at) {
                    setUpdatedAt(
                        `${t('Common:MODIFIED', {
                            date: formatDateToLocal(profileData.updated_at, 'd MMMM HH:mm'),
                        })}`,
                    );
                }
            }
        }
    }, [response]);

    const cancelHandler = () => {
        history.goBack();
    };

    const deleteHandler = async () => {
        setOpen((o) => !o);
    };

    const notifyDeleted = () => toast.success(t('Common:TOAST_PROFILE_DELETED_SUCCESS'));
    const notifyNotDeleted = () => toast.error(t('Common:TOAST_PROFILE_DELETED_ERROR'));

    const permanentlyDeleteHandler = async () => {
        setOpen(false);

        try {
            await AxiosInstance.delete(`${config.baseUrl}/users/${profile.id}`);
            cancelHandler();
            notifyDeleted();
        } catch (error) {
            notifyNotDeleted();
        }
    };

    return (
        <Fragment>
            <div className={classes.ProfileEditDetail}>
                <PageHeader
                    title={isAuthUser ? t('Common:PROFILE_EDIT_TITLE') : profile.name}
                    subtitle={t('Common:PROFILE_EDIT_SUBTITLE')}
                    rightAlignedText={updatedAt}
                ></PageHeader>

                {loading && (
                    <div className={classes.Loader}>
                        <div className="spinner card Box"></div>
                    </div>
                )}

                {!loading && (
                    <Fragment>
                        <Formik
                            onSubmit={(formValues, actions) => handleSubmit(profile, formValues, actions)}
                            initialValues={initialValues}
                            validationSchema={schema}
                        >
                            {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                                const disabledSubmit = isSubmitting || !(isValid && dirty);

                                return (
                                    <ProfileEditDetailForm
                                        handleFormSubmit={handleSubmit}
                                        isSubmitting={isSubmitting}
                                        disabledSubmit={disabledSubmit}
                                        isSaved={isSaved}
                                        deleteHandler={deleteHandler}
                                        cancelHandler={cancelHandler}
                                    />
                                );
                            }}
                        </Formik>
                    </Fragment>
                )}
            </div>

            <Dialog
                open={open}
                onClose={closeModal}
                className={classes.WarningDialog}
                PaperProps={{ className: `dialog` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:WARNING_HEADER')}</DialogTitle>
                <DialogContent>{t('Common:PROFILE_DELETION_WARNING_MESSAGE')}</DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={closeModal} />
                    <Button alt text={t('Common:BUTTON_PERMANENTLY_DELETE')} onClick={permanentlyDeleteHandler} />
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default ProfileEditDetail;
