import React, { FunctionComponent } from 'react';
import { useSpring, animated } from '@react-spring/web';
import classes from './PersonalProgressBar.module.scss';
import { IQuest, TimeState } from '../../../../models/Quest';
import ProgressBar from '../../../../components/ProgressBar/Progressbar';
import { useTranslation } from 'react-i18next';

export interface PersonalProgressBarProps {
    progress: number;
    timeState: TimeState;
    quest: IQuest;
}

const PersonalProgressBar: FunctionComponent<PersonalProgressBarProps> = ({ progress, timeState, quest }) => {
    const props = useSpring({ number: progress * 100, from: { number: 0 }, delay: 500 });
    const progressNumber = props.number.to((x) => `${x.toFixed(0)}%`);
    const { t } = useTranslation();

    return (
        <div className={classes.PersonalProgressBar}>
            <div className={classes.Label}>
                <h2 className={`h5`}>{t('Quests:PERSONAL_PROGRESS_LABEL')}</h2>
                <animated.span>{progressNumber}</animated.span>
            </div>

            <ProgressBar
                progress={progress}
                completed={progress === 1}
                timeState={timeState}
                styles={{
                    borderWidth: 0,
                }}
            ></ProgressBar>
        </div>
    );
};

export default PersonalProgressBar;
