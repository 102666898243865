import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useConfig } from '../../../context/ConfigContext';
import { IUserAnswer } from '../../../models/Element';
import Axios from '../../../utils/axios-instance';

interface FormFields {
    answers: number[];
}

export type ReturnType = {
    handleSubmit: (formFields: FormFields, answerId?: number) => Promise<AxiosResponse<IUserAnswer>>;
    initialValues: FormFields;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
};

// export interface IQuestionAnswerResponse {
//     question_id: number;
//     user_id: number;
//     options: IOption[];
// }

const useForkQuestionForm = (
    initialValues: FormFields,
    deckId: number,
    questionId: number,
    multipleAnswersPossible: boolean,
): ReturnType => {
    const { config } = useConfig();
    const [isSaved, setIsSaved] = useState(initialValues.answers.length != 0);

    const handleSubmit = useCallback((formFields: FormFields, answerId?: number) => {
        const body = {
            answer_id: answerId,
            deck_id: deckId,
            question_id: questionId,
            option_ids: multipleAnswersPossible ? formFields.answers.join() : formFields.answers[0].toString(),
        };

        return Axios.post<IUserAnswer>(`${config.baseUrl}/questions_answer`, body);
    }, []);

    return {
        handleSubmit,
        initialValues,
        isSaved,
        setIsSaved,
    };
};

export default useForkQuestionForm;
