import { faClose, faPlus, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/Button/Button';
import ExtendedMuiList from '../../../../../../components/ExtendedMuiList/ExtendedMuiList';
import { IGroup } from '../../../../../../models/Group';
import classes from './GroupsDialog.module.scss';

interface GroupsDialogProps {
    open: boolean;
    groups: IGroup[];
    toggleOpen: () => void;
    addGroup: (group: IGroup) => void;
}

const GroupsDialog: FunctionComponent<GroupsDialogProps> = ({ groups, open, toggleOpen, addGroup }) => {
    const { t } = useTranslation();

    const filterPotentialGroupsDataBySeach = (searchValues: string[]) => {
        return groups.filter((item) => {
            const name = item.name.toLowerCase();

            const searchableValues = { name };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    const renderPotentialGroupItem = (group: IGroup) => {
        return (
            <div className={classes.GroupItem} key={`group_${group.id}`}>
                <div className={classes.GroupDetails}>
                    <div className={classes.GroupAvatar}>
                        <FontAwesomeIcon className={classes.GroupIcon} icon={faUserFriends} />
                    </div>
                    <div className={classes.GroupName}>{group.name}</div>
                </div>
                <div className="flex-grow"></div>
                <Button
                    className={`${classes.AddButton}`}
                    text=""
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={() => addGroup(group)}
                ></Button>
            </div>
        );
    };

    return (
        <Dialog open={open} onClose={toggleOpen} maxWidth="sm" fullWidth>
            <DialogTitle disableTypography className={classes.DialogTitle}>
                <Typography variant="h6">{t('Common:POTENTIAL_GROUPS_HEADER')}</Typography>

                <Button
                    aria-label="close"
                    className={classes.closeButton}
                    alt
                    text=""
                    icon={<FontAwesomeIcon icon={faClose}></FontAwesomeIcon>}
                    onClick={toggleOpen}
                ></Button>
            </DialogTitle>

            <DialogContent>
                <ExtendedMuiList
                    loading={false}
                    defaultRowsPerPage={5}
                    defaultEnabled={true}
                    searchInputLabel={t('Common:GROUPS_SEARCH_INPUT_LABEL')}
                    filterDataBySearch={filterPotentialGroupsDataBySeach}
                    noItemsMessage={t('Common:LIST_MESSAGE_NO_GROUPS')}
                    noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_GROUPS')}
                    items={groups}
                    renderItem={renderPotentialGroupItem}
                    itemsListClasses={`${classes.GroupsList}`}
                />
            </DialogContent>
        </Dialog>
    );
};

export default GroupsDialog;
