import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { createRef, Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import { Popup } from 'reactjs-popup';
import { useConfig } from '../../context/ConfigContext';
import { useDeck } from '../../context/DeckContext';
import { ICard } from '../../models/Card';
import { IDeck } from '../../models/Deck';
import { IEdge } from '../../models/Edge';
import { IElement, IUserAnswer } from '../../models/Element';
import AxiosInstance from '../../utils/axios-instance';
import useAxiosFetch from '../../utils/useAxiosFetch';
import Button from '../Button/Button';
import { isAnswerError, IUserAnswerError } from '../Elements/Question/OpenQuestion/OpenQuestionFormHook';
import PageHeader from '../PageHeader/PageHeader';
import ReportDialog from '../ReportDialog/ReportDialog';
import TagLabel from '../TagLabel/TagLabel';
import Cards from './Cards/Cards';
import DeckActions from './DeckActions/DeckActions';
import DeckBranchResults from './DeckBranchResults/DeckBranchResults';
import classes from './DeckDetails.module.scss';

type DeckProps = {
    deckData?: IDeck;
    resetDeck: () => void;
};

type ParamTypes = {
    deckId: string;
};

export type CardCompleted = {
    id: number;
    completed: boolean;
};

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DeckDetails: FunctionComponent<DeckProps> = ({ resetDeck }) => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const { deckId } = useParams<ParamTypes>();
    const [deckAPIUrl, setDeckAPIUrl] = useState<string>(() => {
        return deckId;
    });
    const { response, loading, sendRequest } = useAxiosFetch<IDeck>(`${config.baseUrl}/decks/${deckAPIUrl}`);
    const {
        deck,
        setDeck,
        setEdges,
        currentCardIndex,
        setCurrentCardIndex,
        currentCard,
        setPreviousEdgeIndex,
        setHistoryCardIds,
        setCurrentHistoryIndex,
        deckProgress,
        isDeckCompleted,
        setProgressWhereToContinue,
        setOpenCardDialog,
    } = useDeck();
    const [showDeckResults, setShowDeckResults] = useState<boolean>(false);
    const containerRef = createRef<HTMLDivElement>();
    const queryCardNum = useQuery().get('cardNum');

    const [openError, setOpenError] = useState(false);
    const closeErrorModal = () => {
        setOpenError(false);
        // history.replace('/home');
    };

    const [refreshSticky, setRefreshSticky] = useState(0);

    useEffect(() => {
        // Set deckId so loading will start
        if (deckId) {
            setDeckAPIUrl(deckId);
        }

        // Set Category from State
        const props = location.state as { deck: IDeck; currentCardIndex: number; currentCardId: number };
        if (props) {
            if (props.deck) {
                setDeck(props.deck);
            }

            if (props.currentCardIndex) {
                setCurrentCardIndex(props.currentCardIndex);
            }
        }
    }, []);

    const initialiseHistoryCardIds = (newEdges?: IEdge[]) => {
        if (!newEdges || newEdges.length === 0) return;

        const newHistoryCardIds: number[] = [];

        newEdges?.map((edge) => {
            newHistoryCardIds.push(edge.source_id);
        });

        const lastTarget = _.last(newEdges)?.target_id;
        if (lastTarget) {
            newHistoryCardIds.push(lastTarget);
        }

        setCurrentHistoryIndex(newHistoryCardIds.length - 1);
        setHistoryCardIds(newHistoryCardIds);
    };

    useEffect(() => {
        if (response) {
            const deckData = response.data;
            setDeck(deckData);

            const newEdges = _.cloneDeep(deckData.deckSession?.edges);
            setEdges(newEdges || []);

            initialiseHistoryCardIds(newEdges);

            setPreviousEdgeIndex(newEdges && newEdges.length > 0 ? newEdges.length - 1 : -1);

            const cards = deckData.cards;
            if (cards) {
                const deckState = location.state as { deck: IDeck; currentCardIndex: number; currentCardId: number };

                setProgressWhereToContinue(deckData, true, deckState, queryCardNum);
            }
        }
    }, [response]);

    const reset = () => {
        sendRequest().then(() => {
            deleteQueryParamCardNum();
            window.history.replaceState({}, '');

            resetDeck();
        });
    };

    const deleteQueryParamCardNum = () => {
        const queryParams = new URLSearchParams(location.search);

        if (queryParams.has('cardNum')) {
            queryParams.delete('cardNum');
            history.replace({
                search: queryParams.toString(),
            });
        }
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [currentCardIndex]);

    const hasBranchingEndOnCard = (card?: ICard) => {
        if (!card) return false;
        return !Boolean(card?.source_of_edges.length > 0);
    };

    const onNavigateToCardById = (cardId: number | undefined) => {
        setCurrentCardIndex((previousValue) => {
            if (deck.cards) {
                const cardIndex = deck.cards?.findIndex((card) => {
                    return card.id === cardId;
                });

                if (cardIndex === -1) {
                    return currentCardIndex;
                }
                return cardIndex;
            } else {
                return previousValue;
            }
        });
    };

    const onQuestionAnswered = (element: IElement, userAnswer: IUserAnswer | IUserAnswerError) => {
        if (isAnswerError(userAnswer)) {
            setOpenError(true);
        }
    };

    const addNewCardHandler = async (title: string) => {
        return Promise.resolve(undefined);
    };

    const reorderElementsTriggered = useCallback(() => {
        setRefreshSticky((prev) => prev + 1);
    }, []);

    const editHandler = () => {
        history.push({ pathname: `/deck/edit/${deck.id}/${currentCard?.id}`, state: { deck, currentCardIndex } });
    };

    const editDeckHandler = () => {
        setOpenCardDialog(false);
        history.push(`/deck/edit/${deck.id}`);
    };

    const onClickCompleteDeckHandler = () => {
        AxiosInstance.post(`${config.baseUrl}/progress/decks/complete/${deck.id}`);
        setShowDeckResults(true);
    };

    const onClickContinueHandler = () => {
        setProgressWhereToContinue(deck);
    };

    const [open, setOpen] = useState(false);
    const onCloseClickHandler = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <div className={`${classes.Deck} deck-page`} ref={containerRef}>
                <PageHeader
                    title={deck?.title}
                    underline={true}
                    bottomMargin={false}
                    reportButton={
                        <Button
                            className={classes.ReportButton}
                            text=""
                            alert
                            icon={<FontAwesomeIcon icon={faBullhorn} />}
                            onClick={() => {
                                setOpen(true);
                            }}
                        />
                    }
                    isLoading={loading}
                />

                <div className={classes.TagsList}>
                    {deck.tags?.map((tag) => (
                        <TagLabel key={`tag_${tag.id}`} tag={tag} />
                    ))}
                </div>

                <ReportDialog open={open} onCloseClickHandler={onCloseClickHandler} />

                {loading && (
                    <div className={classes.Loader}>
                        <div className="spinner card Box"></div>
                    </div>
                )}

                {!loading && (
                    <Fragment>
                        <div className={`${classes.CardsGroup}`}>
                            <Cards
                                cards={deck.cards || []}
                                onNavigateToCardById={onNavigateToCardById}
                                onQuestionAnswered={onQuestionAnswered}
                                addNewCardHandler={addNewCardHandler}
                                onReorderElementsTriggered={reorderElementsTriggered}
                            />

                            <DeckActions
                                cardCompleted={deckProgress[currentCardIndex]?.completed}
                                deckCompleted={isDeckCompleted}
                                deckProgress={deckProgress}
                                deckCardsTotal={deck.cards?.length || 0}
                                currentIndex={currentCardIndex}
                                hasBranchingEnd={hasBranchingEndOnCard(currentCard)}
                                isPublished={deck.is_published}
                                clickCompleteDeckHandler={onClickCompleteDeckHandler}
                                clickContinueHandler={onClickContinueHandler}
                                clickEditHandler={editHandler}
                                clickEditDeckHandler={editDeckHandler}
                                forceTrackStickyEvent={refreshSticky}
                            ></DeckActions>
                        </div>

                        {/* <div className={classes.DeckStats}>
                            <p>currentCardIndex: {currentCardIndex}</p>
                            <p>Edges:</p>
                            {edges.map((edge, index) => {
                                return (
                                    <p
                                        key={`edge_${edge.id}_${index}`}
                                        style={{ fontWeight: edge.id === currentCardEdge?.id ? 'bold' : 'normal' }}
                                    >
                                        {edge.id} | {edge.source_id} | {edge.target_id}{' '}
                                        {edge.id === currentCardEdge?.id}
                                    </p>
                                );
                            })}
                            {currentCardEdge ? (
                                <p>The current card edge id is {currentCardEdge.id}</p>
                            ) : (
                                <p>No current card edge, this is an end card</p>
                            )}

                            <p>previousEdgeIndex: {previousEdgeIndex}</p>

                            {isHistory ? <p>History</p> : <p>Current</p>}

                            {historyCardIds.map((cardId, i) => {
                                return <p key={`cardId_${i}`}>{cardId}</p>;
                            })}

                            <p>Current history index: {currentHistoryIndex}</p>
                        </div> */}

                        {showDeckResults && (
                            <DeckBranchResults
                                currentCard={currentCard}
                                deckId={deck.id}
                                deckTitle={deck.title}
                                deckResultsUrl={`${config.baseUrl}/decks/results/${deckAPIUrl}`}
                                showDeckResults={showDeckResults}
                                setShowDeckResults={setShowDeckResults}
                                deckReset={reset}
                                showEndings={deck.show_endings}
                            />
                        )}
                    </Fragment>
                )}
            </div>

            <Popup open={openError} closeOnDocumentClick onClose={closeErrorModal}>
                <div className={`modal Box`}>
                    <h2 className="h4">
                        <strong>{t('Common:ERROR_ANSWER_HEADER')}</strong>
                    </h2>
                    <p>{t('Common:ERROR_ANSWER_MESSAGE')}</p>
                </div>

                <div className={`${classes.formActions} ${classes.flexGap}`}>
                    <Button text={t('Common:BUTTON_OK')} alt={false} border={false} onClick={closeErrorModal}></Button>
                </div>
            </Popup>
        </Fragment>
    );
};

export default DeckDetails;
