import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useEffect, useState } from 'react';
import Button from '../Button/Button';
import classes from './DropDownButton.module.scss';

interface IProps {
    onClose?: () => void;
    onOpen?: () => void;
}

const DropDownButton: FunctionComponent<IProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const onClick = () => {
        toggle();
    };

    const openedClass = isOpen ? classes.isOpen : classes.isClosed;

    useEffect(() => {
        if (isOpen) {
            props.onOpen?.();
        } else {
            props.onClose?.();
        }
    }, [isOpen]);

    return (
        <Button
            text=""
            className={`${classes.DropDownButton} ${openedClass}`}
            onKeyPress={() => toggle()}
            onClick={onClick}
            icon={<FontAwesomeIcon className={`${classes.DownArrow}`} icon={faSortDown} />}
        />
    );
};

export default DropDownButton;
