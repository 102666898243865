import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigContext';
import { ICategory } from '../../../models/Categories';
import { IGroup, IGroupsResponse } from '../../../models/Group';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import classes from './GroupsDetail.module.scss';
import GroupSection from './GroupSection/GroupSection';

type CategoryDetailProps = {
    categoryData?: ICategory;
};

const GroupsDetail: FunctionComponent<CategoryDetailProps> = () => {
    const { config } = useConfig();
    const { t } = useTranslation();
    const history = useHistory();
    const { auth, hasPermission } = useAuth();
    const { response, loading } = useAxiosFetch<IGroupsResponse>(`${config.baseUrl}/groups`);
    const [myGroups, setMyGroups] = useState<IGroup[]>([]);
    const [otherGroups, setOtherGroups] = useState<IGroup[]>([]);

    useEffect(() => {
        if (response) {
            const groupsResponse = response.data;
            if (groupsResponse.my_groups) {
                setMyGroups(groupsResponse.my_groups);
            }
            if (groupsResponse.other_groups) {
                setOtherGroups(groupsResponse.other_groups);
            }
        }
    }, [response]);

    const groupList = (
        <Fragment>
            <GroupSection
                noGroupMessage={t('Groups:MY_GROUP_NO_GROUP_MESSAGE')}
                groupSection={{
                    groups: myGroups,
                    title: myGroups.length === 1 ? t('Groups:MY_GROUP_TITLE') : t('Groups:MY_GROUPS_TITLE'),
                }}
            ></GroupSection>

            <GroupSection
                noGroupMessage={t('Groups:OTHER_GROUP_NO_GROUP_MESSAGE')}
                groupSection={{
                    groups: otherGroups,
                    title: otherGroups.length === 1 ? t('Groups:OTHER_GROUP_TITLE') : t('Groups:OTHER_GROUPS_TITLE'),
                }}
            ></GroupSection>
        </Fragment>
    );

    const editHandler = () => {
        history.push('/management/groups');
    };

    return (
        <div className={classes.GroupsDetail}>
            <PageHeader
                title={t('Groups:GROUPS_HEADER')}
                editHandler={hasPermission('group.manage') && isDesktop ? editHandler : undefined}
            />

            {loading && (
                <div className={classes.Loader}>
                    <div className="spinner card Box"></div>
                </div>
            )}

            {!loading && <div className={classes.GroupList}>{groupList}</div>}
        </div>
    );
};

export default GroupsDetail;
