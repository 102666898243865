import { faFloppyDisk } from '@fortawesome/free-regular-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { values } from 'lodash-es';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import CoverImageUpload from '../../../../components/CoverImageUpload/CoverImageUpload';
import InputCheckbox from '../../../../components/InputCheckbox/InputCheckbox';
import InputField from '../../../../components/InputField/InputField';
import { useDeck } from '../../../../context/DeckContext';
import { IDeck } from '../../../../models/Deck';
import { IFile } from '../../../../models/File';
import { SelectOption } from '../../../../models/ImageUpload';
import DeckCover from '../../../Category/CategoryDetail/DeckCover/DeckCover';
import CategorySelect from './CategorySelect/CategorySelect';
import classes from './DeckEditDetailForm.module.scss';
import TagSelect from './TagSelect/TagSelect';

type FormProps = {
    isCreate: boolean;
    handleFormSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    isSubmitting: boolean;
    disabledSubmit: boolean;
    isSaved: boolean;
    cancelHandler: () => void;
    gotoAndViewHandler: (deck: IDeck, currentCardIndex: number) => void;
    clickPublishHandler: () => void;
};

const DeckEditDetailForm: FunctionComponent<FormProps> = ({
    isCreate,
    handleFormSubmit,
    cancelHandler,
    disabledSubmit,
    isSubmitting,
}) => {
    const { t } = useTranslation();
    const { setFieldValue } = useFormikContext();
    const { deck, setDeck } = useDeck();

    useEffect(() => {
        console.log(values);
    }, []);
    useEffect(() => {
        setFieldValue('cover_image_id', deck.cover_image?.id);
    }, [deck.cover_image]);

    useEffect(() => {
        setFieldValue('cover_background_size', deck.cover_background_size);
    }, [deck.cover_background_size]);

    const backgroundSizeChangeHandler = (option: SelectOption | null) => {
        // TODO: these will cause a re-render on all child cards
        if (option) setDeck({ ...deck, cover_background_size: option.value });
    };

    const onUploadImageHandler = (file: IFile | undefined) => {
        // TODO: these will cause a re-render on all child cards
        setDeck({ ...deck, cover_image: file });
    };

    const onAllowHistoryDeckChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeck({ ...deck, allow_history: event.target.checked });
    };

    const onShowEndingsDeckChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeck({ ...deck, show_endings: event.target.checked });
    };

    return (
        <div className={`${classes.DeckEditDetailForm} ${classes.formGroup}`}>
            <form noValidate={true} onSubmit={handleFormSubmit}>
                <div className={classes.DeckDetail}>
                    <div className={classes.InputContainer}>
                        <div className={`Box ${classes.InputContent}`}>
                            <div className={classes.formInput}>
                                <div className={`${classes.formGroup}`}>
                                    <label htmlFor="title">{t('Deck:DECK_TITLE_INPUT_LABEL')}</label>
                                    <InputField
                                        name="title"
                                        type="text"
                                        placeholder={t('Deck:DECK_TITLE_INPUT_PLACEHOLDER')}
                                        // maxLength={16}
                                    ></InputField>
                                </div>

                                <CategorySelect />
                                <TagSelect />

                                <div className={`${classes.formGroup}`}>
                                    <InputCheckbox
                                        id="allow_history"
                                        name="allow_history"
                                        type="checkbox"
                                        label={
                                            <label htmlFor="allow_history">
                                                {t('Common:DECK_ALLOW_HISTORY_CHECKBOX_LABEL')}
                                            </label>
                                        }
                                        onChange={onAllowHistoryDeckChangeHandler}
                                    />
                                </div>
                                <div className={`${classes.formGroup}`}>
                                    <InputCheckbox
                                        id="show_endings"
                                        name="show_endings"
                                        type="checkbox"
                                        label={
                                            <label htmlFor="show_endings">
                                                {t('Common:DECK_SHOW_ENDINGS_CHECKBOX_LABEL')}
                                            </label>
                                        }
                                        onChange={onShowEndingsDeckChangeHandler}
                                    />
                                </div>
                            </div>
                        </div>

                        <CoverImageUpload
                            onChange={onUploadImageHandler}
                            originalFile={deck.cover_image}
                            defaultBackgroundSize={deck.cover_background_size}
                            onChangeBackgroundSize={backgroundSizeChangeHandler}
                        />
                    </div>

                    <div className={classes.DeckCoverWrapper}>
                        <DeckCover deck={deck} editMode={true} />

                        <div className={`${classes.formActions} `}>
                            {isCreate && (
                                <Button
                                    text={t('Common:BUTTON_CANCEL')}
                                    alt={true}
                                    border={true}
                                    onClick={cancelHandler}
                                ></Button>
                            )}

                            <Button
                                className={isCreate ? classes.CreateButton : classes.SaveButton}
                                type="submit"
                                text={isCreate ? t('Common:BUTTON_CREATE') : t('Common:BUTTON_SAVE_DECK_SETTINGS')}
                                disabled={disabledSubmit}
                                alert
                                icon={
                                    isSubmitting ? (
                                        <FontAwesomeIcon
                                            className={classes.ButtonLoadingIcon}
                                            icon={faCircleNotch}
                                            spin={true}
                                        />
                                    ) : (
                                        <FontAwesomeIcon className={classes.SaveIcon} icon={faFloppyDisk} />
                                    )
                                }
                                iconSide={'left'}
                            ></Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default DeckEditDetailForm;
