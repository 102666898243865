import React, { FunctionComponent, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import RegisterForm from './RegisterForm/RegisterForm';

const Register: FunctionComponent = () => {
    const { auth, checkSession } = useAuth();

    useEffect(() => {
        localStorage.clear();
        checkSession();
    }, []);

    useEffect(() => {
        checkSession();
    }, [auth.isLoggedIn]);

    return <RegisterForm></RegisterForm>;
};

export default Register;
