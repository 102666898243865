import { AxiosResponse } from 'axios';
import { FormikHelpers } from 'formik';
import { useCallback } from 'react';
import { useConfig } from '../../context/ConfigContext';
import axiosInstance from '../../utils/axios-instance';

export interface FormFields {
    access_code: string;
    name: string;
}

export type ReturnType = {
    handleSubmit: (formFields: FormFields, actions: FormikHelpers<FormFields>) => Promise<AxiosResponse<any>>;
    initialValues: FormFields;
};

const useRegisterAccessCodeForm = (initialValues: FormFields): ReturnType => {
    const { config } = useConfig();

    const handleSubmit = useCallback((formFields: FormFields, actions: FormikHelpers<FormFields>) => {
        return axiosInstance.get(`${config.baseUrl}/register/${formFields.access_code}`);
    }, []);

    return {
        handleSubmit,
        initialValues,
    };
};

export default useRegisterAccessCodeForm;
