import React, { FunctionComponent } from 'react';

type SVGProps = {
    fill?: string;
};

const ExclamationIcon: FunctionComponent<SVGProps> = ({ fill = '#ffffff' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1.488" height="6.36" viewBox="0 0 1.488 6.36">
            <path
                d="M.616-5.88H-.624v4.288H.616ZM-.744-.256A.724.724,0,0,0-.008.48.733.733,0,0,0,.744-.256.739.739,0,0,0-.008-1,.731.731,0,0,0-.744-.256Z"
                transform="translate(0.744 5.88)"
                fill={fill}
            />
        </svg>
    );
};

export default ExclamationIcon;
