import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { FormikProps, FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Popup } from 'reactjs-popup';
import * as Yup from 'yup';
import Button from '../../../components/Button/Button';
import { useDeck } from '../../../context/DeckContext';
import { useSyncState } from '../../../hooks/useSyncState';
import { ICard } from '../../../models/Card';
import { IDeck } from '../../../models/Deck';
import { IQuest } from '../../../models/Quest';
import useCardService from '../../../services/CardService';
import DeckActions from '../DeckActions/DeckActions';
import CardsForm from '../DeckEditDetail/CardsForm/CardsForm';
import DeckEditDetailForm from '../DeckEditDetail/DeckEditDetailForm/DeckEditDetailForm';
import useDeckEditForm, { FormFields } from '../DeckEditDetail/useDeckEditForm';
import DeckEditHeader from '../DeckEditHeader/DeckEditHeader';
import classes from './DeckEditDetailFormik.module.scss';

interface DeckEditDetailFormProps {
    isCreate: boolean;
    moduleId: number;
    deck: IDeck;
    cardId?: string;
    deckCreatedResponseHandler: () => void;
}

const DeckEditDetailFormik: FunctionComponent<DeckEditDetailFormProps> = ({
    isCreate,
    moduleId,
    deck,
    cardId,
    deckCreatedResponseHandler,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { cards, addCard, setDeck, currentCardProgress, isDeckCompleted, nextCard, previousCard, setOpenCardDialog } =
        useDeck();

    const { create: createCard } = useCardService();

    const schema = Yup.object({
        title: Yup.string().required(t('Common:INPUT_ERROR_TITLE_REQUIRED')),
    });

    const formik: FormikProps<FormFields> = useFormik<FormFields>({
        initialValues: {
            title: deck.title,
            cover_image_id: deck.cover_image?.id || 0,
            cover_background_size: deck.cover_background_size || 'cover',
            is_published: deck.is_published,
            allow_history: deck.allow_history,
            show_endings: deck.show_endings,
            tags: deck.tags?.map((tag) => {
                return { value: tag.id, label: tag.title };
            }),
            categories: deck.categories?.map((category) => {
                return { value: category.id, label: category.title };
            }),
        },
        onSubmit: (formValues, actions) => {
            handleSubmit(deck).then((response) => {
                deckCreatedResponseHandler();
            });
        },
        validationSchema: schema,
    });

    const { handleSubmit, isSaved, cancelHandler, gotoAndViewHandler } = useDeckEditForm(formik, isCreate);

    const [publishing, setPublishing] = useSyncState(false);

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    const notifyPublishSuccess = () => toast.success(t('Common:TOAST_PUBLISH_SUCCESS'));

    const onPublishClickHandler = () => {
        setOpen(true);
    };

    const publishHandler = async () => {
        closeModal();

        setPublishing(true);
        setDeck({ ...deck, is_published: true });
        notifyPublishSuccess();
    };

    useEffect(() => {
        formik.setFieldValue('is_published', deck.is_published);
        const p = publishing();
        if (p) {
            formik.submitForm();
            setPublishing(false);
        }
    }, [deck.is_published, publishing()]);

    const onQuestClickedHandler = (quest: IQuest) => {
        history.push({ pathname: `/quests/edit/${quest.id}`, state: quest });
    };

    const notifyCardCreated = () => toast.success(t('Common:TOAST_CARD_CREATED_SUCCESS'));
    const notifyCardNotCreated = () => toast.error(t('Common:TOAST_CARD_CREATED_ERROR'));

    const onAddCardHandler = async (title?: string) => {
        const values = {
            deck_id: deck.id,
            title: title ? title : `${t('Deck:NEW_CARD_TITLE')} ${(cards?.length || 0) + 1}`,
            end_card: true,
            prev_card_id: _.last(cards)?.id,
        };

        try {
            const response = await createCard(values);
            if (response) {
                const cardData: ICard = response.data;
                addCard(cardData);

                notifyCardCreated();
                setOpenCardDialog(true);
                return Promise.resolve(cardData);
            }
        } catch (error) {
            notifyCardNotCreated();
        }
    };

    const [openCardFlowDialog, setOpenCardFlowDialog] = useState(false);

    const onOpenCardFlow = () => {
        setOpenCardFlowDialog(true);
    };
    const closeCardFlowDialogHandler = useCallback(() => setOpenCardFlowDialog(false), []);

    const quests = deck.quests?.map((quest, idx) => {
        return (
            <div key={`quest_${quest.id}`} className={classes.QuestLink} onClick={() => onQuestClickedHandler(quest)}>
                {quest.title}
                {deck.quests && idx < deck.quests?.length - 1 ? ';' : ''}
            </div>
        );
    });

    return (
        <div className={classes.DeckEditDetailFormik}>
            <FormikProvider value={formik}>
                <div className={classes.DeckEditDetailForm}>
                    <DeckEditHeader isCreate={isCreate} quests={quests}></DeckEditHeader>

                    <div>
                        <DeckEditDetailForm
                            isCreate={isCreate}
                            handleFormSubmit={formik.handleSubmit}
                            isSubmitting={formik.isSubmitting}
                            disabledSubmit={formik.isSubmitting || !(formik.isValid && formik.dirty)}
                            isSaved={isSaved}
                            cancelHandler={cancelHandler}
                            clickPublishHandler={onPublishClickHandler}
                            gotoAndViewHandler={gotoAndViewHandler}
                        />

                        {(!isCreate || isSaved) && (
                            <CardsForm
                                cardId={cardId}
                                onAddCardHandler={onAddCardHandler}
                                openCardFlowDialog={openCardFlowDialog}
                                closeCardFlowDialogHandler={closeCardFlowDialogHandler}
                            />
                        )}
                    </div>
                </div>
                {!isCreate && (
                    <DeckActions
                        isCreate={isCreate}
                        isPublished={deck.is_published}
                        isSubmitting={formik.isSubmitting}
                        currentCardProgress={currentCardProgress}
                        deckCompleted={isDeckCompleted}
                        clickPublishHandler={onPublishClickHandler}
                        gotoAndViewHandler={gotoAndViewHandler}
                        onAddCardHandler={onAddCardHandler}
                        openCardFlow={onOpenCardFlow}
                    ></DeckActions>
                )}
            </FormikProvider>

            <Dialog
                open={open}
                onClose={closeModal}
                className={classes.WarningDialog}
                PaperProps={{ className: `dialog` }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>{t('Common:WARNING_HEADER')}</DialogTitle>
                <DialogContent>{t('Common:PUBLISH_WARNING_MESSAGE')}</DialogContent>
                <DialogActions className={`actions`}>
                    <Button alt border text={t('Common:BUTTON_CANCEL')} onClick={closeModal} />
                    <Button alt text={t('Common:BUTTON_PUBLISH')} onClick={publishHandler} />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DeckEditDetailFormik;
