import { FormikProps } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { SelectOption } from '../../../components/GroupSelect/GroupSelect';
import { useDeck } from '../../../context/DeckContext';
import { IDeck } from '../../../models/Deck';
import useDeckService from '../../../services/DeckService';

export interface FormFields {
    title: string;
    cover_image_id?: number;
    cover_background_size: string;
    is_published: boolean;
    allow_history: boolean;
    show_endings: boolean;
    tags?: SelectOption[];
    categories?: SelectOption[];
}

export type ReturnType = {
    handleSubmit: (deck: IDeck) => Promise<void | IDeck | undefined>;
    isSaved: boolean;
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
    cancelHandler: () => void;
    gotoAndViewHandler: (deck: IDeck, currentCardIndex: number) => void;
};

const useDeckEditForm = (formik: FormikProps<FormFields>, isCreate: boolean): ReturnType => {
    const { setDeck } = useDeck();
    const [isSaved, setIsSaved] = useState(false);
    const { t } = useTranslation();
    const { create, update } = useDeckService();
    const history = useHistory();

    const notifyCreated = () => toast.success(t('Common:TOAST_DECK_CREATED_SUCCESS'));
    const notifySaved = () => toast.success(t('Common:TOAST_DECK_SAVED_SUCCESS'));

    const handleSubmit = (deck: IDeck) => {
        if (isCreate) {
            const data = {
                ...formik.values,
                first_card_title: t('Deck:NEW_CARD_TITLE'),
            };
            return create(data).then((response) => {
                setIsSaved(true);
                formik.setSubmitting(false);
                if (response) {
                    const deckData: IDeck = response.data;
                    setDeck(deckData);
                    history.replace({
                        pathname: `/deck/edit/${deckData.id}`,
                        state: { deck, currentCardIndex: 0 },
                    });
                    notifyCreated();
                    return deckData;
                }
            });
        } else {
            return update(deck.id, { ...formik.values }).then(() => {
                formik.setSubmitting(false);
                setIsSaved(true);
                formik.resetForm({ values: formik.values });
                notifySaved();
            });
        }
    };

    const cancelHandler = () => {
        history.goBack();
    };

    const gotoAndViewHandler = (deck: IDeck, currentCardIndex: number) => {
        history.push({ pathname: `/deck/${deck.id}`, state: { deck, currentCardIndex } });
    };

    return {
        handleSubmit,
        isSaved,
        setIsSaved,
        cancelHandler,
        gotoAndViewHandler,
    };
};

export default useDeckEditForm;
