import React, { FunctionComponent } from 'react';
import { ICard } from '../../../models/Card';
import { IElement, IUserAnswer } from '../../../models/Element';
import MediaElement from '../../Elements/Media/MediaElement';
import EditQuestionElement from '../../Elements/Question/EditQuestionElement';
import { IUserAnswerError } from '../../Elements/Question/OpenQuestion/OpenQuestionFormHook';
import TextElement from '../../TextElement/TextElement';
import { IElementProps } from '../ElementsList/ElementsList';

interface ElementProps extends IElementProps {
    element: IElement;
    index: number;
    isEdit: boolean;
    isReorder: boolean;
    lastAddedElementId: number | null;
}

const ElementComponent: FunctionComponent<ElementProps> = (props) => {
    const elementableType = props.element.elementable_type;

    switch (elementableType) {
        case 'question_element':
            return <EditQuestionElement {...props}></EditQuestionElement>;
        case 'text_element':
            return (
                <TextElement
                    elementData={props.element}
                    index={props.index}
                    isEdit={props.isEdit}
                    isReorder={props.isReorder}
                    autoFocus={props.element.id === props.lastAddedElementId}
                    deleteHandler={props.deleteHandler}
                    loaded={props.loaded}
                    updateElement={props.updateElement}
                ></TextElement>
            );
        case 'media_element':
            return (
                <MediaElement
                    element={props.element}
                    isEdit={props.isEdit}
                    isReorder={props.isReorder}
                    deleteHandler={props.deleteHandler}
                    loaded={props.loaded}
                    updateElement={props.updateElement}
                ></MediaElement>
            );
        default:
            return null;
    }
};

export default ElementComponent;
