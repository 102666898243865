import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover from '@material-ui/core/Popover';
import React, { FunctionComponent } from 'react';
import Button from '../../../../../components/Button/Button';
import { User } from '../../../../../models/User';
import { useStylesPopoverActions } from '../../../../../utils/muiStyles';
import MemberActions from './MemberActions/MemberActions';
import classes from './MemberActionsPopover.module.scss';

export interface IMemberActionsProps {
    user: User;
    onToggleActiveHandler: (user: User) => void;
    onDeleteHandler: (user: User) => void;
}

const MemberActionsPopover: FunctionComponent<IMemberActionsProps> = ({
    user,
    onToggleActiveHandler,
    onDeleteHandler,
}) => {
    const classesPopoverActions = useStylesPopoverActions();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'deck-edit-menu-popover' : undefined;

    return (
        <div className={classes.TagActionsPopover}>
            <Button
                aria-controls="deck-edit-menu"
                aria-haspopup="true"
                text=""
                icon={<FontAwesomeIcon icon={faEllipsis} />}
                onClick={handleClick}
            />

            <Popover
                classes={{ paper: classesPopoverActions.paper }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MemberActions
                    user={user}
                    onToggleActiveHandler={onToggleActiveHandler}
                    onDeleteHandler={onDeleteHandler}
                />
                {/* <AccessCodeActions
                    accessCode={accessCode}
                    onEditHandler={onEditHandler}
                    onDeleteHandler={() => {
                        handleClose();
                        onDeleteHandler(accessCode);
                    }}
                /> */}
            </Popover>
        </div>
    );
};

export default MemberActionsPopover;
