import React from 'react';
import { FunctionComponent } from 'react';
import DOMPurify from 'dompurify';
import classes from './Welcome.module.scss';
import { useConfig } from '../../../context/ConfigContext';

const Welcome: FunctionComponent = () => {
    const { config, loadingConfig } = useConfig();

    const defaultWelcomeCopy = config.tenant.texts['headquarters']?.map((copy) => {
        return (
            <div
                key={copy.text_key}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        copy.elements.reverse().find((element) => element.elementable_type === 'text_element')
                            ?.elementable?.body || '',
                    ),
                }}
            ></div>
        );
    });

    return <div className={`${classes.Welcome} Box`}>{!loadingConfig && defaultWelcomeCopy}</div>;
};

export default Welcome;
