import React, { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import BoxHeader from '../../../components/UI/Box/BoxHeader/BoxHeader';
import withBox from '../../../components/UI/withBox';
import classes from './Members.module.scss';

const Members: FunctionComponent = () => {
    const { url } = useRouteMatch();
    const { t } = useTranslation();
    const history = useHistory();

    const inviteMembersButtonHandler = () => {
        history.push(`${url}/invite/add_invites`);
    };

    return (
        <Fragment>
            <BoxHeader title="Members"></BoxHeader>
            <button className={`${classes.addButton} btn btn-flavour`} onClick={inviteMembersButtonHandler}>
                {t('Members:INVITE_HEADER')}
            </button>
        </Fragment>
    );
};

export default withBox(Members, classes.Members);
