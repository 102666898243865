import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import { Permission } from '../../../models/User';
import classes from './ManagementHeader.module.scss';

interface ManagementHeaderProps {
    title: string;
    buttonText: string;
    permission?: Permission;
    onClick: () => void;
}

const ManagementHeader: FunctionComponent<ManagementHeaderProps> = ({ title, buttonText, onClick, permission }) => {
    const { hasPermission } = useAuth();

    return (
        <div className={`${classes.Header}`}>
            <h2 className={`h3 ${classes.Title}`}>{title}</h2>
            <div className="flex-grow"></div>
            {!permission ||
                (hasPermission(permission) && (
                    <button className={`btn btn-flavour btn-flavour--alt-border`} onClick={onClick}>
                        {buttonText}
                    </button>
                ))}
        </div>
    );
};

export default ManagementHeader;
