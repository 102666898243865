import { IProfile } from '../models/User';
import { createCtx } from './createCtx';

export type ProfileContextType = {
    profile: IProfile;
    setProfile: (profile: IProfile) => void;
    updateProfile: (profile: IProfile) => void;
    isDataChanged: boolean;
    setIsDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

export const [useProfile, CtxProvider] = createCtx<ProfileContextType>();
