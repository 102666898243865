// import { useSpring, animated } from 'react-spring';
import { animated, useSpring } from '@react-spring/web';
import React, { ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState } from 'react';
// import { gsap } from 'gsap';
import { useHistory, useRouteMatch } from 'react-router';
import CoverImage from '../../../../components/CoverImage/CoverImage';
import PublicationStateLabel from '../../../../components/PublicationStateLabel/PublicationStateLabel';
import { useAuth } from '../../../../context/AuthContext';
import { useConfig } from '../../../../context/ConfigContext';
import useHover from '../../../../hooks/useHover';
import { IDeck } from '../../../../models/Deck';
import { getFilePathByName, getFilePathForDefaultImage } from '../../../../models/File';
import classes from './DeckCover.module.scss';

export interface DeckCoverProps {
    editMode?: boolean;
    delay?: number;
    deck: IDeck;
}

export type IncrementedRef = {
    current: HTMLDivElement | null;
};

const DeckCover: ForwardRefRenderFunction<IncrementedRef, DeckCoverProps> = ({ deck, editMode = false }, ref) => {
    const { config, loading, loadingConfig } = useConfig();
    const history = useHistory();
    const { url } = useRouteMatch();
    const containerRef = useRef<HTMLDivElement>(null);
    const { hasPermission } = useAuth();

    const deckRef = useRef<HTMLDivElement>(null);

    const isHover = useHover(containerRef);

    const [defaultCover, setDefaultCover] = useState('');

    useEffect(() => {
        if (!loadingConfig) {
            const customFile = config.tenant.images['deck.png']?.find((file) => file.is_default == false);
            const defaultFile = config.tenant.images['deck.png']?.find((file) => file.is_default == true);
            setDefaultCover(
                getFilePathByName(customFile?.file?.name) || getFilePathForDefaultImage(defaultFile?.file?.name) || '',
            );
        }
    }, [loadingConfig]);

    useImperativeHandle(ref, () => ({
        current: containerRef.current,
    }));
    // const tl = gsap.timeline();

    const calc = (x: number, y: number, width: number, height: number) => {
        const rot = 15;
        return [-(y - height / 2) / rot, (x - width / 2) / rot, 1.04];
    };

    const trans = (x: number, y: number, s: number) =>
        `perspective(700px) rotateX(${-x}deg) rotateY(${-y}deg) scale(${s})`;
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 3, tension: 550, friction: 40 } }));

    const hoverClass = isHover ? classes['hover'] : '';
    const multipleCards = (deck?.cards_count ?? 0) > 1;
    const multipleCardsClass = multipleCards ? classes['multiple-cards'] : classes['single-card'];

    const hasProgress = deck.deckSession;
    const cardsIsCompleted = hasProgress?.is_complete;

    const disabled = !hasPermission('library.manage') && !cardsIsCompleted;

    const clickHandler = () => {
        if (!disabled && !editMode) {
            history.push({ pathname: `/deck/${deck.id}` });
        }
    };

    return (
        <div className={`cover ${classes.DeckCoverContainer}`}>
            <div
                className={`${disabled && classes.FilterActive} ${!editMode && classes.DeckCoverDropshadow}  ${
                    (editMode || disabled) && classes['disabled']
                } ${cardsIsCompleted && hoverClass}`}
                onClick={clickHandler}
                ref={containerRef}
            >
                <animated.div
                    className={`deck ${classes.DeckCover} ${multipleCardsClass} ${
                        (editMode || disabled) && classes['disabled']
                    }`}
                    onMouseMove={(e) => {
                        if (!disabled && !editMode) {
                            const node = e.target as HTMLElement;
                            const rect = node.getBoundingClientRect();
                            const x = e.clientX - rect.left;
                            const y = e.clientY - rect.top;
                            set.start({ xys: calc(x, y, rect.width, rect.height) });
                        }
                    }}
                    onMouseLeave={() => {
                        set.start({ xys: [0, 0, 1] });
                    }}
                    style={{ transform: !disabled && !editMode ? props?.xys?.to(trans) : '' }}
                >
                    <div
                        className={`deck Box transform3djagggedlinesfix ${classes.Cover} ${
                            disabled || editMode ? '' : hoverClass
                        } ${multipleCardsClass} ${!deck.is_published && classes['concept']}`}
                        ref={deckRef}
                    >
                        <div className={classes.CoverImage}>
                            <CoverImage
                                src={getFilePathByName(deck.cover_image?.name) || defaultCover}
                                backgroundSize={deck.cover_background_size}
                            ></CoverImage>
                            {hasPermission('concept.view') && (
                                <div className={classes.PublicationStateLabel}>
                                    <PublicationStateLabel isPublished={deck.is_published} />
                                </div>
                            )}
                        </div>
                    </div>

                    {multipleCards && (
                        <div
                            className={`deck Box transform3djagggedlinesfix ${classes.DeckCoverExtra} ${
                                disabled || editMode ? '' : hoverClass
                            } ${multipleCardsClass}`}
                        >
                            <div className={classes.CoverImage}>
                                <CoverImage
                                    src={getFilePathByName(deck.cover_image?.name) || defaultCover}
                                    backgroundSize={deck.cover_background_size}
                                ></CoverImage>
                            </div>
                        </div>
                    )}
                </animated.div>
            </div>
            {!editMode && (
                <div className={` ${classes.Header}`}>
                    <h3
                        className={`${classes.Title} wrap-text-overflow-2 ${disabled && classes.FilterActive}`}
                    >{`${deck.title}`}</h3>
                    <div className={`${classes.TagsList} ${disabled && classes.FilterActive}`}>
                        {deck.tags?.map((tag) => {
                            return (
                                <div key={`tag_${tag.id}`} className={classes.TagItem}>
                                    {tag.title}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.forwardRef(DeckCover);
