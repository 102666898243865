import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useConfig } from '../../context/ConfigContext';
import { useTheme } from '../../context/ThemeContext';
import { api_scheme } from '../../utils/axios-instance';
import classes from './StyleSheet.module.scss';

const StyleSheet: FunctionComponent = (props) => {
    const { theme } = useTheme();
    const { loadingConfig, config } = useConfig();
    const [resetKey, setResetKey] = useState(0);

    useEffect(() => {
        if (theme.css != '') {
            setResetKey((val) => val + 1);
        }
    }, [theme]);

    return (
        <>
            {!loadingConfig && theme.css.length > 0 && resetKey !== 0 && (
                <link
                    rel="stylesheet"
                    type="text/css"
                    href={`${api_scheme}://${process.env.REACT_APP_API_DOMAIN}${config.baseUrl}/style/active/css?version=${resetKey}`}
                />
            )}
        </>
    );
};

export default StyleSheet;
