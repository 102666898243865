import { faCalendarAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import CoverImage from '../../../components/CoverImage/CoverImage';
import PageHeader from '../../../components/PageHeader/PageHeader';
import QuestContinueActions from '../../../components/QuestContinueActions/QuestContinueActions';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigContext';
import { useQuest } from '../../../context/QuestContext';
import { IDeck } from '../../../models/Deck';
import { getFilePathByName, getFilePathForDefaultImage } from '../../../models/File';
import { IQuest } from '../../../models/Quest';
import { compactNumber } from '../../../utils/compactNumber';
import { timeLeftTillNow } from '../../../utils/date';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import GroupsQuestProgress from './GroupsQuestProgress/GroupsQuestProgress';
import PersonalProgressBar from './PersonalProgressBar/PersonalProgressBar';
import classes from './QuestDetail.module.scss';
import TaskList from './TaskList/TaskList';

type QuestDetailProps = {
    questData?: IQuest;
};

type ParamTypes = {
    questId: string;
};

export type ActionButtonState = 'start' | 'continue';

const QuestDetail: FunctionComponent<QuestDetailProps> = () => {
    const { loading: loadingConfig, config } = useConfig();
    const location = useLocation();
    const history = useHistory();
    const { hasPermission } = useAuth();
    const { t } = useTranslation();
    const { questId } = useParams<ParamTypes>();
    const [questAPIUrl, setQuestAPIUrl] = useState<string>(() => {
        return questId;
    });
    const { response, loading } = useAxiosFetch<IQuest>(`${config.baseUrl}/quests/${questAPIUrl}`);
    const { quest, setQuest } = useQuest();
    let actionButtonState: ActionButtonState = 'start';
    const [defaultCover, setDefaultCover] = useState('');

    const canManage = hasPermission('library.manage');

    const timeLeftClass =
        quest.start_date && quest.end_date ? timeLeftTillNow(quest.start_date, quest.end_date) : 'undefinedLeft';

    const questHasProgress = quest.decks.find((deck) => deck.deckSession) !== undefined;
    // const tasksTotal = questHasProgress
    //     ? quest.decks
    //           .map((deck) => deck.deckSession?.cards_total)
    //           .reduce((a, b) => {
    //               return a && b ? a + b : 0;
    //           }) || 0
    //     : 0;

    const tasksTotal = quest.user_progress?.[0]?.total || quest.decks.length;
    const tasksCompleted = quest.user_progress?.[0]?.completed || 0;

    const sessionTasksCompleted = questHasProgress
        ? quest.decks.map((deck) => (deck.deckSession ? +deck.deckSession.is_complete : 0)).reduce((a, b) => a + b)
        : 0;

    const tasksIsCompleted = tasksCompleted === tasksTotal;
    const tasksProgress = Math.min(Math.max(tasksCompleted / tasksTotal, 0), 1);

    useEffect(() => {
        if (!loadingConfig) {
            const customFile = config.tenant.images['quest.png']?.find((file) => file.is_default == false);
            const defaultFile = config.tenant.images['quest.png']?.find((file) => file.is_default == true);
            setDefaultCover(
                getFilePathByName(customFile?.file?.name) || getFilePathForDefaultImage(defaultFile?.file?.name) || '',
            );
        }
    }, [loadingConfig]);

    const calendar = (
        <div className={`${classes.QuestStat} ${classes.Calendar}`}>
            {quest.time_state === 'in_progress' && !tasksIsCompleted && (
                <span className={`${classes.QuestTime} ${classes[quest.time_state]} ${classes[timeLeftClass]}`}>
                    <FontAwesomeIcon className={classes.CalendarIcon} icon={faCalendarAlt} />
                    {`${quest.distanceToNowFormatted}`}
                </span>
            )}
        </div>
    );

    const participants = (
        <div className={`${classes.QuestStat} ${classes.Participants}`}>
            <FontAwesomeIcon className={classes.PeopleIcon} icon={faUser} />
            <span className={`${classes.QuestStatLabel}`}>{compactNumber(quest.quest_progress.totalParticipants)}</span>
        </div>
    );

    useEffect(() => {
        // Set questId so loading will start
        if (questId) {
            setQuestAPIUrl(questId);
        }

        // Set Quest from State
        const quest = location.state as IQuest;
        if (quest) {
            setQuest(quest);
        }
    }, []);

    useEffect(() => {
        if (response) {
            const questData = response.data;
            setQuest(questData);
        }
    }, [response]);

    const taskButtonOnClickHandler = (task: IDeck) => {
        history.push({ pathname: `/deck/${task.id}`, state: { deck: task } });
    };

    const editHandler = () => {
        history.push({ pathname: `/quests/edit/${quest.id}`, state: quest });
    };

    const taskToContinue = quest.decks.find((deck) => {
        const deckCompleted = deck.deckSession?.is_complete;
        if (!deckCompleted || deckCompleted == undefined) {
            return deck;
        }
    });

    const actionButtonText = () => {
        if (!questHasProgress) {
            actionButtonState = 'start';
            return t('Quests:START');
        }

        if (tasksTotal > 0) {
            if (sessionTasksCompleted === 0) {
                actionButtonState = 'start';
                return t('Quests:START');
            } else if (sessionTasksCompleted >= 1 && sessionTasksCompleted < tasksTotal) {
                actionButtonState = 'continue';
                return t('Quests:CONTINUE');
            }
        }

        actionButtonState = 'continue';
        return t('Quests:CONTINUE');
    };

    const actionButtonOnClickHandler = () => {
        // TODO: Change deck to task.
        switch (actionButtonState) {
            case 'start':
            case 'continue':
                if (taskToContinue) {
                    history.push({ pathname: `/deck/${taskToContinue.id}`, state: taskToContinue });
                }
        }
    };

    return (
        <Fragment>
            <div className={classes.QuestDetail}>
                <PageHeader
                    title={quest.title}
                    editHandler={hasPermission('quest.manage') && isDesktop ? editHandler : undefined}
                    subtitle={quest.description}
                >
                    <div className={`${classes.QuestStats}`}>
                        {quest.is_timed ? calendar : ''}
                        {participants}
                    </div>
                </PageHeader>

                {loading && (
                    <div className={classes.Loader}>
                        <div className="spinner card Box"></div>
                    </div>
                )}

                {!loading && (
                    <div className={classes.QuestDetailContent}>
                        <div className={classes.Container}>
                            <div className={`Box ${classes.Description}`}>
                                <div className={classes.DescriptionContent}>
                                    {ReactHtmlParser(quest.description_full)}
                                </div>
                            </div>

                            <GroupsQuestProgress quest={quest}></GroupsQuestProgress>
                        </div>

                        <div className={classes.QuestCover}>
                            <div className={`Box ${classes[quest.time_state]} ${classes.QuestCoverCard}`}>
                                <div className={`${classes.CoverImage}`}>
                                    <CoverImage
                                        src={getFilePathByName(quest.cover_image?.name) || defaultCover}
                                        backgroundSize={quest.cover_background_size || 'cover'}
                                    ></CoverImage>
                                </div>

                                {(quest.time_state === 'in_progress' || quest.time_state === 'awaiting') && (
                                    <div className={`${classes.TasksListContainer}`}>
                                        <PersonalProgressBar
                                            progress={tasksProgress}
                                            timeState={quest.time_state}
                                            quest={quest}
                                        ></PersonalProgressBar>

                                        <TaskList
                                            questTimeState={quest.time_state}
                                            taskToContinueID={taskToContinue?.id}
                                            decks={quest.decks}
                                            clickHandler={taskButtonOnClickHandler}
                                        />

                                        {(canManage || (taskToContinue && quest.time_state === 'in_progress')) && (
                                            <QuestContinueActions
                                                buttonText={actionButtonText() || ''}
                                                clickContinueHandler={actionButtonOnClickHandler}
                                            ></QuestContinueActions>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default QuestDetail;
