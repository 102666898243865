import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faCircleExclamation, faCopy, faHouse, faPen, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDeck } from '../../../context/DeckContext';
import { ICard } from '../../../models/Card';
import Button from '../../Button/Button';
import ExtendedMuiList from '../../ExtendedMuiList/ExtendedMuiList';
import classes from './CardsList.module.scss';

interface CardsListProps {
    items: ICard[];
    isEdit: boolean;

    onCopyCardById?: (id: number) => void;
    onEditCardById?: (id: number | undefined) => void;
    // onVisitCardById?: (card: ICard | undefined) => void;
    onDeleteCardById?: (card: ICard | undefined) => void;
}

const CardsList: FunctionComponent<CardsListProps> = ({
    items,
    isEdit,
    onCopyCardById,
    onEditCardById,
    // onVisitCardById,
    onDeleteCardById,
}) => {
    const history = useHistory();
    const { deck } = useDeck();
    const { t } = useTranslation();

    const onVisitCardById = (card: ICard) => {
        history.push({ pathname: `/deck/${deck.id}`, state: { deck, currentCardId: card.id } });
    };

    const renderCardItem = (item: ICard) => (
        <div key={`card_${item.id}`} className={`${classes.Item} ${isEdit ? classes['isEdit'] : ''}`}>
            <div>
                <div className={classes.ItemContent}>{`${item.title}`}</div>
                {item.start_card ? (
                    <div className={classes.StartCard}>
                        <FontAwesomeIcon icon={faHouse} />
                    </div>
                ) : (
                    ''
                )}

                {item.source_of_edges.length === 0 && (
                    <div className={classes.EndCard}>
                        <FontAwesomeIcon icon={faCircleExclamation} />
                    </div>
                )}
            </div>

            <div>
                <Button
                    text=""
                    border
                    onClick={() => onVisitCardById(item)}
                    icon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
                ></Button>

                {!deck.is_published && (
                    <Button
                        text=""
                        border
                        icon={<FontAwesomeIcon icon={faCopy} />}
                        onClick={() => onCopyCardById?.(item.id)}
                    />
                )}

                <Button
                    text=""
                    border
                    icon={<FontAwesomeIcon icon={faPen}></FontAwesomeIcon>}
                    onClick={() => onEditCardById?.(item.id)}
                ></Button>

                {items.length > 1 && !deck.is_published && (
                    <Button
                        text=""
                        border
                        danger
                        icon={<FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>}
                        onClick={() => onDeleteCardById?.(item)}
                        disabled={item.start_card === true}
                    ></Button>
                )}
            </div>
        </div>
    );

    const filterDataBySearch = (searchValues: string[]) => {
        const cards = items || [];

        return cards.filter((card) => {
            const title = card.title;

            const searchableValues = { title };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((card) => card.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    return (
        <ExtendedMuiList
            loading={false}
            filterDataBySearch={filterDataBySearch}
            searchInputLabel={t('Common:CARDS_SEARCH_INPUT_LABEL')}
            noItemsMessage={t('Common:LIST_MESSAGE_NO_CARDS')}
            noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_CARDS')}
            items={items || []}
            renderItem={renderCardItem}
            itemsListClasses={classes.CardsList}
        />
    );
};

export default memo(CardsList);
