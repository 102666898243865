import { faExclamation, faImages, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gsap } from 'gsap';
import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import AnimatedNumber from '../../../components/AnimatedNumber/AnimatedNumber';
import CircleIcon from '../../../components/Icons/CircleIcon/CircleIcon';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { useConfig } from '../../../context/ConfigContext';
import { useProfile } from '../../../context/ProfileContext';
import { IProfile } from '../../../models/User';
import { formatDateToLocal } from '../../../utils/date';
import useAxiosFetch from '../../../utils/useAxiosFetch';
import GroupCover, { IncrementedRef } from '../../Groups/GroupsDetail/GroupCover/GroupCover';
import ProfileCover from '../ProfileCover/ProfileCover';
import classes from './ProfileDetail.module.scss';

type ProfileDetailProps = {
    isAuthUser?: boolean;
    profileData?: IProfile;
};

type ParamTypes = {
    profileId: string;
};

const ProfileDetail: FunctionComponent<ProfileDetailProps> = ({ isAuthUser = true }) => {
    const { config } = useConfig();
    const location = useLocation();
    const history = useHistory();
    const { profileId } = useParams<ParamTypes>();
    const [profileAPIUrl, setProfileAPIUrl] = useState<string>(() => {
        return profileId;
    });
    const { response, loading, sendRequest } = useAxiosFetch<IProfile>(
        isAuthUser ? `${config.baseUrl}/user` : `${config.baseUrl}/users/${profileAPIUrl}`,
    );
    const { profile, setProfile, isDataChanged, setIsDataChanged, updateProfile } = useProfile();
    const [updatedAt, setUpdatedAt] = useState<string>('');

    const { t } = useTranslation();

    const tl = gsap.timeline();
    const list = useRef<IncrementedRef[]>([]);
    const [refOnce, setRefOnce] = useState(false);

    useEffect(() => {
        // Set profileId so loading will start
        if (profileId) {
            setProfileAPIUrl(profileId);
        }

        // Set Profile from State
        const profile = location.state as IProfile;
        if (profile) {
            setProfile(profile);
        }
    }, []);

    useEffect(() => {
        if (isDataChanged) {
            sendRequest();
            setIsDataChanged(false);
        }
    }, [location, isDataChanged]);

    useEffect(() => {
        if (response) {
            const profileData = response.data;
            if (profileData) {
                updateProfile(profileData);
                if (profileData.updated_at) {
                    setUpdatedAt(
                        `${t('Common:MODIFIED', {
                            date: formatDateToLocal(profileData.updated_at, 'd MMMM HH:mm'),
                        })}`,
                    );
                }
            }
        }
    }, [response]);

    const animateInList = (listReference: IncrementedRef[]) => {
        const l = listReference.map((el) => el.current);
        tl.set(l, { alpha: 0, scale: 0.7 });
        tl.to(l, {
            duration: 0.25,
            alpha: 1,
            scale: 1,
            ease: 'back',
            stagger: {
                amount: 0.35,
            },
        });
    };

    const setRefs = (target: IncrementedRef | null, listReference: IncrementedRef[], itemIndex: number) => {
        if (target && listReference && !refOnce) {
            const ref = (list.current[itemIndex] = target);
            if (itemIndex === profile.groups.length - 1) {
                // All refs are set for this list. So animation is possible.
                animateInList(listReference);
                setRefOnce(true);
            }
            return ref;
        }
    };

    const groupsCoverList = profile.groups.map((group, i) => {
        return (
            <GroupCover
                group={group}
                key={group.id}
                ref={(target) => {
                    setRefs(target, list.current, i);
                }}
                clickHandler={() => {
                    history.push({ pathname: `groups/${group.id}`, state: group });
                }}
            ></GroupCover>
        );
    });

    const editHandler = () => {
        history.push({ pathname: `/profile/edit/${profile.id}`, state: profile });
    };

    return (
        <Fragment>
            <div className={classes.ProfileDetail}>
                <PageHeader
                    title={isAuthUser ? t('Common:PROFILE_TITLE') : profile.name}
                    subtitle={t('Common:PROFILE_SUBTITLE')}
                    rightAlignedText={updatedAt}
                    editHandler={profile.access_code_id === null ? editHandler : undefined}
                ></PageHeader>

                {loading && (
                    <div className={classes.Loader}>
                        <div className="spinner card Box"></div>
                    </div>
                )}

                {!loading && (
                    <div className={`${classes.Container}`}>
                        <div className={`${classes.FlexContainer}`}>
                            <div className={`Box ${classes.Content} ${classes.Element}`}>
                                {profile.description != '' && (
                                    <Fragment>
                                        <div className={`${classes.ElementHeader}`}>
                                            <h2 className={classes.ElementTitle}>
                                                {t('Common:PROFILE_DESCRIPTION_TITLE')}
                                            </h2>
                                        </div>
                                        <div className={classes.ElementContent}>{profile.description}</div>
                                    </Fragment>
                                )}

                                {profile.user_statistics && (
                                    <div className={classes.UserStatistics}>
                                        <div className={classes.UserStatistic}>
                                            <CircleIcon className={classes.CardsIcon}>
                                                <FontAwesomeIcon icon={faImages} />
                                            </CircleIcon>
                                            <div className={classes.UserStatisticContent}>
                                                <div className={classes.UserStatisticsHeader}>
                                                    <h3 className={classes.UserStatisticsTitle}>
                                                        {t('Common:PROFILE_USERSTATISTIC_TITLE_CARDS')}
                                                    </h3>
                                                    <div className={classes.UserStatisticsSubtitle}>
                                                        {t('Common:PROFILE_USERSTATISTIC_SUBTITLE_CARDS')}
                                                    </div>
                                                </div>
                                                <div className={classes.UserStatisticsValue}>
                                                    <AnimatedNumber
                                                        value={profile.user_statistics?.cardsCompleted}
                                                        fixed={true}
                                                    ></AnimatedNumber>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.UserStatistic}>
                                            <CircleIcon className={classes.QuestIcon}>
                                                <FontAwesomeIcon icon={faMapMarkedAlt} />
                                            </CircleIcon>
                                            <div className={classes.UserStatisticContent}>
                                                <div className={classes.UserStatisticsHeader}>
                                                    <h3 className={classes.UserStatisticsTitle}>
                                                        {t('Common:PROFILE_USERSTATISTIC_TITLE_QUESTS')}
                                                    </h3>
                                                    <div className={classes.UserStatisticsSubtitle}>
                                                        {t('Common:PROFILE_USERSTATISTIC_SUBTITLE_QUESTS')}
                                                    </div>
                                                </div>
                                                <div className={classes.UserStatisticsValue}>
                                                    <AnimatedNumber
                                                        value={profile.user_statistics?.questsCompleted}
                                                        fixed={true}
                                                    ></AnimatedNumber>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes.UserStatistic}>
                                            <CircleIcon className={classes.QuestionsCorrectIcon}>
                                                <FontAwesomeIcon icon={faExclamation} />
                                            </CircleIcon>
                                            <div className={classes.UserStatisticContent}>
                                                <div className={classes.UserStatisticsHeader}>
                                                    <h3 className={classes.UserStatisticsTitle}>
                                                        {t('Common:PROFILE_USERSTATISTIC_TITLE_QUESTIONS')}
                                                    </h3>
                                                    <div className={classes.UserStatisticsSubtitle}>
                                                        {t('Common:PROFILE_USERSTATISTIC_SUBTITLE_QUESTIONS')}
                                                    </div>
                                                </div>
                                                <div className={classes.UserStatisticsValue}>
                                                    <AnimatedNumber
                                                        value={profile.user_statistics?.questionPercentageCorrect}
                                                        fixed={true}
                                                    ></AnimatedNumber>
                                                    <span>%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <ProfileCover className={classes.Cover}></ProfileCover>
                        </div>
                        {profile.groups.length != 0 && (
                            <Fragment>
                                <div className={`${classes.Header}`}>
                                    <h2 className={`h3 ${classes.Title}`}>
                                        {profile.groups.length === 1
                                            ? t('Common:MY_GROUP_TITLE')
                                            : t('Common:MY_GROUPS_TITLE')}
                                    </h2>
                                </div>
                                <div className={`${classes.Groups} ${classes.GroupList}`}>{groupsCoverList}</div>
                            </Fragment>
                        )}
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default ProfileDetail;
