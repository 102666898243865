import React, { FunctionComponent } from 'react';
import classes from './CoverImage.module.scss';

export interface CoverImageProps {
    src: string;
    backgroundSize?: string;
    backgroundPosition?: string;
    backgroundRepeat?: string;
}

const CoverImage: FunctionComponent<CoverImageProps> = ({
    src,
    backgroundSize = 'cover',
    backgroundPosition = 'center',
    backgroundRepeat = 'no-repeat',
}) => {
    return (
        <div
            className={`${classes.CoverImage}`}
            style={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                backgroundImage: `url(${src})`,
                backgroundSize: backgroundSize,
                backgroundPosition: backgroundPosition,
                backgroundRepeat: backgroundRepeat,
            }}
        ></div>
    );
};

export default CoverImage;
